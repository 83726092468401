import React, { useState } from 'react';
import { Confirm, useRefresh, useNotify, useRedirect } from 'react-admin';
import FileCopy from '@material-ui/icons/FileCopy';

import IconButton from '../common/IconButton';

import { clonePackage } from '../../services/api';


const PackageCloneButton = ({ record, variant, color, ...props }) => {
    const refresh = useRefresh();
    const notify = useNotify();
    const redirect = useRedirect();
    const [showDialog, setShowDialog] = useState(false);

    const handleConfirm = async () => {
        const response = await clonePackage(record.id);
        if (response.id) {
            refresh();
            notify('Package Cloned');
            if (props.redirect) {
                redirect('show', '/packages', response.id);
            }
        }

        handleClose();
    };

    const handleOpen = () => {
        setShowDialog(true);
    };

    const handleClose = () => {
        setShowDialog(false);
    };

    if (!record) {
        return null;
    }

    return (
        <>
            <IconButton icon={<FileCopy />} onClick={handleOpen} variant={variant} color={color}>Clone</IconButton>
            <Confirm
                isOpen={showDialog}
                title={`Clone package #${record.reference}`}
                content={`Are you sure you want to clone this package?`}
                onConfirm={handleConfirm}
                onClose={handleClose}
            />
        </>
    );
};

export default PackageCloneButton;
