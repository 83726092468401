import React from 'react';
import { TextInput, NumberInput, ReferenceInput, AutocompleteInput, FormDataConsumer, required } from 'react-admin';

import Create from '../common/Create';
import SimpleForm from '../common/SimpleForm';
import CurrencyInput from '../common/inputs/CurrencyInput';


const optionText = choice => choice.name ? choice.name : '';

const DataPoolCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <TextInput source="reference" />
            <TextInput source="customer_reference" />
            <ReferenceInput label="Customer" source="customer_id" reference="customers" sort={{ field: 'name', order: 'ASC' }}>
                <AutocompleteInput optionText={optionText} />
            </ReferenceInput>
            <FormDataConsumer>
                {({ formData, ...rest }) => (
                    <ReferenceInput label="Billing Address" source="invoicing_address_id" reference="addresses" filter={formData.customer_id && { customer_id: formData.customer_id }} {...rest} validate={required()}>
                        <AutocompleteInput optionText={optionText} />
                    </ReferenceInput>
                )}
            </FormDataConsumer>
            <ReferenceInput sort={{ field: 'name', order: 'ASC' }} label="Billing company" source="billing_company_id" reference="companies" allowEmpty>
                <AutocompleteInput optionText={optionText} />
            </ReferenceInput>
            <NumberInput source="data_included" validate={required()} />
            <NumberInput source="data_price" validate={required()} />
            <NumberInput source="data_extra_price" validate={required()} />
            <CurrencyInput source="currency" />
        </SimpleForm>
    </Create>
);

export default DataPoolCreate;