import React from 'react';
import { DateInput, Query, ListButton, ShowButton, Title, Toolbar, SaveButton } from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import { useHistory, useParams } from 'react-router-dom';
import { useFormState } from 'react-final-form';

import SimpleForm from '../common/SimpleForm';
import Breadcrumb from '../common/Breadcrumb';
import CardActions from '../common/CardActions';
import services from './index';

import { deliverService } from '../../services/api';


const Actions = ({ record, breadcrumbPath }) => (
    <CardActions>
        <Breadcrumb path={breadcrumbPath} />
        <div>
            <ListButton basePath="/services" />
            <ShowButton basePath="/services" record={record} />
        </div>
    </CardActions>
);

const CustomToolbar = ({ onSave, disabled, serviceId, ...props }) => {
    const formState = useFormState();

    const handleClick = async () => {
        if (formState.valid) {
            let data = new FormData();
            data.append("date", formState.values.date);

            try {
                const response = await deliverService(serviceId, data);
                if (response.id && onSave) {
                    onSave();
                }
            }
            catch (e) {
                console.error(e);
            }
        }
    };

    return (
        <Toolbar {...props}>
            <SaveButton handleSubmit={handleClick} handleSubmitWithRedirect={handleClick} disabled={disabled} />
        </Toolbar>
    );
};

const ServiceDeliver = () => {
    const history = useHistory();
    const { service_id } = useParams();
    const id = service_id;

    const handleSave = () => {
        history.push(`/services?filter={'status':'shipped', 'queue':'delivery'}`);;
    }

    return (
        <Query type="GET_ONE" resource="services" payload={{ id: id }}>
            {({ data }) => {
                if (!data) {
                    return null;
                }

                // const type = data?.type?.toUpperCase();
                const disabled = data.status !== 'SHIPPED';
                const breadcrumbPath = [
                    { url: '/services', title: "Services", icon: <services.icon /> },
                    {
                        url: `/services/${data.id}/show`,
                        title: (data ? data.name : data.id)
                    },
                    {
                        url: `/services/${data.id}/deliver`,
                        title: 'Deliver',
                        isActive: true
                    }
                ];

                return (
                    <>
                        <Title title="Deliver" />
                        <CardContent>
                            <Actions record={data} breadcrumbPath={breadcrumbPath} />
                        </CardContent>
                        <Card>
                            <SimpleForm redirect="show" record={data} resource="services" toolbar={<CustomToolbar onSave={handleSave} disabled={disabled} serviceId={id} />}>
                                <DateInput label="Delivery Date" source="date" />
                            </SimpleForm>
                        </Card>
                    </>
                );
            }}
        </Query>
    );
};

export default ServiceDeliver;