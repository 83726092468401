import RouterIcon from '@material-ui/icons/Router';

import PackageLineList from './PackageLineList';
import PackageLineShow from './PackageLineShow';
import PackageLineCreate from './PackageLineCreate';
import PackageLineEdit from './PackageLineEdit';

export default {
	list: PackageLineList,
	show: PackageLineShow,
	edit: PackageLineEdit,
	create: PackageLineCreate,
	icon: RouterIcon,
	title: 'PackageLines'
};
