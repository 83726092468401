import React from 'react';
import { AutocompleteInput } from 'react-admin';

const choices = [
    { id: 'bank_transfer', name: 'Bank transfer' },
    { id: 'direct_debit', name: 'Direct debit' }
];

const PaymentMethodInput = ({ classes, record, source = "payment_method", ...props }) => {
    if (record && record[source] && !choices.find((item) => item.id === record[source])) {
        choices.push({ id: record[source], name: record[source] });
    }

    return (
        <AutocompleteInput choices={choices} source={source} record={record} allowEmpty {...props} />
    );
};

export default PaymentMethodInput;
