import SettingsInputAntennaIcon from '@material-ui/icons/SettingsInputAntenna';

import StarlinkServiceLineList from './StarlinkServiceLineList';
import StarlinkServiceLineShow from './StarlinkServiceLineShow';

export default {
	list: StarlinkServiceLineList,
	show: StarlinkServiceLineShow,
	icon: SettingsInputAntennaIcon,
	title: "Starlink service lines"
};
