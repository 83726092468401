import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Search } from 'semantic-ui-react';
import { makeStyles } from '@material-ui/core/styles';
import orange from '@material-ui/core/colors/orange';

import Chip from './fields/Chip';

import { search } from '../../services/api';

import 'semantic-ui-css/components/search.css';
import 'semantic-ui-css/components/icon.css';
import 'semantic-ui-css/components/input.css';

const useStyles = makeStyles(theme => ({
	root: {
		flex: 1,
		marginLeft: '25px',
		color: 'white',

		'&.ui.category.search .results': {
			'& .result': {
				'& .title': {
					display: 'flex',
					alignItems: 'center',
					'& span + div': {
						marginLeft: '10px',
						fontWeight: 'normal'
					}
				}
			}
		},
		
		...theme.palette.type === 'dark' && {
			'&&.ui .input input': {
				backgroundColor: theme.palette.background.paper,
				color: '#FFF',
				'& + i': {
					color: '#FFF'
				},
				'&:focus': {
					borderColor: '#FFF'
				}
			},
			'&&.ui.category.search .results': {
				backgroundColor: theme.palette.background.paper,
				color: theme.palette.text.primary,

				'& .category': {
					color: theme.palette.text.secondary,
					background: theme.palette.background.default,

					'& .name': {
						color: theme.palette.text.primary
					},

					'& .results': {
						borderLeft: '1px solid rgba(255, 255, 255, 0.15)',
    					borderBottom: '1px solid rgba(255, 255, 255, 0.1)'
					}
				},

				'& .result': {
					color: theme.palette.text.primary,

					'&:hover': {
						background: 'transparent'
					},
					'& .title': {
						color: theme.palette.text.primary,
					},
					'& .description': {
						color: theme.palette.text.secondary,
					}
				},
				'& .message .header': {
					color: theme.palette.text.primary
				}
			}
		},

		'& .results .description': {
			whiteSpace: 'pre'
		}
	}
}));

const resultRenderer = ({ title, description, archived_at, in_stock }) => (
	<div className='content'>
		{title && (
			<div className='title'>
				<span>{title}</span>
				{archived_at && <Chip label="Archived" size="small" />}
				{in_stock && <Chip label="Stock" size="small" color={orange[300]} />}
			</div>
		)}
		{description && <div className='description'>{description}</div>}
	</div>
);

const SearchBar = ({ staticContext, ...props }) => {
	const [value, setValue] = useState('');
	const [results, setResults] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const classes = useStyles();

	const handleResultSelect = (e, { result }) =>  {
		props.history.push(result.url);
		setValue('');
		setResults([]);
	};

	const handleSearchChange = async (e, { value }) => {
		setValue(value);
		setIsLoading(true);
		setResults([])
		
		const results = await search(value);
		setIsLoading(false);
		setResults(results);
	}

	return (
		<Search
			category
			minCharacters={2}
			loading={isLoading}
			onResultSelect={handleResultSelect}
			onSearchChange={handleSearchChange}
			results={results}
			value={value}
			placeholder="Search..."
			className={classes.root}
			resultRenderer={resultRenderer}
			{...props}
		/>
	)
}

export default withRouter(SearchBar);
