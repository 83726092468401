import React from 'react';
import { SelectInput } from 'react-admin';


const choices = [
	{ id: 'OTHER', name: 'OTHER' },
	{ id: 'TO PROVISION', name: 'TO PROVISION'},
	{ id: 'TO MOVE', name: 'TO MOVE' },
	{ id: 'TO RETURN', name : 'TO RETURN'},
	{ id: 'TO CANCEL', name: 'TO CANCEL' },
	{ id: 'TO DECOMMISSION', name: 'TO DECOMMISSION' },
	{ id: 'TO ORDER', name: 'TO ORDER' },
	{ id: 'TO TRANSFER', name: 'TO TRANSFER' },
];

const ActionField = ({ classes, record, source="action", ...props }) => {
	if (!record) {
		return null;
	}

	return (
		<SelectInput choices={choices} source={source} record={record} {...props} />
	);
};

export default ActionField;
