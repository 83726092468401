import React from 'react';

import { AutocompleteInput, FormTab, ReferenceInput, required, TextInput } from 'react-admin';
import Create from '../common/Create';
import ColorInput from '../common/inputs/ColorInput';
import TabbedForm from '../common/TabbedForm';
import CustomerSupportField from './CustomerSupportField';


const CustomerCreate = (props) => (
    <Create {...props}>
        <TabbedForm>
            <FormTab label="summary">
                <TextInput source="name" validate={required()} />
                <TextInput source="short_name" />
                <ReferenceInput label="Account Manager" source="account_manager_id" reference="users" filter={{ is_staff: true }} sort={{ field: 'firstname', order: 'ASC' }}>
                    <AutocompleteInput optionText={choice => choice && choice.fullname ? choice.fullname : ''} />
                </ReferenceInput>
                <CustomerSupportField />
                <TextInput source="default_data_limit_contacts" />
                <TextInput source="description" label="Notes" options={{ multiline: true }} rows="4" />
            </FormTab>
            <FormTab label="Theme">
                <ColorInput source="primary_color" />
                <ColorInput source="secondary_color" />
                {/* TODO: add logo */}
            </FormTab>
        </TabbedForm>
    </Create>
);

export default CustomerCreate;
