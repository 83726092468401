import React, { useState } from 'react';
import { List as ReactAdminList, Pagination, CreateButton, ExportButton, Confirm, useRefresh, useNotify, useUnselectAll, useMutation, useDeleteMany } from 'react-admin';
import IconUndo from '@material-ui/icons/Undo';
import DeleteIcon from '@material-ui/icons/Delete';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';

import CardActions from './CardActions';
import IconButton from './IconButton';
import { UNARCHIVE_MANY } from '../../provider';


const useStyles = makeStyles({
	bulkActionsDisplayed: {
		marginTop: 0
	}
});


const ListActions = ({ basePath, bulkActions, filterValues, permanentFilter, selectedIds, onUnselectItems, filters, showFilter, displayedFilters, total, currentSort, exporter, className, children, resource, hasCreate }) => {
	return (
		<CardActions className={className}>
			{bulkActions && React.cloneElement(bulkActions, {
				basePath,
				filterValues,
				resource,
				selectedIds,
				onUnselectItems,
			})}
			{filters && React.cloneElement(filters, {
				resource,
				showFilter,
				displayedFilters,
				filterValues,
				context: 'button',
			})}
			{children}
			{hasCreate && <CreateButton basePath={basePath} />}
			{exporter && (
				<ExportButton
					disabled={total === 0}
					resource={resource}
					sort={currentSort}
					filter={{ ...filterValues, ...permanentFilter }}
					exporter={exporter}
					maxResults={5000}
				/>
			)}
		</CardActions>
	);
};

const BulkActions = ({ dataProvider, ...props }) => {
	const refresh = useRefresh();
	const notify = useNotify();
	const unselectAll = useUnselectAll();
	const [showDialog, setShowDialog] = useState(false);

	const [unarchive, { loading }] = useMutation(
		{
			type: UNARCHIVE_MANY,
			resource: props.resource,
			payload: { ids: props.selectedIds }
		},
		{
			onSuccess: ({ data }) => {
				unselectAll(props.resource);
				refresh();
				notify(`${data.length} unarchived element${data.length < 2 ? '' : 's'}`);
			},
			onFailure: (error) => {
				unselectAll(props.resource);
				notify(error.message, 'warning');
			}
		}
	);
	
	const [archive, { loadingArchive }] = useDeleteMany(
		props.resource,
		props.selectedIds,
		{
			onSuccess: ({ data }) => {
				let reasons = '';
				const archivedCount = data.reduce((acc, item) => {
					if (item.reason) {
						reasons += `\n${item.reason}`;
					}
					return !item.reason ? acc + 1 : acc
				}, 0);
				unselectAll(props.resource);
				if(reasons) {
					reasons = `\n\n${props.selectedIds.length - archivedCount} element${props.selectedIds.length - archivedCount < 2 ? '' : 's'} not archived:` + reasons;
				}
				notify(`${archivedCount} archived element${archivedCount < 2 ? '' : 's'}${reasons}`);
				if(archivedCount > 0) {
					refresh();
				}
			},
			onFailure: (error) => notify(`Error: ${error.message}`, 'warning'),
		}
	);

	const handleConfirm = () => {
		handleClose();
		archive();
	};

	const handleOpen = () => {
		setShowDialog(true);
	};

	const handleClose = () => {
		setShowDialog(false);
	};

	return (
		<>
			{props.filterValues.archived ? (
				<IconButton onClick={unarchive} icon={<IconUndo />} disabled={loading}>Unarchive</IconButton>
			) :(
				<>
					<IconButton onClick={handleOpen} icon={<DeleteIcon />} disabled={loadingArchive} color="secondary">Archive</IconButton>
						<Confirm
							isOpen={showDialog}
							title={`Archive ${props.selectedIds.length} ${props.resource}`}
							content={`Are you sure you want to archive these ${props.selectedIds.length} items?`}
							onConfirm={handleConfirm}
							onClose={handleClose}
						/>
				</>
			)}
		</>
	);
};

const ListPagination = props => <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />

const List = ({ permanentFilter, ...props }) => {
	const classes = useStyles();

	return (
		<>
			<Helmet>
				<title>KB - {props.options?.label ? props.options.label : props.resource.charAt(0).toUpperCase() + props.resource.slice(1)}</title>
			</Helmet>
			<ReactAdminList
				actions={<ListActions permanentFilter={props.filter} />}
				classes={classes} 
				{...props}
			/>
		</>
	);
};

List.defaultProps = {
	bulkActionButtons: <BulkActions />,
	pagination: <ListPagination />,
	perPage: 25,
	sort:{ field: 'id', order: 'DESC' }
};

export default List;
export { ListActions, BulkActions, ListPagination };