import React from 'react';
import { TextField, FunctionField, NumberField, ShowButton } from 'react-admin';

import Datagrid from '../common/Datagrid';
import DateField from '../common/fields/DateField';


const SpeedTestRow = ({ children, ...props })  => (
	<Datagrid {...props}>
		<DateField source="created_at" showTime options={{ timeZone: 'Europe/Brussels' }} />
		<TextField source="dir" />
		<TextField source="device_serial" />
		<TextField source="device_name" />
		<TextField source="remote_serial" />
		<TextField source="remote_name" />
		<FunctionField label="Bandwidth" render={
            record => `${record.bandwidth} ${record.bandwidth_unit}`
        } />
		<NumberField source="retransmissions" label="retrans" />
		<FunctionField label="cwnd" render={
            record => `${record.cwnd} ${record.cwnd_unit}`
        } />
	
		{children}
		<ShowButton />
	</Datagrid>
);

export default SpeedTestRow;
