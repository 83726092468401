import { AUTH_LOGIN, AUTH_CHECK, AUTH_LOGOUT, AUTH_ERROR, AUTH_GET_PERMISSIONS } from 'react-admin';

import { API_URL } from './services/settings';

export default (type, params) => {
	if (type === AUTH_LOGIN) {
		const { username, password } = params;
		const request = new Request(`${API_URL}/account/login`, {
			method: 'POST',
			body: JSON.stringify({ login: username, password }),
			headers: new Headers({ 'Content-Type': 'application/json' }),
		});
		return fetch(request)
			.then(response => {
				if (response.status < 200 || response.status >= 300) {
					throw new Error(response.statusText);
				}
				return response.json();
			})
			.then(({ token, ephemeral_token, method_name, success, message, user }) => {
				if(!success)
					throw new Error(message);

				let permissions = user?.permissions || [];
				if (user?.is_admin) {
					permissions.push('is_admin');
				}
				localStorage.setItem('permissions', permissions);

				if (ephemeral_token) {
					return Promise.reject({
						ephemeralToken: ephemeral_token,
						methodName: method_name,
					});
				}

				localStorage.setItem('token', token);
			});
	}
	if (type === AUTH_LOGOUT) {
		localStorage.removeItem('token');
		localStorage.removeItem('permissions');
		return Promise.resolve();
	}
	if (type === AUTH_ERROR) {
		const { status } = params;
		if (status === 401 || status === 403) {
			localStorage.removeItem('token');
			return Promise.reject();
		}
		return Promise.resolve();
	}
	if (type === AUTH_CHECK) {
		return localStorage.getItem('token')
			? Promise.resolve()
			: Promise.reject();
	}
	if (type === AUTH_GET_PERMISSIONS) {
		let permissions = localStorage.getItem('permissions');
		if (permissions) {
			permissions = permissions.split(',');
		}
		return permissions ? Promise.resolve(permissions) : Promise.resolve([]);
	}
	return Promise.reject('Unknown method');
}