import React from 'react';
import {
	TabbedShowLayout,
	Tab,
	TextField,
	DateField,
	UrlField
} from 'react-admin';
import { Helmet } from 'react-helmet';

import Show, { ShowActions } from '../common/Show';
import ICServerActionsButtons from './ICServerActionsButtons';


const ICServerTitle = ({ record }) => (
	<>
		<Helmet>
			<title>KB - Server[{record?.name}]</title>
		</Helmet>
		<span>{record?.name}</span>
	</>
);

const ICServerActions = (props) => (
	<ShowActions {...props}>
		<ICServerActionsButtons record={props.data} />
	</ShowActions>
);

const ICServerShow = (props) => (
	<Show title={<ICServerTitle />} actions={<ICServerActions />} {...props}>
		<TabbedShowLayout>
			<Tab label="summary">
				<TextField label="Id" source="id" />
				<TextField source="name"/>
				<UrlField source="base_url" />
				<UrlField source="api_base_url" />
				<TextField source="description" />
				<TextField source="api_key" />
				<DateField source="expiration_date" />
				<TextField source="version" />
				<DateField label="Created at" source="created_at" showTime/>
				<DateField label="Updated at" source="updated_at" showTime/>
			</Tab>
		</TabbedShowLayout>
	</Show>
);

export default ICServerShow;
