import React from 'react';
import {
	TextField,
	SimpleShowLayout
} from 'react-admin';

import Datagrid from '../common/Datagrid';
import DateField from '../common/fields/DateField';
import LinkFieldButton from '../common/LinkFieldButton';
import RecordSplitButton from '../common/RecordSplitButton';
import CustomerReferenceField from '../customers/CustomerReferenceField';


const AddressDrawer = (props) => (
	<SimpleShowLayout {...props}>
		<TextField source="id" />
		<TextField source="name" />
		<CustomerReferenceField />
		<TextField source="street" />
		<TextField source="street_bis" />
		<TextField source="number" />
		<TextField source="box" />
		<TextField source="zipcode" />
		<TextField source="city" />
		<TextField source="country" />
		<TextField source="vat" />
		<TextField source="email" />
		<TextField source="phone" />
		<TextField source="payment_terms" />
	</SimpleShowLayout>
);

const AddressRow = ({ children, ...props }) => (
	<Datagrid drawer={<AddressDrawer {...props} />} {...props}>
		<LinkFieldButton label="Code" sortBy="_accounting_code">
			<TextField source="accounting_code" />
		</LinkFieldButton>
		<LinkFieldButton label="Name" sortBy="name">
			<TextField source="name" />
		</LinkFieldButton>
		<LinkFieldButton label="Customer" source="customer" basePath="/customers" sortable={false}>
			 <TextField source="name" />
		</LinkFieldButton>
		<TextField source="full_address" />
		<TextField source="vat" />
		<DateField source="updated_at" />
		<RecordSplitButton />
		{children}
	</Datagrid>
);

export default AddressRow;