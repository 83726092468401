import React from 'react';
import PropTypes from 'prop-types';
import WanTooltip from './WanTooltip';

const WanField = ({ source, record = {}, ...props }) =>  {
	if (!record || !record[source]) {
		return null;
	}

	return record[source].map(wan =>
		<WanTooltip record={wan} key={`wan_${wan.id}`} />
	);
};

WanField.propTypes = {
	label: PropTypes.string,
	record: PropTypes.object,
	source: PropTypes.string.isRequired,
};

export default WanField;