import React, { useState, useEffect } from 'react';
import { Link } from 'react-admin';
import { Grid, Card, CardHeader, Box, Button } from '@material-ui/core';

import RouterIcon from '@material-ui/icons/Router';

import IconCard from '../common/IconCard';

import DeviceStatsGraph from '../devices/DeviceStatsGraph';
import SimCardStatsGraph from '../simcards/SimCardStatsGraph';
import CustomList from '../common/CustomList';
import DeviceProductRow from '../device_products/DeviceProductRow';

import { fetchStockDashboardStats } from '../../services/api';

const StockDashboard = () => {
    const [stats, setStats] = useState();

    useEffect(() => {
        const fetchStats = async () => {
            const data = await fetchStockDashboardStats();
            setStats(data);
        }

        fetchStats();
    }, []);

    return (
        <>
            <Grid container spacing={2}>
                <Grid container item spacing={2}>
                    <Grid item lg={3} sm={6} xl={3} xs={12}>
                        <Link to={`/devices?filter={"in_stock":true}`}>
                            <IconCard title="Devices" value={stats?.devices?.in_stock} icon={<RouterIcon />} />
                        </Link>
                    </Grid>
                    <Grid item lg={3} sm={6} xl={3} xs={12}>
                        <Link to="/product_items">
                            <IconCard title="Product Items" value={stats?.product_items?.in_stock} icon={<RouterIcon />} />
                        </Link>
                    </Grid>
                    <Grid item lg={3} sm={6} xl={3} xs={12}>
                        <Link to={`/product_items?filter={"brand":"starlink","family":"satellite","in_stock":true,"archived":false}`}>
                            <IconCard title="StarLinks" value={stats?.starlinks?.in_stock} icon={<RouterIcon />} />
                        </Link>
                    </Grid>
                    <Grid item lg={3} sm={6} xl={3} xs={12}>
                        <Link to={`/device_products?filter={"stock_alert": true}`}>
                            <IconCard title="Stocks Alerts" value={stats?.device_products?.stock_alert} icon={<RouterIcon />} />
                        </Link>
                    </Grid>
                </Grid>
                <Grid container item spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader title="Stock Alerts" />
                        <CustomList
                            resource="device_products"
                            sort={{ field: 'id', order: 'DESC' }}
                            filter={{ stock_alert: true }}
                            basePath="/device_products"
                            filters={null}
                            bulkActionButtons={false}
                            exporter={false}
                            perPage={10}
                            pagination={false}
                            actions={false}
                        >
                            <DeviceProductRow />
                        </CustomList>

                        <Grid container justify = "center">
                            <Button
                                component={Link}
                                to='/device_products?filter={"stock_alert": true}'
                                size="small"
                                color="primary"
                            >
                                <Box p={1}>See all</Box>
                            </Button>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item lg={9}>
                    <DeviceStatsGraph />
                </Grid>
                <Grid item lg={9}>
                    <SimCardStatsGraph />
                </Grid>
            </Grid>
        </>
    );
};

export default StockDashboard;
