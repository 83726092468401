import React from 'react';
import { AutocompleteInput } from 'react-admin';

const choices = [
	{ id: 'LOST BY VENN', name: 'LOST BY VENN' },
	{ id: 'LOST BY CUSTOMER', name: 'LOST BY CUSTOMER' },
	{ id: 'DESTROYED BY VENN', name: 'DESTROYED BY VENN' },
	{ id: 'DESTROYED BY CUSTOMER', name: 'DESTROYED BY CUSTOMER' },
	{ id: 'PERMANENTLY DISABLED', name: 'PERMANENTLY DISABLED' },
	{ id: 'DISCONTINUED', name: 'DISCONTINUED' },
	{ id: 'OTHER', name: 'OTHER' },
];

const ArchivedReasonInput = ({ classes, record, source = "archived_reason", ...props }) => {
	if (record && record[source] && !choices.find((provider) => provider.id === record[source])) {
		choices.push({ id: record[source], name: record[source] });
	}

	return (
		<AutocompleteInput choices={choices} source={source} record={record} allowEmpty {...props} />
	);
};

export default ArchivedReasonInput;
