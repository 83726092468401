import React from 'react';
import { List, Filter, TextInput } from 'react-admin';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import { ListActions, ListPagination, BulkActions } from './List';

const theme = theme => createMuiTheme({
	...theme,
	overrides: {
		MuiCard: {
			root: {
				boxShadow: 'none'
			}
		},
		RaFilter: {
			form: {
				marginTop: 0
			}
		}
	}
});

const SearchFilter = props => (
	<Filter {...props} variant="outlined">
		<TextInput label="Search" source="q" alwaysOn />
	</Filter>
);

const CustomList = ({ ...props }) => (
	<ThemeProvider theme={theme}>
		<List
			hasCreate={false}
			hasEdit={false}
			hasList={false}
			hasShow={false}
			filters={<SearchFilter />}
			{...props}
		/>
	</ThemeProvider>
);

List.defaultProps = {
	actions: <ListActions />,
	bulkActionButtons: <BulkActions />,
	pagination: <ListPagination />,
	perPage: 25
};

export default CustomList;
export { theme as themeCustomList };