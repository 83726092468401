import React from 'react';
import { SelectInput } from 'react-admin';

const choices = [
	{ id: 'venus', name: 'VENUS' },
	{ id: 'earth', name: 'EARTH' },
	{ id: 'customer', name: 'CUSTOMER' },
	{ id: 'mercure', name: 'MERCURE' }
];

const ICServerField = ({ classes, record, source = "ic_server_name", ...props }) => {
	if (!record) {
		return null;
	}

	if (record[source] && !choices.find((ic_server) => ic_server.id === record[source])) {
		choices.push({ id: record[source], name: record[source] });
	}

	return (
		<SelectInput choices={choices} source={source} record={record} {...props} />
	);
};

export default ICServerField;
