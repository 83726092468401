import React, { useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { SaveButton as RASaveButton, /*TextInput,*/ useRefresh, useNotify } from 'react-admin';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import SendIcon from '@material-ui/icons/Send';

import SimpleForm from '../../common/SimpleForm';
import IconButton from '../../common/IconButton';
import Toolbar from '../../common/Toolbar';

import { sendInvoice } from '../../../services/api';


const SaveButton = ({ onClick, record, ...props }) => {
	const formState = useFormState();
	const form = useForm();
	const notify = useNotify();

	const handleClick = async () => {
		let formData = new FormData();
		if (formState.values.sent_to) {
			formData.append("sent_to", formState.values.sent_to);
		}
		if (formState.values.subject) {
			formData.append("subject", formState.values.subject);
		}
		if (formState.values.message) {
			formData.append("message", formState.values.message);
		}

		const response = await sendInvoice(record.id, formData);
		if (response.success || response.id) {
			notify('Invoice sent');
		}
		else if (!response.success && response.message) {
			notify(response.message, 'warning');
		}
		else {
			notify('Sending the invoice failed', 'warning');
		}

		form.reset();
		onClick();
	};

	return <RASaveButton {...props} handleSubmit={handleClick} handleSubmitWithRedirect={handleClick} label="Send" />;
};

const SendInvoiceButton = ({ record, variant, color }) => {
	const refresh = useRefresh();
	const [showDialog, setShowDialog] = useState(false);

	const handleSave = async () => {
		handleClose();
		refresh();
	};

	const handleOpen = () => {
		setShowDialog(true);
	};

	const handleClose = () => {
		setShowDialog(false);
	};

	if (!record) {
		return null;
	}

	return (
		<>
			<IconButton icon={<SendIcon />} onClick={handleOpen} variant={variant} color={color}>Send</IconButton>
			<Dialog fullWidth open={showDialog} onClose={handleClose} aria-label="Send invoice">
				<DialogTitle>Send invoice</DialogTitle>
				<DialogContent>
					Do you want to send the invoice {record.number}?
					<SimpleForm toolbar={<Toolbar onClose={handleClose} onSave={handleSave} saveButton={<SaveButton onClick={handleSave} />} />} record={record}>
						{/* TODO: uncomment for future step */}
						{/* <TextInput source="sent_to" label="Send to" helperText={false} fullWidth />
						<TextInput source="subject" helperText={false} fullWidth />
						<TextInput source="message" options={{ multiline: true }} rows="4" helperText={false} fullWidth /> */}
					</SimpleForm>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default SendInvoiceButton;