import React from 'react';
import { FormTab, TextInput } from 'react-admin';

import Create from '../common/Create';
import TabbedForm from '../common/TabbedForm';
import CountryInput from '../common/inputs/CountryInput';


const CompanyCreate = props => (
	<Create {...props}>
		<TabbedForm redirect="list">
			<FormTab label="summary">
				<TextInput source="name" />
				<CountryInput source="country" />
				<TextInput source="street" />
				<TextInput source="zipcode" />
				<TextInput source="city" />
				<TextInput source="vat_number" />
				<TextInput source="bank_name" />
				<TextInput source="bank_bic" />
				<TextInput source="bank_iban" />
				<TextInput source="email" />
				<TextInput source="website" />
				<TextInput source="phone" />
			</FormTab>
		</TabbedForm>
	</Create>
);

export default CompanyCreate;