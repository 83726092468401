import React from 'react';
import { TabbedShowLayout, Tab, TextField, FunctionField, NumberField } from 'react-admin';
import { Helmet } from 'react-helmet';

import Show from '../common/Show';
import DateField from '../common/fields/DateField';
import WanQualityGraph from './WanQualityGraph';

const SpeedtestTitle = ({ record }) => (
    <>
        <Helmet>
            <title>{`KB - Speedtest[${record.device_serial}]`}</title>
        </Helmet>
        <span>{record.device_serial}</span>
    </>
);

const Graph = ({ record }) => (
    <WanQualityGraph data={record.device_wan_quality} />
);

const SpeedTestShow = props => (
    <Show title={<SpeedtestTitle />} {...props}>
        <TabbedShowLayout>
            <Tab label="summary">
                <DateField source="created_at" showTime options={{ timeZone: 'Europe/Brussels' }} />
                <TextField source="dir" />
                <TextField source="device_serial" />
                <TextField source="device_name" />
                <TextField source="remote_serial" />
                <TextField source="remote_name" />
                <FunctionField label="Bandwidth" render={
                    record => `${record.bandwidth} ${record.bandwidth_unit}`
                } />
                <NumberField source="retransmissions" />
                <FunctionField label="cwnd" render={
                    record => `${record.cwnd} ${record.cwnd_unit}`
                } />
                <Graph />
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export default SpeedTestShow;
