import React, { Component } from 'react';
import { Query, ListButton, ShowButton } from 'react-admin';

import Breadcrumb from '../common/Breadcrumb';
import CardActions from '../common/CardActions';

import devices from './index';
import PortsEditForm from '../common/PortsEditForm';


const Actions = ({ record, breadcrumbPath }) => (
	<CardActions>
		<Breadcrumb path={breadcrumbPath} />
		<div>
			<ListButton basePath="/devices" />
			<ShowButton basePath="/devices" record={record} />
		</div>
	</CardActions>
);

class DeviceEditPorts extends Component {
	handleSave = () => {
		const { id } = this.props.match.params;
		this.props.history.push(`/devices/${id}/show`);;
	}

	render() {
		const { id } = this.props.match.params;

		return (
			<Query type="GET_ONE" resource="devices" payload={{ id: id }}>
				{({ data }) => {
					if (!data) {
						return null;
					}

					const record = {
						device_id: data.id,
						ports_map: data.ports_map
					};

					const breadcrumbPath = [
						{ url: '/devices', title: "Devices", icon: <devices.icon /> },
						{
							url: `/devices/${data.id}/show`,
							title: (data ? data.name : data.id)
						},
						{
							url: `/devices/${data.id}/ports`,
							title: "Edit Ports",
							isActive: true
						}
					];

					return <PortsEditForm record={record} onSave={this.handleSave} actions={<Actions record={data} breadcrumbPath={breadcrumbPath} />} {...this.props} />
				}}
			</Query>
		);
	}
};

export default DeviceEditPorts;