import React from 'react';
import { AutocompleteInput } from 'react-admin';

const choices = [
    { id: '0', name: '0' },
	{ id: '100', name: '100' },
	{ id: '101', name: '101' },
    { id: '102', name: '102' },
    { id: '103', name: '103' },
    { id: '104', name: '104' },
    { id: '105', name: '105' },
    { id: '106', name: '106' },
    { id: '107', name: '107' },
    { id: '108', name: '108' },
    { id: '109', name: '109' },
    { id: '110', name: '110' },
    { id: '111', name: '111' },
    { id: '112', name: '112' },
    { id: '113', name: '113' },
    { id: '114', name: '114' },
    { id: '115', name: '115' },
    { id: '116', name: '116' },
    { id: '117', name: '117' },
    { id: '118', name: '118' },
    { id: '119', name: '119' },
    { id: '120', name: '120' },
];

const VlanInput = ({ classes, record, source = "vlan_id", ...props }) => {
	if (record && record[source] && !choices.find((provider) => provider.id === record[source])) {
		choices.push({ id: record[source], name: record[source] });
	}

	return (
		<AutocompleteInput choices={choices} source={source} record={record} allowEmpty {...props} />
	);
};

export default VlanInput;
