import React from 'react';
import {
	Datagrid,
	TextField,
	ReferenceField,
	BooleanInput,
	TextInput,
	ReferenceInput,
	AutocompleteInput
} from 'react-admin';

import DateField from '../common/fields/DateField';
import BooleanField from '../common/fields/BooleanField';
import List from '../common/List';
import Filter from '../common/Filter';
import RecordSplitButton from '../common/RecordSplitButton';
// import ImpersonateButton from './UserActionsButtons/ImpersonateButton';
import EnableMFAButton from './UserActionsButtons/EnableMFAButton';
import DisableMFAButton from './UserActionsButtons/DisableMFAButton';

const optionText = choice => choice.name ? choice.name : '';


const UserFilter = (props) => (
	<Filter {...props}>
		<TextInput label="Search" source="q" alwaysOn />
		<ReferenceInput label="Customer" source="customer_id" reference="customers" sort={{ field: 'name', order: 'ASC' }} allowEmpty alwaysOn>
			<AutocompleteInput optionText={optionText} />
		</ReferenceInput>
		<BooleanInput label="Active" source="active" alwaysOn />
		<BooleanInput label="Admin" source="is_admin" alwaysOn />
		<BooleanInput label="Staff" source="is_staff" alwaysOn />
	</Filter>
);


const filterDefaultValues = {
    active: true,
}


const UserList = props => (
	<List {...props} filters={<UserFilter />} filterDefaultValues={filterDefaultValues} >
		<Datagrid>
			<TextField source="email" />
			<TextField source="firstname" />
			<TextField source="lastname" />
			<ReferenceField label="Customer" source="customer" reference="customers" link="show" allowEmpty>
				<TextField source="name" />
			</ReferenceField>
			<BooleanField label="is_admin" source="is_admin"/>
			<BooleanField label="is_staff" source="is_staff"/>
			<BooleanField label="is_manager" source="is_manager"/>
			<BooleanField label="active" source="active" />
			<BooleanField label="mfa_enabled" source="mfa_enabled" />
			<DateField label="Access at" source="last_login_at" showTime />
			<RecordSplitButton hasEdit={false} archiveButton={<></>}>
				{/* <ImpersonateButton /> */}
				<EnableMFAButton />
				<DisableMFAButton />
			</RecordSplitButton>
		</Datagrid>
	</List>
);

export default UserList;