import React from 'react';
import { TextInput, ReferenceInput, AutocompleteInput, BooleanInput, ReferenceArrayInput, AutocompleteArrayInput } from 'react-admin';
import { Link } from 'react-router-dom';

import GetAppIcon from '@material-ui/icons/GetApp';
import IconAdd from '@material-ui/icons/Add';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';

import List, { ListActions } from '../common/List';
import Filter from '../common/Filter';
import IconButton from '../common/IconButton';
import CustomAutocompleteInput from '../common/inputs/CustomAutocompleteInput';
import ProductItemBuySmInput from './inputs/ProductItemBuySmInput';
import ProductItemRentSoldInput from './inputs/ProductItemRentSoldInput';
import ProductItemRow from './ProductItemRow';
import ProductItemBulkActions from './ProductItemActionsButtons/ProductItemBulkActions';

import { API_URL } from '../../services/settings';


const customerOptionText = choice => choice.name ? choice.name : '';
const productOptionText = choice => choice && choice.reference ? choice.reference : '';
const siteOptionText = choice => choice.name ? choice.name : '';

const ProductItemListActions = (props) => {
    return (
        <ListActions {...props}>
            <IconButton component={Link} to={`/product_items/bulk-create`} icon={<IconAdd />}>Bulk create</IconButton>
            <IconButton component={Link} to={`/product_items/bulk-move`} icon={<SwapHorizIcon />}>Bulk move</IconButton>
            <IconButton icon={<GetAppIcon />} component="a" href={`${API_URL}/product_items/export.xls`}>Export</IconButton>
        </ListActions>
    );
};

// New component otherwise resource is override by "product_items"
const DeviceProductAutocomplete = (props) => (
    <CustomAutocompleteInput {...props} resource="device_products" />
);

const ProductItemFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <ReferenceInput label="Customer" source="customer_id" reference="customers" sort={{ field: 'name', order: 'ASC' }} alwaysOn>
            <AutocompleteInput optionText={customerOptionText} />
        </ReferenceInput>
        <ReferenceInput label="Site" source="site_id" reference="sites" sort={{ field: 'name', order: 'ASC' }} alwaysOn>
            <AutocompleteInput optionText={siteOptionText} />
        </ReferenceInput>
        <ReferenceArrayInput label="Products" source="product_ids" reference="device_products" sort={{ field: 'reference', order: 'ASC' }} alwaysOn>
            <AutocompleteArrayInput optionText={productOptionText} />
        </ReferenceArrayInput>
        <BooleanInput label="Archived" source="archived" alwaysOn />
        <BooleanInput label="Stock" source="in_stock" alwaysOn />
        <BooleanInput label="Reserved" source="reserved" />
        <ProductItemBuySmInput source="buy_sm" label="Buy/SM"  />
        <ProductItemRentSoldInput source="rent_sold" label="VENN Billing Mode" />
        <DeviceProductAutocomplete source="brand" />
        <DeviceProductAutocomplete source="family" />
        <DeviceProductAutocomplete source="sub_family" />
        <DeviceProductAutocomplete source="category" />
    </Filter>
);

const filterDefaultValues = {
    archived: false,
    in_stock: false,
}

const ProductItemList = ({ classes, ...props }) => (
    <List {...props} filters={<ProductItemFilter />} actions={ <ProductItemListActions/> } filterDefaultValues={filterDefaultValues} bulkActionButtons={<ProductItemBulkActions />} exporter={false}>
        <ProductItemRow />
    </List>
);

export default ProductItemList;