import React from 'react';
import { NumberInput, TextInput } from 'react-admin';

import Create from '../common/Create';
import SimpleForm from '../common/SimpleForm';
import CurrencyInput from '../common/inputs/CurrencyInput';
import ServiceTypeField from './ServiceTypeField';
import ServiceFrequencyField from './ServiceFrequencyField';


const ServiceProductCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <ServiceTypeField />
            <ServiceFrequencyField />
            <TextInput source="name" />
            <NumberInput source="price" />
            <NumberInput label="Data Included GB" source="data_included" />
            <NumberInput source="extra_price" />
            <CurrencyInput />
            <TextInput source="description" options={{ multiline: true }} rows="4" />
        </SimpleForm>
    </Create>
);

export default ServiceProductCreate;
