import React from 'react';
import { ReferenceField, Link } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';

const styles = {
	icon: {
		minWidth: 'auto',
		width: '36px',
		height: '36px'
	},
	container: {
		display: 'flex',
		alignItems: 'center',

		'& a:not(:first-child)': {
			marginLeft: '5px'
		}
	}
};

const IncontrolLink = withStyles(styles)(({ record, classes, children, basePath, ...props }) => {
	if (!record || !record.ic_urls) {
		return null;
	}

	const ic_url = record.ic_urls.find(ic_url => ic_url.key === 'device');

	return (
		<Fab component="a" href={ic_url.url} target="__blank" className={classes.icon} size="small" color="primary" {...props}>
			IC
		</Fab>
	);
});

const DeviceLinks = withStyles(styles)(({ record, source, resource, classes, ...props }) => {
	if (!record) {
		return null;
	}

	return (
		<span className={classes.container}>
			<Typography component={Link} to={`/${resource}/${record.id}/show`} variant="body2" color="primary">{record[source]}</Typography>
			{record.ic_urls && (
				<IncontrolLink record={record} />
			)}
		</span>
	);
});

const DeviceReferenceField = props => (
	<ReferenceField {...props}>
		<DeviceLinks source="name" />
	</ReferenceField>
);

DeviceReferenceField.defaultProps = {
	allowEmpty: true,
	source: "device_id",
	reference: "devices",
	link: false,
	basePath: "/devices",
	addLabel: true,
	label: "Device"
};

export default DeviceReferenceField;
export { IncontrolLink };