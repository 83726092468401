import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import SimCardIcon from '@material-ui/icons/SimCard';

import Tooltip from '../common/Tooltip';

const useStyles = makeStyles({
	btn: {
		padding: '2px',
		minWidth: 'auto'
	},
	chip: {
		height: '20px'
	},
	divider: {
		margin: '5px 0'
	},
	tooltip: {
		maxWidth: 'none'
	}
});

const SimCardTooltip = ({ record, icon, ...props }) => {
	const classes = useStyles();

	if (!record) {
		return null;
	}

	return (
		<Tooltip
			classes={{ tooltip: classes.tooltip }}
			title={<Typography variant="subtitle1">{record.iccid}</Typography>}
		>
			<Button size="small" className={classes.btn}>
				<SimCardIcon color="primary" />
			</Button>
		</Tooltip>
	);
};

export default SimCardTooltip;
