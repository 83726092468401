import React from 'react';
import { TextInput, ReferenceInput, AutocompleteInput, FormDataConsumer, DateInput } from 'react-admin';

import Edit from '../common/Edit';
import SimpleForm from '../common/SimpleForm';
import ActionField from './ActionField';
import StatusField from './StatusField';
import PriorityField from './PriorityField';


const optionText = choice => choice.name ? choice.name : '';
const userOptionText = choice => choice && choice.email ? choice.email : '';
const packageOptionText = choice => choice && choice.reference ? `${choice.reference} / ${choice.customer_reference}` : '';

const TaskEdit = props => (
    <Edit {...props}>
        <SimpleForm redirect="list">
			<TextInput label="SO" source="so_number" />
			<ReferenceInput label="Customer" source="customer_id" reference="customers" sort={{ field: 'name', order: 'ASC' }} allowEmpty>
				<AutocompleteInput optionText={optionText} />
			</ReferenceInput>
			<FormDataConsumer>
				{({ formData, ...rest }) => (
					<ReferenceInput
						{...rest}
						label="Site"
						source="site_id"
						reference="sites"
						filter={formData.customer_id && { customer_id: formData.customer_id }}
						key={formData.customer_id}
					><AutocompleteInput optionText={optionText} /></ReferenceInput>
				)}
			</FormDataConsumer>
			<FormDataConsumer>
				{({ formData, ...rest }) => (
					<ReferenceInput
						{...rest}
						label="Package"
						source="package_id"
						reference="packages"
						filter={formData.customer_id && { customer_id: formData.customer_id }}
						key={formData.customer_id}
					>
						<AutocompleteInput optionText={packageOptionText} />
					</ReferenceInput>
				)}
			</FormDataConsumer>
			<FormDataConsumer>
				{({ formData, ...rest }) => (
					<ReferenceInput
						{...rest}
						label="Service"
						source="service_id"
						reference="services"
						filter={formData.package_id && { customer_id: formData.package_id }}
						key={formData.package_id}
					>
						<AutocompleteInput optionText={packageOptionText} />
					</ReferenceInput>
				)}
			</FormDataConsumer>
			<ActionField />
			<StatusField />
			<PriorityField />
			<ReferenceInput label="Assigned To" source="assigned_to_id" reference="users" filter={{ is_staff: true }} sort={{ field: 'email', order: 'ASC' }} alwaysOn >
				<AutocompleteInput optionText={userOptionText} />
			</ReferenceInput>
			<DateInput source="due_date" />
			<TextInput source="notes" options={{ multiline: true }} rows="4" />
		</SimpleForm>
    </Edit>
);

export default TaskEdit;