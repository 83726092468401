import React from 'react';
import { AutocompleteInput } from 'react-admin';
import countriesList from './Countries';

const choices = countriesList;

const DataPricingCountryField = ({ classes, record, source = "country_code", ...props }) => {
	if (!record) {
		return null;
	}

	if (record[source] && !choices.find((provider) => provider.id === record[source])) {
		choices.push({ id: record[source], name: record[source]});
	}

	return (
		<AutocompleteInput choices={choices} source={source} record={record} allowEmpty {...props} />
	);
};

export default DataPricingCountryField;
