import React from 'react';
import { TextField } from 'react-admin';

import Datagrid from '../common/Datagrid';
import DateField from '../common/fields/DateField';
import LinkFieldButton from '../common/LinkFieldButton';
import ResultCodeField from './ResultCodeField';

const IccidField = ({ record }) => (
	<LinkFieldButton label="Iccid" sortBy="iccid" basePath="simcards" to={`/simcards/${record.iccid}/show`} record={record}>
		<TextField source="iccid" />
	</LinkFieldButton>
);

const LocationUpdateRow = ({ children, ...props }) => (
	<Datagrid {...props}>
		<DateField source="timestamp" showTime />
		<IccidField source="iccid" />
		<TextField source="type" />
		<ResultCodeField label="Result code" />
		<TextField source="mcc" />
		<TextField source="mnc" />
		<TextField source="network_name" />
		<TextField source="vpmn" label="TADIG" />
		<TextField source="country_iso3" label="Country" />
		<TextField source="country_name" label="" />
		<TextField source="imsi" />
		<TextField source="imsi_id" />
		{children}
	</Datagrid>
);

export default LocationUpdateRow;
