import React from 'react';
import Typography from '@material-ui/core/Typography';


const choices = {
	'bank_transfer': 'Bank transfer',
    'direct_debit': 'Direct debit'
};

const PaymentMethodField = ({ record, source, ...props }) => {
	if(!record) {
		return null;
	}

	const value = record[source];

	if (!value) {
		return null;
	}

	return (
		<Typography variant="body2">{choices[value] || value}</Typography>
	);
};

PaymentMethodField.defaultProps = {
	source: 'payment_method',
	label: 'Payment method',
	addLabel: true
};

export default PaymentMethodField;
