import React from 'react';
import { useFormState } from 'react-final-form';
import { Title, Toolbar, SaveButton, DateInput, ReferenceInput, AutocompleteInput, SelectArrayInput, /*useNotify*/ } from 'react-admin';
import Card from '@material-ui/core/Card';
import GetAppIcon from '@material-ui/icons/GetApp';

import { CreateActions } from '../common/Create';
import SimpleForm from '../common/SimpleForm';

// import { exportInvoices } from '../../services/api';
import invoices from './';

const optionText = choice => choice.name ? choice.name : '';
const addressOptionText = choice => choice.name ? `${choice?.name} ${choice?.full_address ? `(${choice.full_address})` : ''}` : '';

const types = [
    { id: 'credit', name: 'Credit note' },
    { id: 'invoice', name: 'Invoice' }
];

const formats = [
    { id: 'pdf', name: 'PDF' },
    { id: 'xls', name: 'XLS' },
    { id: 'dbf', name: 'DBF' }
];

const statuses = [
    { id: 'draft', name: 'Draft' },
    { id: 'send', name: 'Send' },
    { id: 'view', name: 'View' },
    { id: 'paid', name: 'Paid' }
];

const CustomSaveButton = ({ onClick, ...props }) => {
    const formState = useFormState();
    // const notify = useNotify();

    const handleClick = async () => {
        let formData = new FormData();
        if (formState.values.from) {
            formData.append("from", formState.values.from);
        }
        if (formState.values.to) {
            formData.append("to", formState.values.to);
        }
        if (formState.values.customer_id) {
            formData.append("customer_id", formState.values.customer_id);
        }
        if (formState.values.billing_company_id) {
            formData.append("billing_company_id", formState.values.billing_company_id);
        }
        if (formState.values.invoicing_address_id) {
            formData.append("invoicing_address_id", formState.values.invoicing_address_id);
        }
        if (formState.values.type) {
            formData.append("type", formState.values.type);
        }
        if (formState.values.statuses) {
            formData.append("statuses", formState.values.statuses);
        }
        if (formState.values.format) {
            formData.append("format", formState.values.format);
        }

        // const result = await exportInvoices(formData);
        // if (result.success) {

        //     onClick();
        // }
        // else {
        //     notify(result.message);
        // }
    };

    return <SaveButton {...props} label="Export" icon={<GetAppIcon />} handleSubmit={handleClick} handleSubmitWithRedirect={handleClick} />;
};

const CustomToolbar = ({ onSave, ...props }) => (
    <Toolbar {...props}>
        <CustomSaveButton onClick={onSave} />
    </Toolbar>
);

const InvoiceExport = () => {
    const breadcrumbPath = [
        { url: '/invoices', title: "Invoices", icon: <invoices.icon /> },
        {
            url: '/invoices/export',
            title: "Export",
            isActive: true
        }
    ];

    const handleSave = async (result) => {

    };

    return (
        <>
            <CreateActions basePath="/invoices" hasList={true} breadcrumb={breadcrumbPath} />
            <Card>
                <Title title="Invoices export" />
                <SimpleForm toolbar={<CustomToolbar onSave={handleSave} />}>
                    <DateInput source="from" />
                    <DateInput source="to" />
                    <ReferenceInput label="Customer" source="customer_id" reference="customers" sort={{ field: 'name', order: 'ASC' }}>
                        <AutocompleteInput />
                    </ReferenceInput>
                    <ReferenceInput label="Billing company" source="billing_company_id" reference="companies" sort={{ field: 'name', order: 'ASC' }} allowEmpty>
                        <AutocompleteInput optionText={optionText} />
                    </ReferenceInput>
                    <ReferenceInput label="Billing address" source="invoicing_address_id" reference="addresses" sort={{ field: 'name', order: 'ASC' }} allowEmpty>
                        <AutocompleteInput optionText={addressOptionText} />
                    </ReferenceInput>
                    <AutocompleteInput choices={types} source="type" allowEmpty />
                    <SelectArrayInput label="Status" source="statuses" choices={statuses} />
                    <AutocompleteInput choices={formats} source="format" />
                </SimpleForm>
            </Card>
        </>
    );
};

export default InvoiceExport;