import React from 'react';
import { AutocompleteInput } from 'react-admin';

const choices = [
    { id: 'RENT', name: 'RENT' },
	{ id: 'SOLD', name: 'SOLD' },
	{ id: 'POC', name: 'POC' },
	{ id: 'NA', name: 'NA' },
];

const ProductItemRentSoldInput = ({ classes, record, source = "rent_sold", ...props }) => {
	if (record && record[source] && !choices.find((provider) => provider.id === record[source])) {
		choices.push({ id: record[source], name: record[source]});
	}

	return (
		<AutocompleteInput choices={choices} source={source} record={record} allowEmpty {...props} />
	);
};

export default ProductItemRentSoldInput;
