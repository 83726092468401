import React from 'react';
import { AppBar, UserMenu, Layout, MenuItemLink } from 'react-admin';
import { useSelector } from 'react-redux';
import SettingsIcon from '@material-ui/icons/Settings';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';

import SearchBar from './common/SearchBar';
import Menu from './Menu';

import { darkTheme, lightTheme } from '../style/themes';

const AppUserMenu = props => (
    <UserMenu {...props}>
        <MenuItemLink
            to="/account"
            primaryText="Account"
            leftIcon={<SettingsIcon />}
        />
    </UserMenu>
);

const CustomAppBar = props => (
    <AppBar {...props}  color="primary" userMenu={<AppUserMenu />}>
        <Typography variant="h6" id="react-admin-title" color="inherit" />
        <SearchBar />
    </AppBar>
);

const AppLayout = props => {
    const theme = useSelector((state) =>
        state.theme === 'dark' ? darkTheme : lightTheme
    );

    return (
        <>
            <Layout {...props} appBar={CustomAppBar} menu={Menu} theme={theme}>
                <CssBaseline />
                {props.children}
            </Layout>
        </>
    );
};

export default AppLayout;