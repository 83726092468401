import PhonelinkRing from '@material-ui/icons/PhonelinkRing';

import LineProviderList from './LineProviderList';
import LineProviderCreate from './LineProviderCreate';
import LineProviderShow from './LineProviderShow';
import LineProviderEdit from './LineProviderEdit';

export default {
	list: LineProviderList,
	create: LineProviderCreate,
	show: LineProviderShow,
	edit: LineProviderEdit,
	icon: PhonelinkRing,
	title: 'Line Providers'
};
