import React from 'react';
import { Tab, TextField, ReferenceField } from 'react-admin';

import DateField from '../../common/fields/DateField';
import NumberCurrencyField from '../../common/fields/NumberCurrencyField';
import { ShowActions } from '../../common/Show';
import ServiceProvisionButton from '../ServiceActionsButtons/ServiceProvisionButton';
import ServiceShipButton from '../ServiceActionsButtons/ServiceShipButton';
import ServiceDeliverButton from '../ServiceActionsButtons/ServiceDeliverButton';
import ServicePauseResumeButton from '../ServiceActionsButtons/ServicePauseResumeButton';

const SDWanServiceActions = (props) => (
    <ShowActions {...props}>
        <ServiceProvisionButton record={props.data} />
        <ServiceShipButton record={props.data} />
		<ServiceDeliverButton record={props.data} />
		<ServicePauseResumeButton record={props.data} />
    </ShowActions>
);

const SDWanServiceShow = (props) => (
    <Tab label="summary" {...props}>
        <TextField source="status" />
        <ReferenceField label="Assignee" source="assignee_id" reference="users"  link="show" allowEmpty>
            <TextField source="email" />
        </ReferenceField>
        <ReferenceField label="Package" source="package_id" reference="packages" link="show" allowEmpty>
            <TextField source="reference" />
        </ReferenceField>
        <TextField source="reference" />
        <TextField source="customer_reference"/>
        <ReferenceField label="Last Invoice" source="last_invoice_id" reference="invoices" link="show" allowEmpty>
            <TextField source="id" />
        </ReferenceField>
        <TextField source="name" />
        <TextField source="type" />
        <TextField source="frequency" />
        <ReferenceField label="Site" source="site_id" reference="sites" link="show" allowEmpty>
            <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Device" source="device_id" reference="devices" link="show" allowEmpty>
            <TextField source="serial" />
        </ReferenceField>
        <ReferenceField label="Data pool" source="data_pool_id" reference="data_pools" link="show" allowEmpty>
            <TextField source="name" />
        </ReferenceField>
        <TextField source="data_pool_reference" />
        <NumberCurrencyField source="price" />
        <DateField source="start_date" />
        <DateField source="end_date" />
        <DateField source="early_end_date" label="Commitment date" />
        <TextField source="notes" options={{ multiline: true }} rows="4" />
        <TextField source="shipping_tracking_code" />
        <DateField source="shipping_date" />
        <DateField source="delivery_date" />
        <ReferenceField label="Sold Hardware" source="package_line_id" reference="package_lines" link="show" allowEmpty>
            <TextField source="name" />
        </ReferenceField>
        <DateField label="Created at" source="created_at" />
        <DateField label="Updated at" source="updated_at" />
    </Tab>
);

export default SDWanServiceShow;
export { SDWanServiceActions };
