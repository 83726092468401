import React from 'react';
import { BooleanInput, TextInput } from 'react-admin';
import GetAppIcon from '@material-ui/icons/GetApp';

import List, { ListActions } from '../common/List';
import Filter from '../common/Filter';
import IconButton from '../common/IconButton';
import CompanyRow from './CompanyRow';

import { API_URL } from '../../services/settings';

const CompanyListActions = (props) => (
    <ListActions {...props}>
        <IconButton icon={<GetAppIcon />} component="a" href={`${API_URL}/companies/export.xls`}>Export</IconButton>
    </ListActions>
);

const CompanyFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <BooleanInput label="Archived" source="archived" alwaysOn />
    </Filter>
);

const filterDefaultValues = {
    archived: false
};

const CompanyList = props => (
    <List {...props} filters={<CompanyFilter />} filterDefaultValues={filterDefaultValues} actions={<CompanyListActions />} exporter={false} >
        <CompanyRow />
    </List>
);

export default CompanyList;