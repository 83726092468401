import React from 'react';
import { AutocompleteInput } from 'react-admin';

const choices = [
	{ id: 'END OF SITE', name: 'END OF SITE' },
	{ id: 'CANCELLED BY CUSTOMER', name: 'CANCELLED BY CUSTOMER' },
];

const ArchivedReasonInput = ({ classes, record, source = "archived_reason", ...props }) => {
	if (record && record[source] && !choices.find((provider) => provider.id === record[source])) {
		choices.push({ id: record[source], name: record[source] });
	}

	return (
		<AutocompleteInput choices={choices} source={source} record={record} allowEmpty {...props} />
	);
};

export default ArchivedReasonInput;
