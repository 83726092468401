import React, { useState } from 'react';
import { useRefresh, useNotify, SaveButton as RASaveButton, TextInput, ReferenceInput, AutocompleteInput, useMutation } from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';

import SimpleForm from '../../common/SimpleForm';
import IconButton from '../../common/IconButton';
import Toolbar from '../../common/Toolbar';

import { attachSimCardToDevice, detachSimCardFromDevice } from '../../../services/api';


const useStyles = makeStyles(theme => ({
	toolbar: {
		paddingTop: '70px'
	},
	suggestionsContainerOpen: {
		position: 'absolute',
		marginBottom: theme.spacing(3),
		zIndex: theme.zIndex.modal + 200,
	}
}));

const deviceOptionText = choice => `${choice.serial ? choice.serial : ''} / ${choice.ic_group_name ? choice.ic_group_name : ''} / ${choice.name ? choice.name : ''}`;

const DetachSaveButton = ({ onClick, record, ...props }) => {
	const notify = useNotify();
	const formState = useFormState();
	const form = useForm();

	const handleClick = async () => {
		let formData = new FormData();
		formData.append("reason", formState.values.reason);
		const response = await detachSimCardFromDevice(record.id, formData);
		form.reset();
		onClick();

		if(response.success === false) {
			notify('SIM not detached', 'warning');
		}
		else {
			notify('SIM detached');
		}
	};

	return <RASaveButton {...props} handleSubmit={handleClick} handleSubmitWithRedirect={handleClick} />;
};

const AttachSaveButton = ({ onClick, record, ...props }) => {
	const notify = useNotify();
	const formState = useFormState();
	const form = useForm();

	const handleClick = async () => {
		let formData = new FormData();
		formData.append("device_id", formState.values.device_id);
		formData.append("port_name", formState.values.port_name);
		const response = await attachSimCardToDevice(record.id, formData);
		form.reset();
		onClick();

		if(response.success === false) {
			notify('SIM not attached', 'warning');
		}
		else {
			notify('SIM attached');
		}
	};

	return <RASaveButton {...props} handleSubmit={handleClick} handleSubmitWithRedirect={handleClick} />;
};

const AttachDetachSimCardButton = ({ record, variant, color }) => {
	const refresh = useRefresh();
	const [showDialog, setShowDialog] = useState(false);
	const [portChoices, setPortChoices] = useState([]);
	const classes = useStyles();
	const [mutate] = useMutation();

	const handleSave = async () => {
		handleClose();
		refresh();
	};

	const handleOpen = (e) => {
		e.stopPropagation();
		setShowDialog(true);
	};

	const handleClose = () => {
		setShowDialog(false);
	};

	const handleChangeDevice = data => mutate({
		type: 'getOne',
		resource: 'devices',
		payload: {
			id: data,
		},
	}, {
		onSuccess: ({ data }) => {
			const ids = Object.values(data.ports_map).filter((id) => id );
			if(ids.length > 0) {
				mutate({
					type: 'getMany',
					resource: 'simcards',
					payload: { ids }
				}, {
					onSuccess: (responseSim) => {
						const portChoices = Object.keys(data.ports_map).map(key => {
							let sim;
							if(data.ports_map[key]) {
								sim = responseSim.data.find(s => s.id === data.ports_map[key]);
							}
							return { id: key, name: `${key} (${sim ? sim.iccid_formatted : 'empty'})` }
						});
						setPortChoices(portChoices);
						return;
					}
				});
			}

			const portChoices = Object.keys(data.ports_map).map(key => ({ id: key, name: `${key} (empty)` }));
			setPortChoices(portChoices);
		}
	});

	if(!record) {
		return null;
	}

	return (
		record.device_id ? (
			<>
				<IconButton icon={<RemoveCircleOutlineIcon />} onClick={handleOpen} variant={variant} color={color}>Detach from device</IconButton>
				<Dialog fullWidth open={showDialog} onClose={handleClose} aria-label="Detach from device">
					<DialogTitle>Detach SIM</DialogTitle>
					<DialogContent>
						Do you want to detach the line {record.subscription_id} from the device?
						<SimpleForm toolbar={<Toolbar onClose={handleClose} onSave={handleSave} saveButton={<DetachSaveButton onClick={handleSave} />} />} record={record}>
							<TextInput source="reason" rows="4" fullWidth multiline />
						</SimpleForm>
					</DialogContent>
				</Dialog>
			</>
		) : (
			<>
				<IconButton icon={<AddCircleOutlineIcon />} onClick={handleOpen} variant={variant} color={color}>Attach to device</IconButton>
				<Dialog fullWidth open={showDialog} onClose={handleClose} aria-label="Attach to device">
					<DialogTitle>Attach SIM</DialogTitle>
					<DialogContent>
						Do you want to attach the SIM {record.subscription_id} to a device?
						<SimpleForm toolbar={<Toolbar className={classes.toolbar} onClose={handleClose} onSave={handleSave} saveButton={<AttachSaveButton onClick={handleSave} />} />} record={record}>
							<ReferenceInput label="Device" source="device_id" reference="devices" filter={{ page_size: 3 }} onChange={handleChangeDevice}>
								<AutocompleteInput
									optionText={deviceOptionText}
									options={{ suggestionsContainerProps: { className: classes.suggestionsContainerOpen, disablePortal: true } }}
								/>
							</ReferenceInput>
							<AutocompleteInput
								source="port_name"
								choices={portChoices}
								options={{ suggestionsContainerProps: { className: classes.suggestionsContainerOpen, disablePortal: true } }}
								variant="outlined"
							/>
						</SimpleForm>
					</DialogContent>
				</Dialog>
			</>
		)
	);
};

export default AttachDetachSimCardButton;