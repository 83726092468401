import React, { useState } from 'react';
import { Confirm, useRefresh, useNotify, useUnselectAll } from 'react-admin';
import IconRemove from '@material-ui/icons/RemoveCircleOutline';

import IconButton from '../../common/IconButton';

import { removeFromSimPool } from '../../../services/api';

const RemoveFromPoolButton = ({ selectedIds, record, variant, color }) => {
	const notify = useNotify();
	const refresh = useRefresh();
	const unselectAll = useUnselectAll();
	const [showDialog, setShowDialog] = useState(false);

	const handleConfirm = () => {
		let errorIds = [];
		selectedIds.forEach(async simId => {
			const response = await removeFromSimPool(record.id, simId);
			if(!response.success) {
				errorIds.push(simId);
			}
		});

		handleClose();
		notify(`${selectedIds.length - errorIds.length} SIM cards removed from SIM pool`, errorIds.length === 0 ? 'info' : 'warning');
		refresh();
		unselectAll('simcards');
	};

	const handleOpen = () => {
		setShowDialog(true);
	};

	const handleClose = () => {
		setShowDialog(false);
	};

	return (
		<>
			<IconButton icon={<IconRemove />} onClick={handleOpen} variant={variant} color={color}>Remove from pool</IconButton>
			<Confirm
				isOpen={showDialog}
				title="Remove from SIM pool"
				content={`Are you sure to remove ${selectedIds.length} simcards from the SIM pool?`}
				onConfirm={handleConfirm}
				onClose={handleClose}
			/>
		</>
	);
};

export default RemoveFromPoolButton;