import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';

import AddressList from './AddressList';
import AddressCreate from './AddressCreate';
import AddressShow from './AddressShow';
import AddressEdit from './AddressEdit';

export default {
	list: AddressList,
	create: AddressCreate,
	show: AddressShow,
	edit: AddressEdit,
	icon: EuroSymbolIcon
};
