import React from 'react';
import Typography from '@material-ui/core/Typography';


const choices = {
	'BE': 'Belgium',
    'FR': 'France',
    'NL': 'Netherlands'
};

const CountryField = ({ record, source, ...props }) => {
	if(!record) {
		return null;
	}

	const value = record[source];

	if (!value) {
		return null;
	}

	return (
		<Typography variant="body2">{choices[value]}</Typography>
	);
};

CountryField.defaultProps = {
	source: 'country',
	label: 'Country',
	addLabel: true
};

export default CountryField;
