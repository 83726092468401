import React from 'react';

const result_codes = {
    'CA': 'DATA_ALLOWED',
    'PI': 'PILOT',
    'CB': 'CALLBACK',
    'RJ': 'REJECT'
};

const ResultCodeField = ({ record, source="result_code" }) => (
    <span>{result_codes[record[source]] || ''}</span>
);

export default ResultCodeField;
