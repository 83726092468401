import RouterIcon from '@material-ui/icons/Router';

import DeviceProductList from './DeviceProductList';
import DeviceProductShow from './DeviceProductShow';
import DeviceProductCreate from './DeviceProductCreate';
import DeviceProductEdit from './DeviceProductEdit';

export default {
	list: DeviceProductList,
	show: DeviceProductShow,
	edit: DeviceProductEdit,
	create: DeviceProductCreate,
	icon: RouterIcon,
	title: 'Device Products'
};
