import React from 'react';
import {
	NumberField,
	TextField,
	SimpleShowLayout,
	ReferenceField,
	ShowButton,
	EditButton
} from 'react-admin';

import Datagrid from '../common/Datagrid';
import DateField from '../common/fields/DateField';
import BooleanField from '../common/fields/BooleanField';
import NumberCurrencyField from '../common/fields/NumberCurrencyField';
import PackageLineProvisionButton from './PackageLineActionsButtons/PackageLineProvisionButton';
import PackageLineShipButton from './PackageLineActionsButtons/PackageLineShipButton';
import PackageLineDeliverButton from './PackageLineActionsButtons/PackageLineDeliverButton';
import SplitButton from '../common/SplitButton';


const TrackingUrlField = ({ record = {} }) => {
	return (
	  <a href={record['shipping_tracking_url']} target="_blank" rel="noopener noreferrer">
		{record['shipping_tracking_code']}
	  </a>
	);
  };



const DeliveryButton = ({ record, children, archiveButton, hasEdit, ...props }) => {
	if (!record) {
		return null;
	}

	return (
		<SplitButton>
			<PackageLineProvisionButton record={record} {...props} />
			<PackageLineShipButton record={record} {...props} />
			<PackageLineDeliverButton record={record} {...props} />
			<ShowButton record={record} {...props} />
			<EditButton record={record} {...props} />
		</SplitButton>
	);
}


const PackageLineDrawer = props => (
	<SimpleShowLayout {...props}>
		<TextField source="id" />
		<ReferenceField label="Package" source="package_id" reference="packages" link="show" allowEmpty>
            <TextField source="reference" />
        </ReferenceField>
		<ReferenceField label="Device Product" source="device_product_id" reference="device_products" link="show" allowEmpty>
            <TextField source="reference" />
        </ReferenceField>
		<NumberField source="qty" />
		<NumberCurrencyField source="price"  />
		<NumberCurrencyField source="total"  />
        <BooleanField label="Invoiced" source="is_invoiced" />
        <TextField source="notes" options={{ multiline: true }} rows="4" />
        <TextField source="serials" options={{ multiline: true }} rows="4" />
		<DateField label="Created at" source="created_at" />
		<DateField label="Updated at" source="updated_at" />
	</SimpleShowLayout>
);

const PackageLineRow = props => (
	<Datagrid drawer={<PackageLineDrawer {...props} />} {...props}>
		<TextField source="status" />
		<ReferenceField label="Package" source="package_id" reference="packages" sortable={true} link="show">
			<TextField source="reference" />
		</ReferenceField>
		<ReferenceField label="Customer" source="package.customer_id" reference="customers" link="show" allowEmpty>
            <TextField source="name" />
        </ReferenceField>
		<ReferenceField label="Site" source="package.site_id" reference="sites" link="show" allowEmpty>
            <TextField source="name" />
        </ReferenceField>
		<ReferenceField label="Device Product" source="device_product_id" reference="device_products" link="show" allowEmpty>
            <TextField source="reference" />
        </ReferenceField>
		<NumberField source="qty" />
		<NumberCurrencyField source="price"  />
		<NumberCurrencyField source="total" />
		<DateField label="Desired Shipment Date" source="package.desired_date" />
        <BooleanField label="Invoiced" source="is_invoiced" />
        <TextField source="notes" options={{ multiline: true }} rows="4" />
        <TextField source="serials" options={{ multiline: true }} rows="4" />
		<TrackingUrlField label="Tracking" source="shipping_tracking_code" />
		<DateField label="Shipping" source="shipping_date" />
		<DateField label="Delivery" source="delivery_date" />
		<DeliveryButton />
	</Datagrid>
);

export default PackageLineRow;
