import React from 'react';
import { TextInput } from 'react-admin';

import List from '../common/List';
import Filter from '../common/Filter';
import StarlinkAccountRow from './StarlinkAccountRow';


const StarlinkAccountFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
    </Filter>
);

const StarlinkAccountList = props => (
    <List {...props} filters={<StarlinkAccountFilter />}>
        <StarlinkAccountRow />
    </List>
);

export default StarlinkAccountList;