import React from 'react';
import { connect } from 'react-redux';
import { TextInput, BooleanInput, refreshView, useNotify } from 'react-admin';
import IconRefresh from '@material-ui/icons/Refresh';

import GetAppIcon from '@material-ui/icons/GetApp';

import CustomerRow from './CustomerRow';
import List, { ListActions } from '../common/List';
import Filter from '../common/Filter';
import IconButton from '../common/IconButton';

import { refreshFreshdesk } from '../../services/api';
import { API_URL } from '../../services/settings';

const CustomerFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <BooleanInput label="Archived" source="archived" alwaysOn />
        <BooleanInput label="Active" source="active" alwaysOn />
    </Filter>
);

const filterDefaultValues = {
    archived: false,
    active: true
};

const CustomerActions = connect(null, { refreshView })(({ refreshView, ...props }) => {
    const notify = useNotify();

    const handleRefreshFreshdesk = async () => {
        const response = await refreshFreshdesk();

        if (response.success) {
            refreshView();
            notify('Synchronization succeeded');
        }
        else {
            notify('Synchronization failed', 'warning');
        }
    };

    return (
        <ListActions {...props}>
            <IconButton icon={<IconRefresh />} onClick={handleRefreshFreshdesk}>Freshdesk</IconButton>
            <IconButton icon={<GetAppIcon />} component="a" href={`${API_URL}/customers/export.xls`}>Export</IconButton>
        </ListActions>
    );
});

const CustomerList = props => (
    <List {...props} filters={<CustomerFilter />} filterDefaultValues={filterDefaultValues} actions={<CustomerActions />}  perPage={100} exporter={false}>
        <CustomerRow />
    </List>
);

export default CustomerList;