import React from 'react';
import { FormDataConsumer } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import SiteQuickCreateButton from './SiteQuickCreateButton';
import SiteQuickEditButton from './SiteQuickEditButton';
import SiteReferenceInput from './SiteReferenceInput';


const useStyles = makeStyles({
	container: {
		display: 'inline-block',
		verticalAlign: 'middle',
		minWidth: '50%',
		'& > div': {
			width: '100%'
		}
	},
	button: {
		marginBottom: '15px',
		marginLeft: '5px'
	}
});

const SiteReferenceInputWithCreate = props => {
	const classes = useStyles();

	return (
		<>
			<SiteReferenceInput {...props} classes={{ container: classes.container }}></SiteReferenceInput>
			<SiteQuickCreateButton source={props.source} resource={props.resource} className={classes.button} filter={props.filter} initialValues={props?.initialValues} />
			<FormDataConsumer>
				{({ formData, ...rest }) =>
					formData.site_id ? <SiteQuickEditButton source={props.source} resource={props.resource} className={classes.button} filter={props.filter} id={formData.site_id} /> : null
				}
			</FormDataConsumer>
		</>
	);
};

export default SiteReferenceInputWithCreate;