import React from 'react';
import { SelectInput } from 'react-admin';


const choices = [
	{ id: '', name: ''},
	{ id: 'PENDING', name: 'PENDING' },
	{ id: 'DONE', name: 'DONE' },
];

const AccountingStatusField = ({ classes, record, source="accounting_status", ...props }) => {
	if (!record) {
		return null;
	}

	return (
		<SelectInput choices={choices} source={source} record={record} {...props} />
	);
};

export default AccountingStatusField;
