import React from 'react';
import { AutocompleteInput } from 'react-admin';

const choices = [
	{ id: 'END SUBSCRIPTION', name: 'END SUBSCRIPTION' },
	{ id: 'END EVENT', name: 'END EVENT' },
	{ id: 'END POC', name: 'END POC' },
	{ id: 'END DEMO', name: 'END DEMO' },
	{ id: 'END LOAN', name: 'END LOAN' },
	{ id: 'SITE TAG CHANGE OLD', name: 'SITE TAG CHANGE OLD' },
	{ id: 'SUBSCRIPTION CHANGE OLD', name: 'SUBSCRIPTION CHANGE OLD' },
	{ id: 'TECHNICAL REPLACEMENT OLD', name: 'TECHNICAL REPLACEMENT OLD' },
	{ id: 'ERROR CORRECTION', name: 'ERROR CORRECTION' },
	{ id: 'OTHER', name: 'OTHER' },
];

const BackToStockReasonInput = ({ classes, record, ...props }) => {
	return (
		<AutocompleteInput choices={choices} record={record} allowEmpty {...props} />
	);
};

export default BackToStockReasonInput;
