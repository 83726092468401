import React from 'react';
import { TextInput, ReferenceInput, AutocompleteInput, BooleanInput, FormDataConsumer } from 'react-admin';

import Create from '../common/Create';
import SimpleForm from '../common/SimpleForm';
import SimCardType from './SimCardType';


const optionText = choice => choice.name ? choice.name : '';
const providerOptionText = choice => `${choice.name} / ${choice.supplier_name } / ${choice.iccid_prefix}`;

const SimCardCreate = props => (
	<Create {...props}>
		<SimpleForm redirect="list">
			<SimCardType />
			<TextInput source="iccid" />
			<TextInput source="imsi" />
			<TextInput source="sponsored_imsi" />
			<FormDataConsumer>
				{({ formData, ...rest }) =>
					<ReferenceInput label="Pool" source="pool_id" reference="simpools" allowEmpty filter={{ field: "name", order: "ASC", ...(formData.customer_id && { customer_id: formData.customer_id }) }} {...rest}>
						<AutocompleteInput optionText={optionText} />
					</ReferenceInput>
				}
			</FormDataConsumer>
			<ReferenceInput label="Provider" source="provider_id" reference="providers" sort={{ field: 'name', order: 'ASC' }} allowEmpty>
				<AutocompleteInput optionText={providerOptionText} />
			</ReferenceInput>
			<TextInput source="carrier_name" />
			<TextInput source="phone_number" />
			<TextInput source="apn_1" />
			<TextInput source="apn_2" />
			<TextInput source="apn_3" />
			<TextInput source="preferred_mtu" />
			<TextInput source="pin" />
			<TextInput source="username" />
			<TextInput source="password" />
			<TextInput source="variant" />
			<BooleanInput source="active" />
			<TextInput source="notes" options={{ multiline: true }} rows="4" />
		</SimpleForm>
	</Create>
);

export default SimCardCreate;