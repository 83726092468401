import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core';

import Tooltip from '../common/Tooltip';
import OnlineStatusIcon from './OnlineStatusIcon';


const styles = {
	btn: {
		padding: '2px',
		minWidth: 'auto'
	},
	chip: {
		height: '20px'
	},
	divider: {
		margin: '5px 0'
	},
	tooltip: {
		maxWidth: 'none'
	}
};


const DeviceTooltip = ({ classes, record, source, icon, ...props }) => {
	if(!record) {
		return null;
	}

	return (
		<Tooltip
			classes={{ tooltip: classes.tooltip }}
			title={
				<React.Fragment>
					<Typography variant="subtitle1">{record.name}</Typography>
					<Typography>{record.serial}</Typography>
					<Typography>{record.product_name}</Typography>

					<Divider className={classes.divider} />
					<Typography>Group name: {record.group_name}</Typography>
					<Typography gutterBottom>IC server name: {record.ic_server_name}</Typography>
				</React.Fragment>
			}
		>
			<Button size="small" className={classes.btn}>
				{icon ? icon : <OnlineStatusIcon record={record} />}
			</Button>
		</Tooltip>
	);
};

export default withStyles(styles)(DeviceTooltip);
