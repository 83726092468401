import React from 'react';
import { AutocompleteInput } from 'react-admin';

const choices = [
	{ id: 'data', name: 'DATA' },
	{ id: 'sdwan', name: 'SDWAN' },
	{ id: 'sdip', name: 'SDIP'},
    { id: 'data_pool', name: 'DATA_POOL' },
    { id: 'line', name: 'LINE' },
	{ id: 'starlink', name: 'STARLINK' },
	{ id: 'custom', name: 'CUSTOM' },
	{ id: 'activation', name: 'ACTIVATION' },
	{ id: 'third_party', name: 'THIRD PARTIES' },
	{ id: 'consultancy', name: 'CONSULTANCY' },
	{ id: 'distribution', name: 'DISTRIBUTION' },
	{ id: 'starlink_hw', name: 'STARLINK HW' },
	{ id: 'other', name: 'OTHER' },
];


const ServiceTypeField = ({ classes, record, source = "service_type", ...props }) => {
	if (!record) {
		return null;
	}

	if (record[source] && !choices.find((provider) => provider.id === record[source])) {
		choices.push({ id: record[source], name: record[source]});
	}

	return (
		<AutocompleteInput choices={choices} source={source} record={record} allowEmpty {...props} />
	);
};

export default ServiceTypeField;
export { choices };
