import React from 'react';
import {
	DateField,
	TextField,
	NumberField,
	SimpleShowLayout
} from 'react-admin';

import Datagrid from '../common/Datagrid';
import LinkFieldButton from '../common/LinkFieldButton';
import RecordSplitButton from '../common/RecordSplitButton';
import BooleanField from '../common/fields/BooleanField';

const DeviceProductDrawer = props => (
	<SimpleShowLayout {...props}>
		<TextField source="id" />
		<TextField source="reference" />
		<TextField source="name" />
		<TextField source="brand" />
		<TextField source="family" />
		<TextField source="sub_family" />
		<TextField source="category" />
		<BooleanField source="managed" />
		<BooleanField source="virtual" />
		<BooleanField source="accessory" />
		<NumberField source="stock_min_level" />
		<NumberField source="stock_max_level" />
		<TextField source="devices_stock_count" label="Stock" />
		<TextField source="stock_status"/>
		<TextField source="description" options={{ multiline: true }} rows="4" />
		<DateField label="Created at" source="created_at" />
		<DateField label="Updated at" source="updated_at" />
	</SimpleShowLayout>
);

const DeviceProductRow = props => (
	<Datagrid drawer={<DeviceProductDrawer {...props} />} {...props}>
		<LinkFieldButton label="Reference" sortBy="reference">
			<TextField source="reference" />
		</LinkFieldButton>
		<TextField source="name" />
		<TextField source="brand" />
		<TextField source="family" />
		<TextField source="sub_family" />
		<TextField source="category" />
		<BooleanField source="managed" />
		<BooleanField source="virtual" />
		<BooleanField source="accessory" />
		<TextField source="devices_count" sortable={false} />
		<TextField source="devices_stock_count" label="Stock" sortable={false} />
		<TextField source="stock_status" sortable={false} />
		<RecordSplitButton />
	</Datagrid>
);

export default DeviceProductRow;