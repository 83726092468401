import React from 'react';
import { AutocompleteInput } from 'react-admin';

const choices = [
	{ id: 'delivery', name: 'Delivery' },
];

const PackageLineQueueField = ({ classes, record, source = "queue", ...props }) => {
	if (!record) {
		return null;
	}

	if (record[source] && !choices.find((provider) => provider.id === record[source])) {
		choices.push({ id: record[source], name: record[source]});
	}

	return (
		<AutocompleteInput choices={choices} source={source} record={record} allowEmpty {...props} />
	);
};

export default PackageLineQueueField;
