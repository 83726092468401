import React from 'react';
import { ShowButton, EditButton } from 'react-admin';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Divider from '@material-ui/core/Divider';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';

import { themeShow } from './Show';

const useStyles = makeStyles(theme => ({
	docked: {
		position: 'absolute'
	},
	drawer: {
		width: '400px',
		paddingTop: '48px',
		backgroundColor: theme.palette.background.paper,
		'& *': {
			boxShadow: 'none'
		}
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	divider: {
		marginBottom: '24px'
	}
}));

const CustomDrawer = ({ children, actions, record, basePath, forwardedRef, ...props}) => {
	const classes =  useStyles();

	return (
		<ThemeProvider theme={themeShow}>
			<Drawer classes={{ docked: classes.docked, paper: classes.drawer }} {...props} ref={forwardedRef}>
				<div className={classes.header}>
					<IconButton onClick={props.onClose}>
						{props.anchor === 'right' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
					</IconButton>
					<div>
						{actions && React.cloneElement(actions, { basePath, record })} 
						{(record && basePath) && (
							<>
								<ShowButton record={record} basePath={basePath} />
								<EditButton record={record} basePath={basePath} />
							</>
						)}
					</div>
				</div>
				<Divider className={classes.divider} />
				{React.cloneElement(children, { basePath, record })} 
			</Drawer>
		</ThemeProvider>
	);
};

CustomDrawer.defaultProps = {
	variant: "persistent",
	anchor: "right"
};

export default React.forwardRef((props, ref) => <CustomDrawer {...props} forwardedRef={ref} />);