import React from 'react';
import { useRefresh } from 'react-admin';
import ClearIcon from '@material-ui/icons/Clear';

import IconButton from '../../common/IconButton';

import { assignService } from '../../../services/api';


const ServiceAssignButton = ({ record, variant, color }) => {
	const refresh = useRefresh();

	const handleClick = async () => {
		await assignService(record.id);
		refresh();
	};


	if(record.status === 'DRAFT') {
		return null;
	}

	return (
		<>
			<IconButton icon={<ClearIcon />} onClick={handleClick} variant={variant}>Assign Me</IconButton>
		</>
	);
};

export default ServiceAssignButton;