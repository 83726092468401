import React from 'react';
import {
	TextField,
	SimpleShowLayout
} from 'react-admin';

import Datagrid from '../common/Datagrid';
import LinkFieldButton from '../common/LinkFieldButton';
import DateField from '../common/fields/DateField';
import RecordSplitButton from '../common/RecordSplitButton';

const LineProviderDrawer = props => (
	<SimpleShowLayout {...props}>
		<TextField source="id" />
		<TextField source="name" />
		<TextField source="lines_count" />
		<DateField label="Created at" source="created_at" />
		<DateField label="Updated at" source="updated_at" />
	</SimpleShowLayout>
);

const LineProviderRow = ({ children, ...props }) => (
	<Datagrid drawer={<LineProviderDrawer {...props} />} {...props}>
		<LinkFieldButton label="Name" sortBy="name">
			<TextField source="name" />
		</LinkFieldButton>
		<TextField source="description" />
		<TextField source="lines_count" sortable={false} />
		<RecordSplitButton />
		{children}
	</Datagrid>
);

export default LineProviderRow;