import React from 'react';
import { NumberField } from 'react-admin';

const NumberCurrencyField = ({ record, ...props }) => {
    if (!record) {
        return null;
    }

    const currency = record?.currency || 'eur';

    return (
        <NumberField
            record={record}
            options={{ style: 'currency', currency, maximumFractionDigits: 2 }}
            {...props}
        />
    );
};

NumberCurrencyField.defaultProps = {
    addLabel: true
};

export default NumberCurrencyField;
