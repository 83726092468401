import React, { useState } from 'react';
import { SaveButton, TextInput, NumberInput, SelectInput, FormDataConsumer, Labeled, DateInput } from 'react-admin';
import IconEdit from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';

import NumberCurrencyField from '../common/fields/NumberCurrencyField';


const InvoiceLineQuickEditButton = ({ className, index, vatList, typeList, currency, variant, ...props }) => {
	const [showDialog, setShowDialog] = useState(false);

	const handleOpenDialog = () => setShowDialog(true);

	const handleCloseDialog = () => setShowDialog(false);

	const handleSaveClick = () => {
		handleCloseDialog();
	};

	let source = '';
	if (props.source) {
		source = `${props.source}.`;
	}

	return (
		<>
			<IconButton onClick={handleOpenDialog} className={className} size="small">
				<IconEdit />
			</IconButton>
			<Dialog fullWidth open={showDialog} onClose={handleCloseDialog} aria-label="Edit Invoice Line">
				<DialogTitle>Edit Invoice Line</DialogTitle>
				<DialogContent>
					<SelectInput label="Type" source={`${source}type`} choices={typeList} variant={variant} />
					<TextInput label="Package Reference" source={`${source}package_reference`} variant={variant} />
					<TextInput label="Customer Reference" source={`${source}customer_reference`} variant={variant} />
					<TextInput label="Description" source={`${source}description`} options={{ multiline: true }} variant={variant} />
					<NumberInput label="Quantity" source={`${source}qty`} variant={variant} />
					<TextInput label="Price" source={`${source}unit_price`} variant={variant} />
					<SelectInput label="VAT" source={`${source}vat_code`} choices={vatList} variant={variant} />
					<TextInput label="start_date" source={`${source}start_date`} variant={variant} />
					<TextInput label="end_date" source={`${source}end_date`} variant={variant} />
					<FormDataConsumer>
						{({ formData }) => {
							if (formData.lines && formData['lines'][index]) {
								const total = formData['lines'][index].qty * formData['lines'][index].unit_price;
								if (total) {
									return (
										<Labeled label="Total">
											<NumberCurrencyField record={{ currency, total }} source="total" />
										</Labeled>
									);
								}
							}
							return null;
						}}
					</FormDataConsumer>
				</DialogContent>
				<DialogActions>
					<SaveButton handleSubmitWithRedirect={handleSaveClick} />
				</DialogActions>
			</Dialog>
		</>
	);
};

export default InvoiceLineQuickEditButton;