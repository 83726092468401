import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';
import {
	translate,
	DashboardMenuItem as RADashboardMenuItem,
	MenuItemLink as RAMenuItemLink,
	getResources,
	WithPermissions
} from 'react-admin';
import inflection from 'inflection';
import preval from 'preval.macro';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import MapIcon from '@material-ui/icons/Map';

import SubMenu from './common/SubMenu';
import customers from './customers';
import contacts from './contacts';
import sites from './sites';
import devices from './devices';
import simcards from './simcards';
import simpools from './simpools';
import lines from './lines';
import servers from './servers';
import providers from './providers';
import users from './users';
import logs from './logs';
import records from './records';
import usages from './usages';
import device_products from './device_products';
import line_providers from './line_providers';
import data_pricings from './data_pricings';
import service_products from './service_products';
import services from './services';
import package_lines from './package_lines';
import packages from './packages';
import product_items from './product_items';
import ips from './ips';
import location_updates from './location_updates';
import data_pools from './data_pools';
import addresses from './addresses';
import companies from './companies';
import speedtests from './speedtests';
import invoices from './invoices';
import starlink_terminals from './starlink_terminals';
import starlink_service_lines from './starlink_service_lines';
import starlink_subscriptions from './starlink_subscriptions';
import starlink_accounts from './starlink_accounts';
import starlink_addresses from './starlink_addresses';

const styles = theme => ({
	active: {
		backgroundColor: theme.palette.action.hover
	}
});

const MenuItemLink = withStyles(styles)(RAMenuItemLink);
const DashboardMenuItem = withStyles(styles)(RADashboardMenuItem);

const translatedResourceName = (resources, name, translate) => {
	const resource = resources.find(resource => resource.name === name);

	if (!resource) {
		return name;
	}

	return (
		translate(`resources.${resource.name}.name`, {
			smart_count: 2,
			_:
				resource.options && resource.options.label
					? translate(resource.options.label, {
						smart_count: 2,
						_: resource.options.label,
					})
					: inflection.humanize(inflection.pluralize(resource.name))
		})
	);
};

class Menu extends Component {
	state = {
		menuDashboards: true,
		menuCRM: true,
		menuInventory: true,
		menuMetadata: true,
		menuAccounting: true,
		menuExperimental: false,
		menuPricing: false,
		menuBusiness: false,
		menuIncontrol: false,
		menuStarlink: false,
		menuReports: true,
	};

	static propTypes = {
		onMenuClick: PropTypes.func,
		logout: PropTypes.object,
	};

	handleToggle = menu => {
		this.setState(state => ({ [menu]: !state[menu] }));
	};

	getBuildDate = () => {
		const buildDate = moment(preval`module.exports = new Date();`);
		const now = moment.now();
		return moment.duration(buildDate-now).humanize(true);
	}

	render() {
		const { onMenuClick, open, translate, resources } = this.props;

		return (
			<React.Fragment>
				<DashboardMenuItem onClick={onMenuClick} />
				<SubMenu
					handleToggle={() => this.handleToggle('menuDashboards')}
					isOpen={this.state.menuDashboards}
					sidebarIsOpen={open}
					name="Dashboards"
				>
					<MenuItemLink
						to={`/dashboards/lines`}
						primaryText={translatedResourceName(resources, 'lines', translate)}
						leftIcon={<lines.icon />}
						onClick={onMenuClick}
					/>
					<MenuItemLink
						to={`/dashboards/usages`}
						primaryText={translatedResourceName(resources, 'usages', translate)}
						leftIcon={<usages.icon />}
						onClick={onMenuClick}
					/>
					<MenuItemLink
						to={`/dashboards/business`}
						primaryText={'Business'}
						leftIcon={<packages.icon />}
						onClick={onMenuClick}
					/>
					<MenuItemLink
						to={`/dashboards/starlink`}
						primaryText={'Starlink'}
						leftIcon={<starlink_terminals.icon />}
						onClick={onMenuClick}
					/>
					<MenuItemLink
						to={`/dashboards/stocks`}
						primaryText={'Stocks'}
						leftIcon={<devices.icon />}
						onClick={onMenuClick}
					/>
					<MenuItemLink
						to={`/dashboards/delivery`}
						primaryText={'Delivery'}
						leftIcon={<devices.icon />}
						onClick={onMenuClick}
					/>
				</SubMenu>
				<SubMenu
					handleToggle={() => this.handleToggle('menuCRM')}
					isOpen={this.state.menuCRM}
					sidebarIsOpen={open}
					name="CRM"
				>
					<MenuItemLink
						to={`/customers`}
						primaryText={translatedResourceName(resources, 'customers', translate)}
						leftIcon={<customers.icon />}
						onClick={onMenuClick}
					/>
					<MenuItemLink
						to={`/contacts`}
						primaryText={translatedResourceName(resources, 'contacts', translate)}
						leftIcon={<contacts.icon />}
						onClick={onMenuClick}
					/>
					<MenuItemLink
						to={`/sites`}
						primaryText={translatedResourceName(resources, 'sites', translate)}
						leftIcon={<sites.icon />}
						onClick={onMenuClick}
					/>
					<MenuItemLink
						to={`/addresses`}
						primaryText='Billing Addresses'
						leftIcon={<addresses.icon />}
						onClick={onMenuClick}
					/>
					<MenuItemLink
						to={`/companies`}
						primaryText='Billing Companies'
						leftIcon={<companies.icon />}
						onClick={onMenuClick}
					/>
					<MenuItemLink
						to={`/invoices`}
						primaryText='Invoices'
						leftIcon={<invoices.icon />}
						onClick={onMenuClick}
					/>
				</SubMenu>
				<SubMenu
					handleToggle={() => this.handleToggle('menuInventory')}
					isOpen={this.state.menuInventory}
					sidebarIsOpen={open}
					name="Inventory"
				>
					<MenuItemLink
						to={`/devices`}
						primaryText={translatedResourceName(resources, 'devices', translate)}
						leftIcon={<devices.icon />}
						onClick={onMenuClick}
					/>
					<MenuItemLink
						to={`/product_items`}
						primaryText={translatedResourceName(resources, 'product_items', translate)}
						leftIcon={<product_items.icon />}
						onClick={onMenuClick}
					/>
					<MenuItemLink
						to={`/simcards?filter={"type":"sim"}`}
						primaryText={translatedResourceName(resources, 'simcards', translate)}
						leftIcon={<simcards.icon />}
						onClick={onMenuClick}
					/>
					<MenuItemLink
						to={`/simcards?filter={"type":"esim"}`}
						primaryText={translatedResourceName(resources, 'eSIM', translate)}
						leftIcon={<simcards.icon />}
						onClick={onMenuClick}
					/>
					<MenuItemLink
						to={`/simpools`}
						primaryText={translatedResourceName(resources, 'simpools', translate)}
						leftIcon={<simpools.icon />}
						onClick={onMenuClick}
					/>
					<MenuItemLink
						to={`/lines`}
						primaryText={translatedResourceName(resources, 'lines', translate)}
						leftIcon={<lines.icon />}
						onClick={onMenuClick}
					/>
					<MenuItemLink
						to={`/ips`}
						primaryText={ips.title || translatedResourceName(resources, 'ips', translate)}
						leftIcon={<ips.icon />}
						onClick={onMenuClick}
					/>
				</SubMenu>
				<SubMenu
					handleToggle={() => this.handleToggle('menuMetadata')}
					isOpen={this.state.menuMetadata}
					sidebarIsOpen={open}
					name="Metadata"
				>
					<MenuItemLink
						to={`/providers`}
						primaryText={providers.title || translatedResourceName(resources, 'providers', translate)}
						leftIcon={<providers.icon />}
						onClick={onMenuClick}
					/>
					<MenuItemLink
						to={`/line_providers`}
						primaryText={line_providers.title || translatedResourceName(resources, 'line_providers', translate)}
						leftIcon={<line_providers.icon />}
						onClick={onMenuClick}
					/>
					<MenuItemLink
						to={`/device_products`}
						primaryText={device_products.title || translatedResourceName(resources, 'device_products', translate)}
						leftIcon={<device_products.icon />}
						onClick={onMenuClick}
					/>
				</SubMenu>
				<WithPermissions
					render={({ permissions }) => (
						permissions && permissions.includes('can_manage_pricing') ?
							<SubMenu
								handleToggle={() => this.handleToggle('menuPricing')}
								isOpen={this.state.menuPricing}
								sidebarIsOpen={open}
								name="Pricing"
							>
							<MenuItemLink
								to={`/data_pricings`}
								primaryText={translatedResourceName(resources, 'data_pricings', translate)}
								leftIcon={<data_pricings.icon />}
								onClick={onMenuClick}
							/>
						</SubMenu>
							: null
					)}
				/>
			
				<SubMenu
					handleToggle={() => this.handleToggle('menuBusiness')}
					isOpen={this.state.menuBusiness}
					sidebarIsOpen={open}
					name="Business"
				>
					<MenuItemLink
						to={`/packages`}
						primaryText={packages.title || translatedResourceName(resources, 'packages', translate)}
						leftIcon={<service_products.icon />}
						onClick={onMenuClick}
					/>
					<MenuItemLink
						to={`/services`}
						primaryText={services.title || translatedResourceName(resources, 'services', translate)}
						leftIcon={<service_products.icon />}
						onClick={onMenuClick}
					/>
					<MenuItemLink
						to={`/package_lines`}
						primaryText={package_lines.title || translatedResourceName(resources, 'package_lines', translate)}
						leftIcon={<package_lines.icon />}
						onClick={onMenuClick}
					/>
					<MenuItemLink
						to={`/service_products`}
						primaryText={service_products.title || translatedResourceName(resources, 'service_products', translate)}
						leftIcon={<service_products.icon />}
						onClick={onMenuClick}
					/>
					<MenuItemLink
						to={`/data_pools`}
						primaryText="Data Pools"
						leftIcon={<data_pools.icon />}
						onClick={onMenuClick}
						dense
					/>
				</SubMenu>
				<SubMenu
					handleToggle={() => this.handleToggle('menuIncontrol')}
					isOpen={this.state.menuIncontrol}
					sidebarIsOpen={open}
					name="InControl"
				>
					<MenuItemLink
						to={`/servers`}
						primaryText={translatedResourceName(resources, 'servers', translate)}
						leftIcon={<servers.icon />}
						onClick={onMenuClick}
					/>
				</SubMenu>
				<SubMenu
					handleToggle={() => this.handleToggle('menuStarlink')}
					isOpen={this.state.menuStarlink}
					sidebarIsOpen={open}
					name="Starlink"
				>
					<MenuItemLink
						to={`/starlink/provision`}
						primaryText="Provision"
						leftIcon={<servers.icon />}
						onClick={onMenuClick}
					/>
					<MenuItemLink
						to={`/starlink_terminals`}
						primaryText="Terminals"
						leftIcon={<starlink_terminals.icon />}
						onClick={onMenuClick}
					/>
					<MenuItemLink
						to={`/starlink_service_lines`}
						primaryText="Service lines"
						leftIcon={<starlink_service_lines.icon />}
						onClick={onMenuClick}
					/>
					<MenuItemLink
						to={`/starlink_subscriptions`}
						primaryText="Subscriptions"
						leftIcon={<starlink_subscriptions.icon />}
						onClick={onMenuClick}
					/>
					<MenuItemLink
						to={`/starlink_accounts`}
						primaryText="Accounts"
						leftIcon={<starlink_accounts.icon />}
						onClick={onMenuClick}
					/>
					<MenuItemLink
						to={`/starlink_addresses`}
						primaryText="Addresses"
						leftIcon={<starlink_addresses.icon />}
						onClick={onMenuClick}
					/>
				</SubMenu>
				<SubMenu
					handleToggle={() => this.handleToggle('menuTools')}
					isOpen={this.state.menuTools}
					sidebarIsOpen={open}
					name="Tools"
				>
					<MenuItemLink
						to={`/speedtests`}
						primaryText={translatedResourceName(resources, 'speedtests', translate)}
						leftIcon={<speedtests.icon />}
						onClick={onMenuClick}
					/>
				</SubMenu>
				<SubMenu
					handleToggle={() => this.handleToggle('menuReports')}
					isOpen={this.state.menuReports}
					sidebarIsOpen={open}
					name="Usage Reports"
				>
					<MenuItemLink
						to={`/usages/reports`}
						primaryText="Mobile"
						leftIcon={<usages.icon />}
						onClick={onMenuClick}
						dense
					/>
					<MenuItemLink
						to={`/starlink_usages/reports`}
						primaryText="Starlink"
						leftIcon={<usages.icon />}
						onClick={onMenuClick}
						dense
					/>
				</SubMenu>
				<MenuItemLink
					to={`/users`}
					primaryText={translatedResourceName(resources, 'users', translate)}
					leftIcon={<users.icon />}
					onClick={onMenuClick}
					dense
				/>
				<MenuItemLink
					to={`/records`}
					primaryText="Ops History"
					leftIcon={<records.icon />}
					onClick={onMenuClick}
					dense
				/>
				<MenuItemLink
					to={`/logs`}
					primaryText={translatedResourceName(resources, 'logs', translate)}
					leftIcon={<logs.icon />}
					onClick={onMenuClick}
					dense
				/>
				<MenuItemLink
					to={`/usages`}
					primaryText={translatedResourceName(resources, 'usages', translate)}
					leftIcon={<usages.icon />}
					onClick={onMenuClick}
					dense
				/>
				<MenuItemLink
					to={`/location_updates`}
					primaryText="Network Events"
					leftIcon={<location_updates.icon />}
					onClick={onMenuClick}
					dense
				/>
				<MenuItemLink
					to={`/devices/map`}
					primaryText="Map"
					leftIcon={<MapIcon />}
					onClick={onMenuClick}
					dense
				/>
				<Typography variant="caption" className="build-date">build {this.getBuildDate()}</Typography>
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => ({
	open: state.admin.ui.sidebarOpen,
	theme: state.theme,
	// locale: state.i18n.locale,
	resources: getResources(state)
});

const enhance = compose(
	withRouter,
	connect(
		mapStateToProps,
		{}
	),
	translate
);

export default enhance(Menu);