import React, { useState } from 'react';
import { Confirm, useNotify } from 'react-admin';
import AccountIcon from '@material-ui/icons/AccountCircle';

import IconButton from '../../common/IconButton';

import { impersonateCustomer } from '../../../services/api';


const ImpersonateButton = ({ record }) => {
	const notify = useNotify();
	const [showDialog, setShowDialog] = useState(false);

	const handleConfirm = async () => {
		const response = await impersonateCustomer(record.id);
		handleClose();

		if (response.success) {
			window.open(response.redirect_url, '_blank');
		}
		else if(response.reason) {
			notify(response.reason, 'warning');
		}
	};

	const handleOpen = () => {
		setShowDialog(true);
	};

	const handleClose = () => {
		setShowDialog(false);
	};

	return (
		<>
			<IconButton icon={<AccountIcon />} onClick={handleOpen}>Impersonate</IconButton>
			<Confirm
				isOpen={showDialog}
				title="Impersonate"
				content={`Are you sure you want to log in as ${record?.name} ?`}
				onConfirm={handleConfirm}
				onClose={handleClose}
			/>
		</>
	);
};

export default ImpersonateButton;