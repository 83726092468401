import SettingsInputAntennaIcon from '@material-ui/icons/SettingsInputAntenna';

import StarlinkTerminalList from './StarlinkTerminalList';
import StarlinkTerminalShow from './StarlinkTerminalShow';
import StarlinkTerminalEdit from './StarlinkTerminalEdit';

export default {
	list: StarlinkTerminalList,
	show: StarlinkTerminalShow,
	edit: StarlinkTerminalEdit,
	icon: SettingsInputAntennaIcon,
	title: "Starlink terminals"
};
