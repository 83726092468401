import React from 'react';
import { ReferenceField, Link } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';

const styles = {
	icon: {
		padding: '7px 8px',
		display: 'inline-flex',
		alignItems: 'center',
		minWidth: 'auto',

		'& img': {
			maxHeight: '20px',
			width: 'auto'
		},
		'& img + span': {
			paddingLeft: '0.5em'
		}
	},
	container: {
		display: 'flex',
		alignItems: 'center',

		'& a:not(:first-child)': {
			marginLeft: '5px'
		}
	}
};

const FreshdeskLink = withStyles(styles)(({ record, classes, children, basePath, ...props }) => {
	if (!record || !record.freshdesk_id) {
		return null;
	}

	// TODO: Move to backend?
	const freshdeskLink = `https://help.venntelecom.com/a/tickets/filters/search?orderBy=created_at&orderType=desc&q[]=created%3A%22two_months%22&q[]=customers%3A%5B%22${record.freshdesk_id}%22%5D&ref=all_tickets`;

	return (
		<Button component="a" href={freshdeskLink} target="__blank" className={classes.icon} size="small" color="primary" {...props}>
			<img src="/img/icons/freshdesk.png" alt="Freshdesk" />
			{children && (<span>{children}</span>)}
		</Button>
	);
});

const CustomerLinks = withStyles(styles)(({ record, source, resource, classes, ...props }) => {
	if (!record) {
		return null;
	}

	return (
		<span className={classes.container}>
			<Typography component={Link} to={`/${resource}/${record.id}/show`} variant="body2" color="primary">{record[source]}</Typography>
			{record.freshdesk_id && (
				<FreshdeskLink record={record} />
			)}
		</span>
	);
});

const CustomerReferenceField = props => (
	<ReferenceField {...props}>
		<CustomerLinks source="name" />
	</ReferenceField>
);

CustomerReferenceField.defaultProps = {
	allowEmpty: true,
	source: "customer_id",
	reference: "customers",
	link: false,
	basePath: "/customers",
	addLabel: true,
	label: "Customer"
};

export default CustomerReferenceField;
export { FreshdeskLink };