import React from 'react';
import { TextInput, ReferenceInput, AutocompleteInput, FormDataConsumer, DateInput } from 'react-admin';

import Edit from '../common/Edit';
import SimpleForm from '../common/SimpleForm';
import StatusField from './StatusField';
import AccountingStatusField from './AccountingStatus';
import LineTypeField from './LineTypeField';
import LineArchiveButton from './LineActionsButtons/LineArchiveButton';
import SiteReferenceInputWithCreate from '../sites/SiteReferenceInputWithCreate';


const optionText = choice => choice && choice.name ? choice.name : '';
const userOptionText = choice => choice && choice.email ? choice.email : '';

const LineEdit = props => (
    <Edit {...props} archiveButton={<LineArchiveButton />}>
        <SimpleForm>
            <TextInput source="id" disabled />
            <TextInput label="SO" source="sales_order_number" />
            <TextInput label="Subscription number" source="subscription_id" />
            <ReferenceInput label="Provider" source="provider_id" reference="line_providers" sort={{ field: 'name', order: 'ASC' }} allowEmpty>
                <AutocompleteInput optionText={optionText} />
            </ReferenceInput>
            <ReferenceInput label="Customer" source="customer_id" reference="customers" sort={{ field: 'name', order: 'ASC' }} allowEmpty>
                <AutocompleteInput optionText={optionText} />
            </ReferenceInput>
            <FormDataConsumer>
                {({ formData, ...rest }) => (
                    <SiteReferenceInputWithCreate
                        {...rest}
                        label="Site"
                        source="site_id"
                        reference="sites"
                        filter={formData.customer_id && { customer_id: formData.customer_id }}
                        key={formData.customer_id}
                    />
                )}
            </FormDataConsumer>
            <ReferenceInput label="Assigned To" source="assigned_to_id" reference="users" filter={{ is_staff: true }} sort={{ field: 'name', order: 'ASC' }} allowEmpty >
                <AutocompleteInput optionText={userOptionText} />
            </ReferenceInput>
            <StatusField />
            <LineTypeField />
            <DateInput source="next_action_date" />
            <DateInput source="installation_date" />
            <DateInput source="decommission_date" />
            <AccountingStatusField />
            <TextInput source="ppoe_login" />
            <TextInput source="ppoe_password" />
            <TextInput source="contact_name" />
            <TextInput source="contact_phone" />
            <TextInput source="contact_email" />
            <TextInput source="installation_notes" options={{ multiline: true }} rows="4" />
            <TextInput source="notes" options={{ multiline: true }} rows="4" />
        </SimpleForm>
    </Edit>
);

export default LineEdit;