import React from 'react';
import { TextField, FunctionField, SimpleShowLayout, ReferenceField } from 'react-admin';

import Datagrid from '../common/Datagrid';
import RecordSplitButton from '../common/RecordSplitButton';
import HumanDateField from '../common/fields/HumanDateField';
import PingButton from './IPActionsButtons/PingButton';
import LinkFieldButton from '../common/LinkFieldButton';
import DateField from '../common/fields/DateField';
import BooleanField from '../common/fields/BooleanField';


const CustomerField = (props) => (
	<ReferenceField label="Customer" source="device_id" resource="devices" reference="devices" allowEmpty link={false} {...props} basePath="/customers">
		<ReferenceField source="customer_id" resource="customers" reference="customers" allowEmpty link="show">
			<TextField source="name" />
		</ReferenceField>
	</ReferenceField>
);


const IPDrawer = (props) => (
	<SimpleShowLayout {...props}>
		<TextField label="Id" source="id" />
		<TextField source="address" />
		<BooleanField label="Used" source="used" />
		<ReferenceField label="Device" source="device_id" reference="devices" link="show" allowEmpty>
			<FunctionField render={record => `${record.name} / ${record.serial}`} />
		</ReferenceField>
		<TextField source="unmanaged_device_description" />
		<CustomerField label="Customer" addLabel />
		<TextField source="subnet" />
		<TextField source="gateway" />
		<TextField source="subnet_type" />
		<TextField source="firewall" />
		<TextField source="vlan_id" />
		<HumanDateField label="Last ping at" addLabel source="last_ping_at" />
		<FunctionField label="Last ping result" render={record => (record.last_ping_result?.avg_rtt) ? `${record.last_ping_result.avg_rtt} ms` : ''} />
		<DateField label="Created at" source="created_at" />
		<DateField label="Updated at" source="updated_at" />
		<TextField source="notes" />
	</SimpleShowLayout>
);

const IPRow = ({ children, ...props }) => (
	<Datagrid drawer={<IPDrawer {...props} />} {...props}>
		<LinkFieldButton label="Address" sortBy="address">
			<TextField source="address" />
		</LinkFieldButton>
		<TextField source="subnet" />
		<LinkFieldButton label="Device" source="device" basePath="/devices" sortable={false}>
			<TextField source="serial" />
		</LinkFieldButton>
		<TextField source="unmanaged_device_description" label="Other Device"/>
		<LinkFieldButton label="Customer" source="customer" basePath="/customers" sortable={false}>
			<TextField source="name" />
		</LinkFieldButton>
		<HumanDateField source="last_ping_at" />
		<FunctionField label="Last ping result" render={record => (record.last_ping_result?.avg_rtt) ? `${record.last_ping_result.avg_rtt} ms` : ''} />
		<BooleanField source="used" />
		<TextField source="subnet_type" />
		<TextField source="vlan_id" />
		<TextField source="firewall" />
		{children}
		<RecordSplitButton>
			<PingButton />
		</RecordSplitButton>
	</Datagrid>
);

IPRow.defaultProps = {
	basePath: '/ips'
};

export default IPRow;