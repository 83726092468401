import React from 'react';
import { TabbedShowLayout, Tab } from 'react-admin';
import { Helmet } from 'react-helmet';

import Show, { ShowActions } from '../../common/Show';
import DefaultServiceShow from './DefaultServiceShow';
import SDWanServiceShow, { SDWanServiceActions } from './SDWanServiceShow';
import SDIPServiceShow, { SDIPnServiceActions } from './SDIPServiceShow';
import DataServiceShow, { DataServiceActions } from './DataServiceShow';
import LineServiceShow, { LineServiceActions } from './LineServiceShow';
import OneTimeServiceShow from './OneTimeServiceShow';
import StarLinkServiceShow, { StarlinkServiceActions } from './StarLinkServiceShow';
import FilteredLogList from '../../logs/FilteredLogList';

const ServiceTitle = ({ record }) => (
	<>
		<Helmet>
			<title>{`KB - Service[${record.name}]`}</title>
		</Helmet>
		<span>{record.name}</span>
	</>
);

const Content = ({ record, ...props }) => {
	const type = record?.type?.toUpperCase();
	const frequency = record?.frequency?.toUpperCase();

	if (frequency === 'ONETIME'){
		return <OneTimeServiceShow record={record} {...props} />;
	}

	if (type === 'SDWAN') {
		return <SDWanServiceShow record={record} {...props} />;
	}

	if (type === 'SDIP') {
		return <SDIPServiceShow record={record} {...props} />;
	}

	if (type === 'DATA') {
		return <DataServiceShow record={record} {...props} />;
	}

	if (type === 'LINE') {
		return <LineServiceShow record={record} {...props} />;
	}

	if (type === 'STARLINK') {
		return <StarLinkServiceShow record={record} {...props} />;
	}

	return <DefaultServiceShow record={record} {...props} />;
};

const Actions = (props) => {
	// TODO: remove when provision form validated
	return <ShowActions {...props} />;

	const type = props?.data?.type?.toUpperCase();
	if (type === 'SDWAN') {
		return <SDWanServiceActions {...props} />;
	}

	if (type === 'DATA') {
		return <DataServiceActions {...props} />;
	}

	if (type === 'LINE') {
		return <LineServiceActions {...props} />;
	}

	if (type === 'STARLINK') {
		return <StarlinkServiceActions {...props} />;
	}

	return <ShowActions {...props} />;
};

const ServiceShow = (props) => (
	<Show title={<ServiceTitle />} actions={<Actions {...props} />} {...props}>
		<TabbedShowLayout>
			<Content />
			<Tab label="Logs">
				<FilteredLogList />
			</Tab>
		</TabbedShowLayout>
	</Show>
);

export default ServiceShow;
