import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import WifiIcon from '@material-ui/icons/Wifi';
import UsbIcon from '@material-ui/icons/Usb';
import CellularIcon from '@material-ui/icons/SignalCellular4Bar';
import DevicesIcon from '@material-ui/icons/Devices';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core';

import green from '@material-ui/core/colors/green';
import orange from '@material-ui/core/colors/orange';
import red from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';

import Tooltip from '../common/Tooltip';


const styles = {
	btn: {
		padding: '2px',
		minWidth: 'auto'
	},
	chip: {
		height: '20px'
	},
	divider: {
		margin: '5px 0'
	},
	tooltip: {
		maxWidth: 'none'
	}
};


const WanIcon = (record) => {
	const colors = {
		'green': green[500],
		'yellow': orange[500],
		'red': red[500],
		'gray': grey[500]
	};
	const iconStyle = {
		color: colors[record.status_led],
		fontSize: '1rem'
	};

	switch(record.wan_type) {
		case 'cellular':
			return <CellularIcon style={iconStyle} />
		case 'wifi':
			return <WifiIcon style={iconStyle} />
		case 'usb':
			return <UsbIcon style={iconStyle} />
		default:
			return <DevicesIcon style={iconStyle} />
	}
};

const WanTooltip = ({ classes, record, ...props }) => (
	<Tooltip
		classes={{ tooltip: classes.tooltip }}
		title={
			<React.Fragment>
				<Typography variant="subtitle1"><b>{`${record.name} (${record.ic_status})`}</b></Typography>
				{record.cellular_network_type && (
					<Chip color="primary" label={record.cellular_network_type} className={classes.chip} />
				)}
				<Typography>WAN type: {record.wan_type}</Typography>
				<Typography>{record.ip_address}</Typography>

				{record.sim_a && (
					<React.Fragment>
						<Divider className={classes.divider} />
						<Typography><b>SIM Card A</b></Typography>
						<Typography>IMSI: {record.sim_a.imsi}</Typography>
						<Typography gutterBottom>ICCID: {record.sim_a.iccid}</Typography>
					</React.Fragment>
				)}
				{record.sim_b && (
					<React.Fragment>
						<Typography><b>SIM Card B</b></Typography>
						<Typography>IMSI: {record.sim_b.imsi}</Typography>
						<Typography gutterBottom>ICCID: {record.sim_b.iccid}</Typography>
					</React.Fragment>
				)}
			</React.Fragment>
		}
	>
		<Button size="small" className={classes.btn}>
			{WanIcon(record)}
		</Button>
	</Tooltip>
);

export default withStyles(styles)(WanTooltip);
