import React from 'react';
import { FormTab, TextInput, ReferenceInput, AutocompleteInput } from 'react-admin';

import Create from '../common/Create';
import TabbedForm from '../common/TabbedForm';
import PaymentTermsInput from './inputs/PaymentTermsInput';
import CountryInput from './inputs/CountryInput';
import PaymentMethodInput from './inputs/PaymentMethodInput';

const optionText = choice => choice.name ? choice.name : '';


const AddressCreate = props => (
	<Create {...props}>
		<TabbedForm redirect="list">
			<FormTab label="summary">
				<ReferenceInput label="Customer" source="customer_id" reference="customers" sort={{ field: 'name', order: 'ASC' }} allowEmpty>
					<AutocompleteInput optionText={optionText} />
				</ReferenceInput>
				<TextInput source="name" />
				<TextInput source="street" />
				<TextInput source="street_bis" />
				<TextInput source="number" />
				<TextInput source="box" />
				<TextInput source="zipcode" />
				<TextInput source="city" />
				<CountryInput source="country" />
				<TextInput source="vat" />
				<TextInput source="email" />
				<TextInput source="phone" />
				<PaymentTermsInput />
				<PaymentMethodInput />
			</FormTab>
		</TabbedForm>
	</Create>
);

export default AddressCreate;