import React from 'react';
import { SingleFieldList } from 'react-admin';

import LineTooltip from './LineTooltip';


const LineListField = ({ data, ...props }) => (
	<SingleFieldList linkType="show" data={data} {...props}>
		<LineTooltip />
	</SingleFieldList>
);

export default LineListField;