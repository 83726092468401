import React, { useState } from 'react';
import { useRefresh } from 'react-admin';
import IconRefresh from '@material-ui/icons/Refresh';
import CircularProgress from '@material-ui/core/CircularProgress';

import IconButton from '../../common/IconButton';

import { syncServer } from '../../../services/api';


const ICServerSyncButton = ({ record, variant, color }) => {
	const [loading, setLoading] = useState(false);
	const refresh = useRefresh();

	const handleSynchronize = async () => {
		setLoading(true);
		const response = await syncServer(record.id);
		if (response.id) {
			refresh();
		}
		setLoading(false);
	};

	return(
		<IconButton 
			icon={loading ? (
				<CircularProgress
					size={12}
					thickness={2} 
				/>
			) : (
				<IconRefresh />
			)}
			onClick={handleSynchronize}
			disabled={loading}
			variant={variant}
			color={color}
		>
			Synchronize
		</IconButton>
	);
};

export default ICServerSyncButton;