import React, { useState, useEffect } from 'react';
import { PieChart, Pie, Tooltip, ResponsiveContainer } from 'recharts';

import { fetchInvoicesReport } from '../../services/api';


const InvoiceReportPieGraph = ({ group_by }) => {
    const [stats, setStats] = useState();

    useEffect(() => {
        const fetchStats = async () => {
            const data = await fetchInvoicesReport({ group_by });
            setStats(data.data);
        }
        fetchStats();
    }, [group_by]);

    return (
        <ResponsiveContainer width="50%" height={400}>
            <PieChart>
                <Pie
                    dataKey="value"
                    nameKey="key"
                    isAnimationActive={false}
                    data={stats}
                    outerRadius={80}
                    fill="#3A8B7E"
                />
                <Tooltip />
            </PieChart>
        </ResponsiveContainer>
    );
};

export default InvoiceReportPieGraph;
