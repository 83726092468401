import React from 'react';
import {
    AutocompleteInput,
    BooleanInput,
    TextInput,
    ReferenceInput,
} from 'react-admin';
import GetAppIcon from '@material-ui/icons/GetApp';

import SiteRow from './SiteRow';
import List, { ListActions } from '../common/List';
import Filter from '../common/Filter';
import IconButton from '../common/IconButton';

import { API_URL } from '../../services/settings';


const optionText = choice => choice.name ? choice.name : '';

const SiteListActions = (props) => (
    <ListActions {...props}>
        <IconButton icon={<GetAppIcon />} component="a" href={`${API_URL}/sites/export.xls`}>Export</IconButton>
    </ListActions>
);

const SiteFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <ReferenceInput label="Customer" source="customer_id" reference="customers"  sort={{ field: 'name', order: 'ASC' }} allowEmpty alwaysOn>
            <AutocompleteInput optionText={optionText} />
        </ReferenceInput>
        <BooleanInput label="Archived" source="archived" alwaysOn />
        <BooleanInput label="Empty" source="empty" alwaysOn />
        <BooleanInput label="Stock" source="is_stock" alwaysOn />
    </Filter>
);

const filterDefaultValues = {
    archived: false
};

const SiteList = props => (
    <List {...props} filters={<SiteFilter />} filterDefaultValues={filterDefaultValues} actions={<SiteListActions />} exporter={false}>
        <SiteRow />
    </List>
);

export default SiteList;