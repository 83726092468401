import React, { useState } from 'react';
import {
	TabbedShowLayout,
	SimpleShowLayout,
	Tab,
	TextField,
	ReferenceArrayField,
	ReferenceManyField,
	Pagination,
} from 'react-admin';
import { Link } from 'react-router-dom';
import IconAdd from '@material-ui/icons/Add';
import { Helmet } from 'react-helmet';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import moment from 'moment';

import Show, { ShowActions } from '../common/Show';
import IconButton from '../common/IconButton';
import MapComponent from '../common/MapComponent';
import UsageGraph from '../common/UsageGraph';
import DeviceRow from '../devices/DeviceRow';
import LineRow from '../lines/LineRow';
import DateField from '../common/fields/DateField';
import CustomerReferenceField from '../customers/CustomerReferenceField';
import { IncontrolLink } from '../devices/fields/DeviceReferenceField';
import FilteredLogList from '../logs/FilteredLogList';
import FilteredRecordList from '../records/FilteredRecordList';
// import CurrentMonthUsageGraph from './CurrentMonthUsageGraph';
import ProductItemRow from '../product_items/ProductItemRow';
import PackageRow from '../packages/PackageRow';
import PackageServiceGridList from '../packages/PackageServiceGridList';
import StarlinkTerminalRow from '../starlink_terminals/StarlinkTerminalRow';


const SiteTitle = ({ record }) => (
	<>
		<Helmet>
			<title>{`KB - Site[${record.name}]`}</title>
		</Helmet>
		<span>{record.name}</span>
	</>
);

const SiteActions = (props) => (
	<ShowActions {...props}>
		<IconButton component={Link} to={`/sites/${props.data ? props.data.id : ''}/build`} icon={<IconAdd />}>Add device</IconButton>
	</ShowActions>
);

const Content = (props) => (
	<Grid container>
		<Grid item md={3} sm={12}>
			<SimpleShowLayout {...props}>
				<TextField source="id" />
				<TextField source="name" />
				<TextField source="description" label="Notes" options={{ multiline: true }} />
				<CustomerReferenceField />
				<TextField source="address" />
				<TextField source="street" />
				<TextField source="number" />
				<TextField source="zipcode" />
				<TextField source="city" />
				<TextField source="country" />
				<TextField source="lat" label="Latitude" />
				<TextField source="lng" label="Longitude" />
				<DateField label="Archived Date" source="archived_at" />
				<TextField source="contract_data_source" label="Contract Data Source" />
				<TextField source="data_limit" label="Data Limit Alert (GB)" />
				<TextField source="data_limit_contacts" label="Data Limit Contacts (CSV)" />
			</SimpleShowLayout>
		</Grid>
		<Grid item md={9} sm={12}>
			<Grid container>
				<Grid item md={7} xs={12}>
				</Grid>
				<Grid item md={5} xs={12}>
					<MapComponent {...props} />
				</Grid>
			</Grid>
		</Grid>
	</Grid>
);

const PackageList = ({ record }) => (
	<Box mt={5}>
		<Typography variant="h6">Packages</Typography>
		<ReferenceManyField record={record} addLabel={false} reference="packages" target="site_id" pagination={<Pagination />} filter={{ archived: false }}>
			<PackageServiceGridList />
		</ReferenceManyField>
	</Box>
);

const SiteShow = props => {
	const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
	const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'));

	const handleClickBar = (data) => {
		setStartDate(moment(data.date).startOf('month').format('YYYY-MM-DD'));
		setEndDate(moment(data.date).endOf('month').format('YYYY-MM-DD'));
	};

	return (
		<Show title={<SiteTitle />} actions={<SiteActions />} {...props}>
			<TabbedShowLayout>
				<Tab label="summary">
					<Content />
					<ReferenceArrayField addLabel={false} reference="devices" source="devices">
						<DeviceRow exclude={['site']}>
							<IncontrolLink />
						</DeviceRow>
					</ReferenceArrayField>
					<ReferenceArrayField addLabel={false} reference="lines" source="lines">
						<LineRow exclude={['site']} />
					</ReferenceArrayField>
					<ReferenceManyField addLabel={false} reference="starlink_terminals" target="site_id" pagination={<Pagination />} filter={{ archived: false }}>
						<StarlinkTerminalRow exclude={['site', 'device']} />
					</ReferenceManyField>
					<PackageList />
					<UsageGraph freq='monthly' title="Data Usage monthly (GB)" onClickBar={handleClickBar} />
					<UsageGraph
						freq='daily'
						title={`Data Usage ${new Date(startDate).toLocaleString('en', { month: 'long', year: 'numeric' })}`}
						options={{ start_date: startDate, end_date: endDate }}
					/>
				</Tab>
				<Tab label="Ops History">
					<FilteredRecordList />
				</Tab>
				<Tab label="logs">
					<FilteredLogList />
				</Tab>
				<Tab label="Product Items" path="product_items">
					<ReferenceManyField addLabel={false} reference="product_items" target="site_id" pagination={<Pagination />} filter={{ archived: false }}>
						<ProductItemRow exclude={['customer', 'reserved']} />
					</ReferenceManyField>
				</Tab>
				<Tab label="Packages" path="packages">
					<ReferenceManyField addLabel={false} reference="packages" target="site_id" pagination={<Pagination />} filter={{ archived: false }}>
						<PackageRow exclude={['site']} />
					</ReferenceManyField>
				</Tab>
				<Tab label="StarLinks" path="starlink_terminals">
					<ReferenceManyField addLabel={false} reference="starlink_terminals" target="site_id" pagination={<Pagination />} filter={{ archived: false }}>
						<StarlinkTerminalRow exclude={['site', 'device']} />
					</ReferenceManyField>
				</Tab>
			</TabbedShowLayout>
		</Show>
	);
};

export default SiteShow;