import React, { useState } from 'react';
import { Confirm, useRefresh, useNotify } from 'react-admin';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

import IconButton from '../../common/IconButton';

import { disassembleDevice } from '../../../services/api';


const DisassembleDeviceButton = ({ record, variant, color }) => {
	const refresh = useRefresh();
	const notify = useNotify();
	const [showDialog, setShowDialog] = useState(false);

	const handleConfirm = async () => {
		const response = await disassembleDevice(record.id);
		
		if(response.success) {
			refresh();
			notify('The device is disassembled');
		}
		else if(response.reason) {
			notify(response.reason, 'warning');
		}
		else {
			notify('Disassemble failed', 'warning');
		}

		handleClose();
	};

	const handleOpen = () => {
		setShowDialog(true);
	};

	const handleClose = () => {
		setShowDialog(false);
	};

	if(!record || record.ops_sim_cards.length === 0) {
		return null;
	}

	return (
		<>
			<IconButton icon={<RemoveCircleIcon />} onClick={handleOpen} variant={variant} color={color}>Disassemble SIMs</IconButton>
			<Confirm
				isOpen={showDialog}
				title="Disassemble SIMs from device"
				content={`Do you want to disassemble SIMs from the device ${record.name} (${record.serial})`}
				onConfirm={handleConfirm}
				onClose={handleClose}
			/>
		</>
	);
};

export default DisassembleDeviceButton;