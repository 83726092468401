import React, { useState } from 'react';
import { useRefresh, useNotify, useUnselectAll, SaveButton as RASaveButton, ReferenceInput, AutocompleteInput, FormDataConsumer, required } from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';

import IconButton from '../../../common/IconButton';
import SimpleForm from '../../../common/SimpleForm';
import Toolbar from '../../../common/Toolbar';
import DeployReasonInput from '../../DeployReasonInput';
import SiteReferenceInputWithCreate from '../../../sites/SiteReferenceInputWithCreate';

import { bulkMoveDevice } from '../../../../services/api';

const optionText = choice => choice && choice.name ? choice.name : '';

const SaveButton = ({ onClick, selectedIds, ...props }) => {
    const formState = useFormState();
    const refresh = useRefresh();
    const form = useForm();
    const notify = useNotify();
    const unselectAll = useUnselectAll();

    const handleClick = async () => {
        const formData = new FormData();
        selectedIds.forEach((id) => {
            formData.append('ids', id);
        });
        if (formState.values.site_id) {
            formData.append('site_id', formState.values.site_id);
        }
        if (formState.values.reason) {
            formData.append('reason', formState.values.reason);
        }

        const response = await bulkMoveDevice(formData);
        if (!response.success && response.reason) {
            notify(response.reason, 'warning');
        }

        unselectAll('devices');
        form.reset();
        onClick();
        refresh();
    };

    return <RASaveButton {...props} handleSubmit={handleClick} handleSubmitWithRedirect={handleClick} disabled={!formState.values.site_id} />;
};


const DeviceBulkMoveButton = ({ dataProvider, variant, color, ...props }) => {
    const [showDialog, setShowDialog] = useState(false);

    const handleSave = () => {
        handleClose();
    };

    const handleOpen = () => {
        setShowDialog(true);
    };

    const handleClose = () => {
        setShowDialog(false);
    };

    return (
        <>
            <IconButton onClick={handleOpen} icon={<SwapHorizIcon />} variant={variant}>Move</IconButton>
            <Dialog fullWidth open={showDialog} onClose={handleClose} aria-label="Move devices">
                <DialogTitle>Move {props.selectedIds.length} devices</DialogTitle>
                <DialogContent>
                    {`Are you sure you want to move these ${props.selectedIds.length} items?`}
                    <SimpleForm toolbar={<Toolbar onClose={handleClose} onSave={handleSave} saveButton={<SaveButton onClick={handleSave} selectedIds={props.selectedIds} />} />}>
                        <ReferenceInput label="Customer" source="customer_id" reference="customers" sort={{ field: 'name', order: 'ASC' }}>
                            <AutocompleteInput optionText={optionText} />
                        </ReferenceInput>
                        <FormDataConsumer>
                            {({ formData, ...rest }) => (
                                <SiteReferenceInputWithCreate
                                    {...rest}
                                    label="Site"
                                    source="site_id"
                                    reference="sites"
                                    filter={formData.customer_id && { customer_id: formData.customer_id }}
                                    validate={required()}
                                    key={formData.customer_id}
                                />
                            )}
                        </FormDataConsumer>
                        <DeployReasonInput source="reason" />
                    </SimpleForm>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default DeviceBulkMoveButton;