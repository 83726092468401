import React, { useState } from 'react';
import { useForm } from 'react-final-form';
import {
	fetchEnd,
	fetchStart,
	showNotification,
	Button,
	SaveButton,
	SimpleForm,
	useDataProvider,
	crudGetMatching,
	TextInput,
	ReferenceInput,
	AutocompleteInput
} from 'react-admin';
import { useDispatch } from 'react-redux';
import IconAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
	suggestionsContainerOpen: {
		position: 'absolute',
		marginBottom: theme.spacing(3),
		zIndex: theme.zIndex.modal + 200,
	}
}));

const optionText = choice => choice.name ? choice.name : '';

let quickCreateSubmit;
const FormDataExtractor = ({ children }) => {
	quickCreateSubmit = useForm().submit;
	return children;
};

const SiteQuickCreateButton = ({ resource, filter = {}, initialValues = {}, className }) => {
	const [showDialog, setShowDialog] = useState(false);
	const { change } = useForm();
	const dataProvider = useDataProvider();
	const dispatch = useDispatch();
	const classes = useStyles();

	const handleOpenDialog = () => setShowDialog(true);

	const handleCloseDialog = () => setShowDialog(false);

	const handleSave = () => {
		quickCreateSubmit();
		setShowDialog(false);
	};

	const handleSubmit = async values => {
		fetchStart();

		try {
			const { data } = await dataProvider.create('sites', { data: values });
			dispatch(
				crudGetMatching(
					'sites',
					`${resource}@site_id`,
					{ page: 1, perPage: 25 },
					{ field: 'id', order: 'DESC' },
					filter
				)
			);
			change('site_id', data.id);
			setShowDialog(false);
			fetchEnd();

		} catch (e) {
			fetchEnd();
			showNotification(e.message, 'error');
		}
	};

	return (
		<>
			<Button onClick={handleOpenDialog} label="ra.action.create" className={className}>
				<IconAdd />
			</Button>
			<Dialog fullWidth open={showDialog} onClose={handleCloseDialog} aria-label="Create Site">
				<DialogTitle>Create Site</DialogTitle>
				<DialogContent>
					<SimpleForm
						submitOnEnter={false}
						resource="sites"
						save={handleSubmit}
						toolbar={null}
						initialValues={initialValues}
					>
						<FormDataExtractor>
							<TextInput source="name" variant="outlined" />
							<ReferenceInput label="Customer" source="customer_id" reference="customers" sort={{ field: 'name', order: 'ASC' }} allowEmpty variant="outlined">
								<AutocompleteInput optionText={optionText} options={{ suggestionsContainerProps: { className: classes.suggestionsContainerOpen } }} />
							</ReferenceInput>
							<TextInput source="street" variant="outlined" />
							<TextInput source="number" variant="outlined" />
							<TextInput source="zipcode" variant="outlined" />
							<TextInput source="city" variant="outlined" />
							<TextInput source="country" variant="outlined" />
							<TextInput source="description" label="Notes" options={{ multiline: true }} rows="4" variant="outlined" />
						</FormDataExtractor>
					</SimpleForm>
				</DialogContent>
				<DialogActions>
					<SaveButton onClick={handleSave} handleSubmitWithRedirect={() => { }} />
					<Button label="ra.action.cancel" onClick={handleCloseDialog}>
						<IconCancel />
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default SiteQuickCreateButton;