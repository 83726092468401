import React from 'react';
import { TabbedShowLayout, Tab, TextField, ReferenceField } from 'react-admin';
import { Helmet } from 'react-helmet';

import Show, { ShowActions } from '../common/Show';
import DateField from '../common/fields/DateField';
import FilteredLogList from '../logs/FilteredLogList';
import FilteredRecordList from '../records/FilteredRecordList';
import LineActionsButtons from './LineActionsButtons';

const LineTitle = ({ record }) => (
    <>
        <Helmet>
            <title>{`KB - Line[${record.id}]`}</title>
        </Helmet>
        <span>{`Line #${record.id}`}</span>
    </>
);

const LineActions = (props) => (
	<ShowActions {...props}>
        <LineActionsButtons record={props.data} />
	</ShowActions>
);

const LineShow = (props) => (
    <Show title={<LineTitle />} actions={<LineActions />} {...props}>
        <TabbedShowLayout>
            <Tab label="summary">
                <TextField label="Id" source="id" />
                <ReferenceField label="Customer" source="customer_id" reference="customers" link="show" allowEmpty>
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField label="Device" source="device_id" reference="devices" link="show" allowEmpty>
                    <TextField source="serial" />
                </ReferenceField>
                <ReferenceField label="Site" source="site_id" reference="sites" link="show" allowEmpty>
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField label="Provider" source="provider_id" reference="line_providers" link="show" allowEmpty>
                    <TextField source="name" />
                </ReferenceField>
                <TextField label="Address" source="address" />
                <TextField label="SO" source="sales_order_number" />
                <TextField label="Subscription Number" source="subscription_id" />
                <TextField label="Status" source="status" />
                <TextField label="Type" source="line_type" />
                <TextField source="contact_name" />
                <TextField source="contact_phone" />
                <TextField source="contact_email" />
                <ReferenceField label="Assigned To" reference="users" source="assigned_to_id" link="show" >
                    <TextField source="email" />
                </ReferenceField>
                <DateField source="next_action_date" />
                <DateField source="installation_date" />
                <TextField source="installation_notes" />
                <DateField source="decommission_date" />
                <TextField source="accounting_status" />
                <TextField label="PPOE Login" source="ppoe_login" />
                <TextField label="PPOE Password" source="ppoe_password" />
                <TextField label="Notes" source="notes" />
                <TextField label="Archived Reason" source="archived_reason" />
                <TextField label="Archived Notes" source="archived_notes" />
                <DateField label="Archived Date" source="archived_date" />
                <DateField label="Created at" source="created_at" />
                <DateField label="Updated at" source="updated_at" />
                <TextField label="Updated by" source="last_updated_by" />
            </Tab>
            <Tab label="Ops History">
                <FilteredRecordList />
            </Tab>
            <Tab label="Logs">
                <FilteredLogList />
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export default LineShow;
