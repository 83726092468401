import PhonelinkRing from '@material-ui/icons/PhonelinkRing';

import ProviderList from './ProviderList';
import ProviderShow from './ProviderShow';
import ProviderCreate from './ProviderCreate';
import ProviderEdit from './ProviderEdit';

export default {
	list: ProviderList,
	show: ProviderShow,
	edit: ProviderEdit,
	create: ProviderCreate,
	icon: PhonelinkRing,
	title: 'Sim Providers'
};
