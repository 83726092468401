import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core';


export default withStyles(theme => ({
	tooltip: {
		backgroundColor: theme.palette.background.default,
		color: theme.palette.text.primary,
		border: `1px solid ${theme.palette.divider}`,
	},
	popper: {
		opacity: 1
	}
}))(Tooltip);
