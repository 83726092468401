import React from 'react';
import { TextField, ReferenceField, SimpleShowLayout } from 'react-admin';

import Datagrid from '../common/Datagrid';
import DateField from '../common/fields/DateField';
import RecordSplitButton from '../common/RecordSplitButton';
import LinkFieldButton from '../common/LinkFieldButton';
import AttachDetachLineButton from './LineActionsButtons/AttachDetachLineButton';
import LineArchiveButton from './LineActionsButtons/LineArchiveButton';
import LineActionsButtons from './LineActionsButtons';

const LineDrawer = (props) => (
	<SimpleShowLayout {...props}>
		<TextField source="id" />
		<TextField label="SO" source="sales_order_number" />
		<ReferenceField label="Customer" source="customer_id" reference="customers" link="show" allowEmpty>
			<TextField source="name" />
		</ReferenceField>
		<ReferenceField label="Device" source="device_id" reference="devices" link="show" allowEmpty>
			<TextField source="serial" />
		</ReferenceField>
		<ReferenceField label="Site" source="site_id" reference="sites" link="show" allowEmpty>
			<TextField source="name" />
		</ReferenceField>
		<ReferenceField label="Provider" source="provider_id" reference="line_providers" link="show" allowEmpty>
			<TextField source="name" />
		</ReferenceField>
		<TextField label="Address" source="address" />
		<TextField label="Subscription" source="subscription_id" />
		<TextField label="Status" source="status" />
		<TextField label="Type" source="line_type" />
		<ReferenceField label="Assigned" reference="users" source="assigned_to_id" >
			<TextField source="email" />
		</ReferenceField>
		<DateField source="next_action_date" />
		<DateField source="installation_date" />
		<DateField source="decommission_date" />
		<TextField source="accounting_status" />
		<TextField label="PPOE Login" source="ppoe_login" />
		<TextField label="PPOE Password" source="ppoe_password" />
		<TextField label="Notes" source="notes" />
		<DateField label="Created at" source="created_at" />
		<DateField label="Updated at" source="updated_at" />
		<TextField label="Updated by" source="last_updated_by" />
	</SimpleShowLayout>
);

const LineSplitButton = ({ record, ...props }) => (
	<RecordSplitButton record={record} {...props} archiveButton={<LineArchiveButton />}>
		<AttachDetachLineButton />
	</RecordSplitButton>
);

const LineRow = ({ children, ...props }) => (
	<Datagrid drawer={<LineDrawer {...props} />} {...props} drawerActions={<LineActionsButtons />}>
		<TextField label="SO" source="sales_order_number" />
		<LinkFieldButton label="Subscription" sortBy="subscription_id">
			<TextField source="subscription_id" />
		</LinkFieldButton>
		<LinkFieldButton label="Customer" source="customer" basePath="/customers" sortable={false}>
			<TextField source="name" />
		</LinkFieldButton>
		<LinkFieldButton label="Site" source="site" basePath="/sites" sortable={false}>
			<TextField source="name" />
		</LinkFieldButton>
		<TextField source="provider_name" label="Provider" />
		<TextField source="status"/>
		<TextField label="Type" source="line_type" />
		<LinkFieldButton label="Device" source="device" basePath="/devices" sortable={false}>
			<TextField source="serial" />
		</LinkFieldButton>
		<ReferenceField label="Assigned" reference="users" source="assigned_to_id" >
			<TextField source="email" />
		</ReferenceField>
		<DateField source="installation_date" />
		<DateField label="Next" source="next_action_date" />
		<DateField source="updated_at" />
		{children}
		<LineSplitButton />
	</Datagrid>
);

LineRow.defaultProps = {
	basePath: '/lines'
};

export default LineRow;