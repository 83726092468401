import React from 'react';
import { TabbedForm as RATabbedForm } from 'react-admin';

const TabbedForm = props => (
	<RATabbedForm {...props} />
);

TabbedForm.defaultProps = {
	variant: "outlined"
};

export default TabbedForm;