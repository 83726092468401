import SimCard from '@material-ui/icons/SimCard';

import DataPoolList from './DataPoolList';
import DataPoolCreate from './DataPoolCreate';
import DataPoolShow from './DataPoolShow';
import DataPoolEdit from './DataPoolEdit';

export default {
	list: DataPoolList,
	create: DataPoolCreate,
	show: DataPoolShow,
	edit: DataPoolEdit,
	icon: SimCard
};
