import React from 'react';
import { TextInput, ReferenceInput, AutocompleteInput, BooleanInput } from 'react-admin';

import GetAppIcon from '@material-ui/icons/GetApp';

import List, { ListActions } from '../common/List';
import Filter from '../common/Filter';
import DataPoolRow from './DataPoolRow';
import IconButton from '../common/IconButton';

import { API_URL } from '../../services/settings';

const optionText = choice => choice.name ? choice.name : '';

const DataPoolListActions = (props) => {
    return (
        <ListActions {...props}>
            <IconButton icon={<GetAppIcon />} component="a" href={`${API_URL}/data_pools/export.xls`}>Export</IconButton>
        </ListActions>
    );
};

const DataPoolFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <ReferenceInput label="Customer" source="customer_id" reference="customers" sort={{ field: 'name', order: 'ASC' }} allowEmpty alwaysOn>
            <AutocompleteInput optionText={optionText} />
        </ReferenceInput>
        <ReferenceInput label="Billing company" source="billing_company_id" reference="companies" sort={{ field: 'name', order: 'ASC' }} allowEmpty alwaysOn>
            <AutocompleteInput optionText={optionText} />
        </ReferenceInput>
        <BooleanInput label="Archived" source="archived" alwaysOn />
    </Filter>
);

const filterDefaultValues = {
    archived: false,
}

const DataPoolList = props => (
    <List {...props} filters={<DataPoolFilter />} perPage={50} filterDefaultValues={filterDefaultValues} actions={<DataPoolListActions />} exporter={false}>
        <DataPoolRow />
    </List>
);

export default DataPoolList;