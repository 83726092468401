import React, { useState, useEffect } from 'react';
import { TextField, ArrayField, FunctionField, SimpleShowLayout } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import Datagrid from '../common/Datagrid';
import MultilineTextField from '../common/fields/MultilineTextField';
import NumberCurrencyField from '../common/fields/NumberCurrencyField';

import { simulatePackage } from '../../services/api';

const useStyles = makeStyles(() => ({
    field: {
        '& > div': {
            alignItems: 'start'
        }
    }
}));

const RowList = (props) => {
    let record = props.record;
    if (record?.rows?.length > 0) {
        record = { ...record, rows: [...record.rows, { price: 'Total', total: record.total }] };
    }

    return (
        <ArrayField {...props} source="rows" record={record}>
            <Datagrid>
                <TextField source="type" />
                <MultilineTextField source="description" />
                <TextField source="qty" label="Quantity" />
                <FunctionField label="Price" render={record => <NumberCurrencyField record={{ currency: props?.record?.currency, ...record }} source="price" />} />
                <FunctionField label="Total" render={record => <NumberCurrencyField record={{ currency: props?.record?.currency, ...record }} source="total" />} />
            </Datagrid>
        </ArrayField>
    );
};

const PackagePreview = ({ record, basePath, ...props }) => {
    const [data, setData] = useState();
    const classes = useStyles();

    useEffect(() => {
        const fetchData = async () => {
            if (record.id) {
                const response = await simulatePackage(record.id);
                setData(response);
            }
        };

        fetchData();
    }, [record.id]);
    
    return (
        <SimpleShowLayout {...props} record={data}>
            <TextField label="Billing company" source="billing_company.name" />
            <FunctionField label="Invoicing address" className={classes.field} render={({ invoicing_address }) => {
                if (!invoicing_address) {
                    return null;
                }

                return (
                    <>
                        {invoicing_address.name}<br />
                        {invoicing_address.street}<br />
                        {invoicing_address.zipcode} {record.city}<br />
                        {invoicing_address.country}<br />
                        {invoicing_address.vat}
                    </>
                );
            }} />
            <RowList />
        </SimpleShowLayout>
    );
};

export default PackagePreview;