import DeviceHubIcon from '@material-ui/icons/DeviceHub';

import IPList from './IPList';
import IPCreate from './IPCreate';
import IPShow from './IPShow';
import IPEdit from './IPEdit';


export default {
	list: IPList,
	create: IPCreate,
	show: IPShow,
	edit: IPEdit,
	icon: DeviceHubIcon,
	title: 'IPs'
};
