import React from 'react';
import { TextInput } from 'react-admin';
import GetAppIcon from '@material-ui/icons/GetApp';

import List, { ListActions } from '../common/List';
import Filter from '../common/Filter';
import InvoiceLineRow from './InvoiceLineRow';
import IconButton from '../common/IconButton';


import { API_URL } from '../../services/settings';


const InvoiceLineActions = (props) => (
    <ListActions {...props}>
        <IconButton icon={<GetAppIcon />} component="a" href={`${API_URL}/invoice_lines/export.xls`}>Export</IconButton>
    </ListActions>
);

const InvoiceLineFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
    </Filter>
);

const filterDefaultValues = {
}


const InvoiceLineList = props => (
    <List {...props} filters={<InvoiceLineFilter />} perPage={50} filterDefaultValues={filterDefaultValues} actions={<InvoiceLineActions />} exporter={false}>
        <InvoiceLineRow />
    </List>
);

export default InvoiceLineList;
