import RouterIcon from '@material-ui/icons/Router';

import PackageList from './PackageList';
import PackageShow from './PackageShow';
import PackageCreate from './PackageCreate';
import PackageEdit from './PackageEdit';

export default {
	list: PackageList,
	show: PackageShow,
	edit: PackageEdit,
	create: PackageCreate,
	icon: RouterIcon,
	title: 'Packages',
	options: { label: 'Packages' }
};
