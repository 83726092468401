import React from 'react';

import ExportStatsButton from './ExportStatsButton';
import StowUnstowButton from './StowUnstowButton';
import RebootButton from './RebootButton';
// import RefreshButton from './RefreshButton';

const StarlinkTerminalActionsButtons = ({ record }) => (
	<>
		<StowUnstowButton record={record} />
		<RebootButton record={record} />
		<ExportStatsButton record={record} />
		{/* <RefreshButton record={record} /> */}
		{/* TODO: add speedtest and report buttons */}
	</>
);

export default StarlinkTerminalActionsButtons;