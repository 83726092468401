import React from 'react';
import { TextInput } from 'react-admin';

import Edit from '../common/Edit';
import SimpleForm from '../common/SimpleForm';

const LineProviderEdit = props => (
    <Edit {...props}>
        <SimpleForm>
			<TextInput source="id" disabled />
            <TextInput source="name" />
            <TextInput source="description" label="Notes" options={{ multiline: true }} rows="4" />
        </SimpleForm>
    </Edit>
);

export default LineProviderEdit;