import React from 'react';
import { TextField, Link } from 'react-admin';
import PhoneIcon from '@material-ui/icons/Phone';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import LinkFieldButton from '../../../common/LinkFieldButton';
import DateField from '../../../common/fields/DateField';
import NumberCurrencyField from '../../../common/fields/NumberCurrencyField';
import ServiceCard from './ServiceCard';
import StatusField from './StatusField';
import IsInvoicedField from './IsInvoicedField';

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(2),
		boxShadow: 'none',
		border: '1px solid #F1F5F6',
		borderRadius: '12px'
	},
	title: {
		'&, & a, & a span': {
			color: '#000',
			fontWeight: 400,
			fontSize: '26px'
		}
	},
	text: {
		fontWeight: 400,
		fontSize: '14px',
		'& a': {
			color: '#000',
			textDecoration: 'underline'
		}
	},
	textContainer: {
		marginTop: '15px',
		marginBottom: '15px'
	},
	content: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between'
	},
	footer: {
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: '15px'
	}
}));

const OneTimeServiceCard = ({ record }) => {
	const classes = useStyles();

	return (
		<Link to={`/services/${record.id}/show`}>
			<ServiceCard
				header={
					<>
						ONETIME - {record.type}
					</>
				}
				title={
					<LinkFieldButton record={record} basePath="/services">
						<TextField source="name" />
					</LinkFieldButton>
				}
				content={<>Price: <NumberCurrencyField source="price" record={record} /></>}
				footer={
					record?.start_date ? (
						<>
							<DateField source="start_date" record={record} /> - {record.end_date ? <DateField source="end_date" record={record} /> : 'Running'}
							<Typography variant="body2" className={classes.text}>
								<StatusField record={record} /> - <IsInvoicedField record={record} />
							</Typography>

						</>
					) :
						<div className={classes.footer}>
							<Typography variant="body2" className={classes.text}>
								<StatusField record={record} /> - <IsInvoicedField record={record} />
							</Typography>
						</div>
				}
				icon={<PhoneIcon />}
			/>
		</Link>
	)
};

export default OneTimeServiceCard;
