import React from 'react';
import { TextField } from 'react-admin';

const statuses = {
    'draft': 'Draft',
    'provisioning': 'Provisioning',
    'deployed': 'Deployed',
    'paused': 'Paused',
	'ended': 'Ended',
};

const StatusField = ({ classes, record, source, ...props }) => {
	if (!record) {
		return null;
	}

	const status = record[source];

	return (
		<TextField source={status} record={statuses} {...props} />
	);
};

StatusField.defaultProps = {
	source: 'status',
	label: 'Status',
	addLabel: true
};

export default StatusField;
