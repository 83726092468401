import React from 'react';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';
import { ReferenceInput, AutocompleteInput } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import { withStyles } from '@material-ui/core';

const styles = {
	container: {
		margin: '15px 0'
	},
	textContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
		paddingBottom: '5px',

		'& label': {
			minWidth: '130px'
		}

	}
};

const simOptionText = choice => (choice && choice.iccid ? choice.iccid : '');

const mapStateToProps = (state, props) => ({
	record: state.admin.resources[props.resource] ? state.admin.resources[props.resource].data[props.id] : null
});

const SimDetails = withStyles(styles)(connect(mapStateToProps)(({ record, classes }) => {
	if (!record) {
		return null;
	}

	return (
		<div className={classes.container}>
			{record.imsi && (
				<div className={classes.textContainer}>
					<InputLabel>Imsi</InputLabel>
					<Typography>{record.imsi}</Typography>
				</div>
			)}
			{record.sponsored_imsi && (
				<div className={classes.textContainer}>
					<InputLabel>Sponsored Imsi</InputLabel>
					<Typography>{record.sponsored_imsi}</Typography>
				</div>
			)}
			{record.provider_name && (
				<div className={classes.textContainer}>
					<InputLabel><span>Provider</span></InputLabel>
					<Typography>{record.provider_name}</Typography>
				</div>
			)}
			{record.supplier_name && (
				<div className={classes.textContainer}>
					<InputLabel>Supplier</InputLabel>
					<Typography>{record.supplier_name}</Typography>
				</div>
			)}
		</div>
	);
}));

const SimReferenceInput = props => (
	<React.Fragment>
		<ReferenceInput {...props} allowEmpty>
			<AutocompleteInput optionText={simOptionText} />
		</ReferenceInput>

		<Field
			name={props.source}
			component={({ input }) =>
				input.value && <SimDetails id={input.value} {...props} />
			}
		/>
	</React.Fragment>
);

export default SimReferenceInput;