import React from 'react';

import Edit, { EditActions } from '../common/Edit';
import SimpleForm from '../common/SimpleForm';
import InvoiceForm from './InvoiceForm';
import InvoiceActionsButtons from './InvoiceActionsButtons';

const InvoiceActions = (props) => (
	<EditActions {...props}>
		<InvoiceActionsButtons record={props?.data} />
	</EditActions>
);

const Form = (props) => {
	if (props?.record?.status !== 'draft') {
		return null;
	}

	return (
		<SimpleForm {...props}>
			<InvoiceForm />
		</SimpleForm>
	)
};

const InvoiceEdit = (props) => (
	<Edit {...props} actions={<InvoiceActions />}>
		<Form />
	</Edit>
);

export default InvoiceEdit;