import React from 'react';
import get from 'lodash/get';
import Typography from '@material-ui/core/Typography';

import moment from 'moment';

const HumanDateField = ({
    className,
    source,
    record = {},
    basePath,
    addLabel,
    ...rest
}) => {
    const momentValue = moment(get(record, source));
    const now = moment.now()
    const value = momentValue.isValid() ? moment.duration(momentValue-now).humanize(true): 'Never';

    return (
        <Typography
            component="span"
            variant="body2"
            className={className}
            {...rest}
        >
            {value && typeof value !== 'string' ? JSON.stringify(value) : value}
        </Typography>
    );
};

export default HumanDateField;