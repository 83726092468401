import React from 'react';
import { TextInput, ReferenceInput, AutocompleteInput, FormDataConsumer } from 'react-admin';

import Edit from '../common/Edit';
import SimpleForm from '../common/SimpleForm';
import SimCardArchiveButton from './SimCardActionsButtons/SimCardArchiveButton';
import SimCardStatusInput from './SimCardSourceField';
import SimCardType from './SimCardType';

const optionText = choice => choice && choice.name ? choice.name : '';
const providerOptionText = choice => `${choice.name} / ${choice.supplier_name } / ${choice.iccid_prefix}`;
const simcardOptionText = choice => choice.iccid ? `${choice.iccid} / ${choice.provider_name}` : '';

const SimCardEdit = props => (
    <Edit {...props} archiveButton={<SimCardArchiveButton />}>
        <SimpleForm>
            <TextInput source="id" disabled />
            <FormDataConsumer>
                { ({ formData, ...rest }) => formData.type === 'esim' && 
                    <ReferenceInput label="Parent" source="parent_id" reference="simcards" allowEmpty filter={{ field: "iccid", order: "ASC", ...({ type: 'sim' })}} {...rest}>
                        <AutocompleteInput optionText={simcardOptionText} />
                    </ReferenceInput>
                }
            </FormDataConsumer>
            <FormDataConsumer>
                {({ formData, ...rest }) => !formData.is_manageable &&
                    <SimCardStatusInput {...rest} />
                }
            </FormDataConsumer>
            <SimCardType />
            <TextInput source="iccid" />
            <TextInput source="imsi" />
            <TextInput source="sponsored_imsi" />
            <FormDataConsumer>
                {({ formData, ...rest }) =>
                    <ReferenceInput label="Pool" source="pool_id" reference="simpools" allowEmpty filter={{ field: "name", order: "ASC", ...(formData.customer_id && { customer_id: formData.customer_id })}} {...rest}>
                        <AutocompleteInput optionText={optionText} />
                    </ReferenceInput>
                }
            </FormDataConsumer>
            <ReferenceInput label="Provider" source="provider_id" reference="providers" sort={{ field: "name", order: "ASC" }}>
                <AutocompleteInput source="provider" optionText={providerOptionText} />
            </ReferenceInput>
            <TextInput source="carrier_name" />
            <TextInput source="phone_number" />
            <TextInput source="apn_1" />
			<TextInput source="apn_2" />
			<TextInput source="apn_3" />
			<TextInput source="preferred_mtu" />
			<TextInput source="pin" />
			<TextInput source="username" />
			<TextInput source="password" />
			<TextInput source="variant" />
            <ReferenceInput label="Owner" source="owner" reference="customers" sort={{ field: 'name', order: 'ASC' }} allowEmpty>
                <AutocompleteInput optionText={optionText} />
            </ReferenceInput>
            <TextInput source="notes" options={{ multiline: true }} rows="4" />
        </SimpleForm>
    </Edit>
);

export default SimCardEdit;