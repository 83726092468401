import React from 'react';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';
import { ReferenceInput, AutocompleteInput, FormDataConsumer } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';


const customerOptionText = choice => choice.name ? choice.name : '';
const addressOptionText = choice => choice.name ? `${choice?.name} ${choice?.full_address ? `(${choice.full_address})` : ''}` : '';

const mapStateToProps = (state, props) => ({
	record: state.admin.resources["addresses"] ? state.admin.resources["addresses"].data[props.id] : null
});

const CustomerDetails = connect(mapStateToProps)(({ record }) => {
	if (!record) {
		return null;
	}

	return (
		<>
			<Typography variant="h5">{record.name}</Typography>
			<Typography>{record.street}</Typography>
			<Typography>{record.zipcode} {record.city}</Typography>
			<Typography>{record.country}</Typography>
			<Typography>{record.vat}</Typography>
		</>
	);
});

const InvoiceCustomerReferenceInput = ({ variant }) => (
	<>
		<Grid item md={4}>
			<ReferenceInput sort={{ field: 'name', order: 'ASC' }} 	label="Customer" source="customer_id" reference="customers" allowEmpty variant={variant} helperText={false}>
				<AutocompleteInput optionText={customerOptionText} />
			</ReferenceInput>

            <FormDataConsumer>
                {({ formData, ...rest }) => (
                    <ReferenceInput
                        sort={{ field: 'name', order: 'ASC' }}
                        label="Invoicing address"
                        reference="addresses"
                        source="invoicing_address_id"
                        allowEmpty
                        variant={variant}
                        filter={formData.customer_id && { customer_id: formData.customer_id }}
                        helperText={false}
                        {...rest}
                    >
                        <AutocompleteInput optionText={addressOptionText} />
                    </ReferenceInput>

                )}
            </FormDataConsumer>
		</Grid>
		<Grid item md={4}>
			<Field
				name="invoicing_address_id"
				component={({ input }) =>
                    input.value && <CustomerDetails id={input.value} />
				}
			/>
		</Grid>
	</>
);

export default InvoiceCustomerReferenceInput;