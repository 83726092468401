import React, { useState } from 'react';
import { Confirm, useRefresh, useNotify, usePermissions } from 'react-admin';
import EncryptionIcon from '@material-ui/icons/EnhancedEncryption';

import IconButton from '../../common/IconButton';

import { enableMFA } from '../../../services/api';

const EnableMFAButton = ({ record, variant, color }) => {
	const [showDialog, setShowDialog] = useState(false);
	const { permissions } = usePermissions();
	const refresh = useRefresh();
	const notify = useNotify();
	const isAdmin = permissions && permissions.includes('is_admin');

	const handleOpen = () => {
		setShowDialog(true);
	};

	const handleClose = () => {
		setShowDialog(false);
	};

	const handleConfirm = async () => {
		handleClose();
		const response = await enableMFA(record.id);

		if (response.success) {
			refresh();
			notify('MFA was successfully enabled');
		}
		else {
			if(response.reason) {
				notify(response.reason, 'warning');
			}
			else {
				notify('An error has occured', 'warning');
			}
		}
	};

	if (!isAdmin || record?.mfa_enabled) return null;

	if (record) {
		return (
			<>
				<IconButton icon={<EncryptionIcon />} variant={variant} color={color} onClick={handleOpen}>Enable MFA</IconButton>
				<Confirm
					isOpen={showDialog}
					title={`Enable MFA`}
					content={`Are you sure you want to enable MFA for ${record.fullname}?`}
					onConfirm={handleConfirm}
					onClose={handleClose}
				/>
			</>
		);
	}

	return null;
};

export default EnableMFAButton;