import React from 'react';
import { AutocompleteInput } from 'react-admin';

const choices = [
	{ id: 'invoice', name: 'Invoice' },
	{ id: 'credit', name: 'Credit' },
];


const InvoiceTypeField = ({ classes, record, source = "invoice_type", ...props }) => {
	if (!record) {
		return null;
	}

	if (record[source] && !choices.find((provider) => provider.id === record[source])) {
		choices.push({ id: record[source], name: record[source]});
	}

	return (
		<AutocompleteInput choices={choices} source={source} record={record} allowEmpty {...props} />
	);
};

export default InvoiceTypeField;
