import React from 'react';
import { AutocompleteInput } from 'react-admin';

const choices = [
    { id: 'BUY', name: 'BUY' },
	{ id: 'SUBSCRIPTION', name: 'SUBSCRIPTION' },
	{ id: 'LOAN', name: 'LOAN' },
	{ id: 'NA', name: 'NA' },
];

const DeviceBuySmField = ({ classes, record, source = "buy_sm", ...props }) => {
	if (record && record[source] && !choices.find((provider) => provider.id === record[source])) {
		choices.push({ id: record[source], name: record[source]});
	}

	return (
		<AutocompleteInput choices={choices} source={source} record={record} allowEmpty {...props} />
	);
};

export default DeviceBuySmField;
