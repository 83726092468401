import React from 'react';
import { TextInput, ReferenceInput, AutocompleteInput } from 'react-admin';

import Edit from '../common/Edit';
import SimpleForm from '../common/SimpleForm';

const optionText = choice => choice.name ? choice.name : '';

const SimPoolEdit = props => (
    <Edit {...props}>
        <SimpleForm>
			<TextInput source="id" disabled />
            <TextInput source="name" />
            <ReferenceInput label="Customer" source="customer_id" reference="customers" sort={{ field: 'name', order: 'ASC' }}>
				<AutocompleteInput optionText={optionText} />
			</ReferenceInput>
            <TextInput source="notes" label="Notes" options={{ multiline: true }} rows="4" />
            <TextInput source="description" label="Description" options={{ multiline: true }} rows="4" />
        </SimpleForm>
    </Edit>
);

export default SimPoolEdit;
