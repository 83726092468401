import React from 'react';
import { Tab, TextField, ReferenceField } from 'react-admin';

import DateField from '../../common/fields/DateField';
import NumberCurrencyField from '../../common/fields/NumberCurrencyField';


const OneTimeServiceShow = (props) => (
    <Tab label="summary" {...props}>
        <TextField source="status" />
        <ReferenceField label="Assignee" source="assignee_id" reference="users"  link="show" allowEmpty>
            <TextField source="email" />
        </ReferenceField>
        <ReferenceField label="Package" source="package_id" reference="packages" link="show" allowEmpty>
            <TextField source="reference" />
        </ReferenceField>
        <TextField source="reference" />
        <TextField source="customer_reference"/>
        <ReferenceField label="Last Invoice" source="last_invoice_id" reference="invoices" link="show" allowEmpty>
            <TextField source="id" />
        </ReferenceField>
        <TextField source="name" />
        <TextField source="type" />
        <TextField source="frequency" />
        <TextField source="product_name" />
        <NumberCurrencyField source="price" />
        <DateField source="start_date" />
        <TextField source="notes" options={{ multiline: true }} rows="4" />
        <DateField label="Created at" source="created_at" />
        <DateField label="Updated at" source="updated_at" />
    </Tab>
);

export default OneTimeServiceShow;
