import React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        boxShadow: 'none',
        border: '1px solid #F1F5F6',
        borderRadius: '12px'
    },
    title: {
        '&, & a, & a span': {
            color: '#000',
            fontWeight: 400,
            fontSize: '26px'
        }
    },
    text: {
        fontWeight: 400,
        fontSize: '14px',
        '& a': {
            color: '#000',
            textDecoration: 'underline'
        }
    },
    content: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between'
    },
    iconWrapper: {
        alignItems: 'center',
        backgroundColor: theme.palette.primary.main,
        borderRadius: '50%',
        display: 'inline-flex',
        height: '4rem',
        justifyContent: 'center',
        marginLeft: 'auto',
        width: '4rem',
        marginBottom: '5px',
        '& svg': {
            color: theme.palette.common.white,
            fontSize: '2rem',
            height: '2rem',
            width: '2rem'
        }
    },
    icon: {
        color: theme.palette.common.white,
        fontSize: '2rem',
        height: '2rem',
        width: '2rem'
    },
    iconContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontWeight: 400,
        fontSize: '14px'
    },
    footer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '15px'
    }
}));

const ServiceCard = ({ title, header, content, footer, icon, contentRight }) => {
    const classes = useStyles();

    return (
        <Paper className={classes.root}>
            <div className={classes.content}>
                <div className={classes.details}>
                    {header && <Typography variant="body2" className={classes.text}>{header}</Typography>}
                    {title && <Typography className={classes.title} variant="h5">{title}</Typography>}
                    {content && <Typography variant="body2" className={classes.text}>{content}</Typography>}
                    <div className={classes.footer}>
                        <Typography variant="body2" className={classes.text}>
                            {footer}
                        </Typography>
                    </div>
                </div>
                <div className={classes.iconContainer}>
                    {contentRight ? (
                        contentRight
                    ) : (
                        <div className={classes.iconWrapper}>
                            {icon}
                        </div>
                    )}
                </div>
            </div>
        </Paper>
    );
};

export default ServiceCard;
