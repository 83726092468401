import React from 'react';
import { TextInput, ReferenceInput, AutocompleteInput } from 'react-admin';

import Create from '../common/Create';
import SimpleForm from '../common/SimpleForm';

const optionText = choice => choice.name ? choice.name : '';

const SimPoolCreate = props => (
	<Create {...props}>
		<SimpleForm redirect="list">
			<TextInput source="name" />
			<ReferenceInput label="Customer" source="customer_id" reference="customers" sort={{ field: 'name', order: 'ASC' }} allowEmpty>
				<AutocompleteInput optionText={optionText} />
			</ReferenceInput>
			<TextInput source="notes" label="Notes" options={{ multiline: true }} rows="4" />
		</SimpleForm>
	</Create>
);

export default SimPoolCreate;