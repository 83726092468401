import React from 'react';
import { TabbedShowLayout, Tab, TextField, ReferenceField } from 'react-admin';
import { Helmet } from 'react-helmet';

import Show from '../common/Show';
import DateField from '../common/fields/DateField';


const StarlinkSubscriptionTitle = ({ record }) => (
    <>
        <Helmet>
            <title>{`KB - Starlink subscription[${record.id}]`}</title>
        </Helmet>
        <span>{record.id}</span>
    </>
);

const StarlinkSubscriptionShow = (props) => (
    <Show title={<StarlinkSubscriptionTitle />} {...props}>
        <TabbedShowLayout>
            <Tab label="summary">
                <TextField source="id" />
                <TextField source="subscription_reference_id" />
                <ReferenceField label="Starlink account" source="starlink_account" reference="starlink_accounts" link="show" allowEmpty>
                    <TextField source="account_number" />
                </ReferenceField>
                <ReferenceField label="Starlink service line" source="starlink_service_line" reference="starlink_service_lines" link="show" allowEmpty>
                    <TextField source="service_line_number" />
                </ReferenceField>
                <TextField source="description" />
                <DateField source="start_at" showTime />
                <DateField source="end_at" showTime />
                <DateField source="service_end_at" showTime />
                <DateField source="normalized_start_at" showTime />
                <DateField source="created_at" showTime />
                <DateField source="updated_at" showTime />
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export default StarlinkSubscriptionShow;