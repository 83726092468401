import SpeedIcon from '@material-ui/icons/Speed';
import SpeedTestCreate from './SpeedTestCreate';
import SpeedTestList from './SpeedTestList';
import SpeedTestShow from './SpeedTestShow';

export default {
	list: SpeedTestList,
	icon: SpeedIcon,
	create: SpeedTestCreate,
	show: SpeedTestShow
};
