import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	icon: {
		fontSize: '20px',
		marginRight: '5px'
	}
});

const IconButton = ({ icon, size, color, children, ...props }) => {
	const classes = useStyles();
	const customIcon = React.cloneElement(icon, { className: classes.icon });

	return (
		<Button size={size} color={color} {...props}>
			{customIcon}
			<span>{children}</span>
		</Button>
	);
};

IconButton.defaultProps = {
	size: 'small',
	color: 'primary'
};

export default IconButton;
