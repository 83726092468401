import React, { useState } from 'react';
import { useFormState, useForm } from 'react-final-form';
import { Title, TextInput, Toolbar, SaveButton, useNotify, required } from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core';

import ips from './';
import { CreateActions } from '../common/Create';
import SimpleForm from '../common/SimpleForm';
import { bulkCreateIP } from '../../services/api';
import VlanInput from './IPVlan';
import FireWallInput from './IPFirewall';
import SubnetTypeInput from './IPSubnetType';

const styles = {
	title: {

	},
	summary: {
		marginTop: '15px',

		'& h3': {
			marginTop: '15px'
		},
		'& p': {
			whiteSpace: 'pre-wrap'
		}
	}
};


const CustomSaveButton = ({ onClick, ...props }) => {
	const formState = useFormState();
	const form = useForm();
	const notify = useNotify();

	const handleClick = async () => {
		if(formState.values.address) {
			let data = new FormData();
			data.append("address", formState.values.address);
			data.append("cidr", formState.values.cidr);
			data.append("subnet_type", formState.values.subnet_type);
			data.append("subnet_notes", formState.values.subnet_notes || '');
			data.append("firewall", formState.values.firewall || '');
			data.append("vlan_id", formState.values.vlan_id || '');
			data.append("gateway", formState.values.gateway || '');
			data.append("dry", false);
		
			const results = await bulkCreateIP(data);

			if(results.success) {
				let message = `${results.created.length} element${results.created.length > 1 ? 's' : ''} created`;
				if (results.existing.length > 0) {
					message += `\n${results.existing.length} element${results.existing.length > 1 ? 's' : ''} existing`;
				}
				notify(message);

				if (results.created.length > 0 || results.existing.length > 0) {
					form.change('address', null);
					form.change('cidr', null);
					form.change('subnet_type', null);
					form.change('subnet_notes', null);
					form.change('firewall', null);
					form.change('vlan_id', null);
					form.change('gateway', null);
				}

				onClick({ results, summary: message });
			} else {
				notify(results.message);
			}
		}
	};

	return <SaveButton {...props} handleSubmit={handleClick} handleSubmitWithRedirect={handleClick} />;
};

const CustomToolbar = ({ onSave, ...props }) => (
	<Toolbar {...props}>
		<CustomSaveButton onClick={onSave} />
	</Toolbar>
);

const IPBulkCreate = ({ classes }) => {
	const [results, setResults] = useState(null);
	const [summary, setSummary] = useState(null);

	const breadcrumbPath = [
		{ url: '/ips', title: "IPs", icon: <ips.icon /> },
		{
			url: '/ips/bulk-create',
			title: "Bulk create",
			isActive: true
		}
	];

	const handleSave = async (result) => {
		setResults(result.results);
		setSummary(result.summary);
	}

	const renderSummary = () => {
		if (!results) {
			return null;
		}

		return (
			<Card className={classes.summary}>
				{results && (
					<CardContent>
						<Typography variant="h6" gutterBottom>Summary</Typography>
						<Typography>{summary}</Typography>

						{results.created.length > 0 && (
							<React.Fragment>
								<Typography variant="subtitle1">Created</Typography>
								{results.created.map((ip =>
									<Typography key={ip.id}>{ip.address}</Typography>
								))}
							</React.Fragment>
						)}

						{results.existing.length > 0 && (
							<React.Fragment>
								<Typography variant="subtitle1">Existing</Typography>
								{results.existing.map((ip =>
									<Typography key={ip.id}>{ip.address}</Typography>
								))}
							</React.Fragment>
						)}
					</CardContent>
				)}
			</Card>
		);
	}

	return (
		<React.Fragment>
			<CreateActions basePath="/ips" hasList={true} breadcrumb={breadcrumbPath} />
			<Card>
				<Title title="Create IPs" />
				<SimpleForm resource="ips" toolbar={<CustomToolbar onSave={handleSave} />}>
					<TextInput source="address" validate={required()} label="Address ex: 10.10.10.0" />
					<TextInput source="cidr" validate={required()} label="CIDR (ex: 24)"/>
					<SubnetTypeInput />
					<TextInput source="subnet_notes" options={{ multiline: true }} rows="4" />
					<TextInput source="notes" options={{ multiline: true }} rows="4" />
					<TextInput source="gateway" label="Gateway ex: 10.10.10.1"/>
					<VlanInput />
					<FireWallInput />
				</SimpleForm>
			</Card>
			{renderSummary()}
		</React.Fragment>
	);
};

export default withStyles(styles)(IPBulkCreate);