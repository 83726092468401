import React from 'react';
import LinkIcon from '@material-ui/icons/Link';

import IconButton from '../../common/IconButton';


const ProviderURLButton = ({ record, variant, color }) => {
    if (!record.provider_url) {
        return null;
    }

    return (
        <IconButton icon={<LinkIcon />} component="a" href={record.provider_url} target="__blank" variant={variant} color={color}>Provider page</IconButton>
    );
};

export default ProviderURLButton;