import React from 'react';
import { TabbedShowLayout, Tab, TextField } from 'react-admin';
import { Helmet } from 'react-helmet';

import Show from '../common/Show';
import DateField from '../common/fields/DateField';

const LineProviderTitle = ({ record }) => (
	<>
		<Helmet>
			<title>{`KB - Line Provider [${record.name}]`}</title>
		</Helmet>
		<span>{record.name}</span>
	</>
);

const LineProviderShow = props => (
	<Show title={<LineProviderTitle />} {...props}>
		<TabbedShowLayout>
			<Tab label="summary">
				<TextField source="id" />
				<TextField source="name" />
				<TextField source="description" label="Notes" />
				<DateField label="Created at" source="created_at" />
				<DateField label="Updated at" source="updated_at" />
			</Tab>
		</TabbedShowLayout>
	</Show>
);

export default LineProviderShow;