import React from 'react';
import { TextField, ReferenceField, SimpleShowLayout, ShowButton } from 'react-admin';

import Datagrid from '../common/Datagrid';
import LinkFieldButton from '../common/LinkFieldButton';
import DateField from '../common/fields/DateField';
import MapComponent from '../common/MapComponent';


const StarlinkAddressDrawer = (props) => (
    <SimpleShowLayout {...props}>
        <TextField source="id" />
        <TextField source="formatted_address" />
        <TextField source="address_reference_id" />
        <ReferenceField label="Starlink account" source="starlink_account" reference="starlink_accounts" link="show" allowEmpty>
            <TextField source="account_number" />
        </ReferenceField>
        <TextField source="metadatas" />
        <TextField source="administrative_area" />
        <TextField source="administrative_area_code" />
        <DateField source="created_at" showTime />
        <DateField source="updated_at" showTime />
        <MapComponent height="300px" />
    </SimpleShowLayout>
);

const StarlinkAddressRow = ({ children, ...props }) => (
    <Datagrid drawer={<StarlinkAddressDrawer {...props} />} {...props}>
        <LinkFieldButton label="Address" sortBy="formatted_address">
            <TextField source="formatted_address" />
        </LinkFieldButton>
        <ReferenceField label="Starlink account" source="starlink_account" reference="starlink_accounts" link="show" allowEmpty>
            <TextField source="account_number" />
        </ReferenceField>
        {children}
        <ShowButton />
    </Datagrid>
);

StarlinkAddressRow.defaultProps = {
    basePath: '/starlink_addresses'
};

export default StarlinkAddressRow;