import React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core';
import orange from '@material-ui/core/colors/orange';

import ListIcon from '@material-ui/icons/List';

const styles = theme => ({
	root: {
		padding: theme.spacing(3),
		boxShadow: '0px 1px 30px 1px rgba(0,0,0,0.11)'
	},
	content: {
		alignItems: 'center',
		display: 'flex'
	},
	title: {
		color: theme.palette.text.secondary,
		fontWeight: 700
	},
	value: {
		marginTop: theme.spacing(),
		color: props => props.warning ? orange[500] : theme.palette.text.primary,
	},
	iconWrapper: {
		alignItems: 'center',
		backgroundColor: theme.palette.primary.main,
		borderRadius: '50%',
		display: 'inline-flex',
		height: '4rem',
		justifyContent: 'center',
		marginLeft: 'auto',
		width: '4rem',
		'& svg': {
			color: theme.palette.common.white,
			fontSize: '2rem',
			height: '2rem',
			width: '2rem'
		}
	},
	icon: {
		color: theme.palette.common.white,
		fontSize: '2rem',
		height: '2rem',
		width: '2rem'
	}
});


const IconCard = ({ title, value, icon, classes}) => (
	<Paper className={classes.root}>
		<div className={classes.content}>
			<div className={classes.details}>
				<Typography className={classes.title} variant="body1">{title}</Typography>
				<Typography className={classes.value} variant="h4">{value}</Typography>
			</div>
			<div className={classes.iconWrapper}>
				{icon ? icon : <ListIcon />}
			</div>
		</div>
	</Paper>
);

export default withStyles(styles)(IconCard);
