import React, { useState, useEffect } from 'react';
import { Title, Link } from 'react-admin';
import Grid from '@material-ui/core/Grid';

import SimCard from '@material-ui/icons/SimCard';
import UserIcon from '@material-ui/icons/People';
import RouterIcon from '@material-ui/icons/Router';
import LineIcon from '@material-ui/icons/Phone';
import SiteIcon from '@material-ui/icons/Business';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import RingVolume from '@material-ui/icons/RingVolume';

import IconCard from '../common/IconCard';
import DeviceStatsGraph from '../devices/DeviceStatsGraph';
import SimCardStatsGraph from '../simcards/SimCardStatsGraph';
import { fetchAdminDashboardStats } from '../../services/api';


const Dashboard = () => {
    const [stats, setStats] = useState();

    useEffect(() => {
        const fetchStats = async () => {
            const data = await fetchAdminDashboardStats();
            setStats(data);
        }

        fetchStats();
    }, []);

    return (
        <>
            <Title title="Kenobi" />

            <Grid container spacing={2}>
                <Grid container item spacing={2}>
                    <Grid item lg={3} sm={6} xl={3} xs={12}>
                        <Link to="/devices">
                            <IconCard title="Devices" value={stats?.devices?.total} icon={<RouterIcon />} />
                        </Link>
                    </Grid>
                    <Grid item lg={3} sm={6} xl={3} xs={12}>
                        <Link to={`/devices?filter={"new":true}`}>
                            <IconCard title="New Devices" value={stats?.devices?.new} icon={<RouterIcon />} warning={stats?.devices?.new > 0 ? true : false} />
                        </Link>
                    </Grid>
                    <Grid item lg={3} sm={6} xl={3} xs={12}>
                        <Link to={`/devices?filter={"in_stock":true}`}>
                            <IconCard title="Devices in stock" value={stats?.devices?.in_stock} icon={<RouterIcon />} />
                        </Link>
                    </Grid>
                    <Grid item lg={3} sm={6} xl={3} xs={12}>
                        <Link to="/devices">
                            <IconCard title="Devices on site" value={stats?.devices?.on_site} icon={<RouterIcon />} />
                        </Link>
                    </Grid>
                </Grid>
                <Grid container item spacing={2}>
                    <Grid item lg={3} sm={6} xl={3} xs={12}>
                        <Link to="/simcards">
                            <IconCard title="SIM cards" value={stats?.sim_cards?.total} icon={<SimCard />} />
                        </Link>
                    </Grid>
                    <Grid item lg={3} sm={6} xl={3} xs={12}>
                        <Link to={`/simcards?filter={"in_device":true}`}>
                            <IconCard title="SIM in device" value={stats?.sim_cards?.in_device} icon={<SimCard />} />
                        </Link>
                    </Grid>
                    <Grid item lg={3} sm={6} xl={3} xs={12}>
                        <Link to={`/devices?filter={"with_sims_errors":true}`}>
                            <IconCard title="SIMs Errors" value={stats?.devices?.with_sims_errors} icon={<RouterIcon />} warning={stats?.devices?.with_sims_errors > 0 ? true : false} />
                        </Link>
                    </Grid>
                </Grid>
                <Grid container item spacing={2}>
                    <Grid item lg={3} sm={6} xl={3} xs={12}>
                        <Link to="/simpools">
                            <IconCard title="SimPools" value={stats?.pools?.total} icon={<SimCard />} />
                        </Link>
                    </Grid>
                    <Grid item lg={3} sm={6} xl={3} xs={12}>
                        <IconCard title="SIM in pool" value={stats?.sim_cards?.in_pool} icon={<SimCard />} />
                    </Grid>
                </Grid>
                <Grid container item spacing={2}>
                    <Grid item lg={3} sm={6} xl={3} xs={12}>
                        <Link to='freshdesk' target='_blank'>
                            <IconCard title="Tickets" value={stats?.tickets?.total} icon={<RingVolume />} warning={stats?.tickets?.total > 0 ? true : false}/>
                        </Link>
                    </Grid>
                    <Grid item lg={3} sm={6} xl={3} xs={12}>
                        <Link to="/customers">
                            <IconCard title="Customers" value={stats?.customers?.total} icon={<UserIcon />} />
                        </Link>
                    </Grid>
                    <Grid item lg={3} sm={6} xl={3} xs={12}>
                        <Link to="/sites">
                            <IconCard title="Sites" value={stats?.sites?.total} icon={<SiteIcon />} />
                        </Link>
                    </Grid>
                    <Grid item lg={3} sm={6} xl={3} xs={12}>
                        <Link to={`/sites?filter={"empty":true}`}>
                            <IconCard title="Empty Sites" value={stats?.sites?.empty} icon={<SiteIcon />} warning={stats?.sites?.empty > 0 ? true : false} />
                        </Link>
                    </Grid>
                </Grid>
                <Grid container item spacing={2}>
                    <Grid item lg={3} sm={6} xl={3} xs={12}>
                        <Link to="/lines">
                            <IconCard title="Lines" value={stats?.lines?.total} icon={<LineIcon />} />
                        </Link>
                    </Grid>
                    <Grid item lg={3} sm={6} xl={3} xs={12}>
                        <Link to={`/lines?filter={"attached": false}`}>
                            <IconCard title="Not Attached" value={stats?.lines?.not_attached} icon={<LineIcon />} warning={stats?.lines?.not_attached > 0 ? true : false} />
                        </Link>
                    </Grid>
                    <Grid item lg={3} sm={6} xl={3} xs={12}>
                        <Link to={`/lines?filter={"next_action_due": true}`}>
                            <IconCard title="Next Action Due" value={stats?.lines?.next_action_due} icon={<LineIcon />} warning={stats?.lines?.next_action_due > 0 ? true : false} />
                        </Link>
                    </Grid>
                </Grid>
                <Grid container item spacing={2}>
                    <Grid item lg={3} sm={6} xl={3} xs={12}>
                        <Link to="/product_items">
                            <IconCard title="Items" value={stats?.product_items?.total} icon={<RouterIcon />} />
                        </Link>
                    </Grid>
                </Grid>
                <Grid container item spacing={2}>
                    <Grid item lg={3} sm={6} xl={3} xs={12}>
                        <Link to="/ips">
                            <IconCard title="IPs" value={stats?.ips?.total} icon={<DeviceHubIcon />} />
                        </Link>
                    </Grid>
                    <Grid item lg={3} sm={6} xl={3} xs={12}>
                        <Link to={`/ips?filter={"available": true}`}>
                            <IconCard title="Available IPs" value={stats?.ips?.available} icon={<DeviceHubIcon />} />
                        </Link>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item lg={9}>
                    <DeviceStatsGraph />
                </Grid>
                <Grid item lg={9}>
                    <SimCardStatsGraph />
                </Grid>
            </Grid>
        </>
    );
}

export default Dashboard;