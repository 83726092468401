import React, { useEffect, useState } from 'react';
import { TextInput, DateInput, ReferenceInput, AutocompleteInput, FormDataConsumer } from 'react-admin';
import { useSelector } from 'react-redux';
import { useForm, useFormState } from 'react-final-form';
import moment from 'moment';
import { FieldArray } from 'react-final-form-arrays';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';


import InvoiceLineForm from './InvoiceLineForm';
import InvoiceCustomerReferenceInput from './InvoiceCustomerReferenceInput';
import CurrencyInput from '../common/inputs/CurrencyInput';
import NumberCurrencyField from '../common/fields/NumberCurrencyField';

const optionText = choice => choice.name ? choice.name : '';


const useStyles = makeStyles(theme => ({
	gridReference: {
		order: 0,
		[theme.breakpoints.down('sm')]: {
			order: 2,
			marginTop: '15px'
		}
	},
	gridTable: {
		[theme.breakpoints.down('sm')]: {
			maxWidth: 'calc(100vw - 80px)'
		},
		[theme.breakpoints.down('xs')]: {
			maxWidth: '100vw'
		},
	}
}));

const TotalTable = ({ vatList, lines, record }) => {
	let total = 0;

	if (lines) {
		total = lines.reduce((acc, line) => acc + (line.qty * line.unit_price || 0), 0);
	}

	let totalVat = total;
	const vatListTr = vatList.map(vat => {
		if (!lines) {
			return null;
		}

		const value = lines.filter(line => line.vat_code === vat.id).reduce((acc, line) => {
			const rate = vatList.find(v => v.id === line.vat_code)?.rate/100;
			return acc + (line.qty * line.unit_price * rate);
		}, 0);
		totalVat += value;

		if (value) {
			return (
				<TableRow key={`vat_${vat.id}`}>
					<TableCell>{vat.name}</TableCell>
					<TableCell><NumberCurrencyField record={{ currency: record?.currency, value }} source="value" /></TableCell>
				</TableRow>
			);
		}

		return null;
	});

	return (
		<Table>
			<TableBody>
				<TableRow>
					<TableCell><b>Total excl. VAT</b></TableCell>
					<TableCell><NumberCurrencyField record={{ currency: record?.currency, total }} source="total" /></TableCell>
				</TableRow>
				{vatListTr}
				<TableRow>
					<TableCell><b>Total incl. VAT</b></TableCell>
					<TableCell><b><NumberCurrencyField record={{ currency: record?.currency, totalVat }} source="totalVat" /></b></TableCell>
				</TableRow>
			</TableBody>
		</Table>
	);
};

const InvoiceForm = ({ record, vatList, typeList, draggable, variant }) => {
	const form = useForm();
	const formState = useFormState();
	const [invoice, setInvoice] = useState(record);
	const classes = useStyles();
	const address = useSelector((state) => state.admin.resources['addresses'] && formState.values.invoicing_address_id ? state.admin.resources['addresses'].data[formState.values.invoicing_address_id] : null);

	useEffect(() => {
		if (record.id) {
			setInvoice(record);
		}

		if (!record.id || (record.lines && record.lines.length === 0)) {
			addLine();
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (address?.id && !formState.values.due_date) {
			form.change('due_date', moment(formState.values.invoiced_at).add(address.payment_terms_days || 0, 'days').toDate());
		}
	}, [address]); // eslint-disable-line react-hooks/exhaustive-deps

	const addLine = () => {
		form.mutators.push('lines', {});
	};

	const onDragEnd = fields => result => {
		if (!result.destination) {
			return;
		}
		fields.swap(result.source.index, result.destination.index);
	};

	const invoiceLines = (
		<FieldArray name="lines" subscription={{}}>
			{({ fields }) => (
				<DragDropContext onDragEnd={onDragEnd(fields)}>
					<Droppable droppableId="droppable">
						{(provided) => (
							<TableBody ref={provided.innerRef}>
								{fields.map((name, index) => (
									<Draggable
										key={name}
										draggableId={name}
										index={index}
									>
										{(provided) => (
											<TableRow
												ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
											>
												<InvoiceLineForm
													index={index}
													draggable={draggable}
													vatList={vatList}
													typeList={typeList}
													variant={variant}
													name={name}
													fields={fields}
													record={formState.values || record}
												/>
											</TableRow>
										)}
									</Draggable>
								))}
								{provided.placeholder}
							</TableBody>
						)}
					</Droppable>
				</DragDropContext>
			)}
		</FieldArray>
	);

	return (
		<Grid container direction="column" spacing={6}>
			<Grid item>
				<Typography variant="h4">Informations</Typography>
			</Grid>
			<Grid item>
				<Grid container direction="row" spacing={6}>
					<Grid item md={4}>
						<ReferenceInput sort={{ field: 'name', order: 'ASC' }} label="Billing company" source="billing_company_id" reference="companies" allowEmpty variant={variant} helperText={false}>
							<AutocompleteInput optionText={optionText} />
						</ReferenceInput>
					</Grid>
				</Grid>
				<Grid container direction="row" spacing={6}>
					<InvoiceCustomerReferenceInput variant={variant} />

					<Grid item md={4}>
						<TextInput source="number" variant={variant} helperText={false} />
						<TextInput source="purchase_order" variant={variant} helperText={false} />
						<CurrencyInput source="currency" record={record} variant={variant} helperText={false} />
						<DateInput source="invoiced_at" variant={variant} helperText={false} />
						<DateInput source="due_date" variant={variant} helperText={false} />
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12} className={classes.gridTable}>
				<Typography variant="h4">Description</Typography>
				<TableContainer>
					<Table size="small">
						<TableHead>
							<TableRow>
								{draggable && <TableCell></TableCell>}
								<TableCell width="40%">Description</TableCell>
								<TableCell>Quantity</TableCell>
								<TableCell>Price</TableCell>
								<TableCell>VAT</TableCell>
								<TableCell>Total excl. VAT</TableCell>
								<TableCell align="center">
									<Button color="primary" variant="outlined" size="small" onClick={addLine}><AddIcon />Add</Button>
								</TableCell>
							</TableRow>
						</TableHead>
						{invoiceLines}
						{form.getState()?.values?.lines?.length > 0 && (
							<TableBody>
								<TableRow>
									{draggable && <TableCell></TableCell>}
									<TableCell></TableCell>
									<TableCell></TableCell>
									<TableCell></TableCell>
									<TableCell></TableCell>
									<TableCell></TableCell>
									<TableCell align="center">
										<Button color="primary" variant="outlined" size="small" onClick={addLine}><AddIcon />Add</Button>
									</TableCell>
								</TableRow>
							</TableBody>
						)}
					</Table>
				</TableContainer>
			</Grid>
			<Grid item>
				<Grid container spacing={6}>
					<Grid item md={7} xs={12} className={classes.gridReference}>
						<Paper align="center" variant="outlined">
							<Typography variant="h6">IBAN</Typography>
							<Typography>{invoice.bank_iban}</Typography>

							<Typography variant="h6">BIC</Typography>
							<Typography>{invoice.bank_bic}</Typography>

							<Typography variant="h6">Reference to communicate</Typography>
							<Typography>+++{invoice.structured_reference}+++</Typography>
						</Paper>
					</Grid>
					<Grid item md={4} xs={12}>
						<FormDataConsumer>
							{({ formData }) => (
								formData.lines && <TotalTable vatList={vatList} lines={Object.values(formData.lines)} record={formState.values || record} />
							)}
						</FormDataConsumer>
					</Grid>
				</Grid>
			</Grid>
			<Grid item>
				<Grid container spacing={2}>
					<Grid item md={6} xs={12}>
						<Typography variant="h5">Terms and conditions of payment</Typography>
						<TextInput label="" source="terms" options={{ multiline: true }} rows="4" variant={variant} />
					</Grid>
					<Grid item md={6} xs={12}>
						<Typography variant="h5">Notes</Typography>
						<TextInput label="" source="notes" options={{ multiline: true }} rows="4" variant={variant} />
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

InvoiceForm.defaultProps = {
	draggable: true,
	vatList: [
		{ id: 'vat_0', name: '0%', rate: 0 },
		{ id: 'vat_21', name: '21%', rate: 21 },
		{ id: 'vat_20', name: '20%', rate: 20 },
		{ id: 'vat_intra_goods_0', name: 'INTRA GOODS 0%', rate: 0 },
		{ id: 'vat_intra_services_0', name: 'INTRA SERVICES 0%', rate: 0 },
		{ id: 'vat_export_goods_0', name: 'EXPORT GOODS 0%', rate: 0 },
		{ id: 'vat_export_services_0', name: 'EXPORT SERVICES 0%', rate: 0 },
		{ id: 'vat_not_subject', name: 'NA', rate: 0 },
		{ id: 'vat_seagoing', name: 'SEAGOING', rate: 0 },
		{ id: 'vat_eu_0', name: 'EU 0%', rate: 0 }
	],
	typeList: [
		{ id: 'data', name: 'DATA' },
		{ id: 'sdwan', name: 'SDWAN' },
		{ id: 'sdip', name:'SDIP' },
		{ id: 'data_pool', name: 'DATA_POOL' },
		{ id: 'line', name: 'LINE' },
		{ id: 'starlink', name: 'STARLINK' },
		{ id: 'custom', name: 'CUSTOM' },
		{ id: 'activation', name: 'ACTIVATION' },
		{ id: 'third_party', name: 'THIRD PARTIES' },
		{ id: 'consultancy', name: 'CONSULTANCY' },
		{ id: 'distribution', name: 'DISTRIBUTION' },
		{ id: 'starlink_hw', name: 'STARLINK HW' },
		{ id: 'other', name: 'OTHER' },
	]
};

export default InvoiceForm;