import Phone from '@material-ui/icons/Phone';

import LineList from './LineList';
import LineEdit from './LineEdit';
import LineCreate from './LineCreate';
import LineShow from './LineShow';

export default {
	list: LineList,
	show: LineShow,
	edit: LineEdit,
	create: LineCreate,
	icon: Phone,
};
