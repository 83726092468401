import React, { useState } from 'react';
import { CardActions, Button, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import Login from './common/Login';

import { forgotPassword } from '../services/api';

const useStyles = makeStyles((theme) => ({
	icon: {
		marginRight: theme.spacing(1)
	},
	form: {
		padding: '0 1em 1em 1em'
	}
}));

const ForgotPasswordPage = () => {
	const [login, setLogin] = useState('');
	const [message, setMessage] = useState('');
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);
	const classes = useStyles();

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		const formData = new FormData();
		formData.append('login', login);
		const response = await forgotPassword(formData);
		setLoading(false);
		if (response.success) {
			setMessage('An email has been sent');
		}
		else {
			setError(response.message);
		}
	};

	return (
		<Login>
			{message ? (
				<Typography align="center">{message}</Typography>
			) : (
					<form onSubmit={handleSubmit}>
						<div className={classes.form}>
							<TextField label="Email" type="text" name="login" value={login} onChange={(e) => setLogin(e.target.value)} helperText={error} error={!!error} fullWidth />
						</div>
						<CardActions>
							<Button
								variant="contained"
								type="submit"
								color="primary"
								disabled={loading}
								fullWidth
							>
								{loading && (
									<CircularProgress
										className={classes.icon}
										size={18}
										thickness={2}
									/>
								)}
								Forgot password
							</Button>
						</CardActions>
					</form>
				)}
		</Login>
	);
};

export default ForgotPasswordPage;
