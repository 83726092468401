import React from 'react';
import { Datagrid, TextInput, TextField, ReferenceField, ReferenceInput, AutocompleteInput, DateInput } from 'react-admin';
import GetAppIcon from '@material-ui/icons/GetApp';

import List, { ListActions } from '../common/List';
import DeviceReferenceInput from '../devices/DeviceReferenceInput';
import Filter from '../common/Filter';
import DateField from '../common/fields/DateField';
import IconButton from '../common/IconButton';
import ObjectReferenceField from './ObjectReferenceField';
import SiteReferenceInput from '../sites/SiteReferenceInput';
import LinkFieldButton from '../common/LinkFieldButton';

import { API_URL } from '../../services/settings';


const simcardOptionText = choice => {
    return choice.iccid ? choice.iccid : '';
};

const lineOptionText = choice => {
    return choice.subscription_id ? choice.subscription_id : '';
};

const customerOptionText = choice => {
    return choice.name ? choice.name : '';
}

const RecordListActions = (props) => {
    const params = new URLSearchParams();
    for (const key in props.filterValues) {
        params.append(key, props.filterValues[key]);
    }

    return (
        <ListActions {...props}>
            <IconButton icon={<GetAppIcon />} component="a" href={`${API_URL}/export/records?${params.toString()}`}>Export</IconButton>
        </ListActions>
    );
};

const RecordFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <SiteReferenceInput alwaysOn />
        <DateInput source="start_date" alwaysOn />
        <DateInput source="end_date" alwaysOn />
        <DeviceReferenceInput source="device_id" />
        <ReferenceInput label="Simcard" source="sim_id" reference="simcards">
            <AutocompleteInput optionText={simcardOptionText} />
        </ReferenceInput>
        <ReferenceInput label="Line" source="line_id" reference="lines">
            <AutocompleteInput optionText={lineOptionText} />
        </ReferenceInput>
        <ReferenceInput label="Customer" source="customer_id" reference="customers">
            <AutocompleteInput optionText={customerOptionText} />
        </ReferenceInput>
    </Filter>
);

const RecordList = props => (
    <List {...props} filters={<RecordFilter />} perPage={25} bulkActionButtons={false} exporter={false} actions={<RecordListActions />}>
        <Datagrid>
            <TextField source="object_type" />
            <ObjectReferenceField source="object_id" sortable={false} />
            <TextField source="action" sortable={false} />
            <LinkFieldButton label="Customer" source="customer" basePath="customers" sortable={false}>
                <TextField source="name" />
            </LinkFieldButton>
            <LinkFieldButton label="Site" source="site" basePath="sites" sortable={false}>
                <TextField source="name" />
            </LinkFieldButton>
            <ReferenceField label="Target" source="target_device" reference="devices" link="show" allowEmpty sortable={false}>
                <TextField source="title" />
            </ReferenceField>
            <DateField source="start_date" showTime />
            <DateField source="end_date" showTime />
            <TextField source="duration" label="Total days" sortable={false} />
            <TextField source="current_month_duration" label="This month" sortable={false} />
            <TextField source="current_month_ratio" label="" sortable={false} />
            <TextField source="comment" />
            <TextField source="user_email" label="user" />
        </Datagrid>
    </List>
);

export default RecordList;
