import React from 'react';
import {
	TextField,
	ReferenceField,
	FunctionField,
	SimpleShowLayout
} from 'react-admin';
import { Link } from 'react-router-dom';

import Datagrid from '../common/Datagrid';
import DateField from '../common/fields/DateField';
import BooleanField from '../common/fields/BooleanField';
import RecordSplitButton from '../common/RecordSplitButton';
import LinkFieldButton from '../common/LinkFieldButton';
import DeviceTooltip from '../devices/DeviceTooltip';
import SiteTooltip from '../sites/SiteTooltip';
import SimPoolTooltip from '../simpools/SimPoolTooltip';
import IconRouter from '@material-ui/icons/Router';
import RemoveFromPoolButton from './SimCardActionsButtons/RemoveFromPoolButton';
import AttachDetachSimCardButton from './SimCardActionsButtons/AttachDetachSimCardButton';
import SimCardArchiveButton from './SimCardActionsButtons/SimCardArchiveButton';
import ProviderURLButton from './SimCardActionsButtons/ProviderURLButton';
import SimCardActionsButtons from './SimCardActionsButtons';
import StatusField from './StatusField';
import OwnerField from './OwnerField';
import ProviderUrlField from './ProviderUrlField';
import SimCardTooltip from './SimCardTooltip';


const SimCardSplitButton = ({ record, ...props }) => (
	<RecordSplitButton record={record} {...props} archiveButton={<SimCardArchiveButton />}>
		{record && record.pool_id && <RemoveFromPoolButton />}
		<AttachDetachSimCardButton />
		<ProviderURLButton />
	</RecordSplitButton>
);

const SimCardDrawer = ({ basePath, resource, ...props }) => (
	<SimpleShowLayout {...props} basePath="/simcards" resource="simcards">
		<TextField source="id" />
		<TextField source="type" />
		<TextField source="variant" />
		<ReferenceField label="Customer" source="customer" reference="customers" link="show" allowEmpty>
			<TextField source="name" />
		</ReferenceField>
		<ReferenceField label="SimPool" source="pool_id" reference="simpools" link="show" allowEmpty>
			<TextField source="name" />
		</ReferenceField>
		<FunctionField label="iccid" source="iccid" render={record => `${record.iccid_formatted}`} />
		<ProviderUrlField />
		<TextField source="imsi" />
		<TextField source="sponsored_imsi" />
		<ReferenceField label="Provider" source="provider" reference="providers" allowEmpty>
			<TextField source="name" />
		</ReferenceField>
		<TextField source="supplier_name" />
		<TextField source="carrier_name" />
		<StatusField label="Status" />
		<ReferenceField label="Device" source="device_id" reference="devices" link="show" allowEmpty>
			<FunctionField render={record => `${record.serial} / ${record.name}`} />
		</ReferenceField>
		<ReferenceField label="IC Device" source="ic_device_id" reference="devices" link="show" allowEmpty>
			<FunctionField render={record => `${record.serial} / ${record.name}`} />
		</ReferenceField>
		<TextField source="phone_number" />
		<TextField source="notes" />
		<TextField source="source" />
		<ReferenceField label="Owner" source="owner" reference="customers" link="show" allowEmpty>
			<TextField source="name" />
		</ReferenceField>
		<DateField label="Created at" source="created_at" />
		<DateField label="Updated at" source="updated_at" />
	</SimpleShowLayout>
);

const IconsField = props => (
	<span style={{ display: 'flex', alignItems: 'center' }}>
		<ReferenceField {...props} source="device_id" resource="devices" reference="devices" allowEmpty link={false} style={{ display: 'flex', alignItems: 'center' }}>
			<FunctionField render={record => (
				<>
					<Link to={`/devices/${record.id}/show`}><DeviceTooltip record={record} icon={<IconRouter color="primary" />} /></Link>
					{record.site && <Link to={`/sites/${record.site.id}/show`}><SiteTooltip record={record.site} /></Link>}
				</>
			)} />
		</ReferenceField>
		{props.record.pool_id && (
			<Link to={`/simpools/${props.record.pool_id}/show`}>
				<ReferenceField {...props} source="pool_id" resource="simpools" reference="simpools" allowEmpty link={false} style={{ display: 'flex', alignItems: 'center' }}>
					<FunctionField render={record => (
						<SimPoolTooltip record={record} />
					)} />
				</ReferenceField>
			</Link>
		)}
		{props.record.parent_id && (
			<Link to={`/simcards/${props.record.parent_id}/show`}>
				<ReferenceField {...props} source="parent_id" resource="simcards" reference="simcards" allowEmpty link={false} style={{ display: 'flex', alignItems: 'center' }}>
					<FunctionField render={record => (
						<SimCardTooltip record={record} />
					)} />
				</ReferenceField>
			</Link>
		)}
	</span>
);

const SimCardRow = ({ children, childrenBefore = false, ...props }) => (
	<Datagrid drawer={<SimCardDrawer {...props} />} {...props} basePath="/simcards" drawerActions={<SimCardActionsButtons />}>
		{childrenBefore && children}
		<BooleanField source="device_active" label="" valueLabelTrue="Active in Device" valueLabelFalse="InActive in Device" />
		<TextField source="type" />
		<TextField source="variant" />
		<LinkFieldButton label="iccid" sortBy="iccid">
			<TextField source="iccid_formatted" />
		</LinkFieldButton>
		<ReferenceField label="Provider" source="provider_id" reference="providers" allowEmpty sortable={false}>
			<TextField source="name" />
		</ReferenceField>
		<TextField label="Supplier Name" source="_supplier_name" />
		<TextField source="carrier_name" />
		<ReferenceField label="Customer" source="customer" reference="customers" allowEmpty link="show" sortable={false}>
			<TextField source="name" />
		</ReferenceField>
		<TextField source="status" />
		<OwnerField label="Owner" />
		<DateField source="updated_at" />
		<IconsField label="" source="icons" />
		{!childrenBefore && children}
		<SimCardSplitButton />
	</Datagrid>
);

export default SimCardRow;