import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Brush } from 'recharts';
import { useTheme } from '@material-ui/core/styles';

import GraphTooltip from '../common/GraphTooltip';


const CustomToolTip = ({ active, payload, label, groupBy }) => {
	if (!active || !payload) {
		return null;
	}

	return (
		<GraphTooltip>
			<p>
				<strong>{label}</strong>
			</p>
			{payload.map((item, i) => (
				<React.Fragment key={i}>
					<p>Usage: <strong>{item.value} GB</strong></p>
					{(item.payload.customer && groupBy !== 'customer') && (<p>Customer: {item.payload.customer}</p>)}
					{(item.payload.site && groupBy !== 'site') && (<p>Site: {item.payload.site}</p>)}
				</React.Fragment>
			))}
		</GraphTooltip>
	);
};

const StarlinkUsageReportGraph =  ({ data, groupBy, small }) => {
	const theme = useTheme();

	return (
		<ResponsiveContainer width="100%" height={small ? 200 : 400}>
			<BarChart
				data={data.slice(0, 100)}
				margin={{
					top: 50, right: 30, left: 20, bottom: 5,
				}}
				maxBarSize={100}
			>
				<CartesianGrid strokeDasharray="3 3" />
				<XAxis dataKey={groupBy} />
				<YAxis dataKey="usage" />
				<Tooltip content={<CustomToolTip groupBy={groupBy} />} cursor={theme.palette.type === 'dark' ? { fill: theme.palette.action.hover } : {}} />
				<Legend />
				<Bar name="Data Usage" dataKey="usage" fill="#3A8B7E" />
				<Brush dataKey={groupBy} height={30} travellerWidth={10} startIndex={0} endIndex={data.length > 10 ? 9 : data.length - 1} stroke="#3A8B7E" />
			</BarChart>
		</ResponsiveContainer>
	);
};

StarlinkUsageReportGraph.defaultProps = {
	groupBy: 'device'
};

export default StarlinkUsageReportGraph;
