import React from 'react';
import { TextField, ReferenceField } from 'react-admin';


const ObjectReferenceField = ({ record, source="object_id", ...props }) => {
    const type = `${record.object_type}s`;
    const getChildSource = (record) => {
		switch(record.object_type) {
			case 'simcard':
				return 'iccid';
			case 'device':
				return 'title';
			default:
				return 'name';
		};
	};
    const childSource = getChildSource(record);
	return (
		<ReferenceField source={source} link="show" allowEmpty {...props} reference={type} basePath={`/${type}`} record={record}>
			<TextField source={childSource} />
		</ReferenceField>
	);
};

export default ObjectReferenceField;
