import React, { useState } from 'react';
import { Confirm, useRefresh, useNotify } from 'react-admin';
import ReplayIcon from '@material-ui/icons/Replay';

import IconButton from '../common/IconButton';

import { restartModemDevice } from '../../services/api';


const RestartModemDeviceButton = ({ record }) => {
	const refresh = useRefresh();
	const notify = useNotify();
	const [showDialog, setShowDialog] = useState(false);

	const handleConfirm = async () => {
		const formData = new FormData();
		formData.append('wan_id', record.id);
		await restartModemDevice(record.device_id, formData);

		refresh();
		notify('The cellular modem is now restarting');

		handleClose();
	};

	const handleOpen = () => {
		setShowDialog(true);
	};

	const handleClose = () => {
		setShowDialog(false);
	};

	if(!record || record.wan_type !== 'cellular') {
		return null;
	}

	return (
		<>
			<IconButton icon={<ReplayIcon />} onClick={handleOpen}>Restart Modem</IconButton>
			<Confirm
				isOpen={showDialog}
				title="Restart device modem"
				content={`Do you want to restart the cellular modem (${record.name})?`}
				onConfirm={handleConfirm}
				onClose={handleClose}
			/>
		</>
	);
};

export default RestartModemDeviceButton;