import React from 'react';
import { ChipField as RAChipField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	root: ({ color }) => {
		if(color) {
			return {
				backgroundColor: color
			}
		}
	},
	small: {
		height: '20px',
		lineHeight: '20px',
		'& span': {
			height: '20px',
			lineHeight: '24px'
		}
	}
});

const ChipField = ({ record, source, color, className, ...props }) => {
	const classes = useStyles({ color });

	return (
		record && (record[source] !== undefined && record[source] !== null  && record[source] !== "") ? 
			<RAChipField className={`${className} ${classes.root} ${props.size === 'small' ? classes.small : {}}`} {...{ record, source, ...props }} /> 
		: null
	);
};

ChipField.defaultProps = {
	size: 'small',
	className: ''
};

export default ChipField;
