import React from 'react';
import { TextInput, NumberInput } from 'react-admin';

import Edit from '../common/Edit';
import SimpleForm from '../common/SimpleForm';
import CountryInput from '../common/inputs/CountryInput';


const CompanyEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="id" disabled />
            <TextInput source="name" />
            <CountryInput source="country" />
            <TextInput source="street" />
            <TextInput source="zipcode" />
            <TextInput source="city" />
            <TextInput source="vat_number" />
            <TextInput source="bank_name" />
            <TextInput source="bank_bic" />
            <TextInput source="bank_iban" />
            <TextInput source="email" />
            <TextInput source="website" />
            <TextInput source="phone" />
            <NumberInput source="invoice_prefix" min={1} max={9} step={1} />
        </SimpleForm>
    </Edit>
);

export default CompanyEdit;