import RouterIcon from '@material-ui/icons/Router';

import ServiceList from './ServiceList';
import ServiceShow from './ServiceShow';
import ServiceCreate from './ServiceCreate';
import ServiceEdit from './ServiceEdit';

export default {
	list: ServiceList,
	show: ServiceShow,
	edit: ServiceEdit,
	create: ServiceCreate,
	icon: RouterIcon,
	title: 'Services'
};
