import React from 'react';
import { TabbedShowLayout, Tab, TextField, EmailField, UrlField } from 'react-admin';
import { Helmet } from 'react-helmet';

import Show from '../common/Show';
import CountryField from '../common/fields/CountryField';


const CompanyTitle = ({ record }) => (
	<>
		<Helmet>
			<title>{`KB - Company[${record.name}]`}</title>
		</Helmet>
		<span>{record.name}</span>
	</>
);

const CompanyShow = props => (
	<Show title={<CompanyTitle />} {...props}>
		<TabbedShowLayout>
			<Tab label="summary">
				<TextField source="name" />
				<CountryField />
				<TextField source="street" />
				<TextField source="zipcode" />
				<TextField source="city" />
				<TextField source="vat_number" />
				<TextField source="bank_name" />
				<TextField source="bank_bic" />
				<TextField source="bank_iban" />
				<EmailField source="email" />
				<UrlField source="website" />
				<TextField source="phone" />
			</Tab>
		</TabbedShowLayout>
	</Show>
);

export default CompanyShow;