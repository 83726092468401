import React, { useState } from 'react';
import { SaveButton as RASaveButton, useRefresh, useNotify } from 'react-admin';
import SaveIcon from '@material-ui/icons/Save';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { Typography } from '@material-ui/core';

import SimpleForm from '../../common/SimpleForm';
import IconButton from '../../common/IconButton';
import Toolbar from './Toolbar';

import { setProviderDefault } from '../../../services/api';


const SaveButton = ({ onClick, record, ...props }) => {
	const notify = useNotify();

	const handleClick = async () => {
		let formData = new FormData();
		formData.append("serial_confirmation", record.serial);
		const response = await setProviderDefault(record.id, formData);
		onClick();

		if(response.ticket_id) {
			notify(`Flash current config success\nTicket id: ${response.ticket_id}`);
		}
	};

	return <RASaveButton {...props} handleSubmit={handleClick} handleSubmitWithRedirect={handleClick} />;
};


const SaveConfigButton = ({ record, variant, color }) => {
	const refresh = useRefresh();
	const [showDialog, setShowDialog] = useState(false);

	const handleSave = () => {
		handleClose();
		refresh();
	};

	const handleOpen = () => {
		setShowDialog(true);
	};

	const handleClose = () => {
		setShowDialog(false);
	};

	return (
		<>
			<IconButton icon={<SaveIcon />} onClick={handleOpen} variant={variant} color={color}>Flash Config</IconButton>
			<Dialog fullWidth open={showDialog} onClose={handleClose} aria-label="Flash Config">
				<DialogTitle>Flash all active parameters configuration in Device</DialogTitle>
				<DialogContent>
					<Typography gutterBottom>Are you sure to save active configuration as service provider default configuration for this device?</Typography>
					<Typography color="error">THIS IS NOT A BACKUP OF DEVICE IN .CONF FILE AND SHOULD ONLY BE USED WITH FULL KNOWLEDGE OF WHAT YOU ARE DOING ! </Typography>
					<SimpleForm toolbar={<Toolbar onClose={handleClose} onSave={handleSave} saveButton={<SaveButton onClick={handleSave} />} />} record={record}>
						{(record && record.serial) && <p>{record.serial}</p>}
					</SimpleForm>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default SaveConfigButton;