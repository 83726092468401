import React from 'react';
import { TextInput, ReferenceInput, ReferenceArrayInput, AutocompleteInput, AutocompleteArrayInput, BooleanInput, useRefresh, useNotify, TextField } from 'react-admin';
import { Link } from 'react-router-dom';
import IconRefresh from '@material-ui/icons/Refresh';
import IconAdd from '@material-ui/icons/Add';
import GetAppIcon from '@material-ui/icons/GetApp';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';

import List, { ListActions } from '../common/List';
import Filter from '../common/Filter';
import IconButton from '../common/IconButton';
import DeviceRow from './DeviceRow';
import DeviceBulkActions from './DeviceActionsButtons/DeviceBulkActions';
import ICServerField from './fields/ICServerField';
import DeviceBuySmField from './fields/DeviceBuySmField';
import DeviceRentSoldField from './fields/DeviceRentSoldField';
import DeviceSupplierField from './fields/DeviceSupplierField';
import SiteReferenceInput from '../sites/SiteReferenceInput';
import CustomAutocompleteInput from '../common/inputs/CustomAutocompleteInput';

import { syncDevices } from '../../services/api';

import { API_URL } from '../../services/settings';

const customerOptionText = choice => choice.name ? choice.name : '';
const productOptionText = choice => choice && choice.reference ? choice.reference : '';

const DeviceListActions = (props) => {
    const refresh = useRefresh();
    const notify = useNotify();

    const handleRefreshIncontrol = async () => {
        const response = await syncDevices();

        if(response.success) {
            refresh();
            notify('Synchronization succeeded');
        }
        else {
            notify('Synchronization failed', 'warning');
        }
    };

    return (
        <ListActions {...props}>
            <IconButton icon={<IconRefresh />} onClick={handleRefreshIncontrol}>Incontrol</IconButton>
            <IconButton component={Link} to={`/devices/bulk-create`} icon={<IconAdd />}>Bulk create</IconButton>
            <IconButton component={Link} to={`/devices/bulk-move`} icon={<SwapHorizIcon />}>Bulk move</IconButton>
            <IconButton icon={<GetAppIcon />} component="a" href={`${API_URL}/devices/export.xls`}>Export</IconButton>
        </ListActions>
    );
};

// New component otherwise resource is override by "devices"
const DeviceProductAutocomplete = (props) => (
    <CustomAutocompleteInput {...props} resource="device_products" />
);

const DeviceFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <ReferenceInput label="Customer" source="customer_id" reference="customers" sort={{ field: 'name', order: 'ASC' }} alwaysOn>
            <AutocompleteInput optionText={customerOptionText} />
        </ReferenceInput>
        <SiteReferenceInput alwaysOn />
        <ReferenceArrayInput label="Products" source="product_ids" reference="device_products" sort={{ field: 'reference', order: 'ASC' }} allowEmpty>
            <AutocompleteArrayInput optionText={productOptionText} />
        </ReferenceArrayInput>
        <ICServerField label="IC server" source="ic_server_name" />
        <BooleanInput label="Only FH" source="only_fusion_hub" alwaysOn />
        <BooleanInput label="Stock" source="in_stock" alwaysOn />
        <BooleanInput label="Custom Config" source="has_custom_config" />
        <BooleanInput label="Archived" source="archived" alwaysOn />
        <DeviceBuySmField source="buy_sm" label="Buy/SM"  />
        <DeviceRentSoldField source="rent_sold" label="VENN Billing Mode" />
        <DeviceSupplierField source="supplier" label="Supplier" />
        <BooleanInput label="Owned by Customer" source="owned_by_customer" />
        <BooleanInput label="Only AP" source="only_aps" />
        <DeviceProductAutocomplete source="brand" />
        <DeviceProductAutocomplete source="family" />
        <DeviceProductAutocomplete source="sub_family" />
        <DeviceProductAutocomplete source="category" />
        <BooleanInput label="Reserved" source="reserved" />
    </Filter>
);

const filterDefaultValues = {
    only_fusion_hub: false,
    archived: false,
}

const Row = (props) => (
    <DeviceRow {...props}>
        {props.filterValues.archived && <TextField source="archived_reason" />}
    </DeviceRow>
);

const DeviceList = ({ classes, ...props }) => (
    <List {...props} filters={<DeviceFilter />} actions={<DeviceListActions />} filterDefaultValues={filterDefaultValues} bulkActionButtons={<DeviceBulkActions />} exporter={false}>
        <Row />
    </List>
);

export default DeviceList;