import UserIcon from '@material-ui/icons/People';

import UserList from './UserList';
import UserEdit from './UserEdit';
import UserCreate from './UserCreate';

export default {
	list: UserList,
	edit: UserEdit,
	create: UserCreate,
	icon: UserIcon
};
