import React from 'react';
import { AutocompleteInput } from 'react-admin';

const choices = [
	{ id: 'NEW SUBSCRIPTION', name: 'NEW SUBSCRIPTION' },
	{ id: 'NEW EVENT', name: 'NEW EVENT' },
	{ id: 'NEW POC', name: 'NEW POC' },
	{ id: 'NEW DEMO', name: 'NEW DEMO' },
	{ id: 'NEW LOAN', name: 'NEW LOAN' },
	{ id: 'SITE TAG CHANGE NEW', name: 'SITE TAG CHANGE NEW' },
	{ id: 'SUBSCRIPTION CHANGE NEW', name: 'SUBSCRIPTION CHANGE NEW' },
	{ id: 'TECHNICAL REPLACEMENT NEW', name: 'TECHNICAL REPLACEMENT NEW' },
	{ id: 'SOLD', name: 'SOLD' },
	{ id: 'ERROR CORRECTION', name: 'ERROR CORRECTION' },
	{ id: 'OTHER', name: 'OTHER' },
];


const DeployReasonInput = ({ classes, record, ...props }) => {
	return (
		<AutocompleteInput choices={choices} record={record} allowEmpty {...props} />
	);
};

export default  DeployReasonInput;
