import React from 'react';
import { FormTab, TextInput, ReferenceInput, AutocompleteInput } from 'react-admin';

import Create from '../common/Create';
import TabbedForm from '../common/TabbedForm';

const optionText = choice => choice.name ? choice.name : '';

const SiteCreate = props => (
	<Create {...props}>
		<TabbedForm redirect="list">
			<FormTab label="summary">
				<TextInput source="name" />
				<ReferenceInput label="Customer" source="customer_id" reference="customers" sort={{ field: 'name', order: 'ASC' }} allowEmpty>
					<AutocompleteInput optionText={optionText} />
				</ReferenceInput>
				<TextInput source="street" />
				<TextInput source="number" />
				<TextInput source="zipcode" />
				<TextInput source="city" />
				<TextInput source="country" />
				<TextInput source="description" label="Notes" options={{ multiline: true }} rows="4" />
			</FormTab>
		</TabbedForm>
	</Create>
);

export default SiteCreate;