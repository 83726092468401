import React from 'react';
import { TextInput, BooleanInput } from 'react-admin';

import GetAppIcon from '@material-ui/icons/GetApp';

import List, { ListActions } from '../common/List';
import Filter from '../common/Filter';
import IconButton from '../common/IconButton';
import ServiceProductRow from './ServiceProductRow';
import ServiceTypeField from './ServiceTypeField';
import ServiceFrequencyField from './ServiceFrequencyField';

import { API_URL } from '../../services/settings';

const ServiceProductListActions = (props) => {
    return (
        <ListActions {...props}>
            <IconButton icon={<GetAppIcon />} component="a" href={`${API_URL}/service_products/export.xls`}>Export</IconButton>
        </ListActions>
    );
};


const ServiceProductFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <ServiceTypeField label="Type" source="service_type" alwaysOn />
        <ServiceFrequencyField label="Frequency" source="frequency" alwaysOn />
        <BooleanInput label="Archived" source="archived" alwaysOn />
    </Filter>
);

const filterDefaultValues = {
    archived: false,
}

const ServiceProductList = props => (
	<List {...props} filters={<ServiceProductFilter/>} filterDefaultValues={ filterDefaultValues } perPage={50} exporter={false} actions={<ServiceProductListActions />}>
		<ServiceProductRow />
	</List>
);

export default ServiceProductList;