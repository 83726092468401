import React from 'react';
import {
	TextField,
	SimpleShowLayout
} from 'react-admin';

import Datagrid from '../common/Datagrid';
import LinkFieldButton from '../common/LinkFieldButton';
import DateField from '../common/fields/DateField';
import RecordSplitButton from '../common/RecordSplitButton';
import CustomList from '../common/CustomList';
import { BulkActions } from '../common/List';
import SimCardRow from '../simcards/SimCardRow';
import CustomerReferenceField from '../customers/CustomerReferenceField';
import BulkRemoveFromPoolButton from '../simcards/SimCardActionsButtons/BulkRemoveFromPoolButton';
import BulkMoveToPoolButton from '../simcards/SimCardActionsButtons/BulkMoveToPoolButton';
import SimPoolActionsButtons from './SimPoolActionsButtons';


const SimCardBulkActions = props => (
	<>
		<BulkMoveToPoolButton {...props} />
		<BulkRemoveFromPoolButton {...props} />
		<BulkActions {...props} />
	</>
);

const SimCardList = ({ hasBulkActions, ...props }) => (
	<CustomList
		{...props}
		title=" "
		hasCreate={false}
		perPage={25}
		bulkActionButtons={<SimCardBulkActions record={props.record} />}
		filter={{ pool_id: props.record.id }}
		basePath="/simcards"
		resource="simcards"
	>
		<SimCardRow exclude={['customer', 'simpool']} />
	</CustomList>
);

const SimPoolDrawer = props => (
	<SimpleShowLayout {...props}>
		<TextField source="id" />
		<CustomerReferenceField />
		<TextField source="name" />
		<DateField label="Created at" source="created_at" />
		<DateField label="Updated at" source="updated_at" />
		<TextField source="description" label="Notes" />
	</SimpleShowLayout>
);

const SimPoolRow = ({ children, ...props }) => (
	<Datagrid expand={<SimCardList {...props} />} drawer={<SimPoolDrawer {...props} />} {...props} drawerActions={<SimPoolActionsButtons />}>
		<LinkFieldButton label="Name" sortBy="name">
			<TextField source="name" />
		</LinkFieldButton>
		<LinkFieldButton label="Customer" source="customer" sortBy="customer_id" basePath="/customers" sortable={false}>
			<TextField source="name" />
		</LinkFieldButton>
		<TextField source="notes" />
		<TextField source="cards_count" sortable={false} />
		<RecordSplitButton />
		{children}
	</Datagrid>
);

export default SimPoolRow;