import React, { useState } from 'react';
import {
	useMutation,
	useRefresh,
	useNotify,
	SaveButton as RASaveButton,
	TextInput,
	DateInput,
	RadioButtonGroupInput
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { useForm, useFormState } from 'react-final-form';
import IconUndo from '@material-ui/icons/Undo';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';

import IconButton from '../../common/IconButton';
import SimpleForm from '../../common/SimpleForm';
import Toolbar from './Toolbar';
import ArchivedReasonInput from '../ArchivedReasonInput';

import { UNARCHIVE } from '../../../provider';


const useStyles = makeStyles(theme => ({
	suggestionsContainerOpen: {
		position: 'absolute',
		marginBottom: theme.spacing(3),
		zIndex: theme.zIndex.modal + 200,
	}
}));

const SaveButton = ({ onClick, record, ...props }) => {
	const formState = useFormState();
	const refresh = useRefresh();
	const form = useForm();
	const notify = useNotify();
	const [mutate] = useMutation();

	const handleClick = async () => {
		mutate(
			{
				type: 'delete',
				resource: 'devices',
				payload: { 
					id: record.id,
					data: {
						archived_reason: formState.values.archived_reason,
						archived_notes: formState.values.archived_notes,
						archived_date: formState.values.archived_date,
						sim_cards_action: formState.values.sim_cards_action,
					}
				},
			},
			{
				onSuccess: (response) => {
					if (response.reason) {
						notify(response.reason, 'warning');
					}
					else {
						notify('1 archived element');
						refresh();
					}
				},
				onFailure: (error) => notify(`Error: ${error.message}`, 'warning'),
			}
		);

		form.reset();
		onClick();
	};

	return <RASaveButton {...props} handleSubmit={handleClick} handleSubmitWithRedirect={handleClick} />;
};

const DeviceArchiveButton = ({ record, resource, label, variant, color }) => {
	const refresh = useRefresh();
	const notify = useNotify();
	const [showDialog, setShowDialog] = useState(false);
	const classes = useStyles();

	const [unarchive, { loading }] = useMutation(
		{
			type: UNARCHIVE,
			resource: resource,
			payload: { id: record.id }
		},
		{
			onSuccess: ({ data }) => {
				refresh();
				notify(`1 unarchived element`);
			},
			onFailure: (error) => {
				notify(error.message, 'warning');
			}
		}
	);

	const handleSave = () => {
		handleClose();
	};

	const handleOpen = () => {
		setShowDialog(true);
	};

	const handleClose = () => {
		setShowDialog(false);
	};

	return (
		record.archived_at ? (
			<IconButton onClick={unarchive} icon={<IconUndo />} disabled={loading} variant={variant} color={color}>Unarchive</IconButton>
		) : (
			<>
				<IconButton icon={<DeleteIcon />} onClick={handleOpen} color="secondary" variant={variant}>{label}</IconButton>
				<Dialog fullWidth open={showDialog} onClose={handleClose} aria-label="Decommission Device">
					<DialogTitle>Decommission Device</DialogTitle>
					<DialogContent>
							Are you sure you want to Decommission this device ?
						<SimpleForm toolbar={<Toolbar onClose={handleClose} onSave={handleSave} saveButton={<SaveButton onClick={handleSave} />} />} record={{ ...record, archived_date: new Date() }}>
							<ArchivedReasonInput
								options={{ suggestionsContainerProps: { className: classes.suggestionsContainerOpen } }}
								label="Reason"
							/>
							<TextInput source="archived_notes" label="Notes" options={{ multiline: true }} rows="4" />
							<DateInput source="archived_date" label="Date" />
							<Typography>{record.lines_count} Lines will be detached from this device.</Typography>
							<Typography>{record.sim_cards_count} SimCards attached to this device. What do you want to do ? </Typography>
							<RadioButtonGroupInput label='' source="sim_cards_action" choices={[
								{ id: 'return', name: 'Back To Stock SimCards' },
								{ id: 'decommission', name: 'Decommission SimCards' },
							]} />
						</SimpleForm>
					</DialogContent>
				</Dialog>
			</>
		)
	);
};

DeviceArchiveButton.defaultProps = {
	label: 'Decommission'
};

export default DeviceArchiveButton;