import React, { useState } from 'react';
import { Confirm, useRefresh } from 'react-admin';
import CircularProgress from '@material-ui/core/CircularProgress';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';

import IconButton from '../../common/IconButton';

import { pingIP } from '../../../services/api';


const PingButton = ({ record, variant, color }) => {
	const refresh = useRefresh();
	const [showDialog, setShowDialog] = useState(false);
	const [message, setMessage] = useState();
	const [loading, setLoading] = useState(false);

	const handleConfirm = async () => {
		if (!message) {
			setLoading(true);
			const response = await pingIP(record.id);
			setMessage(
				<>
					<Typography>Average: {response.avg_rtt} ms</Typography>
					<Typography>Min: {response.min_rtt} ms</Typography>
					<Typography>Max: {response.max_rtt} ms</Typography>
				</>
			);
			setLoading(false);
		}
		else {
			handleClose();
		}
	};

	const handleOpen = () => {
		setShowDialog(true);
	};

	const handleClose = () => {
		setShowDialog(false);
		if (message) {
			refresh();
			setMessage(null);
		}
	};

	if (!record) {
		return null;
	}

	return (
		<>
			<IconButton icon={<ChevronRight />} onClick={handleOpen} variant={variant} color={color}>Ping</IconButton>
			<Confirm
				isOpen={showDialog}
				title="Ping"
				content={
					<>
						{message ? message : `Do you want to ping ${record.address}?`}
						{loading && (
							<Box mt={1} textAlign="center">
								<CircularProgress
									size={18}
									thickness={2}
								/>
							</Box>
						)}
					</>
				}
				onConfirm={handleConfirm}
				onClose={handleClose}
			/>
		</>
	);
};

export default PingButton;
