import React, { useState } from 'react';
import { Confirm, useRefresh } from 'react-admin';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';

import IconButton from '../../common/IconButton';

import { idleService, unidleService } from '../../../services/api';


const ServiceIdleButton = ({ record, variant, color }) => {
	const refresh = useRefresh();
	const [showDialog, setShowDialog] = useState(false);
	const { status, start_date } = record;

	const handleConfirm = async () => {
		if(!status) {
			return;
		}

		if(status === 'IDLE') {
			await unidleService(record.id);
		}
		else {
			await idleService(record.id);
		}

		handleClose();
		refresh();
	};

	const handleOpen = () => {
		setShowDialog(true);
	};

	const handleClose = () => {
		setShowDialog(false);
	};

	if(!['DELIVERED', 'IDLE'].includes(status) || (status !== 'IDLE' && start_date !==null)) {
		return null;
	}

	return (
		<>
			<IconButton icon={status === 'IDLE' ? <CheckIcon /> : <ClearIcon />} onClick={handleOpen} variant={variant} color={color}>{status === 'IDLE' ? 'UnIdle' : 'Idle'}</IconButton>
			<Confirm
				isOpen={showDialog}
				title={status === 'IDLE' ? 'Idle Service' : 'UnIdle Service'}
				content={`Are you sure you want to ${status === 'IDLE' ? 'unidle' : 'idle'} the service ${record.reference}?`}
				onConfirm={handleConfirm}
				onClose={handleClose}
			/>
		</>
	);
};

export default ServiceIdleButton;