import React from 'react';
import { TextInput, ReferenceInput, AutocompleteInput, FormDataConsumer } from 'react-admin';

import Edit from '../common/Edit';
import SimpleForm from '../common/SimpleForm';

const optionText = choice => choice.name ? choice.name : '';
const deviceOptionText = choice => `${choice.serial ? choice.serial : ''} / ${choice.ic_group_name ? choice.ic_group_name : ''} / ${choice.name ? choice.name : ''}`;
const siteOptionText = choice => choice && choice.name ? choice.name : '';

const StarlinkTerminalEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="id" disabled />
            <ReferenceInput label="Customer" source="customer_id" reference="customers" sort={{ field: 'name', order: 'ASC' }} allowEmpty>
				<AutocompleteInput optionText={optionText} />
			</ReferenceInput>
            <FormDataConsumer>
                {({ formData, ...rest }) => (
                    <ReferenceInput label="Site" source="site_id" reference="sites" filter={formData.customer_id && { customer_id: formData.customer_id }} {...rest} allowEmpty>
                        <AutocompleteInput optionText={siteOptionText} />
                    </ReferenceInput>
                )}
            </FormDataConsumer>
            <ReferenceInput label="Device" source="device_id" reference="devices" sort={{ field: 'serial', order: 'ASC' }} allowEmpty>
                <AutocompleteInput optionText={deviceOptionText} />
            </ReferenceInput>
            <TextInput source="ip_address" />
            <TextInput source="port" />
        </SimpleForm>
    </Edit>
);

export default StarlinkTerminalEdit;