import React from 'react';
import { TextField, SimpleShowLayout } from 'react-admin';

import Datagrid from '../common/Datagrid';
import CountryField from '../common/fields/CountryField';
import DateField from '../common/fields/DateField';
import LinkFieldButton from '../common/LinkFieldButton';
import RecordSplitButton from '../common/RecordSplitButton';


const CompanyDrawer = (props) => (
	<SimpleShowLayout {...props}>
		<TextField source="id" />
		<TextField source="name" />
		<CountryField />
		<TextField source="street" />
		<TextField source="zipcode" />
		<TextField source="city" />
		<TextField source="vat_number" />
		<TextField source="bank_name" />
		<TextField source="bank_bic" />
		<TextField source="bank_iban" />
	</SimpleShowLayout>
);

const CompanyRow = ({ children, ...props }) => (
	<Datagrid drawer={<CompanyDrawer {...props} />} {...props}>
		<LinkFieldButton label="Name" sortBy="name">
			<TextField source="name" />
		</LinkFieldButton>
		<CountryField />
		<DateField source="updated_at" />
		<RecordSplitButton />
		{children}
	</Datagrid>
);

export default CompanyRow;