import React from 'react';
import { AutocompleteInput } from 'react-admin';

const choices = [
	{ id: 'RMA', name: 'RMA' },
	{ id: 'DOA', name: 'DOA' },
	{ id: 'LOST', name: 'LOST' },
	{ id: 'LOST BY VENN', name: 'LOST BY VENN' },
	{ id: 'STOLEN', name: 'STOLEN' },
	{ id: 'STOLEN FROM VENN)', name: 'STOLEN FROM VENN' },
	{ id: 'BROKEN', name: 'BROKEN' },
	{ id: 'LOAN RETURNED', name: 'LOAN RETURNED' },
	{ id: 'UNKNOWN', name: 'UNKNOWN' },
	{ id: 'OTHER', name: 'OTHER' },
];

const ArchivedReasonInput = ({ classes, record, source = "archived_reason", ...props }) => {
	if (record && record[source] && !choices.find((provider) => provider.id === record[source])) {
		choices.push({ id: record[source], name: record[source] });
	}

	return (
		<AutocompleteInput choices={choices} source={source} record={record} allowEmpty {...props} />
	);
};

export default ArchivedReasonInput;
