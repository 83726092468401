import React from 'react';
import { ReferenceField, TextField, SimpleShowLayout, NumberField } from 'react-admin';

import Datagrid from '../common/Datagrid';
import RecordSplitButton from '../common/RecordSplitButton';


const InvoiceLineDrawer = (props) => (
	<SimpleShowLayout {...props}>
		<TextField source="id" />
		<ReferenceField label="Invoice" source="invoice_id" reference="invoices" link="show" allowEmpty>
            <TextField source="number" />
        </ReferenceField>
		<TextField source="id" />
		<TextField source="type" />
		<TextField source="package_reference" />
		<TextField source="description" />
		<NumberField source="qty" />
		<NumberField source="unit_price" />
		<TextField source="vat_code" />
		<NumberField source="tax_rate" />
	</SimpleShowLayout>
);

const InvoiceLineRow = ({ children, ...props }) => (
	<Datagrid {...props} drawer={<InvoiceLineDrawer {...props} />}>
		<TextField source="id" />
		<ReferenceField label="Invoice" source="invoice_id" reference="invoices" link="show" allowEmpty>
            <TextField source="id" />
        </ReferenceField>
		<TextField source="type" />
		<TextField source="package_reference" />
		<TextField source="description" />
		<NumberField source="qty" />
		<NumberField source="unit_price" />
		<TextField source="vat_code" />
		<NumberField source="tax_rate" />
		{children}
		<RecordSplitButton />
	</Datagrid>
);

InvoiceLineRow.defaultProps = {
	basePath: '/invoice_lines'
};

export default InvoiceLineRow;
