import React from 'react';
import { TextField, List } from 'react-admin';
import { ThemeProvider } from '@material-ui/core/styles';

import Datagrid from '../common/Datagrid';
import DateField from '../common/fields/DateField';
import { DatasBeforeAfterField, BeforeAfterField } from './BeforeAfterField';

import { themeCustomList } from '../common/CustomList';

const FilteredLogList = ({ resource, record, ...props }) => {
	const getFilter = () => {
		switch(resource) {
			case 'sites':
				return 'site_id';
			case 'simcards':
				return 'sim_id';
			case 'devices':
				return 'device_id';
			case 'lines':
				return 'line_id';
			case 'customers':
				return 'customer_id';
			case 'packages':
				return 'package_id';
			case 'services':
				return 'service_id';
			case 'product_items':
				return 'product_item_id';
			default:
				return null;
		};
	};
	const filter = record ? { [getFilter()]: record.id } : {};

	return (
		<ThemeProvider theme={themeCustomList}>
			<List
				filter={filter}
				sort={{ field: 'created_at', order: 'DESC' }}
				{...props}
				resource="logs"
				basepath="/logs"
				title=" "
				hasCreate={false}
				hasEdit={false}
				hasList={false}
				hasShow={false}
				bulkActionButtons={false}
			>
				<Datagrid>
					<DateField label="Date" source="created_at" showTime />
					<TextField label="Author" source="author_name"  />
					<TextField label="Action" source="verb" />
					<DatasBeforeAfterField sortable={false} />
					<BeforeAfterField source="before" sortable={false} />
					<BeforeAfterField source="after" sortable={false} />
				</Datagrid>
			</List>
		</ThemeProvider>
	);
};

export default FilteredLogList;