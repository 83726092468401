import React from 'react';
import { ReferenceField, TextField, SimpleShowLayout } from 'react-admin';
import { withStyles } from '@material-ui/core';

import Datagrid from '../common/Datagrid';
import MetadataField from '../common/fields/MetadataField';
import BooleanField from '../common/fields/BooleanField';
import LinkFieldButton from '../common/LinkFieldButton';
import HumanDateField from '../common/fields/HumanDateField';
import DeviceUsageGraph from '../common/DeviceUsageGraph';
import RecordSplitButton from '../common/RecordSplitButton';
import WanField from '../wans/WanField';
import OnlineStatusIcon from './OnlineStatusIcon';
import { ICUrls } from './DeviceShow';
import DeviceActionsButtons from './DeviceActionsButtons';
import RebootDeviceButton from './DeviceActionsButtons/RebootDeviceButton';
import DeviceArchiveButton from './DeviceActionsButtons/DeviceArchiveButton';
import BackToStockButton from './DeviceActionsButtons/BackToStockButton';
import SyncDeviceButton from './DeviceActionsButtons/SyncDeviceButton';
import SpeedTestDeviceButton from './DeviceActionsButtons/SpeedTestButton';
import PortsEditButton from './DeviceActionsButtons/PortsEditButton';


const styles = {
	table: {
		'& .column-wans': {
			maxWidth: '120px'
		}
	}
};

const SimCardCountField = ({ record = {} }) => <span>{record.sim_cards_count} / {record.ports_count * 2}</span>;

const ReservedField = ({ record, ...props }) => {
	let options = {};
	if (record.notes) {
		options = {
			valueLabelTrue: record.notes
		};
	}

	return <BooleanField record={record} {...props} {...options} />;
};

const DeviceDrawer = (props) => (
	<SimpleShowLayout {...props}>
		<ICUrls source="ic_urls" />
		<TextField source="id" />
		<TextField source="name" />
		<BooleanField source="virtual" />
		<BooleanField label="Reserved" source="reserved" />
		<BooleanField label="Is New" source="is new" />
		<ReferenceField label="Customer" source="customer_id" reference="customers" link="show" allowEmpty>
			<TextField source="name" />
		</ReferenceField>
		<ReferenceField label="Site" source="site.id" reference="sites" link="show" allowEmpty>
			<TextField source="name" />
		</ReferenceField>
		<TextField source="address" />
		<TextField source="buy_sm" label="Buy/SM" />
		<TextField source="rent_sold" label="VENN Billing Mode" />
		<TextField source="supplier" label="Supplier" />
		<TextField source="ic_name" />
		<TextField source="ic_server_name" />
		<TextField source="ic_organization_name" />
		<TextField source="ic_group_name" />
		<TextField source="ic_tags" />
		<TextField source="ic_notes" />
		<TextField label="Data Limit Alert (GB)" source="data_limit" />
		<TextField label="Data Limit Contacts" source="data_limit_contacts" />
		<TextField source="notes" />
		<TextField source="customer_notes" />
		<HumanDateField source="last_online" />
		<HumanDateField source="last_sync_at" />
		<MetadataField source="ic_datas" filters={[
			'product_id',
			'product_code',
			'product_name',
			'admin_conf',
			'admin_name',
			'admin_password',
			'device_type',
			'fw_ver',
			'vlan_interfaces',
			'vlan_id',
			'vlan_ip',
			'netmask',
			'name',
			'ssid_profiles',
			'ssid',
			'wpa_personal',
			'shared_key',
			'enabled',
			'layer2_isolation',
			'comments'
		]} />
		<DeviceUsageGraph small />
	</SimpleShowLayout>
);

const DeviceRow = ({ children, classes, ...props }) => (
	<Datagrid className={classes.table} {...props} drawer={<DeviceDrawer {...props} />} drawerActions={<DeviceActionsButtons />}>
		<LinkFieldButton label="Serial" sortBy="serial" basePath="/devices">
			<TextField source="serial" />
		</LinkFieldButton>
		<LinkFieldButton label="Name" sortBy="name" basePath="/devices">
			<TextField source="name" />
		</LinkFieldButton>
		<LinkFieldButton label="Customer" source="customer" sortBy="customer_id" basePath="/customers">
			<TextField source="name" />
		</LinkFieldButton>
		<LinkFieldButton label="Site" source="site" sortBy="site.name" basePath="/sites">
			<TextField source="name" />
		</LinkFieldButton>
		<LinkFieldButton label="Product" source="product" sortBy="product_id" basePath="/device_products">
			<TextField source="reference" />
		</LinkFieldButton>
		<OnlineStatusIcon source="online_status" label="" />
		<TextField label="IC Server" source="ic_server_name" />
		<TextField label="IC Org" source="ic_organization_name" />
		<TextField label="IC Group" source="ic_group_name" />
		<WanField source="wans" sortable={false} />
		<SimCardCountField source="sim_cards_count" label="#simcards" sortable={false} />
		<ReservedField source="reserved" />
		<BooleanField label="New" source="is_new"/>
		{children}
		<RecordSplitButton archiveButton={<DeviceArchiveButton />}>
			<RebootDeviceButton />
			<BackToStockButton />
			<SyncDeviceButton />
			<SpeedTestDeviceButton />
			<PortsEditButton />
		</RecordSplitButton>
	</Datagrid>
);

DeviceRow.defaultProps = {
	basePath: '/devices'
};

export default withStyles(styles)(DeviceRow);