import React from 'react';
import { TextInput, ReferenceInput, AutocompleteInput, DateInput } from 'react-admin';

import GetAppIcon from '@material-ui/icons/GetApp';

import List, { ListActions } from '../common/List';
import IconButton from '../common/IconButton';
import Filter from '../common/Filter';
import InvoiceRow from './InvoiceRow';
import InvoiceBulkActions from './InvoiceActionsButtons/InvoiceBulkActions';
import { API_URL } from '../../services/settings';
import InvoiceTypeField from './InvoiceTypeField';
import StatusInput from './inputs/StatusInput';

const optionText = choice => choice.name ? choice.name : '';
const addressOptionText = choice => choice?.name ? `${choice?.name} ${choice?.full_address ? `(${choice.full_address})` : ''}` : '';


const InvoiceListActions = (props) => {
	const params = new URLSearchParams();
	params.append('format', 'xls');
	for (const key in props.filterValues) {
		params.append(key, props.filterValues[key]);
	}

    return (
        <ListActions {...props}>
            <IconButton icon={<GetAppIcon />} component="a" href={`${API_URL}/invoices/export.xls?${params.toString()}`}>Export</IconButton>
        </ListActions>
    );
};

const InvoiceFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <StatusInput alwaysOn />
        <InvoiceTypeField label="Type" source="invoice_type" alwaysOn />
        <ReferenceInput label="Customer" source="customer_id" reference="customers" sort={{ field: 'name', order: 'ASC' }} alwaysOn>
            <AutocompleteInput />
        </ReferenceInput>
        <ReferenceInput label="Billing company" source="billing_company_id" reference="companies" sort={{ field: 'name', order: 'ASC' }} allowEmpty alwaysOn>
            <AutocompleteInput optionText={optionText} />
        </ReferenceInput>
        <ReferenceInput label="Billing address" source="invoicing_address_id" reference="addresses" sort={{ field: 'name', order: 'ASC' }} allowEmpty alwaysOn>
            <AutocompleteInput optionText={addressOptionText} />
        </ReferenceInput>
        <DateInput source="start_date" alwaysOn />
		<DateInput source="end_date" alwaysOn />
    </Filter>
);

const InvoiceList = props => (
	<List {...props} filters={<InvoiceFilter />} bulkActionButtons={<InvoiceBulkActions />} actions={<InvoiceListActions />} exporter={false}>
        <InvoiceRow />
	</List>
);

export default InvoiceList;