import React from 'react';
import { AutocompleteInput } from 'react-admin';

const choices = [
	{ id: 'AMAZON', name: 'AMAZON' },
	{ id: 'PEPLINK', name: 'PEPLINK' },
	{ id: 'SLINGSHOT', name: 'SLINGSHOT' },
	{ id: 'MCS', name: 'MCS' },
	{ id: 'FRONTIER', name: 'FRONTIER' },
	{ id: 'STARLINK', name: 'STARLINK' },
];

const ProductItemSupplierField = props => (
	<AutocompleteInput choices={choices} allowEmpty {...props} />
);

ProductItemSupplierField.defaultProps = {
	source: "supplier"
};

export default ProductItemSupplierField;
