import React, { Component } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Label
} from 'recharts';
import { withTheme } from '@material-ui/core/styles';

import GraphTooltip from '../common/GraphTooltip';

import { getDevicesStats } from '../../services/api';

const CustomToolTip = props => {
  const { active, payload, label } = props;
  if (!active || !payload) {
    return null;
  }
  return (
    <GraphTooltip>
      <p>
        <strong>{label}</strong>
      </p>
      {payload.map((item, i) => (
        <p key={i}><strong>{item.value}</strong></p>
      ))}
    </GraphTooltip>
  );
};

class DevicesStatsGraph extends Component {
  state = {
		data: []
  };

  fetchData = async () => {
    const results = await getDevicesStats();
		this.setState(results);
  }

  componentDidMount() {
    this.fetchData();
  }

  render() {
    const { small, theme } = this.props;

    return (
      <ResponsiveContainer width="100%" height={small ? 200 : 450}>
        <BarChart
          data={this.state.data}
          margin={{
            top: 50, right: 30, left: 20, bottom: 10,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="key" height={50} tick={false}>
            <Label value="Devices in stock" position="insideBottom" style={{ fill: theme.palette.text.primary }} />
          </XAxis>
          <YAxis dataKey="value"/>
          <Tooltip content={<CustomToolTip />} cursor={theme.palette.type === 'dark' ? { fill: theme.palette.background.paper } : {}} />
          <Bar dataKey="value" fill="#3A8B7E" />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}

export default withTheme(DevicesStatsGraph);
