import React from 'react';
import {
    TextInput,
    ReferenceInput,
    AutocompleteInput,
    BooleanInput,
} from 'react-admin';

import List, { ListActions } from '../common/List';
import Filter from '../common/Filter';
import SimPoolRow from './SimPoolRow';

import GetAppIcon from '@material-ui/icons/GetApp';
import IconButton from '../common/IconButton';

import { API_URL } from '../../services/settings';

const optionText = choice => choice.name ? choice.name : '';

const SimPoolFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <ReferenceInput label="Customer" source="customer_id" reference="customers" sort={{ field: 'name', order: 'ASC' }} allowEmpty alwaysOn>
            <AutocompleteInput optionText={optionText} />
        </ReferenceInput>
        <BooleanInput label="Archived" source="archived" alwaysOn />
    </Filter>
);

const filterDefaultValues = {
    archived: false,
}

const SimPoolActions = (props) => (
    <ListActions {...props}>
        <IconButton icon={<GetAppIcon />} component="a" href={`${API_URL}/simpools/export.xls`}>Export</IconButton>
    </ListActions>
);

const SimPoolList = props => (
    <List {...props } filters={<SimPoolFilter/>} actions={<SimPoolActions />} perPage={50} filterDefaultValues={ filterDefaultValues } exporter={false}>
        <SimPoolRow />
    </List>
);

export default SimPoolList;