import React from 'react';
import { TextInput } from 'react-admin';
import List from '../common/List';
import Filter from '../common/Filter';
import SpeedTestRow from './SpeedTestRow';


const SpeedTestFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
    </Filter>
);

const SpeedTestList = props => (
    <List {...props} filters={<SpeedTestFilter/>} sort={{ field: 'created_at', order: 'DESC' }} exporter={false} perPage={100}>
        <SpeedTestRow />
    </List>
);

export default SpeedTestList;