import React from 'react';
import { useTranslate } from 'react-admin';
import { Link } from 'react-router-dom';
import IconReturn from '@material-ui/icons/KeyboardReturn';

import IconButton from '../../common/IconButton';


const PackageLineShipButton = ({ record, variant, color }) => {
    const translate = useTranslate();

    if (record.status !== 'PROVISIONED') {
        return null;
    }

    return (
        <IconButton component={Link} to={`/package_lines/${record.id}/ship`} icon={<IconReturn />} variant={variant} color={color}>{translate('Ship')}</IconButton>
    );
};

export default PackageLineShipButton;