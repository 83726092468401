import React from 'react';
import { TabbedShowLayout, Tab, TextField, NumberField, ReferenceManyField, Pagination } from 'react-admin';
import { Helmet } from 'react-helmet';

import Show from '../common/Show';
import DateField from '../common/fields/DateField';
import BooleanField from '../common/fields/BooleanField';
import ProductItemRow from '../product_items/ProductItemRow';
import DeviceRow from '../devices/DeviceRow';

const DeviceProductTitle = ({ record }) => (
	<>
		<Helmet>
			<title>{`KB - DeviceProduct[${record.name}]`}</title>
		</Helmet>
		<span>{record.name}</span>
	</>
);

const DeviceProductShow = (props) => (
	<Show title={<DeviceProductTitle />} {...props}>
		<TabbedShowLayout>
			<Tab label="summary">
				<TextField label="Id" source="id" />
				<TextField source="reference" />
				<TextField source="name" />
				<TextField source="brand" />
				<TextField source="family" />
				<TextField source="sub_family" />
				<TextField source="category" />
				<BooleanField source="managed" />
				<BooleanField source="virtual" />
				<BooleanField source="accessory" />
				<NumberField source="stock_min_level" />
				<NumberField source="stock_max_level" />
				<TextField source="description" options={{ multiline: true }} rows="4" />
				<DateField label="Created at" source="created_at" />
				<DateField label="Updated at" source="updated_at" />
			</Tab>
			<Tab label="Stock Product items" path="product_items">
				<ReferenceManyField addLabel={false} reference="product_items" target="product_id" pagination={<Pagination />} filter={{ archived: false, in_stock: true }}>
					<ProductItemRow />
				</ReferenceManyField>
			</Tab>
			<Tab label="Stock Devices" path="devices">
				<ReferenceManyField addLabel={false} reference="devices" target="product_id" pagination={<Pagination />} filter={{ archived: false, in_stock: true }}>
					<DeviceRow />
				</ReferenceManyField>
			</Tab>
		</TabbedShowLayout>
	</Show>
);

export default DeviceProductShow;
