import React from 'react';
import { TextInput } from 'react-admin';

import List from '../common/List';
import Filter from '../common/Filter';
import StarlinkServiceLineRow from './StarlinkServiceLineRow';


const StarlinkServiceLineFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
    </Filter>
);

const StarlinkServiceLineList = props => (
    <List {...props} filters={<StarlinkServiceLineFilter />}>
        <StarlinkServiceLineRow />
    </List>
);

export default StarlinkServiceLineList;