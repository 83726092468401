import React from 'react';
import {
	TextField,
	EmailField,
	SimpleShowLayout
} from 'react-admin';

import Datagrid from '../common/Datagrid';
import LinkFieldButton from '../common/LinkFieldButton';
import DateField from '../common/fields/DateField';
import RecordSplitButton from '../common/RecordSplitButton';

const ContactDrawer = (props) => (
	<SimpleShowLayout {...props}>
		<TextField source="id" />
		<TextField source="name" />
		<LinkFieldButton label="Customer" source="customer" sortBy="customer_id" basePath="customers">
			<TextField source="name" />
		</LinkFieldButton>
		<TextField source="contact_type" />
		<TextField source="job_title" />
		<TextField source="email" />
		<TextField source="phone" />
		<TextField source="department" />
		<TextField source="address" />
		<TextField source="lang" />
		<TextField source="timezone" />
		<TextField source="description" />
	</SimpleShowLayout>
);

const ContactRow = ({ children, ...props }) => (
	<Datagrid drawer={<ContactDrawer {...props} />} {...props}>
		<LinkFieldButton label="Name" sortBy="name">
			<TextField source="name" />
		</LinkFieldButton>
		<LinkFieldButton label="Customer" source="customer" sortBy="customer_id" basePath="/customers">
			<TextField source="name" />
		</LinkFieldButton>
		<EmailField source="email" />
		<TextField source="phone" />
		<TextField source="contact_type" />
		<DateField source="updated_at" />
		<RecordSplitButton />
		{children}
	</Datagrid>
);

ContactRow.defaultProps = {
	basePath: '/contacts'
};

export default ContactRow;