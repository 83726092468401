import React from 'react';
import { AutocompleteInput } from 'react-admin';

const choices = [
	{ 'id': 'AW', 'name': 'Aruba' },
	{ 'id': 'AF', 'name': 'Afghanistan' },
	{ 'id': 'AO', 'name': 'Angola' },
	{ 'id': 'AI', 'name': 'Anguilla' },
	{ 'id': 'AX', 'name': 'Åland Islands' },
	{ 'id': 'AL', 'name': 'Albania' },
	{ 'id': 'AD', 'name': 'Andorra' },
	{ 'id': 'AE', 'name': 'United Arab Emirates' },
	{ 'id': 'AR', 'name': 'Argentina' },
	{ 'id': 'AM', 'name': 'Armenia' },
	{ 'id': 'AS', 'name': 'American Samoa' },
	{ 'id': 'AQ', 'name': 'Antarctica' },
	{ 'id': 'TF', 'name': 'French Southern Territories' },
	{ 'id': 'AG', 'name': 'Antigua and Barbuda' },
	{ 'id': 'AU', 'name': 'Australia' },
	{ 'id': 'AT', 'name': 'Austria' },
	{ 'id': 'AZ', 'name': 'Azerbaijan' },
	{ 'id': 'BI', 'name': 'Burundi' },
	{ 'id': 'BE', 'name': 'Belgium' },
	{ 'id': 'BJ', 'name': 'Benin' },
	{ 'id': 'BQ', 'name': 'Bonaire, Sint Eustatius and Saba' },
	{ 'id': 'BF', 'name': 'Burkina Faso' },
	{ 'id': 'BD', 'name': 'Bangladesh' },
	{ 'id': 'BG', 'name': 'Bulgaria' },
	{ 'id': 'BH', 'name': 'Bahrain' },
	{ 'id': 'BS', 'name': 'Bahamas' },
	{ 'id': 'BA', 'name': 'Bosnia and Herzegovina' },
	{ 'id': 'BL', 'name': 'Saint Barthélemy' },
	{ 'id': 'BY', 'name': 'Belarus' },
	{ 'id': 'BZ', 'name': 'Belize' },
	{ 'id': 'BM', 'name': 'Bermuda' },
	{ 'id': 'BO', 'name': 'Bolivia, Plurinational State of' },
	{ 'id': 'BR', 'name': 'Brazil' },
	{ 'id': 'BB', 'name': 'Barbados' },
	{ 'id': 'BN', 'name': 'Brunei Darussalam' },
	{ 'id': 'BT', 'name': 'Bhutan' },
	{ 'id': 'BV', 'name': 'Bouvet Island' },
	{ 'id': 'BW', 'name': 'Botswana' },
	{ 'id': 'CF', 'name': 'Central African Republic' },
	{ 'id': 'CA', 'name': 'Canada' },
	{ 'id': 'CC', 'name': 'Cocos (Keeling) Islands' },
	{ 'id': 'CH', 'name': 'Switzerland' },
	{ 'id': 'CL', 'name': 'Chile' },
	{ 'id': 'CN', 'name': 'China' },
	{ 'id': 'CI', 'name': "Côte d'Ivoire" },
	{ 'id': 'CM', 'name': 'Cameroon' },
	{ 'id': 'CD', 'name': 'Congo, The Democratic Republic of the' },
	{ 'id': 'CG', 'name': 'Congo' },
	{ 'id': 'CK', 'name': 'Cook Islands' },
	{ 'id': 'CO', 'name': 'Colombia' },
	{ 'id': 'KM', 'name': 'Comoros' },
	{ 'id': 'CV', 'name': 'Cabo Verde' },
	{ 'id': 'CR', 'name': 'Costa Rica' },
	{ 'id': 'CU', 'name': 'Cuba' },
	{ 'id': 'CW', 'name': 'Curaçao' },
	{ 'id': 'CX', 'name': 'Christmas Island' },
	{ 'id': 'KY', 'name': 'Cayman Islands' },
	{ 'id': 'CY', 'name': 'Cyprus' },
	{ 'id': 'CZ', 'name': 'Czechia' },
	{ 'id': 'DE', 'name': 'Germany' },
	{ 'id': 'DJ', 'name': 'Djibouti' },
	{ 'id': 'DM', 'name': 'Dominica' },
	{ 'id': 'DK', 'name': 'Denmark' },
	{ 'id': 'DO', 'name': 'Dominican Republic' },
	{ 'id': 'DZ', 'name': 'Algeria' },
	{ 'id': 'EC', 'name': 'Ecuador' },
	{ 'id': 'EG', 'name': 'Egypt' },
	{ 'id': 'ER', 'name': 'Eritrea' },
	{ 'id': 'EH', 'name': 'Western Sahara' },
	{ 'id': 'ES', 'name': 'Spain' },
	{ 'id': 'EE', 'name': 'Estonia' },
	{ 'id': 'ET', 'name': 'Ethiopia' },
	{ 'id': 'FI', 'name': 'Finland' },
	{ 'id': 'FJ', 'name': 'Fiji' },
	{ 'id': 'FK', 'name': 'Falkland Islands (Malvinas)' },
	{ 'id': 'FR', 'name': 'France' },
	{ 'id': 'FO', 'name': 'Faroe Islands' },
	{ 'id': 'FM', 'name': 'Micronesia, Federated States of' },
	{ 'id': 'GA', 'name': 'Gabon' },
	{ 'id': 'GB', 'name': 'United Kingdom' },
	{ 'id': 'GE', 'name': 'Georgia' },
	{ 'id': 'GG', 'name': 'Guernsey' },
	{ 'id': 'GH', 'name': 'Ghana' },
	{ 'id': 'GI', 'name': 'Gibraltar' },
	{ 'id': 'GN', 'name': 'Guinea' },
	{ 'id': 'GP', 'name': 'Guadeloupe' },
	{ 'id': 'GM', 'name': 'Gambia' },
	{ 'id': 'GW', 'name': 'Guinea-Bissau' },
	{ 'id': 'GQ', 'name': 'Equatorial Guinea' },
	{ 'id': 'GR', 'name': 'Greece' },
	{ 'id': 'GD', 'name': 'Grenada' },
	{ 'id': 'GL', 'name': 'Greenland' },
	{ 'id': 'GT', 'name': 'Guatemala' },
	{ 'id': 'GF', 'name': 'French Guiana' },
	{ 'id': 'GU', 'name': 'Guam' },
	{ 'id': 'GY', 'name': 'Guyana' },
	{ 'id': 'HK', 'name': 'Hong Kong' },
	{ 'id': 'HM', 'name': 'Heard Island and McDonald Islands' },
	{ 'id': 'HN', 'name': 'Honduras' },
	{ 'id': 'HR', 'name': 'Croatia' },
	{ 'id': 'HT', 'name': 'Haiti' },
	{ 'id': 'HU', 'name': 'Hungary' },
	{ 'id': 'ID', 'name': 'Indonesia' },
	{ 'id': 'IM', 'name': 'Isle of Man' },
	{ 'id': 'IN', 'name': 'India' },
	{ 'id': 'IO', 'name': 'British Indian Ocean Territory' },
	{ 'id': 'IE', 'name': 'Ireland' },
	{ 'id': 'IR', 'name': 'Iran, Islamic Republic of' },
	{ 'id': 'IQ', 'name': 'Iraq' },
	{ 'id': 'IS', 'name': 'Iceland' },
	{ 'id': 'IL', 'name': 'Israel' },
	{ 'id': 'IT', 'name': 'Italy' },
	{ 'id': 'JM', 'name': 'Jamaica' },
	{ 'id': 'JE', 'name': 'Jersey' },
	{ 'id': 'JO', 'name': 'Jordan' },
	{ 'id': 'JP', 'name': 'Japan' },
	{ 'id': 'KZ', 'name': 'Kazakhstan' },
	{ 'id': 'KE', 'name': 'Kenya' },
	{ 'id': 'KG', 'name': 'Kyrgyzstan' },
	{ 'id': 'KH', 'name': 'Cambodia' },
	{ 'id': 'KI', 'name': 'Kiribati' },
	{ 'id': 'KN', 'name': 'Saint Kitts and Nevis' },
	{ 'id': 'KR', 'name': 'Korea, Republic of' },
	{ 'id': 'KW', 'name': 'Kuwait' },
	{ 'id': 'LA', 'name': "Lao People's Democratic Republic" },
	{ 'id': 'LB', 'name': 'Lebanon' },
	{ 'id': 'LR', 'name': 'Liberia' },
	{ 'id': 'LY', 'name': 'Libya' },
	{ 'id': 'LC', 'name': 'Saint Lucia' },
	{ 'id': 'LI', 'name': 'Liechtenstein' },
	{ 'id': 'LK', 'name': 'Sri Lanka' },
	{ 'id': 'LS', 'name': 'Lesotho' },
	{ 'id': 'LT', 'name': 'Lithuania' },
	{ 'id': 'LU', 'name': 'Luxembourg' },
	{ 'id': 'LV', 'name': 'Latvia' },
	{ 'id': 'MO', 'name': 'Macao' },
	{ 'id': 'MF', 'name': 'Saint Martin (French part)' },
	{ 'id': 'MA', 'name': 'Morocco' },
	{ 'id': 'MC', 'name': 'Monaco' },
	{ 'id': 'MD', 'name': 'Moldova, Republic of' },
	{ 'id': 'MG', 'name': 'Madagascar' },
	{ 'id': 'MV', 'name': 'Maldives' },
	{ 'id': 'MX', 'name': 'Mexico' },
	{ 'id': 'MH', 'name': 'Marshall Islands' },
	{ 'id': 'MK', 'name': 'North Macedonia' },
	{ 'id': 'ML', 'name': 'Mali' },
	{ 'id': 'MT', 'name': 'Malta' },
	{ 'id': 'MM', 'name': 'Myanmar' },
	{ 'id': 'ME', 'name': 'Montenegro' },
	{ 'id': 'MN', 'name': 'Mongolia' },
	{ 'id': 'MP', 'name': 'Northern Mariana Islands' },
	{ 'id': 'MZ', 'name': 'Mozambique' },
	{ 'id': 'MR', 'name': 'Mauritania' },
	{ 'id': 'MS', 'name': 'Montserrat' },
	{ 'id': 'MQ', 'name': 'Martinique' },
	{ 'id': 'MU', 'name': 'Mauritius' },
	{ 'id': 'MW', 'name': 'Malawi' },
	{ 'id': 'MY', 'name': 'Malaysia' },
	{ 'id': 'YT', 'name': 'Mayotte' },
	{ 'id': 'NA', 'name': 'Namibia' },
	{ 'id': 'NC', 'name': 'New Caledonia' },
	{ 'id': 'NE', 'name': 'Niger' },
	{ 'id': 'NF', 'name': 'Norfolk Island' },
	{ 'id': 'NG', 'name': 'Nigeria' },
	{ 'id': 'NI', 'name': 'Nicaragua' },
	{ 'id': 'NU', 'name': 'Niue' },
	{ 'id': 'NL', 'name': 'Netherlands' },
	{ 'id': 'NO', 'name': 'Norway' },
	{ 'id': 'NP', 'name': 'Nepal' },
	{ 'id': 'NR', 'name': 'Nauru' },
	{ 'id': 'NZ', 'name': 'New Zealand' },
	{ 'id': 'OM', 'name': 'Oman' },
	{ 'id': 'PK', 'name': 'Pakistan' },
	{ 'id': 'PA', 'name': 'Panama' },
	{ 'id': 'PN', 'name': 'Pitcairn' },
	{ 'id': 'PE', 'name': 'Peru' },
	{ 'id': 'PH', 'name': 'Philippines' },
	{ 'id': 'PW', 'name': 'Palau' },
	{ 'id': 'PG', 'name': 'Papua New Guinea' },
	{ 'id': 'PL', 'name': 'Poland' },
	{ 'id': 'PR', 'name': 'Puerto Rico' },
	{ 'id': 'KP', 'name': "Korea, Democratic People's Republic of" },
	{ 'id': 'PT', 'name': 'Portugal' },
	{ 'id': 'PY', 'name': 'Paraguay' },
	{ 'id': 'PS', 'name': 'Palestine, State of' },
	{ 'id': 'PF', 'name': 'French Polynesia' },
	{ 'id': 'QA', 'name': 'Qatar' },
	{ 'id': 'RE', 'name': 'Réunion' },
	{ 'id': 'RO', 'name': 'Romania' },
	{ 'id': 'RU', 'name': 'Russian Federation' },
	{ 'id': 'RW', 'name': 'Rwanda' },
	{ 'id': 'SA', 'name': 'Saudi Arabia' },
	{ 'id': 'SD', 'name': 'Sudan' },
	{ 'id': 'SN', 'name': 'Senegal' },
	{ 'id': 'SG', 'name': 'Singapore' },
	{ 'id': 'GS', 'name': 'South Georgia and the South Sandwich Islands' },
	{ 'id': 'SH', 'name': 'Saint Helena, Ascension and Tristan da Cunha' },
	{ 'id': 'SJ', 'name': 'Svalbard and Jan Mayen' },
	{ 'id': 'SB', 'name': 'Solomon Islands' },
	{ 'id': 'SL', 'name': 'Sierra Leone' },
	{ 'id': 'SV', 'name': 'El Salvador' },
	{ 'id': 'SM', 'name': 'San Marino' },
	{ 'id': 'SO', 'name': 'Somalia' },
	{ 'id': 'PM', 'name': 'Saint Pierre and Miquelon' },
	{ 'id': 'RS', 'name': 'Serbia' },
	{ 'id': 'SS', 'name': 'South Sudan' },
	{ 'id': 'ST', 'name': 'Sao Tome and Principe' },
	{ 'id': 'SR', 'name': 'Suriname' },
	{ 'id': 'SK', 'name': 'Slovakia' },
	{ 'id': 'SI', 'name': 'Slovenia' },
	{ 'id': 'SE', 'name': 'Sweden' },
	{ 'id': 'SZ', 'name': 'Eswatini' },
	{ 'id': 'SX', 'name': 'Sint Maarten (Dutch part)' },
	{ 'id': 'SC', 'name': 'Seychelles' },
	{ 'id': 'SY', 'name': 'Syrian Arab Republic' },
	{ 'id': 'TC', 'name': 'Turks and Caicos Islands' },
	{ 'id': 'TD', 'name': 'Chad' },
	{ 'id': 'TG', 'name': 'Togo' },
	{ 'id': 'TH', 'name': 'Thailand' },
	{ 'id': 'TJ', 'name': 'Tajikistan' },
	{ 'id': 'TK', 'name': 'Tokelau' },
	{ 'id': 'TM', 'name': 'Turkmenistan' },
	{ 'id': 'TL', 'name': 'Timor-Leste' },
	{ 'id': 'TO', 'name': 'Tonga' },
	{ 'id': 'TT', 'name': 'Trinidad and Tobago' },
	{ 'id': 'TN', 'name': 'Tunisia' },
	{ 'id': 'TR', 'name': 'Turkey' },
	{ 'id': 'TV', 'name': 'Tuvalu' },
	{ 'id': 'TW', 'name': 'Taiwan, Province of China' },
	{ 'id': 'TZ', 'name': 'Tanzania, United Republic of' },
	{ 'id': 'UG', 'name': 'Uganda' },
	{ 'id': 'UA', 'name': 'Ukraine' },
	{ 'id': 'UM', 'name': 'United States Minor Outlying Islands' },
	{ 'id': 'UY', 'name': 'Uruguay' },
	{ 'id': 'US', 'name': 'United States' },
	{ 'id': 'UZ', 'name': 'Uzbekistan' },
	{ 'id': 'VA', 'name': 'Holy See (Vatican City State)' },
	{ 'id': 'VC', 'name': 'Saint Vincent and the Grenadines' },
	{ 'id': 'VE', 'name': 'Venezuela, Bolivarian Republic of' },
	{ 'id': 'VG', 'name': 'Virgin Islands, British' },
	{ 'id': 'VI', 'name': 'Virgin Islands, U.S.' },
	{ 'id': 'VN', 'name': 'Viet Nam' },
	{ 'id': 'VU', 'name': 'Vanuatu' },
	{ 'id': 'WF', 'name': 'Wallis and Futuna' },
	{ 'id': 'WS', 'name': 'Samoa' },
	{ 'id': 'YE', 'name': 'Yemen' },
	{ 'id': 'ZA', 'name': 'South Africa' },
	{ 'id': 'ZM', 'name': 'Zambia' },
	{ 'id': 'ZW', 'name': 'Zimbabwe' }
];

const CountryInput = ({ classes, record, source = "country", ...props }) => {
	if (!record) {
		return null;
	}

	if (record[source] && !choices.find((item) => item.id === record[source])) {
		choices.push({ id: record[source], name: record[source] });
	}

	return (
		<AutocompleteInput choices={choices} source={source} record={record} allowEmpty {...props} />
	);
};

export default CountryInput;
