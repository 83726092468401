import React, { useState } from 'react';
import { TextInput, DateInput, SaveButton as RASaveButton, useRefresh, useNotify, useUnselectAll } from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconReturn from '@material-ui/icons/KeyboardReturn';

import SimpleForm from '../../common/SimpleForm';
import IconButton from '../../common/IconButton';
import Toolbar from '../../common/Toolbar';

import { bulkShipServices } from '../../../services/api';


const SaveButton = ({ onClick, onAfterSubmit, ids, ...props }) => {
	const formState = useFormState();
	const form = useForm();

	const handleClick = async () => {
        onClick();
		let formData = new FormData();
		formData.append("ids", ids);
		formData.append("date", formState.values?.date);
		formData.append("tracking_code", formState.values?.tracking_code);
        const response = await bulkShipServices(formData);
		form.reset();
		onAfterSubmit(response);
	};

	return <RASaveButton {...props} handleSubmit={handleClick} handleSubmitWithRedirect={handleClick} />;
};

const ServiceBulkShip = ({ selectedIds }) => {
    const [loading, setLoading] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll('services');

    const handleOpen = () => {
        setShowDialog(true);
    };

    const handleClose = () => {
        setShowDialog(false);
    };

    const handleSave = async () => {
        setLoading(true);

    };

    const handleAfterSubmit = (response) => {
        if (response.success) {
            notify(`${selectedIds?.length} item${selectedIds?.length > 1 ? 's' : ''} shipped`);
        }
        handleClose();
        refresh();
        unselectAll();
        setLoading(false);
    };

    return (
        <>
            <IconButton onClick={handleOpen} icon={<IconReturn />} disabled={loading}>Ship</IconButton>
            <Dialog fullWidth open={showDialog} onClose={handleClose} aria-label="Ship services">
                <DialogTitle>Ship services</DialogTitle>
                <DialogContent>
                    <SimpleForm
                        toolbar={<Toolbar onClose={handleClose} onSave={handleSave} saveButton={<SaveButton onClick={handleSave} ids={selectedIds} onAfterSubmit={handleAfterSubmit} />} />}
                        initialValues={{ date: new Date().toLocaleDateString('en-US') }}
                    >
                        <TextInput label="Tracking Code" source="tracking_code" />
                        <DateInput label="Shipping Date" source="date" />
                    </SimpleForm>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default ServiceBulkShip;