import React from 'react';

import AttachDetachLineButton from './AttachDetachLineButton';


const LineActionsButtons = ({ record }) => (
	<>
		<AttachDetachLineButton record={record} />
	</>
);

export default LineActionsButtons;