import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CardActions, Button, Input, FormControl, InputLabel, InputAdornment, IconButton, FormHelperText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import Login from './common/Login';
import { resetPassword } from '../services/api';


const useStyles = makeStyles((theme) => ({
	icon: {
		marginRight: theme.spacing(1)
	},
	form: {
		padding: '0 1em 1em 1em'
	}
}));

const ResetPasswordPage = () => {
	const [password, setPassword] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);
	const history = useHistory();
	const classes = useStyles();
	const { token } = useParams();

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (password) {
			setLoading(true);
			const formData = new FormData();
			formData.append('token', token);
			formData.append('password', password);
			const response = await resetPassword(formData);
			setLoading(false);
			if (response.success) {
				history.push('/');
			}
			else {
				setError(response.message);
			}
		}
	};

	return (
		<Login>
			<form onSubmit={handleSubmit}>
				<div className={classes.form}>
					<FormControl>
						<InputLabel htmlFor="password">Password</InputLabel>
						<Input
							id="password"
							name="password"
							type={showPassword ? 'text' : 'password'}
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							inputProps={{ minLength: 6 }}
							error={!!error}
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={handleClickShowPassword}
										onMouseDown={handleMouseDownPassword}
									>
										{showPassword ? <Visibility /> : <VisibilityOff />}
									</IconButton>
								</InputAdornment>
							}
						/>
						{error && <FormHelperText id="password" error>{error}</FormHelperText>}
					</FormControl>
				</div>
				<CardActions>
					<Button
						variant="contained"
						type="submit"
						color="primary"
						disabled={loading}
						fullWidth
					>
						{loading && (
							<CircularProgress
								className={classes.icon}
								size={18}
								thickness={2}
							/>
						)}
						Reset password
					</Button>
				</CardActions>
			</form>
		</Login>
	);
};

export default ResetPasswordPage;
