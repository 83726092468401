import React from 'react';
import {
    TextInput,
    BooleanInput,
} from 'react-admin';

import List, { ListActions } from '../common/List';
import Filter from '../common/Filter';
import LineProviderRow from './LineProviderRow';

const LineProviderFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <BooleanInput label="Archived" source="archived" alwaysOn />
    </Filter>
);

const filterDefaultValues = {
    archived: false,
}

const LineProviderActions = (props) => (
    <ListActions {...props}>
    </ListActions>
);

const LineProviderList = props => (
    <List {...props } filters={<LineProviderFilter/>} actions={<LineProviderActions />} perPage={50} filterDefaultValues={ filterDefaultValues }>
        <LineProviderRow />
    </List>
);

export default LineProviderList;