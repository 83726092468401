import React, { useEffect } from 'react';
import { TextInput, DateInput, ReferenceInput, AutocompleteInput, FormDataConsumer, BooleanInput, NumberInput } from 'react-admin';
import { useSelector } from 'react-redux';
import { useForm, useFormState } from 'react-final-form';

import Create from '../common/Create';
import SimpleForm from '../common/SimpleForm';
import ServiceTypeField, { choices as serviceTypeChoices } from '../services/ServiceTypeField';


const optionText = choice => `${choice && choice.reference ? choice.reference : ''} / ${choice.name ? choice.name : ''}`;
const salesOrderOptionText = choice => choice && choice.reference ? choice.reference : '';

const redirect = (basePath, id, data, location) => {
    if (data?.package_id && location?.state?.record?.package_id === data.package_id) {
        return `/packages/${data.package_id}/show`;
    }
    return basePath;
};

const ProductEvents = () => {
    const form = useForm();
    const formState = useFormState();
    const service_product = useSelector((state) => state.admin.resources['service_products'] && formState.values.product_id ? state.admin.resources['service_products'].data[formState.values.product_id] : null);

    useEffect(() => {
        if (service_product?.id) {
            // form.change('price', service_product.price);
            // form.change('data_included', service_product.data_included);
            // form.change('data_extra_price', service_product.extra_price);
            // form.change('currency', service_product.currency);

            const type = serviceTypeChoices.find(choice => choice?.name === service_product.service_type);
            form.change('type', type.id);
        }
    }, [service_product]); // eslint-disable-line react-hooks/exhaustive-deps

    return null;
};

const ServiceCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect={(basePath, id, data) => redirect(basePath, id, data, props.location)}>
            <ReferenceInput label="Package" source="package_id" reference="packages" sort={{ field: 'reference', order: 'ASC' }}>
                <AutocompleteInput optionText={salesOrderOptionText} />
            </ReferenceInput>
            <FormDataConsumer>
                {({ formData, ...rest }) => (
                    <ReferenceInput label="Product" source="product_id" reference="service_products" filter={formData.type ? { service_type: formData.type } : { service_type: '' }} sort={{ field: 'name', order: 'ASC' }} {...rest}>
                        <AutocompleteInput optionText={optionText} />
                    </ReferenceInput>
                )}
            </FormDataConsumer>
            <ServiceTypeField source="type" />
            <FormDataConsumer>
                {({ formData, ...rest }) => (
                    formData.type === 'sdwan' && <BooleanInput label="SOLD" source="sold" {...rest}/>
                )}
            </FormDataConsumer>
            <FormDataConsumer>
                {({ formData, ...rest }) => (
                    formData.sold === true && 
                    <>
                    <ReferenceInput
                        label="Device Product"
                        source="device_product_id"
                        reference="device_products"
                        sort={{ field: 'reference', order: 'ASC' }}
                        filter={{ managed: true}}
                        {...rest}
                    >
                        <AutocompleteInput optionText={optionText} />
                    </ReferenceInput>
                    <NumberInput source="device_product_price" {...rest} />
                    </>
                )}
            </FormDataConsumer>
            <DateInput source="start_date" />
            <DateInput source="end_date" />
            <DateInput source="early_end_date" label="Commitment date" />
            <TextInput source="notes" label="Notes" options={{ multiline: true }} rows="4" />
            <ProductEvents />
        </SimpleForm>
    </Create>
);

export default ServiceCreate;
