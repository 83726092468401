import React from 'react';
import { TextField, ReferenceField, SimpleShowLayout } from 'react-admin';

import Datagrid from '../common/Datagrid';
import DateField from '../common/fields/DateField';
import RecordSplitButton from '../common/RecordSplitButton';
import LinkFieldButton from '../common/LinkFieldButton';

const TaskDrawer = (props) => (
	<SimpleShowLayout {...props}>
		<TextField source="id" />
		<TextField label="SO" source="sales_order_number" />
		<ReferenceField label="Customer" source="customer_id" reference="customers" link="show" allowEmpty>
			<TextField source="name" />
		</ReferenceField>
		<ReferenceField label="Site" source="site_id" reference="sites" link="show" allowEmpty>
			<TextField source="name" />
		</ReferenceField>
		<TextField source="priority"/>
		<TextField source="action"/>
		<TextField source="status"/>
		<TextField label="Notes" source="notes" />
		<DateField source="due_date" />
		<DateField label="Created at" source="created_at" />
		<DateField label="Updated at" source="updated_at" />
	</SimpleShowLayout>
);

const TaskSplitButton = ({ record, ...props }) => (
	<RecordSplitButton record={record} {...props}>
	</RecordSplitButton>
);

const TaskRow = ({ children, ...props }) => (
	<Datagrid drawer={<TaskDrawer {...props} />} {...props} >
		<TextField label="SO" source="sales_order_number" />
		<LinkFieldButton label="Customer" source="customer" basePath="/customers" sortable={false}>
			<TextField source="name" />
		</LinkFieldButton>
		<LinkFieldButton label="Package" source="package" basePath="/packages" sortable={false}>
			<TextField source="reference" />
		</LinkFieldButton>
		<LinkFieldButton label="Site" source="site" basePath="/sites" sortable={false}>
			<TextField source="name" />
		</LinkFieldButton>
		<TextField source="priority"/>
		<TextField source="action"/>
		<TextField source="status"/>
		<ReferenceField label="Assigned" reference="users" source="assigned_to_id" >
			<TextField source="email" />
		</ReferenceField>
		<DateField source="due_date" />
		<DateField source="updated_at" />
		{children}
		<TaskSplitButton />
	</Datagrid>
);

TaskRow.defaultProps = {
	basePath: '/lines'
};

export default TaskRow;