import React, { useState } from 'react';
import { TabbedShowLayout, Tab, SimpleShowLayout, ArrayField, ReferenceField, TextField, ReferenceArrayField, ReferenceManyField, Pagination } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import IconLink from '@material-ui/icons/Link';
import IconEdit from '@material-ui/icons/Edit';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import Show, { ShowActions } from '../common/Show';
import DeviceBandwidthGraph from '../common/DeviceBandwidthGraph';
import IconButton from '../common/IconButton';
import MapComponent from '../common/MapComponent';
import DateField from '../common/fields/DateField';
import BooleanField from '../common/fields/BooleanField';
import WanRow from '../wans/WanRow';
import OnlineStatusIcon from './OnlineStatusIcon';
import DeviceActionsButtons from './DeviceActionsButtons';
import CustomerReferenceField from '../customers/CustomerReferenceField';
import SimCardRow from '../simcards/SimCardRow';
import LineRow from '../lines/LineRow';
import FilteredLogList from '../logs/FilteredLogList';
import FilteredRecordList from '../records/FilteredRecordList';
import DeviceConfigBackups from './DeviceConfigBackups';
import UsageGraph from '../common/UsageGraph';
import LocationUpdateRow from '../location_updates/LocationUpdateRow';
import SpeedTestRow from '../speedtests/SpeedTestRow';
import IPRow from '../ips/IPRow';


const DeviceTitle = ({ record }) => {
	const serial = record.serial ? record.serial.split('-').pop() : '';

	return (
		<>
			<Helmet>
				<title>{`KB - Device[${serial} - ${record.name}] ${record.site ? '- ' + record.site.name : ''}`}</title>
			</Helmet>
			<span>{record ? `${record.name} ` : ''}</span>
		</>
	);
};

const DeviceActions = (props) => {
	return (
		<ShowActions {...props}>
			<DeviceActionsButtons record={props.data} />
		</ShowActions>
	);
};

const ICUrls = ({ record, source }) => {
	const [showPopup, setShowPopup] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (e) => {
		setAnchorEl(e.currentTarget);
		setShowPopup(true);
	};

	const handleClose = () => {
		setShowPopup(false);
	};

	if (!record[source]) {
		return null;
	}

	return (
		<React.Fragment>
			<IconButton
				onClick={handleClick}
				aria-owns={anchorEl ? 'simple-menu' : null}
				aria-haspopup="true"
				variant="outlined"
				color="primary"
				icon={<IconLink />}
				size="small"
			>
				InControl
			</IconButton>
			<Menu open={showPopup} onClose={handleClose} anchorEl={anchorEl}>
				{record[source].map((item) =>
					<MenuItem onClick={handleClose} key={item.key} component={(props) => <a {...props} >{item.title}</a>} href={item.url} target="_blank">
						{item.title}
					</MenuItem>
				)}
			</Menu>
		</React.Fragment>
	);
};

const ArchivedReasonField = ({ record, ...props }) => (
	(record.archived_at && record.archived_reason) && (
		<TextField source="archived_reason" record={record} {...props} />
	)
);

const Content = (props) => (
	<Grid container>
		<Grid item md={6}>
			<SimpleShowLayout {...props}>
				<ICUrls source="ic_urls" />
				<TextField source="serial" />
				<TextField source="name" />
				<OnlineStatusIcon source="online_status" />
				<ReferenceField label="Product" source="product_id" reference="device_products" link="show" allowEmpty>
					<TextField source="reference" />
				</ReferenceField>
				<TextField source="ic_datas.fw_ver" label="Firmware" />
				<BooleanField source="has_custom_config" label="Custom Config" />
				<CustomerReferenceField source="customer_id" />
				<BooleanField label="Reserved" source="reserved" />
				<BooleanField label="New" source="is_new" />
				<ReferenceField label="Site" source="site.id" reference="sites" link="show" allowEmpty>
					<TextField source="name" />
				</ReferenceField>
				<TextField source="address" />
				<TextField source="notes" />
				<TextField source="group_name" />
				<TextField source="ic_name" />
				<TextField source="ic_server_name" />
				<TextField source="ic_organization_name" />
				<TextField source="ic_tags" />
				<TextField source="ic_notes" />
				<TextField label="Data Limit Alert (GB)" source="data_limit" />
				<TextField label="Data Limit Contacts" source="data_limit_contacts" />
				<TextField source="customer_notes" />
				<DateField label="Online at" source="last_online" showTime />
				<DateField label="Synced at" source="last_sync_at" showTime />
			</SimpleShowLayout>
		</Grid>
		<Grid item md={6}>
			<MapComponent {...props} />
		</Grid>
		<Grid item md={12}>
			{(props.record && props.record.wans.length > 0) && (
				<>
					<Typography variant="h6">Wans</Typography>
					<SimpleShowLayout {...props}>
						<ArrayField source="wans" addLabel={false} style={{ marginBottom: '16px' }}>
							<WanRow expanded />
						</ArrayField>
					</SimpleShowLayout>
				</>
			)}
			{(props.record && props.record.lines.length > 0) && (
				<>
					<Typography variant="h6">Lines</Typography>
					<SimpleShowLayout {...props}>
						<ReferenceArrayField source="lines" reference="lines" addLabel={false}>
							<LineRow exclude={['device']} />
						</ReferenceArrayField>
					</SimpleShowLayout>
				</>
			)}
		</Grid>
	</Grid>
);

const CustomSimCardRow = ({ data, ports_map, ...props }) => {
	let simcardsData = data;
	for (const [key, value] of Object.entries(ports_map)) {
		simcardsData[value] = { ...simcardsData[value], port: key };
	}

	return (
		<SimCardRow {...props} data={simcardsData} childrenBefore>
			<TextField source="port" />
		</SimCardRow>
	);
};

const PortsTable = ({ record, ...props }) => {
	const { ports_map } = record;
	const simcards = { ids: Object.values(ports_map).filter(item => item != null) };

	return (
		<>
			<Grid container justify="flex-end" style={{ marginTop: 10 }}>
				<Grid item>
					<IconButton variant="outlined" component={Link} to={`/devices/${record.id}/ports`} icon={<IconEdit />}>Edit ports</IconButton>
				</Grid>
			</Grid>
			<ReferenceArrayField addLabel={false} reference="simcards" source="ids" record={simcards} {...props}>
				<CustomSimCardRow ports_map={ports_map} />
			</ReferenceArrayField>
		</>
	);
};

const Tabs = props => (
	<TabbedShowLayout {...props}>
		<Tab label="summary">
			<Content />
			<DeviceBandwidthGraph />
			<UsageGraph freq='monthly' title="Data Usage monthly (GB)" />
			<UsageGraph freq='daily' title="Data Usage last 30 days (GB)" />
			<ReferenceManyField addLabel={false} reference="location_updates" target="device_id" pagination={<Pagination />} sort={{ field: 'timestamp', order: 'DESC' }}>
				<LocationUpdateRow />
			</ReferenceManyField>
		</Tab>
		<Tab label="Ops History">
			<FilteredRecordList />
		</Tab>
		{!isEmpty(props.record.ports_map) && (
			<Tab label="Ops Config">
				<PortsTable />
			</Tab>
		)}
		<Tab label="Wans">
			<ArrayField source="wans" addLabel={false} style={{ marginBottom: '16px' }}>
				<WanRow expanded />
			</ArrayField>
		</Tab>

		{(props.record && props.record.ips.length > 0) && (
			<Tab label="ips">
				<SimpleShowLayout {...props}>
					<ReferenceArrayField source="ips" reference="ips" addLabel={false}>
						<IPRow exclude={['device', 'customer']} />
					</ReferenceArrayField>
				</SimpleShowLayout>
			</Tab>
		)}
		{(props.record && props.record.lines.length > 0) && (
			<Tab label="lines">
				<SimpleShowLayout {...props}>
					<ReferenceArrayField source="lines" reference="lines" addLabel={false}>
						<LineRow exclude={['device']} />
					</ReferenceArrayField>
				</SimpleShowLayout>
			</Tab>
		)}

		<Tab label="Other info">
			<ICUrls source="ic_urls" />
			<TextField label="Id" source="id" />
			<TextField source="name" />
			<TextField source="serial" />
			<ReferenceField label="Parent" source="parent_id" reference="devices" link="show" allowEmpty>
				<TextField source="name" />
			</ReferenceField>
			<TextField source="supplier" />
			<ReferenceField label="Owner" source="owner_id" reference="customers" link="show" allowEmpty>
				<TextField source="name" />
			</ReferenceField>
			<TextField source="buy_sm" label="Supplier Billing Mode" />
			<TextField source="rent_sold" label="VENN Billing Mode" />
			<TextField source="product_name" label="IC Product name" />
			<TextField source="product_code" label="IC Product code" />
			<TextField source="product.reference" label="Product Ref" />
			<TextField source="product.name" label="Product name" />
			<TextField source="product.brand" label="Brand" />
			<TextField source="product.family" label="Family" />
			<TextField source="product.sub_family" label="Sub Family" />
			<TextField source="product.category" label="Category" />
			<BooleanField source="product.managed" label="Managed" />
			<BooleanField source="product.virtual" label="Virtual" />
			<BooleanField source="product.accessory" label="Accessory" />
			<TextField source="product.description" label="Product Description" />
			<BooleanField source="reserved" />
			<BooleanField source="is_new" />
			<DateField label="Created at" source="created_at" />
			<DateField label="Updated at" source="updated_at" />
			<DateField label="Archived date" source="archived_date" />
			<ArchivedReasonField label="Archived reason" addLabel />
			<TextField source="archived_notes" label="Archived notes" />
		</Tab>
		<Tab label="Logs">
			<FilteredLogList />
		</Tab>
		<Tab label="Configs">
			<DeviceConfigBackups />
		</Tab>
		<Tab label="SpeedTests" path="speedtests">
			<ReferenceManyField addLabel={false} reference="speedtests" target="device_id" pagination={<Pagination />} sort={{ field: 'created_at', order: 'DESC' }}>
				<SpeedTestRow exclude={['device_serial', 'device_name']} />
			</ReferenceManyField>
		</Tab>
	</TabbedShowLayout>
);

const DeviceShow = (props) => (
	<Show title={<DeviceTitle />} actions={<DeviceActions {...props} />} {...props}>
		<Tabs />
	</Show>
);

export default DeviceShow;
export { ICUrls };