import LocationOnIcon from '@material-ui/icons/LocationOn';

import SiteList from './SiteList';
import SiteCreate from './SiteCreate';
import SiteShow from './SiteShow';
import SiteEdit from './SiteEdit';

export default {
	list: SiteList,
	create: SiteCreate,
	show: SiteShow,
	edit: SiteEdit,
	icon: LocationOnIcon
};
