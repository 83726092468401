import React, { useState, useEffect } from 'react';
import { Link } from 'react-admin';
import { Grid } from '@material-ui/core';

import BusinessIcon from '@material-ui/icons/Phone';

import IconCard from '../common/IconCard';

import { fetchDeliveryDashboardStats } from '../../services/api';

const DeliveryDashboard = () => {
    const [stats, setStats] = useState();

    useEffect(() => {
        const fetchStats = async () => {
            const data = await fetchDeliveryDashboardStats();
            setStats(data);
        }

        fetchStats();
    }, []);

    return (
        <Grid container spacing={2}>
            <Grid container item spacing={2}>
                <Grid item lg={4} sm={6} xl={4} xs={12}>
                    <Link to={`/services?filter={"status": "provisioning", "queue": "delivery"}`}>
                        <IconCard title="Services - To Provision" value={stats?.services?.provisioning} icon={<BusinessIcon />} warning={stats?.services?.provisioning > 0 ? true : false} />
                    </Link>
                </Grid>
                <Grid item lg={4} sm={6} xl={4} xs={12}>
                    <Link to={`/services?filter={"status": "provisioned", "queue": "delivery"}`}>
                        <IconCard title="Services - To Ship" value={stats?.services?.provisioned} icon={<BusinessIcon />} warning={stats?.services?.provisioned > 0 ? true : false} />
                    </Link>
                </Grid>
                <Grid item lg={4} sm={6} xl={4} xs={12}>
                    <Link to={`/services?filter={"status": "shipped", "queue": "delivery"}`}>
                        <IconCard title="Services - To Deliver" value={stats?.services?.shipped} icon={<BusinessIcon />} warning={stats?.services?.shipped > 0 ? true : false} />
                    </Link>
                </Grid>
            </Grid>
            <Grid container item spacing={2}>
                <Grid item lg={4} sm={6} xl={4} xs={12}>
                    <Link to={`/package_lines?filter={"status": "provisioning", "queue": "delivery"}`}>
                        <IconCard title="Hardware - To Provision" value={stats?.package_lines?.provisioning} icon={<BusinessIcon />} warning={stats?.package_lines?.provisioning > 0 ? true : false} />
                    </Link>
                </Grid>
                <Grid item lg={4} sm={6} xl={4} xs={12}>
                    <Link to={`/package_lines?filter={"status": "provisioned", "queue": "delivery"}`}>
                        <IconCard title="Hardware- To Ship" value={stats?.package_lines?.provisioned} icon={<BusinessIcon />} warning={stats?.package_lines?.provisioned > 0 ? true : false} />
                    </Link>
                </Grid>
                <Grid item lg={4} sm={6} xl={4} xs={12}>
                    <Link to={`/package_lines?filter={"status": "shipped", "queue": "delivery"}`}>
                        <IconCard title="Hardware - To Deliver" value={stats?.package_lines?.shipped} icon={<BusinessIcon />} warning={stats?.package_lines?.shipped > 0 ? true : false} />
                    </Link>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default DeliveryDashboard;
