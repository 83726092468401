import React from 'react';
import { AutocompleteInput } from 'react-admin';

const choices = [
	{ id: 'EUR', name: 'EUR' },
	{ id: 'USD', name: 'USD' },
    { id: 'GBP', name: 'GBP' },
];


const CurrencyInput = ({ classes, record, source = "currency", ...props }) => {
	if (!record) {
		return null;
	}

	return (
		<AutocompleteInput choices={choices} source={source} record={record} allowEmpty {...props} />
	);
};

export default CurrencyInput;
