import React from 'react';
import { TextField, List, ReferenceField } from 'react-admin';
import { ThemeProvider } from '@material-ui/core/styles';

import Datagrid from '../common/Datagrid';
import DateField from '../common/fields/DateField';
import LinkFieldButton from '../common/LinkFieldButton';
import ObjectReferenceField from './ObjectReferenceField';

import { themeCustomList } from '../common/CustomList';

const FilteredRecordList = ({ resource, record, ...props }) => {
	const getFilter = () => {
		switch(resource) {
			case 'sites':
				return 'site_id';
			case 'simcards':
				return 'sim_id';
			case 'devices':
				return 'device_id';
			case 'lines':
				return 'line_id';
			case 'customers':
				return 'customer_id';
			default:
				return null;
		};
	};
	const filter = record ? { [getFilter()]: record.id } : {};

	return (
		<ThemeProvider theme={themeCustomList}>
			<List
				filter={filter}
				sort={{ field: 'id', order: 'DESC' }}
				{...props}
				resource="records"
				basepath="/records"
				title=" "
				hasCreate={false}
				hasEdit={false}
				hasList={false}
				hasShow={false}
				bulkActionButtons={false}
				actions={null}
			>
				<Datagrid>
					{resource === 'sites' && (
						<TextField source="object_type" />
					)}
					{resource === 'sites' && (
						<ObjectReferenceField source="object_id" sortable={false} />
					)}
					{resource !== 'sites' && (
						<LinkFieldButton label="Customer" source="customer" basePath="/customers" sortable={false}>
							<TextField source="name" />
						</LinkFieldButton>
					)}
					{resource !== 'sites' && (
						<LinkFieldButton label="Site" source="site" basePath="/sites" sortable={false}>
							<TextField source="name" />
						</LinkFieldButton>
					)}
					<DateField source="start_date" />
					<DateField source="end_date" />
					<TextField source="comment" />
					<ReferenceField label="Related Device" source="target_device" reference="devices" link="show" allowEmpty basePath="/devices">
						<TextField source="title" />
					</ReferenceField>
					<TextField source="user_email" label="user" />
				</Datagrid>
			</List>
		</ThemeProvider>
	);
};

export default FilteredRecordList;