import React from 'react';
import { TextInput, BooleanInput, ReferenceInput, AutocompleteInput } from 'react-admin';
import GetAppIcon from '@material-ui/icons/GetApp';

import List, { ListActions } from '../common/List';
import Filter from '../common/Filter';
import PackageRow from './PackageRow';
import IconButton from '../common/IconButton';
import StatusInput from './StatusInput';

import { API_URL } from '../../services/settings';

const customerOptionText = choice => choice.name ? choice.name : '';

const PackageActions = (props) => (
    <ListActions {...props}>
        <IconButton icon={<GetAppIcon />} component="a" href={`${API_URL}/packages/export/invoicing.xls`}>Generate</IconButton>
        <IconButton icon={<GetAppIcon />} component="a" href={`${API_URL}/packages/export.xls`}>Export</IconButton>
    </ListActions>
);

const PackageFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <ReferenceInput label="Customer" source="customer_id" reference="customers" sort={{ field: 'name', order: 'ASC' }} alwaysOn>
            <AutocompleteInput optionText={customerOptionText} />
        </ReferenceInput>
        <StatusInput source="status" alwaysOn />
        <BooleanInput label="Archived" source="archived" alwaysOn />
        <BooleanInput label="Not Invoiced" source="not_invoiced" alwaysOn />
    </Filter>
);

const filterDefaultValues = {
    archived: false,
}

const PackageList = props => (
	<List {...props} filters={<PackageFilter/>} actions={<PackageActions />} filterDefaultValues={ filterDefaultValues } perPage={50} exporter={false}>
		<PackageRow />
	</List>
);

export default PackageList;