import React from 'react';
import { ShowButton, EditButton } from 'react-admin';

import SplitButton from './SplitButton';
import ArchiveButton from './ArchiveButton';

const RecordSplitButton = ({ record, children, archiveButton, hasEdit, ...props }) => (
	<SplitButton>
		<ShowButton record={record} {...props} />
		{hasEdit && <EditButton record={record} {...props} />}
		{React.Children.map(children, (child => child && React.cloneElement(child, { record, ...props }) ))}
		{archiveButton ? React.cloneElement(archiveButton, { record, ...props }) : <ArchiveButton record={record} {...props} />}
	</SplitButton>
);

RecordSplitButton.defaultProps = {
	hasEdit: true
};

export default RecordSplitButton;