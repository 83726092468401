import React from 'react';
import { TopToolbar } from 'react-admin';
import { withStyles } from '@material-ui/core';

const styles = {
	root: {
		justifyContent: 'space-between',
		alignItems: 'center',
		
		'& button': {
			whiteSpace: 'nowrap'
		}
	}
};

const CardActions = ({ classes, ...props }) => (
	<TopToolbar classes={classes} {...props} />
);

export default withStyles(styles)(CardActions);