import React from 'react';
import { AutocompleteInput } from 'react-admin';


const choices = [
    { id: 'vat_0', name: '0%' },
	{ id: 'vat_21', name: '21%' },
	{ id: 'vat_20', name: '20%' },
    { id: 'vat_intra_goods_0', name: 'INTRA GOODS 0%' },
    { id: 'vat_intra_services_0', name: 'INTRA SERVICES 0%' },
    { id: 'vat_export_goods_0', name: 'EXPORT GOODS 0%' },
	{ id: 'vat_export_services_0', name: 'EXPORT SERVICES 0%' },
    { id: 'vat_not_subject', name: 'NA' },
	{ id: 'vat_seagoing', name: 'SEAGOING' },
	{ id: 'vat_eu_0', name: 'EU 0%' }
];

const VATCodeInput = ({ classes, record, source = "vat_code", ...props }) => {
	if (!record) {
		return null;
	}

	return (
		<AutocompleteInput choices={choices} source={source} record={record} allowEmpty {...props} />
	);
};

export default VATCodeInput;
