import React from 'react';
import { Tab, TabbedShowLayout, TextField, ReferenceField, NumberField } from 'react-admin';

import Show from '../common/Show';
import DateField from '../common/fields/DateField';
import BooleanField from '../common/fields/BooleanField';
import NumberCurrencyField from '../common/fields/NumberCurrencyField';


const PackageLineShow = (props) => (
    <Show {...props}>
        <TabbedShowLayout>
            <Tab label="summary" {...props}>
                <ReferenceField label="Package" source="package_id" reference="packages" link="show" allowEmpty>
                    <TextField source="reference" />
                </ReferenceField>
                <ReferenceField label="Service" source="service_id" reference="services" link="show" allowEmpty>
                    <TextField source="reference" />
                </ReferenceField>
                <ReferenceField label="Device Product" source="device_product_id" reference="device_products" link="show" allowEmpty>
                    <TextField source="reference" />
                </ReferenceField>
                <NumberCurrencyField source="price" />
                <NumberField source="qty" />
                <NumberCurrencyField source="total" />
                <BooleanField label="Invoiced" source="is_invoiced" />
                <TextField source="status" />
                <TextField source="notes" options={{ multiline: true }} rows="4" />
                <TextField source="serials" options={{ multiline: true }} rows="4" />
                <TextField source="shipping_tracking_code" />
                <DateField source="shipping_date" />
                <DateField source="delivery_date" />
                <DateField label="Created at" source="created_at" />
                <DateField label="Updated at" source="updated_at" />
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export default PackageLineShow;
