import React, { useEffect, useState } from 'react';
import { TabbedShowLayout, Tab, TextField, NumberField, FunctionField, ReferenceField, SimpleShowLayout } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import MuiTab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Helmet } from 'react-helmet';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import moment from 'moment';

import Show, { ShowActions } from '../common/Show';
import MapComponent from '../common/MapComponent';
import DateField from '../common/fields/DateField';
import BooleanField from '../common/fields/BooleanField';
import ToggleButtonGroup, { ToggleButton } from '../common/ToggleButtonGroup';
import StarlinkTerminalActionsButtons from './StarlinkTerminalActionsButtons';
import StateField from './fields/StateField';

import { fetchStarlinkTerminalStats, fetchStarlinkTerminalStatus } from '../../services/api';
import { API_URL } from '../../services/settings';


const StarlinkTerminalTitle = ({ record }) => (
    <>
        <Helmet>
            <title>{`KB - Starlink terminal[${record.id}]`}</title>
        </Helmet>
        <span>{`Starlink terminal #${record.id}`}</span>
    </>
);

const StarlinkTerminalActions = (props) => (
    <ShowActions {...props}>
        <StarlinkTerminalActionsButtons record={props.data} />
    </ShowActions>
);

const Content = ({ record, ...props }) => {
    const [latencyStats, setLatencyStats] = useState();
    const [uplinkSpeedStats, setUplinkSpeedStats] = useState();
    const [downlinkSpeedStats, setDownlinkSpeedStats] = useState();
    const [pingDropStats, setPingDropStats] = useState();
    const [obstructedTimeStats, setObstructedTimeStats] = useState();
    const [status, setStatus] = useState();
    const [delta, setDelta] = useState('1h');
    const [loadingStats, setLoadingStats] = useState();
    const [obstructionMapSrc, setObstructionMapSrc] = useState();
    const [source, setSource] = useState('starlink');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const responseStatus = await fetchStarlinkTerminalStatus(record.terminal_id, { source });
                setStatus(responseStatus);
            } catch (e) {
                console.error(e.message);
            }
        };

        fetchData();

        const request = new XMLHttpRequest();
        const src = `${API_URL}/starlink_terminals/${record.terminal_id}/obstruction_map.png`;
        request.open("GET", src, true);
        request.send();
        request.onload = async () => {
            if (request.status === 200 && request.getResponseHeader('Content-Type')?.startsWith('image')) {
                setObstructionMapSrc(src);
            }
        }

        const interval = setInterval(() => {
            fetchData();
        }, 30000);

        return () => {
            clearInterval(interval);
        };
    }, [record.terminal_id, source]);

    useEffect(() => {
        setLoadingStats(true);

        const fetchData = async () => {
            try {
                const responseUplinkSpeedStats = await fetchStarlinkTerminalStats(record.terminal_id, { name: 'uplink_speed', delta, source });
                setUplinkSpeedStats(responseUplinkSpeedStats);
            } catch (e) {
                console.error(e.message);
            }

            try {
                const responseDownlinkSpeedStats = await fetchStarlinkTerminalStats(record.terminal_id, { name: 'downlink_speed', delta, source });
                setDownlinkSpeedStats(responseDownlinkSpeedStats);
            } catch (e) {
                console.error(e.message);
            }

            try {
                const responsePingDropStats = await fetchStarlinkTerminalStats(record.terminal_id, { name: 'ping_drop', delta, source });
                setPingDropStats(responsePingDropStats);
            } catch (e) {
                console.error(e.message);
            }

            try {
                const responseObstructedTimeStats = await fetchStarlinkTerminalStats(record.terminal_id, { name: 'obstructed_time', delta, source });
                setObstructedTimeStats(responseObstructedTimeStats);
            } catch (e) {
                console.error(e.message);
            }

            try {
                const responseLatencyStats = await fetchStarlinkTerminalStats(record.terminal_id, { name: 'latency', delta, source });
                setLatencyStats(responseLatencyStats);
            } catch (e) {
                console.error(e.message);
            }
            setLoadingStats(false);
        };

        fetchData();

        const interval = setInterval(() => {
            fetchData();
        }, 30000);

        return () => {
            clearInterval(interval);
        };

    }, [record.terminal_id, delta, source]);

    const handleChangeDelta = (e, newValue) => {
        setDelta(newValue);
    };

    const formatTimestampToTime = (ts) => new Date(ts * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    const formatTimestamp = (ts) => new Date(ts * 1000).toLocaleString();
    const momentTimestamp = (ts, timeZone = 'UTC') => {
        return moment(ts*1000).utcOffset('+0200').format('MMMM Do YYYY, h:mm:ss a');
      };

    const convertBpsToMbps = (value) => (value / 1000000).toFixed(2);
    const formatPercentage = (value) => (value * 100).toFixed(2);

    return (
        <Grid container spacing={3}>
            <Grid item md={6}>
                <SimpleShowLayout {...props} record={record}>
                    <TextField source="id" />
                    <TextField source="terminal_id" label="Terminal ID" />
                    <TextField source="kit_serial_number" />
                    <TextField source="dish_serial_number" />
                    <TextField source="ip_address" />
                    <ReferenceField label="Service line number" source="starlink_service_line_id" reference="starlink_service_lines" link="show" allowEmpty>
                        <TextField source="service_line_number" />
                    </ReferenceField>
                    <ReferenceField label="Starlink account" source="starlink_account" reference="starlink_accounts" link="show" allowEmpty>
                        <TextField source="account_number" />
                    </ReferenceField>
                    <ReferenceField label="Customer" source="customer_id" reference="customers" link="show" allowEmpty>
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField label="Site" source="site_id" reference="sites" link="show" allowEmpty>
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField label="Device" source="device_id" reference="devices" link="show" allowEmpty>
                        <TextField source="serial" />
                    </ReferenceField>
                    <BooleanField source="monitored" />
                    <DateField source="monitored_until" showTime />
                    <DateField source="created_at" showTime />
                    <DateField source="updated_at" showTime />
                </SimpleShowLayout>
            </Grid>
            <Grid item md={6}>
                <MapComponent {...props} record={record} />
            </Grid>
            <Grid item md={12}>
                <ToggleButtonGroup value={source} setValue={setSource}>
                    <ToggleButton value="starlink">Telemetry</ToggleButton>
                    <ToggleButton value="oob">OOB</ToggleButton>
                </ToggleButtonGroup>
            </Grid>
            <Grid item md={6}>
                <Typography variant="h6">Antenna status</Typography>
                <SimpleShowLayout {...props} record={status}>
                    <FunctionField
                        label="Last status at"
                        render={() => status?.ts ? `${momentTimestamp(status.ts)}` : ''}
                    />
                    <FunctionField
                        label="Downlink speed"
                        render={() => status?.metrics?.downlink_speed?.value ? `${convertBpsToMbps(status.metrics.downlink_speed.value)} Mbps` : ''}
                    />
                    <FunctionField
                        label="Uplink speed"
                        render={() => status?.metrics?.uplink_speed?.value ? `${convertBpsToMbps(status.metrics.uplink_speed.value)} Mbps` : ''}
                    />
                    <NumberField label="Latency" source="metrics.latency.value" options={{ style: 'unit', unit: 'millisecond' }} />
                    <FunctionField label="Uptime" render={() => status?.metrics?.uptime?.value ? moment.duration(status.metrics.uptime.value, 'seconds').humanize() : ''} />
                    <StateField label="State from OOBM" source="state" />
                    <TextField label="Software update state" source="software_update_state" />
                    <FunctionField
                        label="Unobstructed ratio"
                        render={() => status?.metrics?.unobstructed_time?.value ? `${formatPercentage(status.metrics.unobstructed_time.value)}%` : ''}
                    />
                </SimpleShowLayout>
                <SimpleShowLayout {...props} record={status?.alerts}>
                    <BooleanField source="alert_install_pending" reverse={true} />
                    <BooleanField source="alert_is_heating" reverse={true} />
                    <BooleanField source="alert_mast_not_near_vertical" reverse={true} />
                    <BooleanField source="alert_motors_stuck" reverse={true} />
                    <BooleanField source="alert_roaming" reverse={true} />
                    <BooleanField source="alert_slow_ethernet_speeds" reverse={true} />
                    <BooleanField source="alert_thermal_shutdown" reverse={true} />
                    <BooleanField source="alert_unexpected_location" reverse={true} />
                </SimpleShowLayout>
            </Grid>
            <Grid item md={6}>
                {(obstructionMapSrc && source === 'oob') && (
                    <>
                        <Typography variant="h6">Antenna obstruction</Typography>
                        <img src={`${API_URL}/starlink_terminals/${record.id}/obstruction_map.png`} alt="Obstruction map" />
                    </>
                )}
            </Grid>
            {/* TODO */}
            {/* <Grid item md={12}>
                <Typography variant="h6">Device</Typography>
            </Grid> */}
            <Grid item md={12}>
                <Typography variant="h6">Network statistics</Typography>
                <Box mb={3}>
                    <Tabs value={delta} onChange={handleChangeDelta} indicatorColor="primary">
                        <MuiTab label="15m" id="15m" value="15m" />
                        <MuiTab label="1 hr" id="1h" value="1h" />
                        <MuiTab label="24 hrs" id="24h" value="24h" />
                        <MuiTab label="7 days" id="7d" value="7d" />
                        <MuiTab label="30 days" id="30d" value="30d" />
                    </Tabs>
                </Box>

                {loadingStats ? (
                    <Box display="flex" justifyContent="center" m={3}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Grid container spacing={3}>
                        {downlinkSpeedStats?.data && (
                            <Grid item md={6}>
                                <Typography variant="subtitle1" gutterBottom>Downlink throughput</Typography>
                                <ResponsiveContainer width="100%" height={300}>
                                    <AreaChart width={500} height={300} data={downlinkSpeedStats.data}>
                                        <CartesianGrid stroke="#f5f5f5" />
                                        <XAxis dataKey="ts" tickFormatter={formatTimestampToTime} />
                                        <YAxis dataKey="value" tickFormatter={convertBpsToMbps} label={{ value: 'Mbps', angle: -90, position: 'insideLeft' }} />
                                        <Tooltip labelFormatter={formatTimestamp} formatter={convertBpsToMbps} />
                                        <Area
                                            name="Downlink speed"
                                            dataKey="value"
                                            stroke="#68C0B4"
                                            fill="#68C0B4"
                                            fillOpacity={0.1}
                                            unit="Mbps"
                                            dot={false}
                                            legendType="rect"
                                        />
                                    </AreaChart>
                                </ResponsiveContainer>
                            </Grid>
                        )}

                        {uplinkSpeedStats?.data && (
                            <Grid item md={6}>
                                <Typography variant="subtitle1" gutterBottom>Uplink throughput</Typography>
                                <ResponsiveContainer width="100%" height={300}>
                                    <AreaChart width={500} height={300} data={uplinkSpeedStats.data}>
                                        <CartesianGrid stroke="#f5f5f5" />
                                        <XAxis dataKey="ts" tickFormatter={formatTimestampToTime} />
                                        <YAxis dataKey="value" tickFormatter={convertBpsToMbps} label={{ value: 'Mbps', angle: -90, position: 'insideLeft' }} />
                                        <Tooltip labelFormatter={formatTimestamp} formatter={convertBpsToMbps} />
                                        <Area
                                            name="Uplink speed"
                                            dataKey="value"
                                            stroke="#68C0B4"
                                            fill="#68C0B4"
                                            fillOpacity={0.1}
                                            unit="Mbps"
                                            dot={false}
                                            legendType="rect"
                                        />
                                    </AreaChart>
                                </ResponsiveContainer>
                            </Grid>
                        )}

                        {latencyStats?.data && (
                            <Grid item md={6}>
                                <Typography variant="subtitle1" gutterBottom>Latency</Typography>
                                <ResponsiveContainer width="100%" height={300}>
                                    <AreaChart width={500} height={300} data={latencyStats.data}>
                                        <CartesianGrid stroke="#f5f5f5" />
                                        <XAxis dataKey="ts" tickFormatter={formatTimestampToTime} />
                                        <YAxis dataKey="value" unit="ms" />
                                        <Tooltip labelFormatter={formatTimestamp} />
                                        <Area
                                            name="Latency"
                                            dataKey="value"
                                            stroke="#68C0B4"
                                            fill="#68C0B4"
                                            fillOpacity={0.1}
                                            unit="ms"
                                            dot={false}
                                            legendType="rect"
                                        />
                                    </AreaChart>
                                </ResponsiveContainer>
                            </Grid>
                        )}

                        {pingDropStats?.data && (
                            <Grid item md={6}>
                                <Typography variant="subtitle1" gutterBottom>Ping drop rate</Typography>
                                <ResponsiveContainer width="100%" height={300}>
                                    <AreaChart width={500} height={300} data={pingDropStats.data}>
                                        <CartesianGrid stroke="#f5f5f5" />
                                        <XAxis dataKey="ts" tickFormatter={formatTimestampToTime} />
                                        <YAxis dataKey="value" unit="%" domain={[0, 1]} tickFormatter={formatPercentage} />
                                        <Tooltip labelFormatter={formatTimestamp} formatter={formatPercentage} />
                                        <Area
                                            name="Ping drop"
                                            dataKey="value"
                                            stroke="#68C0B4"
                                            fill="#68C0B4"
                                            fillOpacity={0.1}
                                            unit="%"
                                            dot={false}
                                            legendType="rect"
                                        />
                                    </AreaChart>
                                </ResponsiveContainer>
                            </Grid>
                        )}

                        {/* TODO: add signal quality graph */}

                        {obstructedTimeStats?.data && (
                            <Grid item md={6}>
                                <Typography variant="subtitle1" gutterBottom>Obstruction</Typography>
                                <ResponsiveContainer width="100%" height={300}>
                                    <AreaChart width={500} height={300} data={obstructedTimeStats.data}>
                                        <CartesianGrid stroke="#f5f5f5" />
                                        <XAxis dataKey="ts" tickFormatter={formatTimestampToTime} />
                                        <YAxis dataKey="value" unit="%" domain={[0, 1]} tickFormatter={formatPercentage} />
                                        <Tooltip labelFormatter={formatTimestamp} formatter={formatPercentage} />
                                        <Area
                                            name="Obstruction"
                                            dataKey="value"
                                            stroke="#68C0B4"
                                            fill="#68C0B4"
                                            fillOpacity={0.1}
                                            unit="%"
                                            dot={false}
                                            legendType="rect"
                                        />
                                    </AreaChart>
                                </ResponsiveContainer>
                            </Grid>
                        )}
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

const StarlinkTerminalShow = (props) => (
    <Show title={<StarlinkTerminalTitle />} actions={<StarlinkTerminalActions {...props} />} {...props}>
        <TabbedShowLayout>
            <Tab label="summary">
                <Content />
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export default StarlinkTerminalShow;
