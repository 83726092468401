import RouterIcon from '@material-ui/icons/Router';

import ServiceProductList from './ServiceProductList';
import ServiceProductShow from './ServiceProductShow';
import ServiceProductCreate from './ServiceProductCreate';
import ServiceProductEdit from './ServiceProductEdit';

export default {
	list: ServiceProductList,
	show: ServiceProductShow,
	edit: ServiceProductEdit,
	create: ServiceProductCreate,
	icon: RouterIcon,
	title: 'Service Products'
};
