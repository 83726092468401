import React, { Component } from 'react';
import { Query, ListButton, ShowButton } from 'react-admin';

import Breadcrumb from '../common/Breadcrumb';
import BuildForm from '../common/BuildForm';
import CardActions from '../common/CardActions';


const Actions = ({ record, breadcrumbPath }) => (
	<CardActions>
		<Breadcrumb path={breadcrumbPath} />
		<div>
			<ListButton basePath="/devices" />
			<ShowButton basePath="/devices" record={record} />
		</div>
	</CardActions>
);

class SiteDevicePrepare extends Component {
	handleSave = () => {
		const { id } = this.props.match.params;
		this.props.history.push(`/sites/${id}/show`);
	}

	render() {
		const { id } = this.props.match.params;

		return (
			<Query type="GET_ONE" resource="sites" payload={{ id }}>
				{({ data }) => {
					if (!data) {
						return null;
					}

					const record = {
						site_id: data.id,
						customer_id: data.customer_id ? data.customer_id : null
					};

					const breadcrumbPath = [
						{ url: '/sites', title: "Sites" },
						{
							url: `/sites/${data.id}/show`,
							title: (data ? data.name : data.id)
						},
						{
							url: `/sites/${data.id}/build`,
							title: "Build",
							isActive: true
						}
					];

					return <BuildForm record={record} onSave={this.handleSave} actions={<Actions record={data} breadcrumbPath={breadcrumbPath} />} {...this.props} />
				}}
			</Query>
		);
	}
};

export default SiteDevicePrepare;