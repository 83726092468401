import React from 'react';
import {
	TextField,
	ReferenceManyField,
	Pagination,
	ReferenceArrayField,
	SimpleShowLayout
} from 'react-admin';

import Datagrid from '../common/Datagrid';
import DateField from '../common/fields/DateField';
import BooleanField from '../common/fields/BooleanField';
import DeviceRow from '../devices/DeviceRow';
import LinkFieldButton from '../common/LinkFieldButton';
import MapComponent from '../common/MapComponent';
import RecordSplitButton from '../common/RecordSplitButton';
import CustomerReferenceField from '../customers/CustomerReferenceField';
import DeviceListField from '../devices/fields/DeviceListField';
import LineListField from '../lines/LineListField';


const DevicesAndLinesField = (props) => (
	<>
		<ReferenceManyField {...props} perPage={5} reference="devices" target="site_id">
			<DeviceListField style={{ display: 'inline-block' }} />
		</ReferenceManyField>
		<ReferenceArrayField label="" reference="lines" source="lines" sortable={false} {...props}>
			<LineListField style={{ display: 'inline-block' }} />
		</ReferenceArrayField>
	</>
);

const DevicesList = props => (
	<ReferenceManyField {...props} perPage={5} pagination={<Pagination />} reference="devices" target="site_id">
		<DeviceRow exclude={['site']} />
	</ReferenceManyField>
);

const SiteDrawer = (props) => (
	<SimpleShowLayout {...props}>
		<TextField source="id" />
		<TextField source="name" />
		<BooleanField source="is_stock" />
		<TextField source="description" options={{ multiline: true }} />
		<CustomerReferenceField />
		<TextField source="address" />
		<TextField source="street" />
		<TextField source="number" />
		<TextField source="zipcode" />
		<TextField source="city" />
		<TextField source="country" />
		<TextField source="contract_data_source" label="Contract Data Source" />
		<TextField source="data_limit" label="Data Limit Alert (GB)" />
		<TextField source="data_limit_contacts" label="Data Limit Contacts (CSV)" />
		<MapComponent height="300px" />
	</SimpleShowLayout>
);

const SiteRow = ({ children, ...props }) => (
	<Datagrid expand={<DevicesList {...props} />} drawer={<SiteDrawer {...props} />} {...props}>
		<LinkFieldButton label="Name" sortBy="name">
			<TextField source="name" />
		</LinkFieldButton>
		<LinkFieldButton label="Customer" source="customer" basePath="/customers" sortable={false}>
			 <TextField source="name" />
		</LinkFieldButton>
		<TextField source="address" />
		<DevicesAndLinesField label="Devices" />
		<DateField source="updated_at" />
		<BooleanField label="Stock" source="is_stock" />
		<RecordSplitButton />
		{children}
	</Datagrid>
);

SiteRow.defaultProps = {
	basePath: '/sites'
};

export default SiteRow;