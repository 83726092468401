import React from 'react';
import { TextField, SimpleShowLayout } from 'react-admin';

import Datagrid from '../common/Datagrid';


const StarlinkUsageDrawer = (props) => (
    <SimpleShowLayout {...props}>
        <TextField source="date" />
        <TextField source="usage" />
    </SimpleShowLayout>
);

const StarlinkUsageRow = ({ children, ...props }) => (
    <Datagrid drawer={<StarlinkUsageDrawer {...props} />} {...props}>
        <TextField source="date" />
        <TextField source="usage" />
        {children}
    </Datagrid>
);

StarlinkUsageRow.defaultProps = {
    basePath: '/starlink_usages'
};

export default StarlinkUsageRow;