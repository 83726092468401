import React from 'react';

import SplitButton from '../../common/SplitButton';
import BackToStockButton from './BackToStockButton';
import SaveConfigButton from './SaveConfigButton';
import RebootDeviceButton from './RebootDeviceButton';
import IncontrolMoveDeviceButton from './IncontrolMoveDeviceButton';
import DisassembleDeviceButton from './DisassembleDeviceButton';
import SyncDeviceButton from './SyncDeviceButton';
import SpeedTestDeviceButton from './SpeedTestButton';
import MoveDeviceButton from './MoveDeviceButton';
import PortsEditButton from './PortsEditButton';


const DeviceActionsButtons = ({ record }) => (
	<>
		<BackToStockButton record={record} />
		<SyncDeviceButton record={record} />
		<MoveDeviceButton record={record} />
		<SplitButton color="primary">
			<RebootDeviceButton record={record} />
			<DisassembleDeviceButton record={record} />
			<SaveConfigButton record={record} />
			<IncontrolMoveDeviceButton record={record} />
			<SpeedTestDeviceButton record={record} />
			<PortsEditButton record={record} />
		</SplitButton>
	</>
);

export default DeviceActionsButtons;