import Task from '@material-ui/icons/Check';

import TaskList from './TaskList';
import TaskEdit from './TaskEdit';
import TaskCreate from './TaskCreate';
import TaskShow from './TaskShow';

export default {
	list: TaskList,
	show: TaskShow,
	edit: TaskEdit,
	create: TaskCreate,
	icon: Task,
};
