import React, { useState } from 'react';
import { Confirm, useRefresh } from 'react-admin';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';

import IconButton from '../../common/IconButton';

import { pauseService, resumeService } from '../../../services/api';


const ServicePauseResumeButton = ({ record, variant, color }) => {
	const refresh = useRefresh();
	const [showDialog, setShowDialog] = useState(false);
	const { status } = record;

	const handleConfirm = async () => {
		if(!status) {
			return;
		}

		if(status === 'PAUSED') {
			await resumeService(record.id);
		}
		else {
			await pauseService(record.id);
		}

		handleClose();
		refresh();
	};

	const handleOpen = () => {
		setShowDialog(true);
	};

	const handleClose = () => {
		setShowDialog(false);
	};

	if(!['DELIVERED', 'PAUSED'].includes(status)) {
		return null;
	}

	return (
		<>
			<IconButton icon={status === 'PAUSED' ? <CheckIcon /> : <ClearIcon />} onClick={handleOpen} variant={variant} color={color}>{status === 'PAUSED' ? 'Resume' : 'Pause'}</IconButton>
			<Confirm
				isOpen={showDialog}
				title={status === 'PAUSED' ? 'Suspend SIM' : 'Enable SIM'}
				content={`Are you sure you want to ${status === 'PAUSED' ? 'resume' : 'pause'} the service ${record.reference}?`}
				onConfirm={handleConfirm}
				onClose={handleClose}
			/>
		</>
	);
};

export default ServicePauseResumeButton;