import React from 'react';
import { TextField, ReferenceArrayField, SimpleShowLayout, ShowButton } from 'react-admin';

import Datagrid from '../common/Datagrid';
import LinkFieldButton from '../common/LinkFieldButton';
import DateField from '../common/fields/DateField';
import StarlinkAddressRow from '../starlink_addresses/StarlinkAddressRow';
import StarlinkSubscriptionRow from '../starlink_subscriptions/StarlinkSubscriptionRow';
import StarlinkServiceLineRow from '../starlink_service_lines/StarlinkServiceLineRow';
import StarlinkTerminalRow from '../starlink_terminals/StarlinkTerminalRow';


const StarlinkAccountDrawer = (props) => (
    <SimpleShowLayout {...props}>
        <TextField source="id" />
        <TextField source="account_name" />
        <TextField source="account_number" />
        <TextField source="region_code" />
        <DateField source="created_at" showTime />
        <DateField source="updated_at" showTime />
        <ReferenceArrayField addLabel={false} reference="starlink_addresses" source="starlink_addresses">
            <StarlinkAddressRow />
        </ReferenceArrayField>
        <ReferenceArrayField addLabel={false} reference="starlink_subscriptions" source="starlink_subscriptions">
            <StarlinkSubscriptionRow />
        </ReferenceArrayField>
        <ReferenceArrayField addLabel={false} reference="starlink_service_lines" source="starlink_service_lines">
            <StarlinkServiceLineRow />
        </ReferenceArrayField>
        <ReferenceArrayField addLabel={false} reference="starlink_terminals" source="starlink_terminals">
            <StarlinkTerminalRow />
        </ReferenceArrayField>
    </SimpleShowLayout>
);

const StarlinkAccountRow = ({ children, ...props }) => (
    <Datagrid drawer={<StarlinkAccountDrawer {...props} />} {...props}>
        <LinkFieldButton label="Account name" sortBy="account_name">
            <TextField source="account_name" />
        </LinkFieldButton>
        <LinkFieldButton label="Account number" sortBy="account_number">
            <TextField source="account_number" />
        </LinkFieldButton>
        <TextField source="region_code" />
        {children}
        <ShowButton />
    </Datagrid>
);

StarlinkAccountRow.defaultProps = {
    basePath: '/starlink_accounts'
};

export default StarlinkAccountRow;