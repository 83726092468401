import React, { useState, useEffect } from 'react';
import { Show as ReactAdminShow, ShowView as ReactAdminShowView, ListButton, EditButton } from 'react-admin';
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import orange from '@material-ui/core/colors/orange';

import CardActions from './CardActions';
import Breadcrumb from './Breadcrumb';
import Chip from './fields/Chip';

const styles = {
	container: {
		display: 'flex',
		alignItems: 'center'
	},
	breadcrumb: {
		display: 'flex',
		alignItems: 'center'
	},
	chip: {
		marginLeft: '15px'
	}
};

const ShowActions = withStyles(styles)(({ basePath, className, data, hasList, hasEdit, children, resource, breadcrumb, classes }) => {
	const [icon, setIcon] = useState(null);
	const [title, setTitle] = useState(null);

	useEffect(() => {
		async function getIcon() {
			try {
				const moduleResource = await import(`../${resource}`);
				if (moduleResource.default && moduleResource.default.icon) {
					setIcon(<moduleResource.default.icon />);
					setTitle(moduleResource.default.title);
				}
			} catch (e) {
				console.error(e);
			}
		}

		getIcon();
	}, [resource]);

	const breadcrumbPath = [
		{ url: basePath, title: title || resource, isActive: false, icon },
		{
			url: data ? `${basePath}/${data.id}/show` : '',
			title: data ? (data.name ? data.name : data.id) : '',
			isActive: true
		}
	];

	return (
		<CardActions className={className}>
			<div className={classes.breadcrumb}>
				<Breadcrumb path={breadcrumb || breadcrumbPath} />
				{data?.archived_at && (
					<Chip label="Archived" size="small" className={classes.chip} />
				)}
				{data?.in_stock && (
					<Chip label="Stock" size="small" className={classes.chip} color={orange[300]} />
				)}
				{data?.support_level && (
					<Chip label={data.support_level} size="small" className={classes.chip} color={orange[300]} />
				)}
			</div>
			<div className={classes.container}>
				{children}
				{hasList && <ListButton basePath={basePath} />}
				{hasEdit && <EditButton basePath={basePath} record={data} />}
			</div>
		</CardActions>
	);
});

const themeShow = theme => createMuiTheme({
	...theme,
	overrides: {
		...theme.overrides,
		MuiFormControl: {
			root: {
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'flex-start',
				alignItems: 'center',
				paddingBottom: '5px',

				'.show-page & > label + div': {
					width: 'auto',
					padding: 0
				},
				'.show-page .fullwidth & > label + div': {
					width: '100%',
					padding: '10.5px'
				}
			}
		},
		MuiFormLabel: {
			root: {
				minWidth: '150px'
			}
		}
	}
});

const Show = (props) => (
	<MuiThemeProvider theme={themeShow}>
		<ReactAdminShow {...props} />
	</MuiThemeProvider>
);

Show.defaultProps = {
	actions: <ShowActions />
};

const ShowView = (props) => (
	<MuiThemeProvider theme={themeShow}>
		<ReactAdminShowView {...props} />
	</MuiThemeProvider>
);

export default Show;
export { ShowView, ShowActions, themeShow };