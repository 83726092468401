import React from 'react';
import { TextField, ReferenceField } from 'react-admin';


const ObjectReferenceField = ({ record, source="object_id", ...props }) => {
	const type = `${record.object_type}s`;
	let childSource;

	if (record.object_type === 'simcard') {
	  childSource = 'iccid';
	} else if (record.object_type === 'user') {
	  childSource = 'email';
	} else {
	  childSource = 'name';
	}

	return (
		<ReferenceField source={source} link="show" allowEmpty {...props} reference={type} basePath={`/${type}`} record={record}>
			<TextField source={childSource} />
		</ReferenceField>
	);
};

export default ObjectReferenceField;
