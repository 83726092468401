import React from 'react';
import { TabbedShowLayout, Tab, TextField } from 'react-admin';
import { Helmet } from 'react-helmet';

import Show, { ShowActions } from '../common/Show';
import DateField from '../common/fields/DateField';
import CustomList from '../common/CustomList';
import SimCardRow from '../simcards/SimCardRow';
import CustomerReferenceField from '../customers/CustomerReferenceField';
import { BulkActions } from '../common/List';
import BulkRemoveFromPoolButton from '../simcards/SimCardActionsButtons/BulkRemoveFromPoolButton';
import BulkAddSimCardsButton from './SimPoolActionsButtons/BulkAddSimCardsButton';


const SimCardBulkActions = props => (
	<>
		<BulkRemoveFromPoolButton {...props} />
		<BulkActions {...props} />
	</>
);

const SimCardList = props => (
	<CustomList
		filter={{ pool_id: props.record.id }}
		sort={{ field: 'id', order: 'DESC' }}
		{...props}
		resource="simcards"
		basepath="/simcards"
		title=" "
		hasCreate={false}
		hasEdit={false}
		hasList={false}
		hasShow={false}
		bulkActionButtons={<SimCardBulkActions record={props.record} />}
	>
		<SimCardRow />
	</CustomList>
);

const SimPoolActions = (props) => (
	<ShowActions {...props}>
		<BulkAddSimCardsButton record={props.data} />
	</ShowActions>
);

const SimPoolTitle = ({ record }) => (
	<>
		<Helmet>
			<title>{`KB - Simpool[${record.name}]`}</title>
		</Helmet>
		<span>{record.name}</span>
	</>
);

const SimPoolShow = props => (
	<Show title={<SimPoolTitle />} actions={<SimPoolActions />} {...props}>
		<TabbedShowLayout>
			<Tab label="summary">
				<TextField source="id" />
				<CustomerReferenceField />
				<TextField source="name" />
				<TextField source="description" label="Notes" />
				<DateField label="Created at" source="created_at" />
				<DateField label="Updated at" source="updated_at" />
				<SimCardList /> 
			</Tab>
		</TabbedShowLayout>
	</Show>
);

export default SimPoolShow;