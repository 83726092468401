import React from 'react';
import { ReferenceInput, AutocompleteInput } from 'react-admin';


const optionText = choice => choice && choice.name ? (choice.customer && choice.customer.name ? `${choice.customer.name} / ` : '') + choice.name : '';

const SiteReferenceInput = props => (
	<ReferenceInput {...props}>
		<AutocompleteInput optionText={optionText} />
	</ReferenceInput>
);

SiteReferenceInput.defaultProps = {
	label: "Site",
	source: "site_id",
	reference:"sites",
	sort: { field: 'name', order: 'ASC' }
};

export default SiteReferenceInput;