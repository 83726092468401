import React from 'react';
import { AutocompleteInput } from 'react-admin';

const choices = [
	{ id: 'draft', name: 'DRAFT' },
	{ id: 'send', name: 'SENT' },
	{ id: 'view', name: 'VIEW' },
	{ id: 'paid', name: 'PAID' }
];

const StatusInput = ({ classes, record, source, ...props }) => {
	if (!record) {
		return null;
	}

	return (
		<AutocompleteInput choices={choices} source={source} record={record} allowEmpty {...props} />
	);
};

StatusInput.defaultProps = {
    source: 'status'
};

export default StatusInput;
