import React from 'react';
import { AutocompleteInput } from 'react-admin';

const choices = [
	{ id: '', name: '' },
	{ id: 'none', name: 'None' },
	{ id: 'untangle-int', name: 'Untangle-Int' },
    { id: 'untangle-ext', name: 'Untangle-Ext' },
    { id: 'untangle-msf', name: 'Untangle-MSF' },
    { id: 'untangle-lab', name: 'Untangle-Lab' },
    { id: 'untangle-mgmt', name: 'Untangle-Mgmt' },
    { id: 'mikrotik', name: 'Mikrotik' },
];

const FireWallInput = ({ classes, record, source = "firewall", ...props }) => {
	if (record && record[source] && !choices.find((provider) => provider.id === record[source])) {
		choices.push({ id: record[source], name: record[source] });
	}

	return (
		<AutocompleteInput choices={choices} source={source} record={record} allowEmpty {...props} />
	);
};

export default FireWallInput;
