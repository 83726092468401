import React from 'react';
import { AutocompleteInput } from 'react-admin';

const choices = [
    { id: 'sim', name: 'SIM' },
    { id: 'esim', name: 'ESIM' },
];

const SimCardType = ({ classes, record, source = "type", ...props }) => {
	if (record && record[source] && !choices.find((provider) => provider.id === record[source])) {
		choices.push({ id: record[source], name: record[source]});
	}

	return (
		<AutocompleteInput choices={choices} source={source} record={record} allowEmpty {...props} />
	);
};

export default SimCardType;
