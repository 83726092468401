import React from 'react';
import { AutocompleteInput } from 'react-admin';

const choices = [
	{ id: 'PEPLINK', name: 'PEPLINK' },
	{ id: 'FRONTIER', name: 'FRONTIER' },
	{ id: 'OTHER', name: 'OTHER' }
];

const DeviceSupplierField = props => (
	<AutocompleteInput choices={choices} allowEmpty {...props} />
);

DeviceSupplierField.defaultProps = {
	source: "supplier"
};

export default DeviceSupplierField;
