import { CHANGE_THEME } from '../actions/theme';

const initialState = localStorage.getItem('theme') || 'light';

const themeReducer = (previousState=initialState, action) => {
	if (action.type === CHANGE_THEME) {
		localStorage.setItem('theme', action.payload);
		return action.payload;
	}
	return previousState;
};

export default themeReducer;