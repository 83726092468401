import React from 'react';
import { SelectInput } from 'react-admin';

const choices = [
    { id: 'create', name: 'Create' },
    { id: 'update', name: 'Update' },
    { id: 'delete', name: 'Delete' },
    { id: 'archive', name: 'Archive' },
    { id: 'login', name: 'Login' },
    { id: 'impersonate', name: 'Impersonate' },
];

const ActivityVerbInput = ({ classes, record, source = 'activity_verb', ...props }) => {
    if (!record) {
        return null;
    }

    return (
        <SelectInput
            choices={choices}
            source={source}
            record={record}
            {...props}
        />
    );
};

export default ActivityVerbInput;
