import React from 'react';

import AddSimToPoolButton from './AddSimToPoolButton';


const SimPoolActionsButtons = ({ record }) => (
	<>
		<AddSimToPoolButton record={record} />
	</>
);

export default SimPoolActionsButtons;