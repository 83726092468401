import React, { useState } from 'react';
import { TextInput, DateInput, SaveButton as RASaveButton, useRefresh, useNotify, useUnselectAll } from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconReturn from '@material-ui/icons/KeyboardReturn';

import SimpleForm from '../../common/SimpleForm';
import IconButton from '../../common/IconButton';
import Toolbar from '../../common/Toolbar';

import { deliverService } from '../../../services/api';


const SaveButton = ({ onClick, onAfterSubmit, ids, ...props }) => {
    const formState = useFormState();
    const form = useForm();

    const handleClick = async () => {
        let count = 0;
        onClick();
        if (formState.values?.date) {
            let data = new FormData();
            data.append("date", formState.values.date);

            for (const id of ids) {
                try {
                    const response = await deliverService(id, data);
                    if (response.id) {
                        count++;
                    }
                }
                catch (e) {
                    console.error(e);
                }
            };

            form.reset();
            onAfterSubmit(count);
        }
    };

    return <RASaveButton {...props} handleSubmit={handleClick} handleSubmitWithRedirect={handleClick} />;
};

const ServiceBulkDeliver = ({ selectedIds }) => {
    const [loading, setLoading] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll('services');

    const handleOpen = () => {
        setShowDialog(true);
    };

    const handleClose = () => {
        setShowDialog(false);
    };

    const handleSave = async () => {
        setLoading(true);
    };

    const handleAfterSubmit = (count) => {
        if (count) {
            notify(`${count} item${count > 1 ? 's' : ''} delivered`);
        }
        handleClose();
        refresh();
        unselectAll();
        setLoading(false);
    };

    return (
        <>
            <IconButton onClick={handleOpen} icon={<IconReturn />} disabled={loading}>Deliver</IconButton>
            <Dialog fullWidth open={showDialog} onClose={handleClose} aria-label="Deliver services">
                <DialogTitle>Deliver services</DialogTitle>
                <DialogContent>
                    <SimpleForm
                        toolbar={<Toolbar onClose={handleClose} onSave={handleSave} saveButton={<SaveButton onClick={handleSave} ids={selectedIds} onAfterSubmit={handleAfterSubmit} />} />}
                    >
                        <DateInput label="Delivery Date" source="date" required />
                    </SimpleForm>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default ServiceBulkDeliver;