import React from 'react';
import { TextField, Link } from 'react-admin';
import { PieChart, Pie, Cell } from 'recharts';
import PhoneIcon from '@material-ui/icons/Phone';

import LinkFieldButton from '../../../common/LinkFieldButton';
import DateField from '../../../common/fields/DateField';
import NumberCurrencyField from '../../../common/fields/NumberCurrencyField';
import ServiceCard from './ServiceCard';
import StatusField from './StatusField';

import variables from '../../../../style/variables';

const StarLinkServiceCard = ({ record }) => {
	let data = [];
	if (record.current_usage > record.data_included) {
		data = [
			{
				name: 'Overconsumption',
				value: Math.abs(record.data_included - record.current_usage),
				color: '#FC6F73'
			},
			{ name: 'Data included', value: record.data_included, color: variables.colors.primary },
		];
	}
	else {
		data = [
			{ name: 'Current usage', value: record.current_usage, color: variables.colors.primary },
			{ name: 'Remaining data', value: record.data_included - record.current_usage, color: '#B3B3B3' }
		];
	}

	const data_extra_price = record.starlink_service_line?.last_service_plan?.overageLine?.pricePerGB || 0.0;
	const data_extra_price_currency = record.starlink_service_line?.last_service_plan?.isoCurrencyCode || "EUR";

	return (
	<Link to={`/services/${record.id}/show`}>
		<ServiceCard
			header={
				<>
					StarLink
					{record.line ? (
						<>
							{` - `}
							<LinkFieldButton label="Line" source="line" basePath="/lines" record={record}>
								<TextField source="subscription_id" />
							</LinkFieldButton>
							{` - `}
							<LinkFieldButton label="Starlink" source="starlink_service_line" basePath="/starlink_service_lines" record={record}>
								<TextField source="service_line_number" />
							</LinkFieldButton>
						</>
					) : ''}
					{record?.line?.provider_name ? ` - ${record.line.provider_name}` : ''}
				</>
			}
			title={
				<LinkFieldButton record={record} basePath="/services">
					<TextField source="name" />
				</LinkFieldButton>
			}
			content={
				<>
					Price: <NumberCurrencyField source="price" record={record} /> - 
					Overconsumption: {data_extra_price} {data_extra_price_currency}/GB
				</>
			}
			contentRight={
				<>
					<PieChart width={100} height={100}>
						<Pie
							data={data}
							innerRadius={30}
							outerRadius={40}
							fill="#8884d8"
							dataKey="value"
							blendStroke
						>
							{data.map((entry, index) => <Cell key={`cell-${index}`} fill={entry.color} />)}
						</Pie>
					</PieChart>
					<span>{record.current_usage}/{record.data_included}GB</span>
				</>
			}
			footer={
				record?.start_date ? (
					<>
						<DateField source="start_date" record={record} /> - {record.end_date ? <DateField source="end_date" record={record} /> : 'Running'}
						<StatusField record={record} />
					</>
				) : <StatusField record={record} />
			}
			icon={<PhoneIcon />}
		/>
	</Link>
	);
};

export default StarLinkServiceCard;
