import React from 'react';
import { TextInput, NumberInput } from 'react-admin';

import Create from '../common/Create';
import SimpleForm from '../common/SimpleForm';
import VATCodeInput from '../common/inputs/VATCodeInput';


const redirect = (basePath, id, data, location) => {
    if (data?.invoice_id && location?.state?.record?.invoice_id === data.invoice_id) {
        return `/invoices/${data.invoice_id}/show`;
    }
    return basePath;
};

const InvoiceLineCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect={(basePath, id, data) => redirect(basePath, id, data, props.location)}>
            <TextInput source="invoice_id" disabled />
			<TextInput label="Package Reference" source='package_reference' />
			<TextInput label="Customer Reference" source='customer_reference' />
			<TextInput label="Description" source='description' options={{ multiline: true }} />
			<NumberInput label="Quantity" source='qty' />
			<TextInput label="Price" source='unit_price' />
            <VATCodeInput source="vat_code" label="VAT" />
			<TextInput label="start_date" source='start_date' />
			<TextInput label="end_date" source='end_date' />
        </SimpleForm>
    </Create>
);

export default InvoiceLineCreate;
