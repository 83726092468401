import React from 'react';
import { TextField, ReferenceField, NumberField, SimpleShowLayout } from 'react-admin';

import Datagrid from '../common/Datagrid';
import LinkFieldButton from '../common/LinkFieldButton';
import DateField from '../common/fields/DateField';
import RecordSplitButton from '../common/RecordSplitButton';
import CustomerReferenceField from '../customers/CustomerReferenceField';


const DataPoolDrawer = props => (
	<SimpleShowLayout {...props}>
		<TextField source="id" />
		<CustomerReferenceField />
		<TextField source="reference"  label="Datapool ID Reference" />
		<TextField source="customer_reference" label="Customer Reference" />
		<NumberField label="Data (GB)"  sortable={false} source="data_included" />
		<NumberField label="Price" sortable={false} source="data_price" options={{ style: 'currency', currency: 'eur', maximumFractionDigits: 2 }} />
		<NumberField label="Usage (GB)" sortable={false} source="usage" />
		<NumberField label="Overusage (GB)" sortable={false} source="extra_usage" />
		<NumberField label="Overusage Price (EUR/GB)" sortable={false} source="data_extra_price" options={{ style: 'currency', currency: 'eur', maximumFractionDigits: 2 }} />
		<NumberField label="Total Price" sortable={false} source="total_price" />
		<NumberField label="Packages count" sortable={false} source="packages_count" />
		<DateField source="start_date" />
		<DateField source="end_date" />
		<DateField label="Created at" source="created_at" />
		<DateField label="Updated at" source="updated_at" />
	</SimpleShowLayout>
);

const DataPoolRow = ({ children, ...props }) => (
	<Datagrid /*expand={<ServiceList {...props} />}*/ drawer={<DataPoolDrawer {...props} />} {...props}>
		<LinkFieldButton label="Datapool ID Reference" sortBy="reference">
			<TextField source="reference" />
		</LinkFieldButton>
		<ReferenceField label="Customer" source="customer" reference="customers" link="show" allowEmpty>
			<TextField source="name" />
		</ReferenceField>
		<TextField source="customer_reference" label="Customer Reference" />
		<NumberField label="Data (GB)"  sortable={false} source="data_included" />
		<NumberField label="Price" sortable={false} source="data_price" options={{ style: 'currency', currency: 'eur', maximumFractionDigits: 2 }} />
		<NumberField label="Usage (GB)" sortable={false} source="usage" />
		<NumberField label="Overusage (GB)" sortable={false} source="extra_usage" />
		<NumberField label="Overusage(EUR/GB)" sortable={false} source="data_extra_price" options={{ style: 'currency', currency: 'eur', maximumFractionDigits: 2 }} />
		<NumberField label="Total Price" sortable={false} source="total_price" options={{ style: 'currency', currency: 'eur', maximumFractionDigits: 2 }} />
		<NumberField label="Packages count" sortable={false} source="packages_count" />
		<DateField source="start_date" />
		<DateField source="end_date" />
		<RecordSplitButton />
		{children}
	</Datagrid>
);

export default DataPoolRow;