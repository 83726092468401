import React, { useState } from 'react';
import { Confirm, useRefresh } from 'react-admin';
import IconReturn from '@material-ui/icons/KeyboardReturn';

import IconButton from '../../common/IconButton';

import { stowStarlinkTerminal, unstowStarlinkTerminal } from '../../../services/api';


const StowUnstowButton = ({ record, variant, color }) => {
	const [showDialogStow, setShowDialogStow] = useState(false);
	const [showDialogUnstow, setShowDialogUnstow] = useState(false);
	const refresh = useRefresh();

	const handleOpenStow = () => {
		setShowDialogStow(true);
	};

	const handleCloseStow = () => {
		setShowDialogStow(false);
	};

	const handleOpenUnstow = () => {
		setShowDialogUnstow(true);
	};

	const handleCloseUnstow = () => {
		setShowDialogUnstow(false);
	};

	const handleStow = async () => {
		handleCloseStow();
		await stowStarlinkTerminal(record.terminal_id);
		refresh();
	};

	const handleUnstow = async () => {
		handleCloseUnstow();
		await unstowStarlinkTerminal(record.terminal_id);
		refresh();
	};

	if (!record) {
		return null;
	}

	// TODO: check status later to show correct button
	return (
		<>
			<IconButton icon={<IconReturn />} variant={variant} color={color} onClick={handleOpenStow}>Stow</IconButton>
			<Confirm
				isOpen={showDialogStow}
				title={`Stow #${record.id}`}
				content={`Are you sure you want to stow this item?`}
				onConfirm={handleStow}
				onClose={handleCloseStow}
			/>

			<IconButton icon={<IconReturn />} variant={variant} color={color} onClick={handleOpenUnstow}>Unstow</IconButton>
			<Confirm
				isOpen={showDialogUnstow}
				title={`Unstow #${record.id}`}
				content={`Are you sure you want to unstow this item?`}
				onConfirm={handleUnstow}
				onClose={handleCloseUnstow}
			/>
		</>
	);
};

export default StowUnstowButton;