import green from '@material-ui/core/colors/green';
import orange from '@material-ui/core/colors/orange';
import red from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';

const deviceColors = {
	'online': green[500],
	'never_online': orange[500],
	'offline': red[500],
	'unknown': grey[500]
};

export default deviceColors;