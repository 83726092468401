import React from 'react';
import {
    TextInput,
    ReferenceInput,
    AutocompleteInput
} from 'react-admin';

import Edit from '../common/Edit';
import SimpleForm from '../common/SimpleForm';
import CountryInput from './inputs/CountryInput';
import PaymentMethodInput from './inputs/PaymentMethodInput';
import PaymentTermsInput from './inputs/PaymentTermsInput';

const optionText = choice => choice.name ? choice.name : '';

const AddressEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="id" disabled />
            <ReferenceInput label="Customer" source="customer_id" reference="customers" sort={{ field: 'name', order: 'ASC' }} allowEmpty>
				<AutocompleteInput optionText={optionText} />
			</ReferenceInput>
            <TextInput source="accounting_code" />
            <TextInput source="name" />
            <TextInput source="vat" />
            <TextInput source="street" />
            <TextInput source="street_bis" />
            <TextInput source="number" />
            <TextInput source="box" />
            <TextInput source="zipcode" />
            <TextInput source="city" />
            <CountryInput source="country" />
            <TextInput source="email" />
            <TextInput source="phone" />
            <PaymentTermsInput />
            <PaymentMethodInput />
        </SimpleForm>
    </Edit>
);

export default AddressEdit;