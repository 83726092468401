import React, { useState } from 'react';
import { useNotify } from 'react-admin';
import { useLocation, useHistory } from 'react-router-dom';
import { CardActions, Button, TextField, Typography, Box, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import Login from '../../common/Login';

import { dispatchMFACode, confirmMFACode } from '../../../services/api';

const useStyles = makeStyles((theme) => ({
	icon: {
		marginRight: theme.spacing(1)
	},
	form: {
		padding: '0 8px 8px 8px'
	},
	resend: {
		display: 'flex',
		justifyContent: 'center',
		margin: theme.spacing(1)
	},
}));

const MFACodeForm = () => {
	const [code, setCode] = useState('');
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);
	const history = useHistory();
	const classes = useStyles();
	const { state } = useLocation();
	const notify = useNotify();

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (code) {
			setLoading(true);
			const response = await confirmMFACode(
				JSON.stringify({ ephemeral_token: state?.ephemeralToken, code })
			);
			setLoading(false);
			if (response.success && response.token) {
				localStorage.setItem('token', response.token);
				history.push('/');
			}
			else {
				setError(response.message || 'An error has occured');
			}
		}
	};

	const resendCode = async (e) => {
		e.preventDefault();
		const response = await dispatchMFACode(
			JSON.stringify({ ephemeral_token: state?.ephemeralToken })
		);
		if (response.success) {
			notify("Code sent");
		} else {
			notify(response.message || 'An error has occured');
		}
	};

	return (
		<Login>
			{
				<>
					<form onSubmit={handleSubmit}>
						<div className={classes.form}>
							<TextField
								label="Two-factor authentication code"
								type="text"
								name="code"
								value={code}
								onChange={(e) => setCode(e.target.value)}
								helperText={error}
								error={!!error}
								size="small"
								fullWidth
							/>
							<Box mt={2}>
								<Typography variant="body1">
									Enter the code you received by {state?.methodName}
								</Typography>
							</Box>
						</div>
						<CardActions>
							<Button
								variant="contained"
								type="submit"
								color="primary"
								disabled={loading}
								fullWidth
							>
								{loading && (
									<CircularProgress
										className={classes.icon}
										size={18}
										thickness={2}
									/>
								)}
								Verify code
							</Button>
						</CardActions>
					</form>
					{['sms', 'email'].includes(state?.methodName) && (
						<div className={classes.resend}>
							<Link
								component="button"
								variant="body2"
								onClick={resendCode}
							>
								Resend code?
							</Link>
						</div>
					)}
				</>
			}
		</Login>
	);
};

export default MFACodeForm;
