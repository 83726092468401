import React from 'react';
import {
	NumberField,
	TextField,
	SimpleShowLayout
} from 'react-admin';

import Datagrid from '../common/Datagrid';
import LinkFieldButton from '../common/LinkFieldButton';
import RecordSplitButton from '../common/RecordSplitButton';
import DateField from '../common/fields/DateField';
import StatusField from './StatusField';
import PackageCloneButton from './PackageCloneButton';

const PackageDrawer = props => (
	<SimpleShowLayout {...props}>
		<TextField source="id" />
		<TextField source="reference" />
		<TextField source="so_number" label="SO Number" />
		<StatusField />
		<TextField source="name" />
		<TextField source="description" options={{ multiline: true }} rows="4" />
		<TextField source="shipping_address" />
		<DateField label="Created at" source="created_at" />
		<DateField label="Updated at" source="updated_at" />
	</SimpleShowLayout>
);

const PackageRow = props => (
	<Datagrid drawer={<PackageDrawer {...props} />} {...props}>
		<LinkFieldButton label="Package Reference" sortBy="reference">
			<TextField source="reference" />
		</LinkFieldButton>
		<LinkFieldButton label="Customer" source="customer" basePath="/customers" sortable={false}>
			<TextField source="name" />
		</LinkFieldButton>
		<LinkFieldButton label="Site" source="site" basePath="/sites" sortable={false}>
			<TextField source="name" />
		</LinkFieldButton>
		<LinkFieldButton label="Customer Reference" sortBy="customer_reference">
			<TextField source="customer_reference" />
		</LinkFieldButton>
		<StatusField />
		<TextField source="invoicing_delivery_method" label="Billing method" />
		<NumberField source="services_count" label="Services"/>
		<NumberField source="fulfillment_rate" label="Fulfillment" sortable={false}/>
		<RecordSplitButton>
			<PackageCloneButton />
		</RecordSplitButton>
	</Datagrid>
);

export default PackageRow;