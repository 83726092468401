import React, { useState } from 'react';
import { Confirm, useRefresh, useNotify } from 'react-admin';
import SpeedIcon from '@material-ui/icons/Speed';

import IconButton from '../../common/IconButton';

import { speedTestDevice } from '../../../services/api';


const SpeedTestDeviceButton = ({ record, variant, color }) => {
	const refresh = useRefresh();
	const notify = useNotify();
	const [showDialog, setShowDialog] = useState(false);

	const handleConfirm = async () => {
		const response = await speedTestDevice(record.id);

		if(response.success === true) {
			refresh();
			notify('The device is now SpeedTesting wait 20 seconds before refresh');
		}
		else if(response.reason) {
			notify(response.reason, 'warning');
		}
		else {
			notify('SpeedTest failed', 'warning');
		}

		handleClose();
	};

	const handleOpen = () => {
		setShowDialog(true);
	};

	const handleClose = () => {
		setShowDialog(false);
	};

	if(!record) {
		return null;
	}

	return (
		<>
			<IconButton icon={<SpeedIcon />} onClick={handleOpen} variant={variant} color={color}>SpeedTest</IconButton>
			<Confirm
				isOpen={showDialog}
				title="SpeedTest device"
				content={`Do you want to SpeedTest the device ${record.name} (${record.serial})`}
				onConfirm={handleConfirm}
				onClose={handleClose}
			/>
		</>
	);
};

export default SpeedTestDeviceButton;