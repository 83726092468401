import React from 'react';
import { Datagrid, TextInput, TextField, ReferenceField, ReferenceInput, AutocompleteInput, DateInput, FunctionField, SelectInput } from 'react-admin';

import GetAppIcon from '@material-ui/icons/GetApp';

import { API_URL } from '../../services/settings';

import List, { ListActions } from '../common/List';
import IconButton from '../common/IconButton';
import Filter from '../common/Filter';
import DateField from '../common/fields/DateField';
import SiteReferenceInput from '../sites/SiteReferenceInput';


const sourceChoices = [
    { id: 'incontrol', name: 'InControl' },
    { id: 'telna', name: 'TELNA' },
    { id: 'webbing', name: 'WEBBING' },
    { id: 'phenix', name: 'PHENIX' }
];

const optionText = choice => choice.name ? choice.name : '';
const simcardOptionText = choice => choice.iccid ? choice.iccid : '';

const UsageFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <ReferenceInput label="Simcard" source="sim_id" reference="simcards" alwaysOn>
            <AutocompleteInput optionText={simcardOptionText} />
        </ReferenceInput>
        <DateInput source="start_date" alwaysOn />
        <DateInput source="end_date" alwaysOn />
        <SelectInput source="source" choices={sourceChoices} alwaysOn />
        <SiteReferenceInput alwaysOn />
        <ReferenceInput label="Supplier" source="supplier_name" reference="data_suppliers" allowEmpty alwaysOn>
            <AutocompleteInput optionText={optionText} />
        </ReferenceInput>
    </Filter>
);

const UsagesActions = (props) => (
    <ListActions {...props}>
        <IconButton icon={<GetAppIcon />} component="a" href={`${API_URL}/usages/export.xls`}>Export</IconButton>
    </ListActions>
);

const UsageList = props => (
    <List {...props} filters={<UsageFilter />} actions={<UsagesActions />} perPage={100} bulkActionButtons={false} exporter={false}>
        <Datagrid>
            <DateField source="date" />
            <ReferenceField label="Simcard" source="sim" reference="simcards" link="show" allowEmpty sortable={false}>
                <FunctionField label="iccid" source="iccid" render={record => `${record.iccid_formatted}`} />
            </ReferenceField>
            <TextField source="source" />
            <ReferenceField label="Site" source="site" reference="sites" link="show" allowEmpty sortable={false}>
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField label="Pool" source="pool" reference="simpools" link="show" allowEmpty sortable={false}>
                <TextField source="name" />
            </ReferenceField>
            <TextField source="total" label="GB" sortable={false} />
            <TextField source="total_price" label="EUR" sortable={false} />
        </Datagrid>
    </List>
);

export default UsageList;
