import React, { useState } from 'react';
import { Confirm, useRefresh } from 'react-admin';
import CancelIcon from '@material-ui/icons/Cancel';

import IconButton from '../../common/IconButton';

import { deactivateStarlinkServiceLine } from '../../../services/api';


const DeactivateButton = ({ record, variant, color }) => {
	const [showDialog, setShowDialog] = useState(false);
	const refresh = useRefresh();

	const handleOpen = () => {
		setShowDialog(true);
	};

	const handleClose = () => {
		setShowDialog(false);
	};

	const handleDeactivate = async () => {
		handleClose();
		await deactivateStarlinkServiceLine(record.id);
		refresh();
	};

	if (!record) {
		return null;
	}

	return (
		<>
			<IconButton icon={<CancelIcon />} variant={variant} color={color} onClick={handleOpen}>Deactivate</IconButton>
			<Confirm
				isOpen={showDialog}
				title={`Deactivate #${record.id}`}
				content={`Are you sure you want to deactivate this service line?`}
				onConfirm={handleDeactivate}
				onClose={handleClose}
			/>
		</>
	);
};

export default DeactivateButton;