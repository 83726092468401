import React from 'react';
import { Typography } from '@material-ui/core';
import moment from 'moment';

import DateField from '../common/fields/DateField';

const DatasBeforeAfterField = ({ record }) => {
	const datas = JSON.parse(record.datas);
	if (!datas || !datas.before) {
		return null;
	}

	let elements = [];
	for (const key of Object.keys(datas.before)) {
		elements.push(<Typography variant="body2" key={`log_${record.id}_${key}`}>{key}</Typography>);
	}
	return elements;
};

const BeforeAfterField = ({ record, source }) => {
	const datas = JSON.parse(record.datas);
	if (!datas || !datas[source]) {
		return null;
	}

	let elements = [];
	for (const [key, value] of Object.entries(datas[source])) {
		if (!key.endsWith('_id') && moment(value, moment.ISO_8601, true).isValid()) {
			elements.push(<DateField key={`log_${record.id}_${key}`} source={key} record={datas[source]} showTime />);
		}
		else {
			elements.push(<Typography variant="body2" key={`log_${record.id}_${key}`}>{value ? JSON.stringify(value) : '\u00A0'}</Typography>);
		}
	}
	return elements;
};

export { DatasBeforeAfterField, BeforeAfterField };
