import React from 'react';
import { TabbedShowLayout, Tab, TextField, NumberField, ReferenceManyField, Pagination } from 'react-admin';
import { Helmet } from 'react-helmet';

import ServiceRow from '../services/rows/ServiceRow';
import Show from '../common/Show';
import DateField from '../common/fields/DateField';
import NumberCurrencyField from '../common/fields/NumberCurrencyField';

const ServiceProductTitle = ({ record }) => (
	<>
		<Helmet>
			<title>{`KB - ServiceProduct[${record.name}]`}</title>
		</Helmet>
		<span>{record.name}</span>
	</>
);

const ServiceProductShow = (props) => (
	<Show title={<ServiceProductTitle />} {...props}>
		<TabbedShowLayout>
			<Tab label="summary">
				<TextField label="Id" source="id" />
				<TextField source="reference" />
				<TextField source="service_type" />
				<TextField source="frequency" />
				<TextField source="name" />
				<NumberCurrencyField source="price" />
				<NumberField source="data_included" />
				<NumberCurrencyField source="extra_price" />
				<TextField source="currency" />
				<TextField source="description" options={{ multiline: true }} rows="4" />
				<DateField label="Created at" source="created_at" />
				<DateField label="Updated at" source="updated_at" />
			</Tab>
			<Tab label="Services" path="services">
				<ReferenceManyField addLabel={false} reference="services" target="product_id" pagination={<Pagination />} filter={{ archived: false }}>
					<ServiceRow />
				</ReferenceManyField>
			</Tab>
		</TabbedShowLayout>
	</Show>
);

export default ServiceProductShow;
