import React from 'react';
import { ReferenceInput, AutocompleteInput, useRefresh, useNotify } from 'react-admin';
import GetAppIcon from '@material-ui/icons/GetApp';
import IconRefresh from '@material-ui/icons/Refresh';

import List, { ListActions } from '../common/List';
import Filter from '../common/Filter';
import DataPricingRow from './DataPricingRow';
import IconButton from '../common/IconButton';
import DataPricingProviderField from './DataPricingProviderField';
import DataPricingCountryField from './DataPricingCountryField';

import { API_URL } from '../../services/settings';
import { fixDataPricing } from '../../services/api';

const optionText = choice => choice.name ? choice.name : '';

const DataPricingActions = (props) => {
    const refresh = useRefresh();
    const notify = useNotify();

    const handleFixDataPricing = async () => {
        const response = await fixDataPricing();

        if(response.success) {
            refresh();
            notify('Last month data usages updated');
        }
        else {
            notify('Synchronization failed', 'warning');
        }
    };

    return (
        <ListActions {...props}>
            <IconButton icon={<IconRefresh />} onClick={handleFixDataPricing}>Update Usages</IconButton>
            <IconButton icon={<GetAppIcon />} component="a" href={`${API_URL}/data_pricings/export.xls`}>Export</IconButton>
        </ListActions>
    );
   
};

const DataPricingFilter = (props) => (
    <Filter {...props}>
        <DataPricingProviderField label="Provider" source="provider" alwaysOn />
        <ReferenceInput label="Customer" source="customer_id" reference="customers"  sort={{ field: 'name', order: 'ASC' }} allowEmpty alwaysOn>
            <AutocompleteInput optionText={optionText} />
        </ReferenceInput>
        <DataPricingCountryField label="Country" source="country_code" alwaysOn />
    </Filter>
);

const DataPricingList = props => (
    <List {...props} filters={<DataPricingFilter/>} actions={<DataPricingActions />} exporter={false} perPage={100}>
        <DataPricingRow />
    </List>
);

export default DataPricingList;