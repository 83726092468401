import React, { useState, useEffect } from 'react';
import { Edit as ReactAdminEdit, ListButton, ShowButton, Toolbar, SaveButton } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import CardActions from '../common/CardActions';
import Breadcrumb from '../common/Breadcrumb';
import ArchiveButton from '../common/ArchiveButton';

const useStyles = makeStyles({
	toolbar: {
		display: 'flex',
		justifyContent: 'space-between',
	},
});

const EditActions = ({ basePath, className, data, hasList, hasShow, children, resource, breadcrumb }) => {
	const [icon, setIcon] = useState(null);
	const [title, setTitle] = useState(null);

	useEffect(() => {
		async function getIcon() {
			try {
				const moduleResource = await import(`../${resource}`);
				if(moduleResource.default && moduleResource.default.icon) {
					setIcon(<moduleResource.default.icon />);
					setTitle(moduleResource.default.title);
				}
			} catch(e) {
				console.error(e);
			}
		}

		getIcon();
	}, [resource]);

	const breadcrumbPath = [
		{ url: basePath, title: title || resource, isActive: false, icon },
		{
			url: data ? `${basePath}/${data.id}/show` : '',
			title: data ? (data.name ? data.name : data.id) : '',
			isActive: false
		},
		{
			url: data ? `${basePath}/${data.id}` : '',
			title: 'Edit',
			isActive: true
		}
	];

	return (
		<CardActions className={className}>
			<Breadcrumb path={breadcrumb || breadcrumbPath} />
			<div>
				{children}
				{hasList && <ListButton basePath={basePath} />}
				{hasShow && <ShowButton basePath={basePath} record={data} />}
			</div>
		</CardActions>
	);
};

const CustomToolbar = ({ archiveButton, ...props }) => (
	<Toolbar {...props} classes={useStyles()}>
		<SaveButton />
		{archiveButton ? archiveButton : <ArchiveButton />}
	</Toolbar>
);


const Edit = ({ children, toolbar, archiveButton, ...props }) => (
	<ReactAdminEdit {...props}>
		{React.cloneElement(children, { toolbar: toolbar || <CustomToolbar archiveButton={archiveButton} /> })}
	</ReactAdminEdit>
);

Edit.defaultProps = {
	actions: <EditActions />,
	undoable: false,
	archiveButton: null
};

export default Edit;
export { EditActions };