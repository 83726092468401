import React, { useState } from 'react';
import { SaveButton as RASaveButton, useRefresh, useNotify, TextInput } from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import IconButton from '../../common/IconButton';
import SimpleForm from '../../common/SimpleForm';
import Toolbar from '../../common/Toolbar';

import { addSimcardsToSimPool } from '../../../services/api';


const SaveButton = ({ onClick, record, ...props }) => {
	const formState = useFormState();
	const form = useForm();
	const notify = useNotify();

	const handleClick = async () => {
		if (formState.values.new_iccids) {
			const response = await addSimcardsToSimPool(record.id, JSON.stringify({ iccids: formState.values.new_iccids }));
			if (response.id) {
				notify('Successfully added simcards', 'success');
			}
			else if (response.message) {
				notify(response.message, 'warning');
			}

			form.reset();
			onClick();
		}
	};

	return <RASaveButton {...props} handleSubmit={handleClick} handleSubmitWithRedirect={handleClick} />;
};

const BulkAddSimCardsButton = ({ record, variant, color }) => {
	const refresh = useRefresh();
	const [showDialog, setShowDialog] = useState(false);

	const handleSave = () => {
		refresh();
		handleClose();
	};

	const handleOpen = () => {
		setShowDialog(true);
	};

	const handleClose = () => {
		setShowDialog(false);
	};

	return (
		<>
			<IconButton icon={<AddIcon />} onClick={handleOpen} variant={variant} color={color}>Add SIM to pool</IconButton>
			<Dialog fullWidth open={showDialog} onClose={handleClose} aria-label="Add SIM to pool" disablePortal>
				<DialogTitle>Add SIM to pool</DialogTitle>
				<DialogContent>
					<SimpleForm toolbar={<Toolbar onClose={handleClose} onSave={handleSave} saveButton={<SaveButton onClick={handleSave} />} />} record={record}>
						<TextInput label="iccids" source="new_iccids" options={{ multiline: true }} rows="4" fullWidth formClassName="fullwidth" />
					</SimpleForm>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default BulkAddSimCardsButton;