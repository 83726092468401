import React from 'react';
import { TextInput, BooleanInput, NumberInput } from 'react-admin';

import Edit from '../common/Edit';
import CustomAutocompleteInput from '../common/inputs/CustomAutocompleteInput';
import SimpleForm from '../common/SimpleForm';

const DeviceProductEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="id" disabled />
            <TextInput source="name" />
            <CustomAutocompleteInput source="brand" />
            <TextInput source="reference" />
            <CustomAutocompleteInput source="family" />
            <CustomAutocompleteInput source="sub_family" />
            <CustomAutocompleteInput source="category" />
            <BooleanInput source="managed" />
            <BooleanInput source="virtual" />
            <BooleanInput source="accessory" />
            <NumberInput source="stock_min_level" />
            <NumberInput source="stock_max_level" />
            <TextInput source="description" options={{ multiline: true }} rows="4" />
        </SimpleForm>
    </Edit>
);

export default DeviceProductEdit;