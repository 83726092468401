import React from 'react';
import { TextField, SimpleShowLayout, ShowButton } from 'react-admin';

import Datagrid from '../common/Datagrid';
import LinkFieldButton from '../common/LinkFieldButton';
import DateField from '../common/fields/DateField';


const StarlinkSubscriptionDrawer = (props) => (
    <SimpleShowLayout {...props}>
        <TextField source="id" />
        <TextField source="subscription_reference_id" />
        <LinkFieldButton label="Starlink account" source="starlink_account" sortBy="starlink_account_id" basePath="/starlink_account">
			<TextField source="account_number" />
		</LinkFieldButton>
        <LinkFieldButton label="Service line number" source="starlink_service_line" sortBy="starlink_service_line_id" basePath="/starlink_service_lines">
			<TextField source="service_line_number" />
		</LinkFieldButton>
        <TextField source="description" />
        <DateField source="start_at" showTime />
        <DateField source="end_at" showTime />
        <DateField source="service_end_at" showTime />
        <DateField source="normalized_start_at" showTime />
        <DateField source="created_at" showTime />
        <DateField source="updated_at" showTime />
    </SimpleShowLayout>
);

const StarlinkSubscriptionRow = ({ children, ...props }) => (
    <Datagrid drawer={<StarlinkSubscriptionDrawer {...props} />} {...props}>
        <LinkFieldButton label="Subscription reference ID" sortBy="subscription_reference_id">
            <TextField source="subscription_reference_id" />
        </LinkFieldButton>
        <TextField source="description" />
        <DateField source="normalized_start_at" />
        <DateField source="end_at" />
        <DateField source="service_end_at" />
        <LinkFieldButton label="Service line number" source="starlink_service_line" sortBy="starlink_service_line_id" basePath="/starlink_service_lines">
			<TextField source="service_line_number" />
		</LinkFieldButton>
        <LinkFieldButton label="Starlink account" source="starlink_account" sortBy="starlink_account_id" basePath="/starlink_account">
			<TextField source="account_number" />
		</LinkFieldButton>
        {children}
        <ShowButton />
    </Datagrid>
);

StarlinkSubscriptionRow.defaultProps = {
    basePath: '/starlink_subscriptions'
};

export default StarlinkSubscriptionRow;