import React, { useState } from 'react';
import { useFormState } from 'react-final-form';
import { Button, SaveButton, useUpdate, useNotify } from 'react-admin';
import IconEdit from '@material-ui/icons/Edit';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { makeStyles } from '@material-ui/core/styles';

import SiteEdit from './SiteEdit';


const useStyles = makeStyles({
	form: {
		overflowY: 'auto',
		'& > div > div': {
			boxShadow: 'none'
		}
	}
});

const CustomToolbar = ({ id, handleClose, ...props }) => {
	const formState = useFormState();
	const [update] = useUpdate('sites', id);
	const notify = useNotify();

	const handleSave = () => {
		update(
			{
				payload: { data: formState.values },
			},
			{
				onSuccess: () => {
					notify('ra.notification.updated', 'info', {
						smart_count: 1,
					});
					handleClose();
				},
			}
		);
	};

	return (
		<DialogActions>
			<SaveButton handleSubmitWithRedirect={handleSave} />
			<Button label="ra.action.cancel" onClick={handleClose}>
				<IconCancel />
			</Button>
		</DialogActions>
	);
};

const SiteQuickEditButton = ({ className, id }) => {
	const [showDialog, setShowDialog] = useState(false);
	const classes = useStyles();
	
	const handleOpenDialog = () => setShowDialog(true);

	const handleCloseDialog = () => setShowDialog(false);

	return (
		<>
			<Button onClick={handleOpenDialog} label="ra.action.edit" className={className}>
				<IconEdit />
			</Button>
			<Dialog fullWidth open={showDialog} onClose={handleCloseDialog} aria-label="Create Site">
				<DialogTitle>Edit Site</DialogTitle>
				<SiteEdit id={id} resource="sites" basePath="/sites" actions={null} toolbar={<CustomToolbar id={id} handleClose={handleCloseDialog} />} className={classes.form} />
			</Dialog>
		</>
	);
};

export default SiteQuickEditButton;