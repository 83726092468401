import { DateField as RADateField } from 'react-admin';

const DateField = RADateField;

DateField.defaultProps = {
	options: {
		timeZone: 'UTC'
	},
	addLabel: true
};

export default DateField;
