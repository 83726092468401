import React from 'react';
import { TextInput, ReferenceInput, AutocompleteInput, BooleanInput } from 'react-admin';

import Edit from '../common/Edit';
import SimpleForm from '../common/SimpleForm';
import SiteDataSourceField from './SiteDataSourceField';

const optionText = choice => choice ? choice.name : '';

const SiteEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="id" disabled />
            <BooleanInput source="is_stock" />
            <TextInput source="name" />
            <ReferenceInput label="Customer" source="customer_id" reference="customers" sort={{ field: 'name', order: 'ASC' }} allowEmpty>
                <AutocompleteInput optionText={optionText} />
            </ReferenceInput>
            <TextInput source="street" />
            <TextInput source="number" />
            <TextInput source="zipcode" />
            <TextInput source="city" />
            <TextInput source="country" />
            <TextInput source="description" label="Notes" options={{ multiline: true }} rows="4" />
            <SiteDataSourceField label="Contract Data Source" />
            <TextInput label="Data Limit Alert (GB)" source="data_limit" />
            <TextInput label="Data Limit Contacts (CSV)" source="data_limit_contacts" options={{ multiline: true }} rows="4"/>
        </SimpleForm>
    </Edit>
);

export default SiteEdit;