import moment from 'moment';

import { Grid } from '@material-ui/core';
import { Link } from 'react-admin';
import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import StarlinkTerminalIcon from '@material-ui/icons/SettingsInputAntenna';

import IconCard from '../common/IconCard';

import { fetchStarlinkDashboardStats } from '../../services/api';

const StarlinkDashboard = () => {
    const [stats, setStats] = useState();

    useEffect(() => {
        const fetchStats = async () => {
            const data = await fetchStarlinkDashboardStats();
            setStats(data);
        }

        fetchStats();
    }, []);

    return (
        <Grid container spacing={2}>
            <Grid container item spacing={2}>
                <Grid item lg={4} sm={6} xl={4} xs={12}>
                    <Link to={`/starlink_terminals`}>
                        <IconCard title="Terminals - Deployed" value={stats?.terminals?.deployed} icon={<StarlinkTerminalIcon />} />
                    </Link>
                </Grid>
                <Grid item lg={4} sm={6} xl={4} xs={12}>
                    <Link to={`/starlink_terminals`}>
                        <IconCard title="Terminals - Monitored" value={stats?.terminals?.monitored} icon={<StarlinkTerminalIcon />} />
                    </Link>
                </Grid>
            </Grid>
            <Grid container item spacing={2}>
                <Grid item lg={4} sm={6} xl={4} xs={12}>
                    <Link to={`/starlink_subscriptions?filter={"active":true}`}>
                        <IconCard title="Subscriptions - Active" value={stats?.subscriptions?.active} icon={<StarlinkTerminalIcon />} />
                    </Link>
                </Grid>
            </Grid>
            <ResponsiveContainer width="50%" height={400}>
                <BarChart
                    data={stats?.subscriptions?.monthly}
                    margin={{ top: 25, right: 0, left: 0, bottom: 25 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" fontFamily="sans-serif" tickSize tickFormatter={(tick) => moment(tick).format("DD/MM/YYYY")} />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar
                        dataKey="value"
                        barSize={170}
                        fontFamily="sans-serif"
                        name="Activated Subscriptions per month"
                        fill="#3A8B7E"
                    />
                </BarChart>
            </ResponsiveContainer>
        </Grid>
    );
};

export default StarlinkDashboard;