import React, { useState, useEffect } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { SaveButton as RASaveButton, AutocompleteInput, useRefresh } from 'react-admin';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import SimpleForm from '../../common/SimpleForm';
import IconButton from '../../common/IconButton';
import Toolbar from './Toolbar';

import { getIcGroups, incontrolMoveDevice } from '../../../services/api';


const SaveButton = ({ onClick, record, ...props }) => {
	const formState = useFormState();
	const form = useForm();

	const handleClick = async () => {
		let formData = new FormData();
		formData.append("target_group_id", formState.values.ic_group_id);
		await incontrolMoveDevice(record.id, formData);
		form.reset();
		onClick();
	};

	return <RASaveButton {...props} handleSubmit={handleClick} handleSubmitWithRedirect={handleClick} />;
};

const IncontrolMoveDeviceButton = ({ record, variant, color }) => {
	const refresh = useRefresh();
	const [showDialog, setShowDialog] = useState(false);
	const [groups, setGroups] = useState([]);

	useEffect(() => {
		const fetchIcGroups = async () => {
			const groups = await getIcGroups(record.ic_server_name, record.ic_organization_id);
			setGroups(groups);
		};

		if(record && record.ic_server_name && record.ic_organization_id) {
			fetchIcGroups();
		}
	}, [record]);

	const handleSave = async () => {
		handleClose();
		refresh();
	};

	const handleOpen = () => {
		setShowDialog(true);
	};

	const handleClose = () => {
		setShowDialog(false);
	};

	if(!record) {
		return null;
	}

	return (
		<>
			<IconButton icon={<SwapHorizIcon />} onClick={handleOpen} variant={variant} color={color}>Incontrol move</IconButton>
			<Dialog fullWidth open={showDialog} onClose={handleClose} aria-label="Move device">
				<DialogTitle>Move device</DialogTitle>
				<DialogContent>
					Are you sure to move the device {record.name} ({record.serial})?
					<SimpleForm toolbar={<Toolbar onClose={handleClose} onSave={handleSave} saveButton={<SaveButton onClick={handleSave} />} />} record={record}>
						<AutocompleteInput choices={groups} source="ic_group_id" />
					</SimpleForm>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default IncontrolMoveDeviceButton;