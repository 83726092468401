import React from 'react';
import ProductItemBulkMoveButton from './ProductItemBulkMoveButton';
import ProductItemBulkArchiveButton from './ProductItemBulkArchiveButton';

const ProductItemBulkActions = (props) => (
    <>
        <ProductItemBulkMoveButton {...props} />
        <ProductItemBulkArchiveButton {...props} />
    </>
);

export default ProductItemBulkActions;