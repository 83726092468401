import React from 'react';
import { TabbedShowLayout, Tab, TextField, ReferenceManyField, Pagination } from 'react-admin';
import { Helmet } from 'react-helmet';

import Show from '../common/Show';
import CustomerReferenceField from '../customers/CustomerReferenceField';
import InvoiceRow from '../invoices/InvoiceRow';
import PaymentMethodField from './fields/PaymentMethodField';


const AddressTitle = ({ record }) => (
	<>
		<Helmet>
			<title>{`KB - Address[${record.name}]`}</title>
		</Helmet>
		<span>{record.name}</span>
	</>
);

const AddressShow = props => (
	<Show title={<AddressTitle />} {...props}>
		<TabbedShowLayout>
			<Tab label="summary">
				<TextField source="accounting_code" />
				<TextField source="name" />
				<TextField source="vat" />
				<CustomerReferenceField />
				<TextField source="street" />
				<TextField source="street_bis" />
				<TextField source="number" />
				<TextField source="box" />
				<TextField source="zipcode" />
				<TextField source="city" />
				<TextField source="country" />
				<TextField source="email" />
				<TextField source="phone" />
				<TextField source="payment_terms" />
				<PaymentMethodField />
			</Tab>
			<Tab label="Invoices" path="invoices">
				<ReferenceManyField addLabel={false} reference="invoices" target="invoicing_address_id" pagination={<Pagination />} filter={{ archived: false }}>
					<InvoiceRow />
				</ReferenceManyField>
			</Tab>
		</TabbedShowLayout>
	</Show>
);

export default AddressShow;