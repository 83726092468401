import React from 'react';
import {
    DateInput,
    PasswordInput,
    TextInput,
    CheckboxGroupInput,
    FormTab
} from 'react-admin';

import Edit from '../common/Edit';
import TabbedForm from '../common/TabbedForm';

const OrganizationsCheckboxGroupInput = ({ record, ...props }) => (
    record.available_organizations && (
        <CheckboxGroupInput choices={record.available_organizations} row={false} {...props} />
    )
);

const ICServerEdit = props => (
    <Edit {...props}>
        <TabbedForm>
            <FormTab label="Summary">
                <TextInput source="id" disabled />
                <TextInput source="name" disabled />
                <TextInput source="description" options={{ multiline: true }} rows="4" />
                <TextInput label="Host" source="hostname" />
                <TextInput label="API Host" source="api_hostname" />
                <TextInput label="Version" source="version" />
                <DateInput label="Expiration Date" source="expiration_date" />
                <TextInput label="API Key" source="api_key" />
                <PasswordInput label="API Secret" source="api_secret" />
            </FormTab>
            <FormTab label="Organizations">
                <OrganizationsCheckboxGroupInput source="organizations" label="" />
            </FormTab>
        </TabbedForm>
    </Edit>
);

export default ICServerEdit;