import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, Legend, ResponsiveContainer } from 'recharts';

const InvoiceSimOnlyReportGraph =  ({ data, small }) => {
	return (
		<ResponsiveContainer width="100%" height={600}>
			<BarChart
				data={data}
				margin={{
					top: 50, right: 30, left: 20, bottom: 5,
				}}
				maxBarSize={100}
			>
				<CartesianGrid strokeDasharray="3 3" />
				<XAxis dataKey="date" />
				<YAxis dataKey="value" />
				<Legend />
				<Tooltip />
				<Bar name="SimOnly Total Income Tax Excl." dataKey="value" fill="#3A8B7E" />
			</BarChart>
		</ResponsiveContainer>
	);
};

export default InvoiceSimOnlyReportGraph;
