import React from 'react';
import { TextInput, BooleanInput } from 'react-admin';

import Create from '../common/Create';
import CustomAutocompleteInput from '../common/inputs/CustomAutocompleteInput';
import SimpleForm from '../common/SimpleForm';

const DeviceProductCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <TextInput source="name" />
            <CustomAutocompleteInput source="brand" />
            <TextInput source="reference" />
            <CustomAutocompleteInput source="family" />
            <CustomAutocompleteInput source="sub_family" />
            <CustomAutocompleteInput source="category" />
            <BooleanInput source="managed" />
            <BooleanInput source="virtual" />
            <BooleanInput source="accessory" />
            <TextInput source="description" options={{ multiline: true }} rows="4" />
        </SimpleForm>
    </Create>
);

export default DeviceProductCreate;
