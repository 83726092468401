import React from 'react';
import { TextInput } from 'react-admin';

import List from '../common/List';
import Filter from '../common/Filter';
import StarlinkUsageRow from './StarlinkUsageRow';


const StarlinkUsageFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
    </Filter>
);

const StarlinkUsageList = (props) => (
    <List {...props} filters={<StarlinkUsageFilter />}>
        <StarlinkUsageRow />
    </List>
);

export default StarlinkUsageList;