import React, { useState } from 'react';
import { Confirm, useRefresh, useNotify } from 'react-admin';
import IconRemove from '@material-ui/icons/RemoveCircleOutline';

import IconButton from '../../common/IconButton';

import { removeFromSimPool } from '../../../services/api';

const RemoveFromPoolButton = ({ record, variant, color }) => {
	const notify = useNotify();
	const refresh = useRefresh();
	const [showDialog, setShowDialog] = useState(false);

	const handleConfirm = async () => {
		const response = await removeFromSimPool(record.pool_id, record.id);
		handleClose();
		refresh();
		notify(response.reason, response.success ? 'info' : 'warning');
	};

	const handleOpen = () => {
		setShowDialog(true);
	};

	const handleClose = () => {
		setShowDialog(false);
	};

	if(record && record.pool_id) {
		return (
			<>
				<IconButton icon={<IconRemove />} onClick={handleOpen} variant={variant} color={color}>Remove from pool</IconButton>
				<Confirm
					isOpen={showDialog}
					title="Remove from SIM pool"
					content={`Are you sure to remove the SIM ${record.iccid} from the SIM pool?`}
					onConfirm={handleConfirm}
					onClose={handleClose}
				/>
			</>
		);
	}

	return null;
};

export default RemoveFromPoolButton;