import React from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';

import IconButton from '../../common/IconButton';

import { API_URL } from '../../../services/settings';

const ExportPDFButton = ({ record }) => (
    <IconButton icon={<GetAppIcon />} component="a" target="__blank" href={`${API_URL}/invoices/${record?.id}/invoice${record?.number ? `_${record.number}` : ''}.pdf`}>Export</IconButton>
);

export default ExportPDFButton;