import React from 'react';
import { useFormState, useForm } from 'react-final-form';
import { Title, TextInput, ReferenceInput, AutocompleteInput, Toolbar, SaveButton, FormDataConsumer, useNotify, required } from 'react-admin';
import Card from '@material-ui/core/Card';

import devices from './';
import { CreateActions } from '../common/Create';
import SimpleForm from '../common/SimpleForm';
import SiteReferenceInputWithCreate from '../sites/SiteReferenceInputWithCreate';
import DeployReasonInput from './DeployReasonInput';
import DeviceRentSoldField from '../devices/fields/DeviceRentSoldField';

import { bulkMoveDevice } from '../../services/api';


const optionText = choice => choice && choice.name ? choice.name : '';

const CustomSaveButton = ({ onClick, ...props }) => {
	const formState = useFormState();
	const form = useForm();
	const notify = useNotify();

	const handleClick = async () => {
		if (formState.values.device_serials) {
			const formData = new FormData();
			formData.append('device_serials', formState.values.device_serials)
			if (formState.values.site_id) {
				formData.append('site_id', formState.values.site_id);
			}
			if (formState.values.reason) {
				formData.append('reason', formState.values.reason);
			}
			if (formState.values.rent_sold) {
				formData.append('rent_sold', formState.values.rent_sold);
			}

			const response = await bulkMoveDevice(formData);
			if (!response.success && response.reason) {
				notify(response.reason, 'warning');
			}
			else {
				notify('Devices moved');
				form.change('site_id', null);
				form.change('device_serials', null);
				form.change('reason', null);
				form.change('rent_sold', null);
			}
		}
	};

	return <SaveButton {...props} handleSubmit={handleClick} handleSubmitWithRedirect={handleClick} />;
};

const CustomToolbar = ({ onSave, ...props }) => (
	<Toolbar {...props}>
		<CustomSaveButton onClick={onSave} />
	</Toolbar>
);

const ProductItemBulkMove = () => {
	const breadcrumbPath = [
		{ url: '/devices', title: "Devices", icon: <devices.icon /> },
		{
			url: '/devices/bulk-move',
			title: "Bulk move",
			isActive: true
		}
	];

	return (
		<>
			<CreateActions basePath="/devices" hasList={true} breadcrumb={breadcrumbPath} />
			<Card>
				<Title title="Move Devices" />
				<SimpleForm resource="devices" toolbar={<CustomToolbar />}>
					<ReferenceInput label="Customer" source="customer_id" reference="customers" sort={{ field: 'name', order: 'ASC' }}>
						<AutocompleteInput optionText={optionText} />
					</ReferenceInput>
					<FormDataConsumer>
						{({ formData, ...rest }) => (
							<SiteReferenceInputWithCreate
								{...rest}
								label="Site"
								source="site_id"
								reference="sites"
								filter={formData.customer_id && { customer_id: formData.customer_id }}
								validate={required()}
								key={formData.customer_id}
							/>
						)}
					</FormDataConsumer>
					<DeployReasonInput source="reason" />
					<DeviceRentSoldField source="rent_sold" label="VENN Billing Mode" />
					<TextInput source="device_serials" label="Serial numbers" rows="20" multiline fullWidth />
				</SimpleForm>
			</Card>
		</>
	);
};

export default ProductItemBulkMove;