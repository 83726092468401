import React, { useState } from 'react';
import { Confirm, useRefresh, useNotify } from 'react-admin';
import ReplayIcon from '@material-ui/icons/Replay';

import IconButton from '../../common/IconButton';

import { syncDevice } from '../../../services/api';


const SyncDeviceButton = ({ record, variant, color }) => {
	const refresh = useRefresh();
	const notify = useNotify();
	const [showDialog, setShowDialog] = useState(false);

	const handleConfirm = async () => {
		const response = await syncDevice(record.id);
		if (response) {
			notify(response.message || response.reason);
		}
		refresh();
		handleClose();
	};

	const handleOpen = () => {
		setShowDialog(true);
	};

	const handleClose = () => {
		setShowDialog(false);
	};

	if(!record) {
		return null;
	}

	return (
		<>
			<IconButton icon={<ReplayIcon />} onClick={handleOpen} variant={variant} color={color}>Sync</IconButton>
			<Confirm
				isOpen={showDialog}
				title="Sync"
				content={`Do you want to sync the device ${record.name} (${record.serial})`}
				onConfirm={handleConfirm}
				onClose={handleClose}
			/>
		</>
	);
};

export default SyncDeviceButton;
