import React from 'react';
import {
    BooleanInput,
    TextInput,
    ReferenceInput,
    AutocompleteInput,
} from 'react-admin';

import { Link } from 'react-router-dom';
import IconAdd from '@material-ui/icons/Add';

import GetAppIcon from '@material-ui/icons/GetApp';

import { API_URL } from '../../services/settings';

import List, { ListActions } from '../common/List';
import Filter from '../common/Filter';
import IconButton from '../common/IconButton';
import VlanInput from './IPVlan';
import FireWallInput from './IPFirewall';

import IPRow from './IPRow';


const IPFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <ReferenceInput label="Customer" source="customer_id" reference="customers" sort={{ field: 'name', order: 'ASC' }} alwaysOn>
            <AutocompleteInput />
        </ReferenceInput>
        <BooleanInput source="available" alwaysOn />
        <VlanInput alwaysOn />
		<FireWallInput alwaysOn />
    </Filter>
);

const filterDefaultValues = {
    available: true,
}

const IPActions = (props) => (
    <ListActions {...props}>
        <IconButton component={Link} to={`/ips/bulk-create`} icon={<IconAdd />}>Bulk create</IconButton>
        <IconButton icon={<GetAppIcon />} component="a" href={`${API_URL}/ips/export.xls`}>Export</IconButton>
    </ListActions>
);

const IPList = ({ classes, ...props }) => (
    <List {...props} filters={<IPFilter />} actions={<IPActions />} exporter={false} filterDefaultValues={filterDefaultValues}>
        <IPRow />
    </List>
);

export default IPList;