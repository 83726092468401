import moment from 'moment';

import React, { useState, useEffect } from 'react';
import { Link } from 'react-admin';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Grid } from '@material-ui/core';

import BusinessIcon from '@material-ui/icons/Phone';

import IconCard from '../common/IconCard';

import { fetchBusinessDashboardStats } from '../../services/api';

const BusinessDashboard = () => {
    const [stats, setStats] = useState();

    useEffect(() => {
        const fetchStats = async () => {
            const data = await fetchBusinessDashboardStats();
            setStats(data);
        }

        fetchStats();
    }, []);

    return (
        <Grid container spacing={2}>
            <Grid container item spacing={2}>
                <Grid item lg={3} sm={6} xl={3} xs={12}>
                    <Link to={`/services?filter={"status": "provisioning"}`}>
                        <IconCard title="Services - To Provision" value={stats?.services?.provisioning} icon={<BusinessIcon />} warning={stats?.services?.provisioning > 0 ? true : false} />
                    </Link>
                </Grid>
                <Grid item lg={3} sm={6} xl={3} xs={12}>
                    <Link to={`/services?filter={"to_invoice": true}`}>
                        <IconCard title="Services - To Invoice" value={stats?.services?.to_invoice} icon={<BusinessIcon />} warning={stats?.services?.to_invoice > 0 ? true : false} />
                    </Link>
                </Grid>
                <Grid item lg={3} sm={6} xl={3} xs={12}>
                    <Link to={`/services?filter={"status": "idle"}`}>
                        <IconCard title="Services - IDLE" value={stats?.services?.idle} icon={<BusinessIcon />} warning={stats?.services?.idle > 0 ? true : false} />
                    </Link>
                </Grid>
                <Grid item lg={3} sm={6} xl={3} xs={12}>
                    <Link to={`/services?filter={"not_invoiced": true}`}>
                        <IconCard title="Services - Not Invoiced" value={stats?.services?.not_invoiced} icon={<BusinessIcon />} warning={stats?.services?.not_invoiced > 0 ? true : false} />
                    </Link>
                </Grid>
            </Grid>
            <Grid container item spacing={2}>
                <Grid item lg={3} sm={6} xl={4} xs={12}>
                    <Link to={`/packages?filter={"status": "draft"}`}>
                        <IconCard title="Packages - Draft" value={stats?.packages?.draft} icon={<BusinessIcon />} warning={stats?.packages?.draft > 0 ? true : false} />
                    </Link>
                </Grid>
                <Grid item lg={3} sm={6} xl={4} xs={12}>
                    <Link to={`/packages?filter={"status": "provisioning"}`}>
                        <IconCard title="Packages - To Provision" value={stats?.packages?.provisioning} icon={<BusinessIcon />} warning={stats?.packages?.provisioning > 0 ? true : false} />
                    </Link>
                </Grid>
                <Grid item lg={3} sm={6} xl={4} xs={12}>
                    <Link to={`/packages?filter={"not_invoiced": true}`}>
                        <IconCard title="Packages - Not Invoiced" value={stats?.packages?.not_invoiced} icon={<BusinessIcon />} warning={stats?.packages?.not_invoiced > 0 ? true : false} />
                    </Link>
                </Grid>
            </Grid>
            <Grid container item spacing={2}>
                <Grid item lg={4} sm={6} xl={4} xs={12}>
                    <Link to={`/package_lines?filter={"not_invoiced": "true"}`}>
                        <IconCard title="Hardware - To Invoice" value={stats?.package_lines?.to_invoice} icon={<BusinessIcon />} warning={stats?.package_lines?.to_invoice > 0 ? true : false} />
                    </Link>
                </Grid>
            </Grid>
            <ResponsiveContainer width="50%" height={400}>
                <BarChart
                    data={stats?.services?.monthly}
                    margin={{ top: 25, right: 0, left: 0, bottom: 25 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" fontFamily="sans-serif" tickSize tickFormatter={(tick) => moment(tick).format("DD/MM/YYYY")} />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar
                        dataKey="value"
                        barSize={170}
                        fontFamily="sans-serif"
                        name="Started Services per month"
                        fill="#3A8B7E"
                    />
                </BarChart>
            </ResponsiveContainer>
        </Grid>
    );
};

export default BusinessDashboard;
