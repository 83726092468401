import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import InvoiceIcon from '@material-ui/icons/Description';

import { Link } from 'react-admin';
import { fetchAccountingDashboardStats, fetchInvoicesReport, fetchInvoicesSimOnlyReport } from '../../services/api';

import IconCard from '../common/IconCard';

import InvoiceReportGraph from '../invoices/InvoiceReportGraph';
import InvoiceSimOnlyReportGraph from '../invoices/InvoiceSimOnlyReportGraph';
import InvoiceReportPieGraph from '../invoices/InvoiceReportPieGraph';


const AccountingDashboard = () => {
    // TODO : To refactor 
    const [stats, setStats] = useState();
    const [invoiceReportStats, setInvoiceReportStats] = useState();
    const [simOnlyReportStats, setSimOnlyReportStats] = useState();

    useEffect(() => {
        const fetchStats = async () => {
            const data = await fetchInvoicesReport({});
            setInvoiceReportStats(data.data);
        }

        fetchStats();
    }, []);

    useEffect(() => {
        const fetchStats = async () => {
            const simOnlyData = await fetchInvoicesSimOnlyReport();
            setSimOnlyReportStats(simOnlyData.data);
        }

        fetchStats();
    }, []);

    useEffect(() => {
        const fetchStats = async () => {
            const data = await fetchAccountingDashboardStats();
            setStats(data);
        }

        fetchStats();
    }, []);

    return (
        <Grid container spacing={2}>
            <Grid container item spacing={2}>
                <Grid item lg={3} sm={6} xl={3} xs={12}>
                    <Link to={`/invoices`}>
                        <IconCard title="Total" value={stats?.invoices?.total || 0} icon={<InvoiceIcon />} />
                    </Link>
                </Grid>
                <Grid item lg={3} sm={6} xl={3} xs={12}>
                    <Link to={`/invoices?filter={"status": 'draft'}`}>
                        <IconCard title="Draft" value={stats?.invoices_by_status?.draft || 0} icon={<InvoiceIcon />} warning={stats?.invoices_by_status?.draft > 0 ? true : false} />
                    </Link>
                </Grid>
                <Grid item lg={3} sm={6} xl={3} xs={12}>
                    <Link to={`/invoices?filter={"status": 'sent'}`}>
                        <IconCard title="Sent" value={stats?.invoices_by_status?.sent || 0} icon={<InvoiceIcon />} />
                    </Link>
                </Grid>
                <Grid item lg={3} sm={6} xl={3} xs={12}>
                    <Link to={`/invoices?filter={"status": 'paid'}`}>
                        <IconCard title="Paid" value={stats?.invoices_by_status?.paid || 0} icon={<InvoiceIcon />} />
                    </Link>
                </Grid>
            </Grid>
            <Grid container item spacing={2}>
                <InvoiceReportGraph data={invoiceReportStats} />
                <InvoiceSimOnlyReportGraph data={simOnlyReportStats} />
                <InvoiceReportPieGraph group_by='customer' />
                <InvoiceReportPieGraph group_by='company' />
                <InvoiceReportPieGraph group_by='currency' />
            </Grid>
        </Grid>
    );
};

export default AccountingDashboard;