import React, { useState } from 'react';
import { useInput, FieldTitle } from 'react-admin';
import { SketchPicker } from 'react-color';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
	container: {
		display: 'flex',
		alignItems: 'center',
        marginBottom: '24px',
        marginTop: '8px'
	},
	swatch: {
		padding: '5px',
		background: '#fff',
		borderRadius: '1px',
		boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
		display: 'inline-block',
		cursor: 'pointer',
		marginLeft: '15px'
	},
	color: ({ color }) => {
		if(color) {
			return {
				backgroundColor: color,
				width: '36px',
				height: '14px',
				borderRadius: '2px',
			}
		}
	},
	popover: {
		position: 'absolute',
		zIndex: '2',
	},
	cover: {
		position: 'fixed',
		top: '0px',
		right: '0px',
		bottom: '0px',
		left: '0px',
	},
});


const ColorInput = ({ label, variant, className, ...props }) => {
	const [show, setShow] = useState(false);
	const { input, meta: { touched, error }, isRequired } = useInput(props);
	const classes = useStyles({ color: input.value });
	const { source, resource } = props;

	const handleChange = ({ hex }) => {
		input.onChange(hex);
	};

	const handleClick = () => {
		setShow(currentShow => !currentShow)
	};

	const handleClose = () => {
		setShow(false);
	};

	return (
		<>
			<div className={classes.container}>
				<TextField
					{...input}
					name={props.source}
					label={
						<FieldTitle
							label={label}
							source={source}
							resource={resource}
							isRequired={isRequired}
						/>
					}

					onChange={input.onChange}
					onFocus={handleClick}
					error={!!(touched && error)}
					helperText={touched && error}
					required={isRequired}
					variant={variant}
					className={className}
					size="small"
				/>
                {input.value && (
                    <div className={classes.swatch} onClick={handleClick}>
                        <div className={classes.color} />
                    </div>
                )}
			</div>
			{show && (
				<div className={classes.popover}>
					<div className={classes.cover} onClick={handleClose} />
					<SketchPicker
						color={input.value}
						onChange={handleChange}
					/>
				</div>
			)}
		</>
	);
};

export default ColorInput;