import React from 'react';
import { ReferenceField, TextField, DateField, SimpleShowLayout } from 'react-admin';

import Datagrid from '../common/Datagrid';
import LinkFieldButton from '../common/LinkFieldButton';
import RecordSplitButton from '../common/RecordSplitButton';
import BooleanField from '../common/fields/BooleanField';
import CustomerReferenceField from '../customers/CustomerReferenceField';
import BackToStockButton from './ProductItemActionsButtons/BackToStockButton';
import ProductItemArchiveButton from './ProductItemActionsButtons/ProductItemArchiveButton';


const ProductItemDrawer = (props) => (
	<SimpleShowLayout {...props}>
		<TextField source="id" />
		<TextField source="serial" />
		<TextField source="buy_sm" label="Supplier Billing Mode" />
		<TextField source="rent_sold" label="VENN Billing Mode" />
		<CustomerReferenceField source="customer_id" />
		<ReferenceField label="Product ref" source="product_id" reference="device_products" link="show" allowEmpty>
			<TextField source="reference" />
		</ReferenceField>
		<TextField source="product.name" label="Product name" />
		<TextField source="product.brand" label="Brand" />
		<TextField source="product.family" label="Family" />
		<TextField source="product.sub_family" label="Sub Family" />
		<TextField source="product.category" label="Category" />
		<BooleanField source="product.managed" label="Managed" />
		<BooleanField source="product.virtual" label="Virtual" />
		<BooleanField source="product.accessory" label="Accessory" />
		<TextField source="product.description" label="Product Description" />
		<TextField source="notes" />
		<DateField source="sent_date" />
		<DateField source="created_at" />
		<DateField source="updated_at" />
		<DateField source="archived_date" />
		<TextField label="Archived notes" />
	</SimpleShowLayout>
);

const ReservedField = ({ record, ...props }) => {
	let options = {};
	if (record.notes) {
		options = {
			valueLabelTrue: record.notes
		};
	}

	return <BooleanField record={record} {...props} {...options} />;
};

const ProductItemRow = ({ children, ...props }) => (
	<Datagrid {...props} drawer={<ProductItemDrawer {...props} />}>
		<LinkFieldButton label="Serial" sortBy="serial" basePath="/product_items">
			<TextField source="serial" />
		</LinkFieldButton>
		<LinkFieldButton label="Customer" source="customer" sortBy="customer_id" basePath="/customers">
			<TextField source="name" />
		</LinkFieldButton>
		<LinkFieldButton label="Site" source="site" sortBy="site_id" basePath="/sites">
			<TextField source="name" />
		</LinkFieldButton>
		<LinkFieldButton label="Product" source="product" sortBy="product_id" basePath="/device_products">
			<TextField source="reference" />
		</LinkFieldButton>
		<TextField source="rent_sold" label="VENN Billing Mode" />
		<ReservedField source="reserved" />
		<BooleanField label="New" source="is_new" />
		{children}
		<RecordSplitButton archiveButton={<ProductItemArchiveButton />}>
			<BackToStockButton />
		</RecordSplitButton>
	</Datagrid>
);

ProductItemRow.defaultProps = {
	basePath: '/product_items'
};

export default ProductItemRow;