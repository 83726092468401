import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core';


import Tooltip from '../common/Tooltip';
import StatusIcon from './StatusIcon';


const styles = {
	btn: {
		padding: '2px',
		minWidth: 'auto'
	},
	chip: {
		height: '20px'
	},
	divider: {
		margin: '5px 0'
	},
	tooltip: {
		maxWidth: 'none'
	}
};


const LineTooltip = ({ classes, record, source, icon, ...props }) => {
	if(!record) {
		return null;
	}

	return (
		<Tooltip
			classes={{ tooltip: classes.tooltip }}
			title={
				<React.Fragment>
					<Typography variant="subtitle1">{record.subscription_id}</Typography>
					<Typography>Status: {record.status}</Typography>
					<Typography>Provider: {record.provider_name}</Typography>
					{record.line_type && (<Typography>Type: {record.line_type}</Typography>)}
				</React.Fragment>
			}
		>
			<Button size="small" className={classes.btn}>
				{icon ? icon : <StatusIcon record={record} />}
			</Button>
		</Tooltip>
	);
};

export default withStyles(styles)(LineTooltip);
