import React from 'react';
import { TabbedShowLayout, Tab, TextField, ReferenceField, ReferenceArrayField, ReferenceManyField, Pagination, UrlField } from 'react-admin';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconAdd from '@material-ui/icons/Add';

import Show, { ShowActions } from '../common/Show';
import IconButton from '../common/IconButton';
import DateField from '../common/fields/DateField';
import ServiceGrid from '../services/ServiceGrid';
import StatusField from './StatusField';
import InvoiceRow from '../invoices/InvoiceRow';
import PackagePreview from './PackagePreview';
import FilteredLogList from '../logs/FilteredLogList';
import PackageLineGrid from '../package_lines/PackageLineGrid';
import PackageConfirmButton from './PackageConfirmButton';
import PackageInvoiceButton from './PackageInvoiceButton';
import PackageCloneButton from './PackageCloneButton';

const PackageTitle = ({ record }) => (
	<>
		<Helmet>
			<title>{`KB - Package[${record.reference}]`}</title>
		</Helmet>
		<span>{record.reference}</span>
	</>
);

const ServiceList = ({ record }) => {
	let list = [];

	if (record.services_by_type?.sdwan) {
		list.push(
			<Box mb={5} key="services_sdwan">
				<Typography variant="h6">SDWan</Typography>
				<ReferenceArrayField addLabel={false} reference="services" record={record} source="services_by_type.sdwan">
					<ServiceGrid />
				</ReferenceArrayField>
			</Box>
		);
	}
	if (record.services_by_type?.sdip) {
		list.push(
			<Box mb={5} key="services_sdip">
				<Typography variant="h6">SDIP</Typography>
				<ReferenceArrayField addLabel={false} reference="services" record={record} source="services_by_type.sdip">
					<ServiceGrid />
				</ReferenceArrayField>
			</Box>
		);
	}
	if (record.services_by_type?.data) {
		list.push(
			<Box mb={5} key="services_data">
				<Typography variant="h6">Data</Typography>
				<ReferenceArrayField addLabel={false} reference="services" record={record} source="services_by_type.data">
					<ServiceGrid />
				</ReferenceArrayField>
			</Box>
		);
	}
	if (record.services_by_type?.line) {
		list.push(
			<Box mb={5} key="services_line">
				<Typography variant="h6">Line</Typography>
				<ReferenceArrayField addLabel={false} reference="services" record={record} source="services_by_type.line">
					<ServiceGrid />
				</ReferenceArrayField>
			</Box>
		);
	}
	if (record.services_by_type?.starlink) {
		list.push(
			<Box mb={5} key="services_starlink">
				<Typography variant="h6">StarLink</Typography>
				<ReferenceArrayField addLabel={false} reference="services" record={record} source="services_by_type.starlink">
					<ServiceGrid />
				</ReferenceArrayField>
			</Box>
		);
	}
	if (record.services_by_type?.custom) {
		list.push(
			<Box mb={5} key="services_custom">
				<Typography variant="h6">Custom</Typography>
				<ReferenceArrayField addLabel={false} reference="services" record={record} source="services_by_type.custom">
					<ServiceGrid />
				</ReferenceArrayField>
			</Box>
		);
	}
	if (record.services_by_frequency?.onetime) {
		list.push(
			<Box mb={5} key="services_custom">
				<Typography variant="h6">OneTime</Typography>
				<ReferenceArrayField addLabel={false} reference="services" record={record} source="services_by_frequency.onetime">
					<ServiceGrid />
				</ReferenceArrayField>
			</Box>
		);
	}
	return (
		<Box mt={5}>
			{list}
		</Box>
	);
};

const PackageActions = (props) => (
	<ShowActions {...props}>
		<IconButton
			component={Link}
			to={{
				pathname: '/services/create',
				state: {
					record: {
						package_id: props.data?.id ? parseInt(props.data.id) : '',
						site_id: props.data?.site_id ? parseInt(props.data.site_id) : ''
					},
					_scrollToTop: true
				},
			}}
			icon={<IconAdd />}
		>
			Add service
		</IconButton>
		<IconButton
			component={Link}
			to={{
				pathname: '/package_lines/create',
				state: {
					record: {
						package_id: props.data?.id ? parseInt(props.data.id) : '',
					},
					_scrollToTop: true
				},
			}}
			icon={<IconAdd />}
		>
			Add Sales
		</IconButton>
		<PackageConfirmButton record={props.data} />
		<PackageInvoiceButton record={props.data} />
		<PackageCloneButton record={props.data} redirect />
	</ShowActions>
);

const PackageShow = (props) => (
	<Show title={<PackageTitle />} actions={<PackageActions />} {...props}>
		<TabbedShowLayout>
			<Tab label="summary">
				<TextField source="reference" label="Package ID Reference" />
				<TextField source="so_number" label="SO Number" />
				<ReferenceField label="Customer" source="customer_id" reference="customers" link="show" allowEmpty>
					<TextField source="name" />
				</ReferenceField>
				<TextField source="customer_reference" component="pre" />
				<ReferenceField label="Site" source="site_id" reference="sites" link="show" allowEmpty>
					<TextField source="name" />
				</ReferenceField>
				<ReferenceField label="Billing Address" source="invoicing_address_id" reference="addresses" link="show" allowEmpty>
					<TextField source="name" />
				</ReferenceField>
				<StatusField />
				<ReferenceField label="Billing company" source="billing_company_id" reference="companies" link="show" allowEmpty>
					<TextField source="name" />
				</ReferenceField>
				<TextField source="invoicing_delivery_method" />
				<TextField source="invoice_regroupment_reference" />
				<TextField source="default_vat_code" label="Forced VAT" />
				<ReferenceField label="Data Pool" source="data_pool_id" reference="data_pools" link="show" allowEmpty>
					<TextField source="reference" />
				</ReferenceField>
				<DateField source="start_date" />
				<DateField source="end_date" />
				<TextField label="Notes" source="notes" component="pre" />
				<TextField label="Shipping Address" source="shipping_address" component="pre" />
				<DateField label="Desired Shipment Date" source="desired_date" />
				<UrlField label="Freshdesk URL" source="support_url" />
				<DateField label="Created at" source="created_at" />
				<DateField label="Updated at" source="updated_at" />
				<DateField label="Archived at" source="archived_at" />
				<ServiceList />
				<Typography variant="h5">Sales Lines</Typography>
				<ReferenceManyField addLabel={false} reference="package_lines" target="package_id" pagination={<Pagination />} filter={{ archived: false }}>
					<PackageLineGrid exclude={['package']} />
				</ReferenceManyField>
			</Tab>
			<Tab label="Invoices" path="invoices">
				<ReferenceManyField addLabel={false} reference="invoices" target="package_id" pagination={<Pagination />} filter={{ archived: false }}>
					<InvoiceRow exclude={['package']} />
				</ReferenceManyField>
			</Tab>
			<Tab label="Preview" path="preview">
				<PackagePreview />
			</Tab>
			<Tab label="Logs">
				<FilteredLogList />
			</Tab>
		</TabbedShowLayout>
	</Show>
);

export default PackageShow;
