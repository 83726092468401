import React from 'react';
import { TextField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    circle: {
        display: 'inline-block',
        width: '7px',
        height: '7px',
        borderRadius: '12px',
        backgroundColor: (props) => props.backgroundColor ? props.backgroundColor : '#B3B3B3',
        marginRight: '5px'
    }
});

const statuses = {
    'online': 'ONLINE',
    'offline': 'OFFLINE',
    'unknown': 'UNKNOWN'
};

const StateField = ({ record, source, ...props }) => {
    let backgroundColor;
    const status = record ? record[source] : null;

    switch (status) {
        case 'online':
            backgroundColor = '#80F563';
            break;
        case 'offline':
            backgroundColor = '#FF8686';
            break;
        default:
            backgroundColor = '#B3B3B3';
            break;
    }
    const classes = useStyles({ backgroundColor });

    if (!record) {
        return null;
    }

    return (
        <span className={classes.container}>
            <span className={classes.circle} />
            <TextField source={status} record={statuses} {...props} />
        </span>
    );
};

StateField.defaultProps = {
    source: 'state',
    label: 'State',
    addLabel: true
};

export default StateField;
