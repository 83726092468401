import React from 'react';
import { useListContext } from 'react-admin';
import Grid from '@material-ui/core/Grid';

import PackageLineCard from './PackageLineCard';

const PackageLineGrid = () => {
    const { ids, data, basePath } = useListContext();

    return (
        <Grid container spacing={3}>
            {ids.map(id => (
                <Grid item xs={12} sm={12} md={6} lg={4} key={`package_line_${id}`}>
                    <PackageLineCard record={data[id]} basePath={basePath} />
                </Grid>
            ))}
        </Grid>
    );
};

export default PackageLineGrid;