import React, { useState } from 'react';
import { useRefresh } from 'react-admin';
import IconRefresh from '@material-ui/icons/Refresh';
import CircularProgress from '@material-ui/core/CircularProgress';

import IconButton from '../../common/IconButton';

import { refreshOrganizationsServer } from '../../../services/api';


const ICServerRefreshOrganizationsButton = ({ record, variant, color }) => {
	const [loading, setLoading] = useState(false);
	const refresh = useRefresh();

	const handleRefresh = async () => {
		setLoading(true);
		const response = await refreshOrganizationsServer(record.id);
		if (response) {
			refresh();
		}
		setLoading(false);
	};

	return(
		<IconButton 
			icon={loading ? (
				<CircularProgress
					size={12}
					thickness={2} 
				/>
			) : (
				<IconRefresh />
			)}
			onClick={handleRefresh}
			disabled={loading}
			variant={variant}
			color={color}
		>
			Refresh organizations
		</IconButton>
	);
};

export default ICServerRefreshOrganizationsButton;