import React from 'react';
import { TabbedShowLayout, Tab, TextField, ReferenceField } from 'react-admin';
import { Helmet } from 'react-helmet';

import Show, { ShowActions } from '../common/Show';
import DateField from '../common/fields/DateField';

const TaskTitle = ({ record }) => (
    <>
        <Helmet>
            <title>{`KB - Task[${record.id}]`}</title>
        </Helmet>
        <span>{`Task #${record.id}`}</span>
    </>
);

const TaskActions = (props) => (
	<ShowActions {...props}>
	</ShowActions>
);

const TaskShow = (props) => (
    <Show title={<TaskTitle />} actions={<TaskActions />} {...props}>
        <TabbedShowLayout>
            <Tab label="summary">
                <TextField label="Id" source="id" />
                <ReferenceField label="Customer" source="customer_id" reference="customers" allowEmpty>
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField label="Package" source="package_id" reference="packages" sortable={false}>
			        <TextField source="reference" />
		        </ReferenceField>
		        <ReferenceField label="Site" source="site_id" reference="sites"  sortable={false}>
			        <TextField source="name" />
		        </ReferenceField>
                <TextField label="SO" source="sales_order_number" />
                <TextField source="action"/>
		        <TextField source="status"/>
                <TextField source="priority"/>
		        <ReferenceField label="Assigned" reference="users" source="assigned_to_id" >
			        <TextField source="email" />
		        </ReferenceField>
		        <DateField source="due_date" />
                <DateField label="Updated at" source="updated_at" />
                <TextField label="Updated by" source="last_updated_by" />
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export default TaskShow;
