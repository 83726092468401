import React from 'react';
import { useTranslate } from 'react-admin';
import { Link } from 'react-router-dom';
import IconReturn from '@material-ui/icons/KeyboardReturn';

import IconButton from '../../common/IconButton';


const PackageLineDeliverButton = ({ record, variant, color }) => {
    const translate = useTranslate();

    if (record.status !== 'SHIPPED') {
        return null;
    }

    return (
        <IconButton component={Link} to={`/package_lines/${record.id}/deliver`} icon={<IconReturn />} variant={variant} color={color}>{translate('Deliver')}</IconButton>
    );
};

export default PackageLineDeliverButton;