import React, { useState } from 'react';
import { Confirm, useRefresh, useNotify } from 'react-admin';
import RefreshIcon from '@material-ui/icons/Refresh';

import IconButton from '../common/IconButton';

import { invoicePackage } from '../../services/api';


const PackageInvoiceButton = ({ record, variant, color }) => {
    const refresh = useRefresh();
    const notify = useNotify();
    const [showDialog, setShowDialog] = useState(false);

    const handleConfirm = async () => {
        const response = await invoicePackage(record.id);
        if (response.id) {
            refresh();
            notify('Package Invoiced');
        }

        handleClose();
    };

    const handleOpen = () => {
        setShowDialog(true);
    };

    const handleClose = () => {
        setShowDialog(false);
    };

    if (!record || record.status === 'draft') {
        return null;
    }

    return (
        <>
            <IconButton icon={<RefreshIcon />} onClick={handleOpen} variant={variant} color={color}>Generate Invoice</IconButton>
            <Confirm
                isOpen={showDialog}
                title={`Invoice package #${record.reference}`}
                content={`Are you sure you want to invoice this package? Only OneTime and Sales Lines`}
                onConfirm={handleConfirm}
                onClose={handleClose}
            />
        </>
    );
};

export default PackageInvoiceButton;
