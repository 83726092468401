import React from 'react';
import { Login } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import { theme } from '../../style/themes';

const useStyles = makeStyles((theme) => ({
	main: {
		background: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("/img/login.jpg")',
		backgroundSize: 'cover',
		backgroundPosition: 'center right',
		backgroundRepeat: 'no-repeat'
	},
	card: {
		paddingBottom: theme.spacing(2)
	}
}));

const CustomLogin = (props) => {
	const classes = useStyles();

	return (
		<Login theme={theme} backgroundImage="" classes={classes} {...props} />
	);
};

export default CustomLogin;
