import React from 'react';
import { TextField } from 'react-admin';

import Datagrid from '../common/Datagrid';
import DateField from '../common/fields/DateField';
import RecordSplitButton from '../common/RecordSplitButton';
import LinkFieldButton from '../common/LinkFieldButton';

const DataPricingRow = ({ children, ...props })  => (
	<Datagrid {...props}>
		<TextField source="provider" />
		<LinkFieldButton label="Customer" source="customer" basePath="/customers" sortable={false}>
			<TextField source="name" />
		</LinkFieldButton>
		<TextField source="country_code" />
		<TextField source="vpmn" label="Network"/>
		<TextField source="imsi" />
		<TextField label="Price/MB" source="price" options={{ style: 'currency', currency: 'EUR' }} />
		<TextField label="Buy Price/MB" source="buy_price" options={{ style: 'currency', currency: 'EUR' }} />
		<DateField source="updated_at" />
		<RecordSplitButton />
		{children}
	</Datagrid>
);

export default DataPricingRow;