import React from 'react';

import DeactivateButton from './DeactivateButton';

const StarlinkServiceLineActionsButtons = ({ record }) => (
	<>
		<DeactivateButton record={record} />
	</>
);

export default StarlinkServiceLineActionsButtons;