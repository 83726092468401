import React from 'react';
import { Chip } from '@material-ui/core';

const OwnerField = ({ record }) => {
	if (record.owner_id === 1)
		return null;

	return (
		<Chip label="Customer" color="primary" size="small" />
	);
};

OwnerField.defaultProps = {
	addLabel: true
};

export default OwnerField;