import SimCard from '@material-ui/icons/SimCard';

import SimCardList from './SimCardList';
import SimCardCreate from './SimCardCreate';
import SimCardShow from './SimCardShow';
import SimCardEdit from './SimCardEdit';

export default {
	list: SimCardList,
	create: SimCardCreate,
	show: SimCardShow,
	edit: SimCardEdit,
	icon: SimCard
};
