import React from 'react';
import { TabbedShowLayout, Tab, ReferenceField, TextField, FunctionField, ReferenceManyField, Pagination, Query } from 'react-admin';
import { Helmet } from 'react-helmet';
import { withStyles } from '@material-ui/core/styles';

import { ShowView, ShowActions } from '../common/Show';
import DateField from '../common/fields/DateField';
import UsageGraph from '../common/UsageGraph';
import SimCardActionsButtons from './SimCardActionsButtons';
import FilteredLogList from '../logs/FilteredLogList';
import SimCardLastLiveData from './SimCardLastLiveData';
import StatusField from './StatusField';
import OwnerField from './OwnerField';
import ProviderUrlField from './ProviderUrlField';
import LocationUpdateRow from '../location_updates/LocationUpdateRow';
import SimCardRow from './SimCardRow';


const styles = {
	iframe: {
		width: '100%',
		minHeight: '100vh',
		border: 'none'
	}
};

const SimCardTitle = ({ record }) => (
	<>
		<Helmet>
			<title>{`KB - Simcard[${record.id}]`}</title>
		</Helmet>
		<span>{`Simcard #${record.id}`}</span>
	</>
);

const SimCardActions = (props) => (
	<ShowActions {...props}>
		<SimCardActionsButtons record={props.data} />
	</ShowActions>
);


const Tabs = withStyles(styles)(({ classes, ...props }) => (
	<TabbedShowLayout {...props}>
		<Tab label="summary">
			<TextField source="id" />
			<TextField source="type" />
			<ReferenceField label="Parent" source="parent_id" reference="simcards" link="show" allowEmpty>
				<TextField source="iccid" />
			</ReferenceField>
			<StatusField label="Status" />
			<FunctionField label="iccid" source="iccid" render={record => `${record.iccid_formatted}`} />
			<TextField source="phone_number" />
			<ProviderUrlField />
			<ReferenceField label="Customer" source="customer" reference="customers" link="show" allowEmpty>
				<TextField source="name" />
			</ReferenceField>
			<ReferenceField label="SimPool" source="pool_id" reference="simpools" link="show" allowEmpty>
				<TextField source="name" />
			</ReferenceField>
			<OwnerField label="Owner" />
			<ReferenceField label="Site" source="site" reference="sites" link="show" allowEmpty>
				<TextField source="name" />
			</ReferenceField>
			<ReferenceField label="Device" source="device_id" reference="devices" link="show" allowEmpty>
				<FunctionField render={record => `${record.serial} / ${record.name}`} />
			</ReferenceField>
			<ReferenceField label="IC Device" source="ic_device_id" reference="devices" link="show" allowEmpty>
				<FunctionField render={record => `${record.serial} / ${record.name}`} />
			</ReferenceField>
			<TextField source="imsi" />
			<TextField source="imsi_profile" />
			<TextField source="sponsored_imsi" />
			<ReferenceField label="Provider" source="provider" reference="providers" allowEmpty>
				<TextField source="name" />
			</ReferenceField>
			<TextField source="supplier_name" />
			<TextField source="carrier_name" />
			<TextField source="apn_1" />
			<TextField source="apn_2" />
			<TextField source="apn_3" />
			<TextField source="preferred_mtu" />
			<TextField source="pin" />
			<TextField source="username" />
			<TextField source="password" />
			<TextField source="variant" />
			<TextField source="notes" />
			<TextField label="Archived Reason" source="archived_reason" />
			<TextField label="Archived Notes" source="archived_notes" />
			<DateField label="Archived Date" source="archived_date" />
			<TextField source="source" />
			<DateField label="Created at" source="created_at" />
			<DateField label="Updated at" source="updated_at" />
			<SimCardLastLiveData />
			<UsageGraph />
		</Tab>
		<Tab label="Logs">
			<FilteredLogList />
		</Tab>
		<Tab label="Network Events" path="location_updates">
			<ReferenceManyField addLabel={false} reference="location_updates" target="simcard_id" pagination={<Pagination />} sort={{ field: 'timestamp', order: 'DESC' }}>
				<LocationUpdateRow />
			</ReferenceManyField>
		</Tab>
		<Tab label="eSIMs" path="simcards">
			<ReferenceManyField addLabel={false} reference="simcards" target="parent_id" pagination={<Pagination />} sort={{ field: 'iccid', order: 'DESC' }}>
				<SimCardRow />
			</ReferenceManyField>
		</Tab>
	</TabbedShowLayout>
));

const SimCardShow = props => (
	// Workaround to have show page works with iccid
	<Query type="GET_ONE" resource="simcards" payload={{ id: props.id }}>
		{({ data }) => {
			if (!data) {
				return null;
			}

			return (
				<ShowView title={<SimCardTitle />} actions={<SimCardActions {...props} />} {...props} data={data}>
					<Tabs />
				</ShowView>
			);
		}}
	</Query>
);

export default SimCardShow;