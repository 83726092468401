import React, { useState } from 'react';
import { SaveButton as RASaveButton, useRefresh, useNotify, ReferenceInput, AutocompleteInput, ArrayInput, SimpleFormIterator } from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import IconButton from '../../common/IconButton';
import SimpleForm from '../../common/SimpleForm';
import Toolbar from '../../common/Toolbar';

import { updateSimCard } from '../../../services/api';


const optionText = choice => choice.iccid_formatted ? choice.iccid_formatted : '';

const SaveButton = ({ onClick, selectedIds, record, ...props }) => {
	const formState = useFormState();
	const form = useForm();
	const notify = useNotify();

	const handleClick = async () => {
		let errorIds = [];

		formState.values.simcards.forEach(async ({ id }) => {
			const response = await updateSimCard(id, JSON.stringify({ pool_id: record.id }));
			if(!response.success) {
				errorIds.push(id);
			}
		});
		form.reset();
		notify(`${formState.values.simcards.length - errorIds.length} SIM cards added to pool ${record.name}`, errorIds.length === 0 ? 'info' : 'warning')
		onClick();
	};

	return <RASaveButton {...props} handleSubmit={handleClick} handleSubmitWithRedirect={handleClick} />;
};

const AddSimToPoolButton = ({ record, variant, color }) => {
	const refresh = useRefresh();
	const [showDialog, setShowDialog] = useState(false);

	const handleSave = () => {
		refresh();
		handleClose();
	};

	const handleOpen = () => {
		setShowDialog(true);
	};

	const handleClose = () => {
		setShowDialog(false);
	};

	return (
		<>
			<IconButton icon={<AddIcon />} onClick={handleOpen} variant={variant} color={color}>Add SIM to pool</IconButton>
			<Dialog fullWidth open={showDialog} onClose={handleClose} aria-label="Move to SIM pool" disablePortal>
				<DialogTitle>Add SIM to pool</DialogTitle>
				<DialogContent>
					<SimpleForm toolbar={<Toolbar onClose={handleClose} onSave={handleSave} saveButton={<SaveButton onClick={handleSave} />} />} record={record}>
						<ArrayInput source="simcards">
							<SimpleFormIterator>
								<ReferenceInput label="SIM" source="id" reference="simcards" allowEmpty>
									<AutocompleteInput optionText={optionText} />
								</ReferenceInput>
							</SimpleFormIterator>
						</ArrayInput>
					</SimpleForm>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default AddSimToPoolButton;