import React from 'react';
import { SimpleForm as RASimpleForm } from 'react-admin';

const SimpleForm = props => (
	<RASimpleForm {...props} />
);

SimpleForm.defaultProps = {
	variant: "outlined"
};

export default SimpleForm;