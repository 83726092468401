import React from 'react';
import Icon from '@material-ui/icons/Stop';
import green from '@material-ui/core/colors/green';
import orange from '@material-ui/core/colors/orange';
import red from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';

const colors = {
	'XDSL INSTALLED': green[500],
	'XDSL VALIDATED': green[500],
	'FIBER VALIDATED': green[500],
	'TEL VALIDATED': green[500],
	'XDSL TO CANCEL': orange[500],
	'XDSL CANCELLED': red[500],
	'CANCELLED': red[500],
	'XDSL UNKNOWN STATUS': grey[500]
};

const StatusIcon = ({ record, source }) => {
	const iconStyle = { color: colors[record[source]] };
	return <Icon style={iconStyle} />
};

StatusIcon.defaultProps = {
	addLabel: true,
	source: 'status'
};

export default StatusIcon;