import React, { useState, useEffect } from 'react';
import { Link } from 'react-admin';
import { Grid, Card, CardHeader, Box, Button } from '@material-ui/core';

import LineIcon from '@material-ui/icons/Phone';
import BusinessIcon from '@material-ui/icons/Phone';

import IconCard from '../common/IconCard';
import CustomList from '../common/CustomList';
import LineRow from '../lines/LineRow';

import { fetchLineDashboardStats } from '../../services/api';

const LineDashboard = () => {
    const [stats, setStats] = useState();

    useEffect(() => {
        const fetchStats = async () => {
            const data = await fetchLineDashboardStats();
            setStats(data);
        }

        fetchStats();
    }, []);

    return (
        <Grid container spacing={2}>
            <Grid container item spacing={2}>
                <Grid item lg={4} sm={6} xl={4} xs={12}>
                    <Link to="/lines">
                        <IconCard title="Lines" value={stats?.total} icon={<LineIcon />} />
                    </Link>
                </Grid>
                <Grid item lg={4} sm={6} xl={4} xs={12}>
                    <Link to={`/lines?filter={"attached": false}`}>
                        <IconCard title="Not Attached" value={stats?.not_attached} icon={<LineIcon />} warning={stats?.not_attached > 0 ? true : false} />
                    </Link>
                </Grid>
                <Grid item lg={4} sm={6} xl={4} xs={12}>
                    <Link to={`/lines?filter={"next_action_due": true}`}>
                        <IconCard title="Next Action Due" value={stats?.next_action_due} icon={<LineIcon />} warning={stats?.next_action_due > 0 ? true : false} />
                    </Link>
                </Grid>
            </Grid>
            <Grid container item spacing={2}>
                <Grid item lg={4} sm={6} xl={4} xs={12}>
                    <Link to={`/services?filter={"status": "provisioning", "queue": "lines"}`}>
                        <IconCard title="Services - To Provision" value={stats?.services?.provisioning} icon={<BusinessIcon />} warning={stats?.services?.provisioning > 0 ? true : false} />
                    </Link>
                </Grid>
            </Grid>
            <Grid container item spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader title="Next Action Due" />
                        <CustomList
                            resource="lines"
                            sort={{ field: 'id', order: 'DESC' }}
                            filter={{ next_action_due: true }}
                            basePath="/lines"
                            filters={null}
                            bulkActionButtons={false}
                            exporter={false}
                            perPage={10}
                            pagination={false}
                            actions={false}
                        >
                            <LineRow />
                        </CustomList>

                        <Grid container justify = "center">
                            <Button
                                component={Link}
                                to='/lines?filter={"next_action_due": true}'
                                size="small"
                                color="primary"
                            >
                                <Box p={1}>See all lines</Box>
                            </Button>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default LineDashboard;