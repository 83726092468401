import RouterIcon from '@material-ui/icons/Router';

import ProductItemList from './ProductItemList';
import ProductItemCreate from './ProductItemCreate';
import ProductItemShow from './ProductItemShow';
import ProductItemEdit from './ProductItemEdit';


export default {
	list: ProductItemList,
	create: ProductItemCreate,
	show: ProductItemShow,
	edit: ProductItemEdit,
	icon: RouterIcon,
	title: 'Product Items'
};
