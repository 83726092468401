import React from 'react';
import { TabbedShowLayout, Tab, TextField, ReferenceField, EmailField } from 'react-admin';
import { Helmet } from 'react-helmet';

import Show from '../common/Show';
import DateField from '../common/fields/DateField';

const ContactTitle = ({ record }) => (
	<>
		<Helmet>
			<title>KB - Contact[{record.name}]</title>
		</Helmet>
		<span>{record.name}</span>
	</>
);

const ContactShow = (props) => (
	<Show title={<ContactTitle />} {...props}>
		<TabbedShowLayout>
			<Tab label="summary">
				<TextField source="id" />
				<ReferenceField label="Customer" source="customer.id" reference="customers" link="show" allowEmpty>
					<TextField source="name" />
				</ReferenceField>
				<TextField source="contact_type" />
				<TextField source="name" />
				<TextField source="job_title" />
				<EmailField source="email" />
				<TextField source="phone" />
				<TextField source="department" />
				<TextField source="address"/>
				<TextField source="lang" />
				<TextField source="timezone" />
				<TextField source="description" label="Notes" />
				<DateField source="created_at" />
				<DateField source="updated_at" />
			</Tab>
		</TabbedShowLayout>
	</Show>
);

export default ContactShow;
