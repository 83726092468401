import React from 'react';
import {
	TextInput,
	ReferenceInput,
	AutocompleteInput,
	BooleanInput
} from 'react-admin';

import Create from '../common/Create';
import SimpleForm from '../common/SimpleForm';

const optionText = choice => choice.name ? choice.name : '';

const UserCreate = props => (
	<Create {...props}>
		<SimpleForm redirect="list">
			<TextInput source="email" />
			<TextInput source="firstname" />
			<TextInput source="lastname" />
			<TextInput source="password" />
			<ReferenceInput label="Customer" source="customer_id" reference="customers" allowEmpty sort={{ field: "name", order: "ASC" }}>
				<AutocompleteInput optionText={optionText} />
			</ReferenceInput>
			<BooleanInput label="is_admin" source="is_admin" />
			<BooleanInput label="is_staff" source="is_staff" />
			<BooleanInput label="is_manager" source="is_manager" />
			<BooleanInput label="Can Order" source="can_order" />
			<BooleanInput label="Can Manage Users" source="can_manage_users" />
			<BooleanInput label="Can Manage Pricing" source="can_manage_pricing" />
		</SimpleForm>
	</Create>
);

export default UserCreate;