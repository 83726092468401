import React from 'react';
import { BooleanInput, TextInput, ReferenceInput, AutocompleteInput } from 'react-admin';

import GetAppIcon from '@material-ui/icons/GetApp';

import List, { ListActions } from '../common/List';
import Filter from '../common/Filter';
import StarlinkTerminalRow from './StarlinkTerminalRow';

import IconButton from '../common/IconButton';

import { API_URL } from '../../services/settings';

const filterDefaultValues = {
    archived: false
};

const accountOptionText = choice => choice.account_number ? choice.account_number : '';


const StarlinkTerminalListActions = (props) => (
    <ListActions {...props}>
        <IconButton icon={<GetAppIcon />} component="a" href={`${API_URL}/starlink_terminals/export.xls`}>Export</IconButton>
    </ListActions>
);


const StarlinkTerminalFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <ReferenceInput label="Starlink account" source="account_id" reference="starlink_accounts" sort={{ field: 'account_number', order: 'ASC' }} alwaysOn>
            <AutocompleteInput optionText={accountOptionText} />
        </ReferenceInput>
        <ReferenceInput label="Customer" source="customer_id" reference="customers" sort={{ field: 'name', order: 'ASC' }} alwaysOn>
            <AutocompleteInput />
        </ReferenceInput>
        <BooleanInput label="Available" source="available" alwaysOn />
        <BooleanInput label="Archived" source="archived" alwaysOn />
        <BooleanInput label="Monitored" source="monitored" alwaysOn />
    </Filter>
);

const StarlinkTerminalList = props => (
    <List {...props} filters={<StarlinkTerminalFilter />} filterDefaultValues={filterDefaultValues} actions={<StarlinkTerminalListActions />} exporter={false}>
        <StarlinkTerminalRow />
    </List>
);

export default StarlinkTerminalList;