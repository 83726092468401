import React, { useState } from 'react';
import { useRefresh, useNotify, useUnselectAll, useMutation, SaveButton as RASaveButton, TextInput, DateInput, BooleanInput } from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import IconUndo from '@material-ui/icons/Undo';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';

import IconButton from '../../common/IconButton';
import SimpleForm from '../../common/SimpleForm';
import Toolbar from '../../common/Toolbar';
import ArchivedReasonInput from '../ArchivedReasonInput';

import { UNARCHIVE_MANY } from '../../../provider';


const useStyles = makeStyles(theme => ({
	suggestionsContainerOpen: {
		position: 'absolute',
		marginBottom: theme.spacing(3),
		zIndex: theme.zIndex.modal + 200,
	}
}));


const SaveButton = ({ onClick, selectedIds, ...props }) => {
	const formState = useFormState();
	const refresh = useRefresh();
	const form = useForm();
	const notify = useNotify();
	const [mutate] = useMutation();
	const unselectAll = useUnselectAll();

	const handleClick = () => {
		mutate(
			{
				type: 'deleteMany',
				resource: 'lines',
				payload: {
					ids: selectedIds,
					data: {
						archived_reason: formState.values.archived_reason,
						archived_notes: formState.values.archived_notes,
						archived_date: formState.values.archived_date,
						decommission: formState.values.decommission
					}
				},
			},
			{
				onSuccess: ({ data }) => {
					let reasons = '';
					const archivedCount = data.reduce((acc, item) => {
						if(item.reason) {
							reasons += `\n${item.reason}`;
						}
						return !item.reason ? acc + 1 : acc
					}, 0);
					unselectAll('lines');
					if(reasons) {
						reasons = `\n\n${selectedIds.length - archivedCount} element${selectedIds.length - archivedCount < 2 ? '' : 's'} not archived:` + reasons;
					}
					notify(`${archivedCount} archived element${archivedCount < 2 ? '' : 's'}${reasons}`);
					if (archivedCount > 0) {
						refresh();
					}
				},
				onFailure: (error) => notify(`Error: ${error.message}`, 'warning'),
			}
		);

		form.reset();
		onClick();
	};

	return <RASaveButton {...props} handleSubmit={handleClick} handleSubmitWithRedirect={handleClick} />;
};


const LineBulkActions = ({ dataProvider, variant, color, ...props }) => {
	const refresh = useRefresh();
	const notify = useNotify();
	const unselectAll = useUnselectAll();
	const [showDialog, setShowDialog] = useState(false);
	const classes = useStyles();

	const [unarchive, { loading }] = useMutation(
		{
			type: UNARCHIVE_MANY,
			resource: props.resource,
			payload: { ids: props.selectedIds }
		},
		{
			onSuccess: ({ data }) => {
				unselectAll(props.resource);
				refresh();
				notify(`${data.length} unarchived element${data.length < 2 ? '' : 's'}`);
			},
			onFailure: (error) => {
				unselectAll(props.resource);
				notify(error.message, 'warning');
			}
		}
	);

	const handleSave = () => {
		handleClose();
	};

	const handleOpen = () => {
		setShowDialog(true);
	};

	const handleClose = () => {
		setShowDialog(false);
	};

	return (
		<>
			{props.filterValues.archived ? (
				<IconButton onClick={unarchive} icon={<IconUndo />} disabled={loading} variant={variant} color={color}>Unarchive</IconButton>
			) : (
				<>
					<IconButton onClick={handleOpen} icon={<DeleteIcon />} color="secondary" variant={variant}>Archive</IconButton>
					<Dialog fullWidth open={showDialog} onClose={handleClose} aria-label="Archive lines">
						<DialogTitle>Archive {props.selectedIds.length} lines</DialogTitle>
						<DialogContent>
							{`Are you sure you want to archive these ${props.selectedIds.length} items?`}
							<SimpleForm toolbar={<Toolbar onClose={handleClose} onSave={handleSave} saveButton={<SaveButton onClick={handleSave} selectedIds={props.selectedIds} />} />} initialValues={{ archived_date: new Date() }}>
								<ArchivedReasonInput
									options={{ suggestionsContainerProps: { className: classes.suggestionsContainerOpen } }}
								/>
								<TextInput source="archived_notes" label="Notes" options={{ multiline: true }} rows="4" />
								<DateInput source="archived_date" />
								<BooleanInput source="decommission" />
							</SimpleForm>
						</DialogContent>
					</Dialog>
				</>
			)}
		</>
	);
};

export default LineBulkActions;