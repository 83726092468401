import React from 'react';
import { useTranslate } from 'react-admin';
import { Tooltip } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

const BooleanField = ({ record, source, valueLabelTrue, valueLabelFalse, reverse }) => {
    const translate = useTranslate();

    if (!record || record[source] === null || record[source] === undefined) {
        return null;
    }

    const value = record ? (reverse ? !record[source] : record[source]) : null;
    const ariaLabel = record[source] === false ? (valueLabelFalse || 'ra.boolean.false') : (valueLabelTrue || 'ra.boolean.true');
    const iconStyle = { color: value === false ? red[500] : green[500] };

    return (
        <Tooltip title={translate(ariaLabel, { _: ariaLabel })}>
            <FiberManualRecordIcon style={iconStyle} />
        </Tooltip>
    );
};

BooleanField.defaultProps = {
    addLabel: true,
    reverse: false
};

export default BooleanField;
