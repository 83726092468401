import React from 'react';
import { TextInput, ReferenceInput, AutocompleteInput, FormDataConsumer, DateInput } from 'react-admin';

import Create from '../common/Create';
import SimpleForm from '../common/SimpleForm';
import StatusField from './StatusField';
import LineTypeField from './LineTypeField';
import SiteReferenceInputWithCreate from '../sites/SiteReferenceInputWithCreate';

const optionText = choice => choice.name ? choice.name : '';

const LineCreate = props => (
	<Create {...props}>
		<SimpleForm redirect="list">
			<TextInput label="SO" source="sales_order_number" />
			<TextInput label="Subscription number" source="subscription_id" />
			<ReferenceInput label="Provider" source="provider_id" reference="line_providers" sort={{ field: 'name', order: 'ASC' }} allowEmpty>
				<AutocompleteInput optionText={optionText} />
			</ReferenceInput>
			<ReferenceInput label="Customer" source="customer_id" reference="customers" sort={{ field: 'name', order: 'ASC' }} allowEmpty>
				<AutocompleteInput optionText={optionText} />
			</ReferenceInput>
			<FormDataConsumer>
				{({ formData, ...rest }) => (
					<SiteReferenceInputWithCreate
						{...rest}
						label="Site"
						source="site_id"
						reference="sites"
						filter={formData.customer_id && { customer_id: formData.customer_id }}
						key={formData.customer_id}
					/>
				)}
			</FormDataConsumer>
			<StatusField />
			<DateInput source="next_action_date" />
			<LineTypeField />
			<TextInput source="ppoe_login" />
			<TextInput source="ppoe_password" />
			<TextInput source="contact_name" />
			<TextInput source="contact_phone" />
			<TextInput source="contact_email" />
			<DateInput source="installation_date" />
			<TextInput source="installation_notes" options={{ multiline: true }} rows="4" />
			<TextInput source="notes" options={{ multiline: true }} rows="4" />
		</SimpleForm>
	</Create>
);

export default LineCreate;
