import React from 'react';
import { TextInput } from 'react-admin';

import Create from '../common/Create';
import SimpleForm from '../common/SimpleForm';

const ProviderCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <TextInput source="iccid_prefix" />
            <TextInput source="name" />
            <TextInput source="supplier_name" />
            <TextInput source="carrier_name" />
            <TextInput source="product_name" />
            <TextInput source="prefered_apn" />
            <TextInput source="alternative_apn" />
            <TextInput source="username" />
            <TextInput source="password" />
            <TextInput source="contry_iso" />
            <TextInput source="country" />
            <TextInput source="country_code" />
            <TextInput source="mcc" />
            <TextInput source="description" options={{ multiline: true }} rows="4" />
        </SimpleForm>
    </Create>
);

export default ProviderCreate;

