import React from 'react';
import { AutocompleteInput } from 'react-admin';

const choices = [
	{ id: 'draft', name: 'DRAFT' },
	{ id: 'provisioning', name: 'PROVISIONING' },
	{ id: 'provisioned', name: 'PROVISIONED' },
	{ id: 'delivered', name: 'DELIVERED' },
	{ id: 'idle', name: 'IDLE'},
	{ id: 'ending', name: 'ENDING' },
	{ id: 'ended', name: 'ENDED' },
	{ id: 'shipped', name: 'SHIPPED' },
];

const ServiceStatusField = ({ classes, record, source = "status", ...props }) => {
	if (!record) {
		return null;
	}

	if (record[source] && !choices.find((provider) => provider.id === record[source])) {
		choices.push({ id: record[source], name: record[source]});
	}

	return (
		<AutocompleteInput choices={choices} source={source} record={record} allowEmpty {...props} />
	);
};

export default ServiceStatusField;
