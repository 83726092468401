import React, { useState } from 'react';
import { Confirm, useMutation, useRefresh, useNotify, useDelete } from 'react-admin';
import IconUndo from '@material-ui/icons/Undo';
import DeleteIcon from '@material-ui/icons/Delete';

import IconButton from './IconButton';

import { UNARCHIVE } from '../../provider';

const ArchiveButton = ({ record, resource, label }) => {
	const refresh = useRefresh();
	const notify = useNotify();
	const [showDialog, setShowDialog] = useState(false);

	const [archive, { loadingArchive }] = useDelete(
		resource,
		record.id,
		record,
		{
			onSuccess: (response) => {
				if(response.reason) {
					notify(response.reason, 'warning');
				}
				else {
					notify('1 archived element');
					refresh();
				}
			},
			onFailure: (error) => notify(`Error: ${error.message}`, 'warning'),
		}
	);

	const [unarchive, { loading }] = useMutation(
		{
			type: UNARCHIVE,
			resource: resource,
			payload: { id: record.id }
		},
		{
			onSuccess: ({ data }) => {
				refresh();
				notify(`1 unarchived element`);
			},
			onFailure: (error) => {
				notify(error.message, 'warning');
			}
		}
	);

	const handleConfirm = () => {
		handleClose();
		archive();
	};

	const handleOpen = () => {
		setShowDialog(true);
	};

	const handleClose = () => {
		setShowDialog(false);
	};

	return (
		record.archived_at ? (
			<IconButton onClick={unarchive} icon={<IconUndo />} disabled={loading}>Unarchive</IconButton>
		) : (
			<>
				<IconButton onClick={handleOpen} icon={<DeleteIcon />} disabled={loadingArchive} color="secondary">{label}</IconButton>
				<Confirm
					isOpen={showDialog}
					title={`Archive #${record.id}`}
					content={`Are you sure you want to archive this item?`}
					onConfirm={handleConfirm}
					onClose={handleClose}
				/>
			</>
		)
	);
};

ArchiveButton.defaultProps = {
	label: 'Archive'
};

export default ArchiveButton;