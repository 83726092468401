import React from 'react';
import { BooleanInput, TextInput, ReferenceInput, AutocompleteInput } from 'react-admin';

import List from '../common/List';
import Filter from '../common/Filter';
import StarlinkSubscriptionRow from './StarlinkSubscriptionRow';

const accountOptionText = choice => choice.account_number ? choice.account_number : '';

const StarlinkSubscriptionFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <ReferenceInput label="Starlink account" source="account_id" reference="starlink_accounts" sort={{ field: 'account_number', order: 'ASC' }} alwaysOn>
            <AutocompleteInput optionText={accountOptionText} />
        </ReferenceInput>
        <BooleanInput label="Active" source="active" alwaysOn />
    </Filter>
);

const filterDefaultValues = {
    active: true,
}

const StarlinkSubscriptionList = props => (
    <List {...props} filters={<StarlinkSubscriptionFilter />} filterDefaultValues={filterDefaultValues}>
        <StarlinkSubscriptionRow />
    </List>
);

export default StarlinkSubscriptionList;