import React from 'react';
import BulkSpeedTestButton from './BulkSpeedTestButton';
import BulkMoveButton from './BulkMoveButton';
import BulkArchiveButton from './BulkArchiveButton';

const DeviceBulkActions = (props) => (
    <>
        <BulkSpeedTestButton {...props} />
        <BulkMoveButton {...props} />
        <BulkArchiveButton {...props} />
    </>
);

export default DeviceBulkActions;