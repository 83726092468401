import React from 'react';
import { TextInput, NumberInput, ReferenceInput, AutocompleteInput, Toolbar, SaveButton, useNotify, useRedirect } from 'react-admin';
import { useFormState } from 'react-final-form';

import Create from '../common/Create';
import SimpleForm from '../common/SimpleForm';
import DataPricingProviderField from './DataPricingProviderField';
import DataPricingCountryField from './DataPricingCountryField';

import { createDataPricing } from '../../services/api';

const optionText = choice => choice.name ? choice.name : '';

const CustomToolbar = ({ onSave, ...props }) => {
    const formState = useFormState();
    const notify = useNotify();
    const redirect = useRedirect();

    const handleClick = async () => {
        if(formState.valid) {
            const response = await createDataPricing(JSON.stringify(formState.values));
            if (response.success === false) {
                notify(response.message, 'warning');
            }
            else {
                redirect('/data_pricings');
            }
        }
    };

    return (
        <Toolbar {...props}>
            <SaveButton handleSubmit={handleClick} handleSubmitWithRedirect={handleClick} />
        </Toolbar>
    );
};

const DataPricingCreate = (props) => (
    <Create {...props}>
        <SimpleForm toolbar={<CustomToolbar />}>
            <ReferenceInput label="Customer" source="customer_id" reference="customers" sort={{ field: 'name', order: 'ASC' }} allowEmpty>
                <AutocompleteInput optionText={optionText} />
            </ReferenceInput>
            <DataPricingProviderField label="Provider" source="provider" />
            <DataPricingCountryField source="country_code" />
            <TextInput source="vpmn" />
            <TextInput source="imsi" />
            <NumberInput label="Price/MB" source="price" />
            <NumberInput label="Buy Price/MB" source="buy_price" />
        </SimpleForm>
    </Create>
);

export default DataPricingCreate;

