import React from 'react';
import { TextInput, ReferenceInput, AutocompleteInput, BooleanInput } from 'react-admin';

import ContactRow from './ContactRow';
import List from '../common/List';
import Filter from '../common/Filter';


const optionText = choice => {
    return choice.name ? choice.name : '';
};

const ContactFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" variant="outlined" alwaysOn />
        <ReferenceInput label="Customer" source="customer_id" reference="customers" sort={{ field: 'name', order: 'ASC' }} variant="outlined" alwaysOn>
            <AutocompleteInput optionText={optionText} />
        </ReferenceInput>
        <BooleanInput label="Archived" source="archived" alwaysOn/>
        <BooleanInput label="DRP" source="drp" />
    </Filter>
);

const filterDefaultValues = {
    archived: false
};

const ContactList = props => (
    <List {...props} filters={<ContactFilter />} filterDefaultValues={filterDefaultValues}>
        <ContactRow />
    </List>
);

export default ContactList;