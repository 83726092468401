import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    container: {
        paddingLeft: '10px',
        paddingRight: '10px'
    },
    circle: {
        display: 'inline-block',
        width: '7px',
        height: '7px',
        borderRadius: '12px',
        backgroundColor: (props) => props.backgroundColor ? props.backgroundColor : '#B3B3B3',
        marginRight: '5px'
    },
    label: {
        display: 'inline-block'
    }
});

const IsInvoicedField = ({ record, source, ...props }) => {
    let backgroundColor;
    let label;
    const value = record ? record[source] : null;

    if (value) {
        backgroundColor = '#80F563';
        label = 'Invoiced';
    }
    else {
        backgroundColor = '#FF8686';
        label =  'Not invoiced';
    }

    const classes = useStyles({ backgroundColor });

    if (!record) {
        return null;
    }

    return (
        <span className={classes.container}>
            <span className={classes.circle} />
            <Typography variant="body2" className={classes.label}>{label}</Typography>
        </span>
    );
};

IsInvoicedField.defaultProps = {
    source: 'is_invoiced',
    label: 'Invoiced',
    addLabel: true
};

export default IsInvoicedField;
