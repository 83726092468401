import React from 'react';
import { TextField, ReferenceManyField, Pagination, SimpleShowLayout } from 'react-admin';

import Datagrid from '../common/Datagrid';
import LinkFieldButton from '../common/LinkFieldButton';
import DateField from '../common/fields/DateField';
import RecordSplitButton from '../common/RecordSplitButton';
import SimCardRow from '../simcards/SimCardRow';

const SimCardList = props => (
	<ReferenceManyField {...props} perPage={5} pagination={<Pagination />} reference="simcards" target="provider_id">
		<SimCardRow exclude={['provider']} />
	</ReferenceManyField>
);

const ProviderDrawer = props => (
	<SimpleShowLayout {...props}>
		<TextField source="id" />
		<TextField source="iccid_prefix" />
		<TextField source="name" />
		<TextField source="supplier_name" />
		<TextField source="carrier_name" />
		<TextField source="product_name" />
		<TextField source="prefered_apn" />
		<TextField source="alternative_apn" />
		<TextField source="username" />
		<TextField source="password" />
		<TextField source="contry_iso" />
		<TextField source="country" />
		<TextField source="country_code" />
		<TextField source="mcc" />
		<TextField source="description" />
		<DateField source="created_at" />
		<DateField source="updated_at" />
	</SimpleShowLayout>
);

const ProviderRow = props => (
	<Datagrid expand={<SimCardList {...props} />} drawer={<ProviderDrawer {...props} />} {...props}>
		<TextField source="iccid_prefix" />
		<LinkFieldButton label="Name" sortBy="name">
			<TextField source="name" />
		</LinkFieldButton>
		<TextField source="sims.length" label="SIM Cards" sortable={false} />
		<TextField source="supplier_name" />
		<TextField source="carrier_name" />
		<TextField source="prefered_apn" />
		<RecordSplitButton />
	</Datagrid>
);

export default ProviderRow;