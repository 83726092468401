import React from 'react';
import {
	DateField,
	TextField,
	SimpleShowLayout,
	NumberField
} from 'react-admin';

import Datagrid from '../common/Datagrid';
import NumberCurrencyField from '../common/fields/NumberCurrencyField';
import LinkFieldButton from '../common/LinkFieldButton';
import RecordSplitButton from '../common/RecordSplitButton';

const ServiceProductDrawer = props => (
	<SimpleShowLayout {...props}>
		<TextField source="id" />
		<TextField source="service_type" label="Type" />
		<TextField source="frequency" label="Type" />
		<TextField source="reference" />
		<TextField source="name" />
		<NumberCurrencyField source="price" />
		<NumberField source="data_included" />
		<NumberCurrencyField source="extra_price" />
		<TextField source="currency" />
		<TextField source="description" options={{ multiline: true }} rows="4" />
		<DateField label="Created at" source="created_at" />
		<DateField label="Updated at" source="updated_at" />
	</SimpleShowLayout>
);

const ServiceProductRow = props => (
	<Datagrid drawer={<ServiceProductDrawer {...props} />} {...props}>
		<TextField source="service_type" label="Type" />
		<LinkFieldButton label="Reference" sortBy="reference">
			<TextField source="reference" />
		</LinkFieldButton>
		<TextField source="name" />
		<TextField source="services_count" />
		<NumberCurrencyField source="price" />
		<NumberField source="data_included" />
		<NumberCurrencyField source="extra_price" />
		<TextField source="currency" />
		<TextField source="frequency" />
		<RecordSplitButton />
	</Datagrid>
);

export default ServiceProductRow;