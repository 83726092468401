import React from 'react';
import { TabbedShowLayout, Tab, TextField, ReferenceField } from 'react-admin';
import { Helmet } from 'react-helmet';

import Show from '../common/Show';
import DateField from '../common/fields/DateField';
import MapComponent from '../common/MapComponent';


const StarlinkAddressTitle = ({ record }) => (
    <>
        <Helmet>
            <title>{`KB - Starlink address[${record.id}]`}</title>
        </Helmet>
        <span>{record.id}</span>
    </>
);

const StarlinkAddressShow = (props) => (
    <Show title={<StarlinkAddressTitle />} {...props}>
        <TabbedShowLayout>
            <Tab label="summary">
                <TextField source="id" />
                <TextField source="formatted_address" />
                <TextField source="address_reference_id" />
                <ReferenceField label="Starlink account" source="starlink_account" reference="starlink_accounts" link="show" allowEmpty>
                    <TextField source="account_number" />
                </ReferenceField>
                <TextField source="metadatas" />
                <TextField source="administrative_area" />
                <TextField source="administrative_area_code" />
                <DateField source="created_at" showTime />
                <DateField source="updated_at" showTime />
                <MapComponent height="300px" />
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export default StarlinkAddressShow;