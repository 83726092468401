import DescriptionIcon from '@material-ui/icons/Description';

import InvoiceLineList from './InvoiceLineList';
import InvoiceLineCreate from './InvoiceLineCreate';
import InvoiceLineEdit from './InvoiceLineEdit';


export default {
	list: InvoiceLineList,
	edit: InvoiceLineEdit,
	create: InvoiceLineCreate,
	icon: DescriptionIcon
};
