import React, { useState } from 'react';
import { SaveButton as RASaveButton, useRefresh, useNotify, ReferenceInput, AutocompleteInput } from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import IconButton from '../../common/IconButton';
import SimpleForm from '../../common/SimpleForm';
import Toolbar from '../../common/Toolbar';

import { updateSimCard } from '../../../services/api';


const optionText = choice => choice.name ? choice.name : '';

const SaveButton = ({ onClick, record, ...props }) => {
	const formState = useFormState();
	const form = useForm();
	const notify = useNotify();

	const handleClick = async () => {
		const data = { pool_id: formState.values.pool_id };

		const response = await updateSimCard(record.id, JSON.stringify(data));

		if(response.success === false) {
			notify(`${record.iccid_formatted} not moved`, 'warning');
		}
		else {
			notify(`${record.iccid_formatted} moved`);
		}

		form.reset();
		onClick();
	};

	return <RASaveButton {...props} handleSubmit={handleClick} handleSubmitWithRedirect={handleClick} />;
};

const MoveToPoolButton = ({ record, variant, color }) => {
	const refresh = useRefresh();
	const [showDialog, setShowDialog] = useState(false);

	const handleSave = () => {
		refresh();
		handleClose();
	};

	const handleOpen = () => {
		setShowDialog(true);
	};

	const handleClose = () => {
		setShowDialog(false);
	};

	if(!record) {
		return null;
	}

	return (
		<>
			<IconButton icon={<SwapHorizIcon />} onClick={handleOpen} variant={variant} color={color}>Move to SIM pool</IconButton>
			<Dialog fullWidth open={showDialog} onClose={handleClose} aria-label="Move to SIM pool" disablePortal>
				<DialogTitle>Move to SIM pool</DialogTitle>
				<DialogContent>
					Are you sure to move the simcard {record.iccid_formatted} to the SIM pool?
					<SimpleForm toolbar={<Toolbar onClose={handleClose} onSave={handleSave} saveButton={<SaveButton onClick={handleSave} />} />} record={record}>
						<ReferenceInput label="Pool" source="pool_id" reference="simpools" sort={{ field: "name", order: "ASC" }} allowEmpty>
							<AutocompleteInput optionText={optionText} />
						</ReferenceInput>
					</SimpleForm>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default MoveToPoolButton;