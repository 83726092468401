import React from 'react';
import { TextField } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	ul: {
		paddingLeft: '10px'
	},
	typography: {
		display: 'inline-block',
		paddingRight: '5px'
	}
});

const MetadataField = ({ source, record, filters, exclude, ...props }) => {
	const classes = useStyles();

	const renderMetadata = (data, source) => {
		if (typeof data === 'object') {
			return (
				<ul key={`data_${source}`} className={classes.ul}>
					<li>
						<Typography variant="body2">{source}</Typography>
						<ul className={classes.ul}>
							{Object.entries(data).map(([key, value]) => {
								if (!value || (filters.length > 0 && (!filters.includes(key) && isNaN(key))) || exclude.includes(key)) {
									return null;
								}

								if (typeof value === 'object') {
									return renderMetadata(value, key);
								}

								return (
									<li key={`${key}_${value}`}>
										<Typography variant="body2" className={classes.typography}>{key}: </Typography>
										<TextField record={data} source={key} />
									</li>
								);
							})}
						</ul>
					</li>
				</ul>
			);
		}
	};

	if(!record[source]) {
		return null;
	}

	return renderMetadata(record[source], source);
};

MetadataField.defaultProps = {
	record: {},
	filters: [],
	exclude: []
};

export default MetadataField;