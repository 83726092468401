import React from 'react';
import { Labeled } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	link: {
		textDecoration: 'none'
	}
});

const ProviderUrlField = ({ record }) => {
    const classes = useStyles();
	if (!record.provider_url) {
		return null;
	}

    return (
        <Labeled label="Provider URL">
            <a href={record.provider_url} target="__blank" className={classes.link}>Open provider</a>
        </Labeled>
    );
};

export default ProviderUrlField;