import React from 'react';
import { Link } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import Login from './common/Login';
import MFALoginForm from './mfaMethods/forms/MFALoginForm';

const useStyles = makeStyles((theme) => ({
	link: {
		display: 'block',
		textAlign: 'center',
		textDecoration: 'underline',
		marginTop: theme.spacing(1)
	},
}));

const LoginPage = () => {
	const classes = useStyles();

	return (
		<Login>
			{/* This component is a copy of react-admin LoginForm */}
			{/* Refer to "Custom added" to see the changes */}
			<MFALoginForm />
			<Link to="/forgot-password" className={classes.link}>Forgot password?</Link>
		</Login>
	);
};

export default LoginPage;
