import React, { useState } from 'react';
import { Confirm, useRefresh, useNotify } from 'react-admin';
import RefreshIcon from '@material-ui/icons/Refresh';

import IconButton from '../../common/IconButton';

import { reverseInvoice } from '../../../services/api';


const ReverseInvoiceButton = ({ record, variant, color }) => {
    const refresh = useRefresh();
    const notify = useNotify();
    const [showDialog, setShowDialog] = useState(false);

    const handleConfirm = async () => {
        const response = await reverseInvoice(record.id);
        if (response.id) {
            refresh();
            notify('Credit note added');
        }

        handleClose();
    };

    const handleOpen = () => {
        setShowDialog(true);
    };

    const handleClose = () => {
        setShowDialog(false);
    };

    if (!record || record.status === 'draft') {
        return null;
    }

    return (
        <>
            <IconButton icon={<RefreshIcon />} onClick={handleOpen} variant={variant} color={color}>Reverse</IconButton>
            <Confirm
                isOpen={showDialog}
                title={`Reverse invoice #${record.id}`}
                content={`Are you sure you want to reverse this invoice?`}
                onConfirm={handleConfirm}
                onClose={handleClose}
            />
        </>
    );
};

export default ReverseInvoiceButton;