import React from 'react';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
	root: ({ color }) => {
		const style = {
			'& span': {
				height: '20px',
				lineHeight: '24px'
			}
		};

		if (color) {
			return {
				...style,
				backgroundColor: color
			}
		}

		return style;
	}
});

const CustomChip = ({ color, ...props }) => {
	const classes = useStyles({ color });

	return (
		<Chip classes={classes} {...props} />
	);
};

export default CustomChip;
