import React from 'react';
import { Datagrid, TextInput, TextField, ReferenceInput, AutocompleteInput, BooleanInput } from 'react-admin';

import List from '../common/List';
import DateField from '../common/fields/DateField';
import Filter from '../common/Filter';
import ObjectReferenceField from './ObjectReferenceField';
import { DatasBeforeAfterField, BeforeAfterField } from './BeforeAfterField';
import ActivityVerbInput from './ActivityVerbInput';

const optionText = choice => choice.email ? choice.email : choice.login;

const LogFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <ReferenceInput label="Author" source="user_id" reference="users" allowEmpty alwaysOn sort={{ field: 'email', order: 'ASC' }}>
            <AutocompleteInput optionText={optionText} />
        </ReferenceInput>
        <ActivityVerbInput label="Action" source="activity_verb" alwaysOn />
        <BooleanInput label="System" source="by_system" alwaysOn />
    </Filter>
);

const filterDefaultValues = {
    by_system: false
};

const LogList = props => (
    <List {...props} filters={<LogFilter />} perPage={25} bulkActionButtons={false} filterDefaultValues={filterDefaultValues} >
        <Datagrid>
            <DateField label="Date" source="created_at" showTime />
            <TextField label="Author" source="author_name"  />
            <TextField label="Action" source="verb" />
            <TextField source="object_type" sortable={false} />
            <ObjectReferenceField source="object_id" sortable={false} />
            <DatasBeforeAfterField sortable={false} />
            <BeforeAfterField source="before" sortable={false} />
            <BeforeAfterField source="after" sortable={false} />
        </Datagrid>
    </List>
);

export default LogList;
