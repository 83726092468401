import React, { useEffect, useState } from 'react';
import { Confirm, useNotify, useRefresh } from 'react-admin';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';

import IconButton from '../../common/IconButton';

import { disableSimCard, enableSimCard, getStatusSimCard } from '../../../services/api';


const EnableDisableButton = ({ record, variant, color }) => {
	const notify = useNotify();
	const refresh = useRefresh();
	const [showDialog, setShowDialog] = useState(false);
	const [status, setStatus] = useState();

	useEffect(() => {
		const getStatus = async () => {
			const response = await getStatusSimCard(record.id);
			setStatus(response.status);
		};

		if(record && record.is_manageable ) {
			getStatus();
		}
	}, [record]);

	const handleConfirm = async () => {
		if(!status) {
			return;
		}

		let response;
		if(status === 'ACTIVE') {
			response = await disableSimCard(record.id);
		}
		else {
			response = await enableSimCard(record.id);
		}

		if(response) {
			if(response.id) {
				notify(`SIM ${status === 'ACTIVE' ? 'suspended' : 'enabled'}`);
				setStatus(status === 'ACTIVE' ? 'SUSPENDED' : 'ACTIVE');
			}
			else if(!response.success) {
				notify('Error', 'warning');
			}
		}

		handleClose();
		refresh();
	};

	const handleOpen = () => {
		setShowDialog(true);
	};

	const handleClose = () => {
		setShowDialog(false);
	};

	if(!record || (!record.is_manageable) || !status) {
		return null;
	}

	return (
		<>
			<IconButton icon={status === 'ACTIVE' ? <ClearIcon /> : <CheckIcon />} onClick={handleOpen} variant={variant} color={color}>{status === 'ACTIVE' ? 'Suspend' : 'Enable'} SIM</IconButton>
			<Confirm
				isOpen={showDialog}
				title={status === 'ACTIVE' ? 'Suspend SIM' : 'Enable SIM'}
				content={`Are you sure you want to ${status === 'ACTIVE' ? 'suspend' : 'enable'} the SIM ${record.iccid}?`}
				onConfirm={handleConfirm}
				onClose={handleClose}
			/>
		</>
	);
};

export default EnableDisableButton;