import BusinessIcon from '@material-ui/icons/Business';

import CustomerList from './CustomerList';
import CustomerCreate from './CustomerCreate';
import CustomerShow from './CustomerShow';
import CustomerEdit from './CustomerEdit';

export default {
	list: CustomerList,
	create: CustomerCreate,
	show: CustomerShow,
	edit: CustomerEdit,
	icon: BusinessIcon
};
