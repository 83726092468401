import PhonelinkRing from '@material-ui/icons/PhonelinkRing';

import DataPricingList from './DataPricingList';
import DataPricingEdit from './DataPricingEdit';
import DataPricingCreate from './DataPricingCreate';

export default {
	list: DataPricingList,
	edit: DataPricingEdit,
	create: DataPricingCreate,
	icon: PhonelinkRing
};
