import React, { useState } from 'react';
import { useFormState } from 'react-final-form';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { Title, Toolbar, SaveButton, useQuery } from 'react-admin';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import SimpleForm from '../common/SimpleForm';
import SimReferenceInput from '../simcards/SimReferenceInput';


import { updateDevicePorts } from '../../services/api';
import rowStyle from '../wans/rowStyle';


const CustomToolbar = ({ onSave, ...props }) => {
	const formState = useFormState();

	const handleClick = async () => {
		let data = new FormData();
		data.append("ports_map", JSON.stringify(formState.values.ports_map));
		await updateDevicePorts(formState.values.device_id, data);

		if(onSave) {
			onSave();
		}
	};

	return (
		<Toolbar {...props}>
			<SaveButton handleSubmit={handleClick} handleSubmitWithRedirect={handleClick}  />
		</Toolbar>
	);
};

const PortsEditForm = ({ record, actions, onSave }) => {
	const [device_id, setDevice_id] = useState(record.device_id);

	const renderPorts = (device) => {
		if (!device || !device.ports_map) {
			return;
		}

		const { ports_map } = device;

		return (
			<Grid container spacing={3} fullWidth>
				{Object.keys(ports_map).map((key) => (
					<Grid item xs={3} key={key}>
						<SimReferenceInput label={key} source={`ports_map[${key}]`} resource="simcards" reference="simcards" variant="outlined" />
					</Grid>
				))}
			</Grid>
		);
	};

	const renderIcSims = (device) => {
		if(!device || !device.ic_sim_cards) {
			return;
		}

		return (
			<Table fullWidth>
				<TableHead>
					<TableRow>
						<TableCell>Port</TableCell>
						<TableCell>ICCID</TableCell>
						<TableCell>Wan name</TableCell>
						<TableCell>Wan IC_ID</TableCell>
						<TableCell>Status</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{device.ic_sim_cards.map(sim => {
						const wan = device.wans.find(w => w.id === sim.wan);

						return (
							<TableRow key={`sim_${sim.id}`} style={rowStyle(wan)}>
								<TableCell>{sim.device_port_name}</TableCell>
								<TableCell>{sim.iccid}</TableCell>
								<TableCell>{wan.name}</TableCell>
								<TableCell>{wan.ic_id}</TableCell>
								<TableCell>{wan.ic_status}</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		);
	};

	const { data } = useQuery({
		type: 'getOne',
		resource: 'devices',
		payload: { id: device_id }
	});

	if (data) {
		record = {
			...record,
			device_id: data.id,
			ports_map: data.ports_map,
			customer_id: data.customer_id,
			...(data.site_id !== 1 && { site_id: data.site_id })
		};
	}

	return (
		<Card>
			<Title title="Update Ports" />
			<CardContent>
				{actions}
			</CardContent>

			<SimpleForm redirect="show" record={record} resource="devices" toolbar={<CustomToolbar onSave={onSave} />} initialValues={{ data_limit: 10 }}>
				{data && renderPorts(data)}
				{data && renderIcSims(data)}
			</SimpleForm>
		</Card>
	);
};

export default PortsEditForm;