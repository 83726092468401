import React, { useState, useEffect } from 'react';
import { FieldTitle, InputHelperText, useInput } from 'react-admin';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

import { getAutocomplete } from '../../../services/api';

const useStyles = makeStyles({
	autocomplete: {
		minWidth: '200px'
	}
});

const CustomAutocompleteInput = ({ variant, optionText, label, freeSolo, helperText, basePath, allowEmpty, record, ...props }) => {
	const classes = useStyles();
	const [choices, setChoices] = useState(props.choices);
	const options = choices.map(optionText);
	const { source, resource, ...rest } = props;
	const {
		input: { name, value, onChange },
		isRequired,
		meta: { touched, error, submitError }
	} = useInput({
		resource,
		source,
		...rest,
	});

	useEffect(() => {
		const fetchData = async () => {
			const response = await getAutocomplete(resource, source);
			if (response.success) {
				setChoices(response.values.filter(value => value !== null).map(value => ({ id: value, name: value })));
			}
		};

		fetchData();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Autocomplete
			freeSolo={freeSolo}
			options={options}
			value={value}
			onInputChange={(e, value) => onChange(value)}
			className={classes.autocomplete}
			renderInput={(params) => (
				<TextField
					{...props}
					{...params}
					name={name}
					variant={variant}
					label={
						<FieldTitle
							label={label}
							source={source}
							resource={resource}
							isRequired={isRequired}
						/>
					}
					error={!!(touched && error)}
					helperText={
						<InputHelperText
							touched={touched}
							error={error || submitError}
							helperText={helperText}
						/>
					}
					required={isRequired}
				/>
			)}
		/>
	);
};

CustomAutocompleteInput.defaultProps = {
	freeSolo: true,
	variant: 'outlined',
	choices: [],
	optionText: (option) => option.name,
	margin: 'dense'
};

export default CustomAutocompleteInput;