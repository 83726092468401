import React, { useState } from 'react';
import { Confirm, useRefresh, useNotify } from 'react-admin';
import RefreshIcon from '@material-ui/icons/Refresh';

import IconButton from '../common/IconButton';

import { confirmPackage } from '../../services/api';


const PackageConfirmButton = ({ record, variant, color }) => {
    const refresh = useRefresh();
    const notify = useNotify();
    const [showDialog, setShowDialog] = useState(false);

    const handleConfirm = async () => {
        const response = await confirmPackage(record.id);
        if (response.id) {
            refresh();
            notify('Package Confirmed');
        }

        handleClose();
    };

    const handleOpen = () => {
        setShowDialog(true);
    };

    const handleClose = () => {
        setShowDialog(false);
    };

    if (!record || record.status !== 'draft') {
        return null;
    }

    return (
        <>
            <IconButton icon={<RefreshIcon />} onClick={handleOpen} variant={variant} color={color}>Confirm</IconButton>
            <Confirm
                isOpen={showDialog}
                title={`Confirm package #${record.reference}`}
                content={`Are you sure you want to confirm this package?`}
                onConfirm={handleConfirm}
                onClose={handleClose}
            />
        </>
    );
};

export default PackageConfirmButton;
