import React from 'react';
import { TextInput } from 'react-admin';

import List from '../common/List';
import Filter from '../common/Filter';
import StarlinkAddressRow from './StarlinkAddressRow';


const StarlinkAddressFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
    </Filter>
);

const StarlinkAddressList = (props) => (
    <List {...props} filters={<StarlinkAddressFilter />}>
        <StarlinkAddressRow />
    </List>
);

export default StarlinkAddressList;