import React, { useState } from 'react';
import { useFormState, useForm } from 'react-final-form';
import { Title, TextInput, ReferenceInput, AutocompleteInput, Toolbar, SaveButton, useNotify, BooleanInput } from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core';
import SimCardType from './SimCardType';

import simcards from './';
import { CreateActions } from '../common/Create';
import SimpleForm from '../common/SimpleForm';
import { bulkCreateSimCard } from '../../services/api';

const styles = {
	title: {

	},
	summary: {
		marginTop: '15px',

		'& h3': {
			marginTop: '15px'
		},
		'& p': {
			whiteSpace: 'pre-wrap'
		}
		
	}
};

const optionText = choice => choice.name ? choice.name : '';
const providerOptionText = choice => `${choice.name} / ${choice.supplier_name } / ${choice.iccid_prefix}`;

const CustomSaveButton = ({ onClick, ...props }) => {
	const formState = useFormState();
	const form = useForm();
	const notify = useNotify();

	const handleClick = async () => {
		if(formState.values.sims_refs) {
			let data = new FormData();
			data.append("sims_refs", formState.values.sims_refs);

			if(formState.values.type) {
				data.append("type", formState.values.type);
			}
			if(formState.values.pool_id) {
				data.append("pool_id", formState.values.pool_id);
			}
			if(formState.values.provider_id) {
				data.append("provider_id", formState.values.provider_id);
			}
			if(formState.values.sponsored_imsi) {
				data.append("sponsored_imsi", formState.values.sponsored_imsi);
			}
			if(formState.values.carrier_name) {
				data.append("carrier_name", formState.values.carrier_name);
			}
			if(formState.values.active) {
				data.append("active", formState.values.active);
			}
			if(formState.values.notes) {
				data.append("notes", formState.values.notes);
			}
			if(formState.values.apn_1) {
				data.append("apn_1", formState.values.apn_1);
			}
			if(formState.values.apn_2) {
				data.append("apn_2", formState.values.apn_2);
			}
			if(formState.values.apn_3) {
				data.append("apn_3", formState.values.apn_3);
			}
			if(formState.values.preferred_mtu) {
				data.append("preferred_mtu", formState.values.preferred_mtu);
			}
			if(formState.values.pin) {
				data.append("pin", formState.values.pin);
			}
			if(formState.values.username) {
				data.append("username", formState.values.username);
			}
			if(formState.values.password) {
				data.append("password", formState.values.password);
			}
			if(formState.values.variant) {
				data.append("variant", formState.values.variant);
			}		

			data.append("dry", false);
			const results = await bulkCreateSimCard(data);

			if(results.success) {
				let message = `${results.created.length} element${results.created.length > 1 ? 's' : ''} created`;
				if (results.existing.length > 0) {
					message += `\n${results.existing.length} element${results.existing.length > 1 ? 's' : ''} existing`;
				}
				notify(message);

				if (results.created.length > 0 || results.existing.length > 0) {
					form.change('type', null);
					form.change('pool_id', null)
					form.change('sims_refs', null);
					form.change('provider_id', null);
					form.change('sponsored_imsi', null);
					form.change('carrier_name', null);
					form.change('active', null);
					form.change('notes', null);
					form.change('apn_1', null);
					form.change('apn_2', null);
					form.change('apn_3', null);
					form.change('preferred_mtu', null);
					form.change('pin', null);
					form.change('username', null);
					form.change('password', null);
					form.change('variant', null);
				}

				onClick({ results, summary: message });
			}
		}
	};

	return <SaveButton {...props} handleSubmit={handleClick} handleSubmitWithRedirect={handleClick} />;
};

const CustomToolbar = ({ onSave, ...props }) => (
	<Toolbar {...props}>
		<CustomSaveButton onClick={onSave} />
	</Toolbar>
);

const SimCardBulkCreate = ({ classes }) => {
	const [results, setResults] = useState(null);
	const [summary, setSummary] = useState(null);

	const breadcrumbPath = [
		{ url: '/simcards', title: "Simcards", icon: <simcards.icon /> },
		{
			url: '/simcards/bulk-create',
			title: "Bulk create",
			isActive: true
		}
	];

	const handleSave = async (result) => {
		setResults(result.results);
		setSummary(result.summary);
	}

	const renderSummary = () => {
		if (!results) {
			return null;
		}

		return (
			<Card className={classes.summary}>
				{results && (
					<CardContent>
						<Typography variant="h6" gutterBottom>Summary</Typography>
						<Typography>{summary}</Typography>

						{results.created.length > 0 && (
							<React.Fragment>
								<Typography variant="subtitle1">Created</Typography>
								{results.created.map((sim =>
									<Typography key={sim.iccid || sim.imsi}>{sim.iccid || sim.imsi}</Typography>
								))}
							</React.Fragment>
						)}

						{results.existing.length > 0 && (
							<React.Fragment>
								<Typography variant="subtitle1">Existing</Typography>
								{results.existing.map((sim =>
									<Typography key={sim.iccid || sim.imsi}>{sim.iccid || sim.imsi}</Typography>
								))}
							</React.Fragment>
						)}
					</CardContent>
				)}
			</Card>
		);
	}

	return (
		<React.Fragment>
			<CreateActions basePath="/simcards" hasList={true} breadcrumb={breadcrumbPath} />
			<Card>
				<Title title="Create Simcards" />
				<SimpleForm resource="simcards" toolbar={<CustomToolbar onSave={handleSave} />}  >
					<SimCardType />
					<ReferenceInput label="Pool" source="pool_id" reference="simpools" sort={{ field: "name", order: "ASC" }} allowEmpty>
						<AutocompleteInput optionText={optionText} />
					</ReferenceInput>
					<ReferenceInput label="Provider" source="provider_id" reference="providers" sort={{ field: 'name', order: 'ASC' }} allowEmpty>
						<AutocompleteInput optionText={providerOptionText} />
					</ReferenceInput>
					<TextInput source="carrier_name" />
					<BooleanInput source="active" />
					<TextInput source="notes" options={{ multiline: true }} rows="4" />
					<TextInput source="sponsored_imsi" />
					<TextInput source="apn_1" />
					<TextInput source="apn_2" />
					<TextInput source="apn_3" />
					<TextInput source="preferred_mtu" />
					<TextInput source="pin" />
					<TextInput source="username" />
					<TextInput source="password" />
					<TextInput source="variant" />
					<TextInput source="sims_refs" label="iccid, tel" rows="20" multiline fullWidth />
				</SimpleForm>
			</Card>

			{renderSummary()}
		</React.Fragment>
	);
};

export default withStyles(styles)(SimCardBulkCreate);