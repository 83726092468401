import React from 'react';
import {
    BooleanInput,
    TextInput,
    ReferenceInput,
    AutocompleteInput,
    TextField
} from 'react-admin';

import GetAppIcon from '@material-ui/icons/GetApp';

import List, { ListActions } from '../common/List';
import Filter from '../common/Filter';

import TaskRow from './TaskRow';
import StatusField from './StatusField';
import IconButton from '../common/IconButton';

import { API_URL } from '../../services/settings';


const userOptionText = choice => choice && choice.email ? choice.email : '';
const packageOptionText = choice => choice.reference ? choice.reference : '';

const TaskListActions = (props) => {
    return (
        <ListActions {...props}>
            <IconButton icon={<GetAppIcon />} component="a" href={`${API_URL}/lines/export.xls`}>Export</IconButton>
        </ListActions>
    );
};

const TaskFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <ReferenceInput label="Customer" source="customer_id" reference="customers" sort={{ field: 'name', order: 'ASC' }} alwaysOn>
            <AutocompleteInput />
        </ReferenceInput>
        <ReferenceInput label="Package" source="package_id" reference="packages" sort={{ field: 'reference', order: 'ASC' }} allowEmpty alwaysOn>
            <AutocompleteInput optionText={packageOptionText} />
        </ReferenceInput>
        <StatusField label="Status" source="status" alwaysOn />
        <ReferenceInput label="Assigned To" source="assigned_to_id" reference="users" filter={{ is_staff: true }} sort={{ field: 'name', order: 'ASC' }} alwaysOn >
            <AutocompleteInput optionText={userOptionText} />
        </ReferenceInput>
        <BooleanInput label="Archived" source="archived" alwaysOn />
    </Filter>
);

const filterDefaultValues = {
    archived: false
}

const Row = (props) => (
    <TaskRow {...props}>
        {props.filterValues.archived && <TextField source="archived_reason" />}
    </TaskRow>
);

const TaskList = props => (
    <List {...props} filters={<TaskFilter />} actions={<TaskListActions />} filterDefaultValues={filterDefaultValues} exporter={false}>
        <Row />
    </List>
);

export default TaskList;