import React from 'react';
import { SelectInput } from 'react-admin';

const choices = [
    { id: 'draft', name: 'Draft' },
    { id: 'provisioning', name: 'Provisioning' },
    { id: 'deployed', name: 'Deployed' },
    { id: 'paused', name: 'Paused' },
    { id: 'ended', name: 'Ended' },
];

const StatusInput = ({ classes, record, source = "status", ...props }) => {
	if (!record) {
		return null;
	}

	return (
		<SelectInput source={source} choices={choices} record={record} {...props} />
	);
};

export default StatusInput;
