import React from 'react';

import RemoveFromPoolButton from './RemoveFromPoolButton';
import EnableDisableButton from './EnableDisableButton';
import MoveToPoolButton from './MoveToPoolButton';
import AttachDetachSimCardButton from './AttachDetachSimCardButton';


const SimCardActionsButtons = ({ record }) => (
	<>
		<MoveToPoolButton record={record} />
		<EnableDisableButton record={record} />
		<RemoveFromPoolButton record={record} />
		<AttachDetachSimCardButton record={record} />
	</>
);

export default SimCardActionsButtons;