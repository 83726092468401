import React from 'react';

import ICServerSyncButton from './ICServerSyncButton';
import ICServerRefreshOrganizationsButton from './ICServerRefreshOrganizationsButton';


const ICServerActionsButtons = ({ record }) => (
	<>
		<ICServerSyncButton record={record} />
		<ICServerRefreshOrganizationsButton record={record} />
	</>
);

export default ICServerActionsButtons;