import React from 'react';
import { TextField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    container: {
        paddingLeft: '10px',
        paddingRight: '10px'
    },
    circle: {
        display: 'inline-block',
        width: '7px',
        height: '7px',
        borderRadius: '12px',
        backgroundColor: (props) => props.backgroundColor ? props.backgroundColor : '#B3B3B3',
        marginRight: '5px'
    }
});

const statuses = {
    'DRAFT': 'DRAFT',
    'PROVISIONING': 'PROVISIONING',
    'PROVISIONED': 'PROVISIONED',
    'SHIPPED': 'SHIPPED',
    'DELIVERED': 'DELIVERED',
    'IDLE': 'IDLE',
};

const StatusField = ({ record, source, ...props }) => {
    let backgroundColor;
    const status = record ? record[source] : null;

    switch (status) {
        case 'DRAFT':
            backgroundColor = '#B3B3B3';
            break;
        case 'PROVISIONING':
            backgroundColor = '#FFB735';
            break;
        case 'PROVISIONED':
            backgroundColor = '#FFB735';
            break;
        case 'SHIPPED':
            backgroundColor = '#FFB735';
            break;
        case 'DELIVERED':
            backgroundColor = '#80F563';
            break;
        default:
            backgroundColor = '#B3B3B3';
            break;
    }
    const classes = useStyles({ backgroundColor });

    if (!record) {
        return null;
    }

    return (
        <span className={classes.container}>
            <span className={classes.circle} />
            <TextField source={status} record={statuses} {...props} />
        </span>
    );
};

StatusField.defaultProps = {
    source: 'status',
    label: 'Status',
    addLabel: true
};

export default StatusField;
