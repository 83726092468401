import React from 'react';
import {
    BooleanInput,
    TextInput,
    ReferenceInput,
    AutocompleteInput,
    TextField
} from 'react-admin';

import GetAppIcon from '@material-ui/icons/GetApp';

import List, { ListActions } from '../common/List';
import Filter from '../common/Filter';

import LineRow from './LineRow';
import StatusField from './StatusField';
import AccountingStatusField from './AccountingStatus';
import IconButton from '../common/IconButton';
import LineTypeField from './LineTypeField';
import LineBulkActions from './LineActionsButtons/LineBulkActions';

import { API_URL } from '../../services/settings';


const optionText = choice => choice.name ? choice.name : '';
const userOptionText = choice => choice && choice.email ? choice.email : '';

const LineListActions = (props) => {
    return (
        <ListActions {...props}>
            <IconButton icon={<GetAppIcon />} component="a" href={`${API_URL}/lines/export.xls`}>Export</IconButton>
        </ListActions>
    );
};

const LineFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <ReferenceInput label="Customer" source="customer_id" reference="customers" sort={{ field: 'name', order: 'ASC' }} alwaysOn>
            <AutocompleteInput />
        </ReferenceInput>
        <ReferenceInput label="Provider" source="provider_id" reference="line_providers" sort={{ field: 'name', order: 'ASC' }} allowEmpty alwaysOn>
            <AutocompleteInput optionText={optionText} />
        </ReferenceInput>
        <StatusField label="Status" source="status" alwaysOn />
        <LineTypeField label="Type" source="line_type" alwaysOn />
        <BooleanInput label="Archived" source="archived" alwaysOn />
        <BooleanInput label="Attached" source="attached" />
        <BooleanInput label="Next Action Due" source="next_action_due" />
        <AccountingStatusField label="Accounting Status" source="accounting_status" />
        <ReferenceInput label="Assigned To" source="assigned_to_id" reference="users" filter={{ is_staff: true }} sort={{ field: 'name', order: 'ASC' }} >
            <AutocompleteInput optionText={userOptionText} />
        </ReferenceInput>
    </Filter>
);

const filterDefaultValues = {
    archived: false
}

const Row = (props) => (
    <LineRow {...props}>
        {props.filterValues.archived && <TextField source="archived_reason" />}
    </LineRow>
);

const LineList = props => (
    <List {...props} filters={<LineFilter />} actions={<LineListActions />} filterDefaultValues={filterDefaultValues} bulkActionButtons={<LineBulkActions />} exporter={false}>
        <Row />
    </List>
);

export default LineList;