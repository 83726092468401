import SettingsInputAntennaIcon from '@material-ui/icons/SettingsInputAntenna';

import StarlinkSubscriptionList from './StarlinkSubscriptionList';
import StarlinkSubscriptionShow from './StarlinkSubscriptionShow';

export default {
	list: StarlinkSubscriptionList,
	show: StarlinkSubscriptionShow,
	icon: SettingsInputAntennaIcon,
	title: "Starlink subscriptions"
};
