import React from 'react';
import {
    BooleanInput,
    TextInput
} from 'react-admin';

import GetAppIcon from '@material-ui/icons/GetApp';

import AddressRow from './AddressRow';
import List, { ListActions } from '../common/List';
import Filter from '../common/Filter';
import IconButton from '../common/IconButton';

import { API_URL } from '../../services/settings';



const SiteListActions = (props) => (
    <ListActions {...props}>
        <IconButton icon={<GetAppIcon />} component="a" href={`${API_URL}/addresses/export.xls`}>Export</IconButton>
    </ListActions>
);

const AddressFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <BooleanInput label="Archived" source="archived" alwaysOn />
    </Filter>
);

const filterDefaultValues = {
    archived: false
};

const AddressList = props => (
    <List {...props} filters={<AddressFilter />} filterDefaultValues={filterDefaultValues} actions={<SiteListActions />} exporter={false} >
        <AddressRow />
    </List>
);

export default AddressList;