import React from 'react';
import { SelectInput } from 'react-admin';


const choices = [
	{ id: 'INCONTROL', name: 'INCONTROL' },
	{ id: 'TELNA', name: 'TELNA' },
];

const SiteDataSourceField = ({ classes, record, source="contract_data_source", ...props }) => {
	if (!record) {
		return null;
	}

	return (
		<SelectInput choices={choices} source={source} record={record} {...props} />
	);
};

export default SiteDataSourceField;
