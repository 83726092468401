import React from 'react';
import { TextField, SimpleShowLayout } from 'react-admin';

import Datagrid from '../common/Datagrid';
import DateField from '../common/fields/DateField';
import BooleanField from '../common/fields/BooleanField';
import LinkFieldButton from '../common/LinkFieldButton';
import RecordSplitButton from '../common/RecordSplitButton';
import { FreshdeskLink } from './CustomerReferenceField';
import ImpersonateButton from './CustomerActionsButtons/ImpersonateButton';

const CustomerDrawer = (props) => (
	<SimpleShowLayout {...props}>
		<TextField source="id" />
		<TextField source="name" />
		<TextField source="short_name" />
		<TextField source="sites_count" label="#Sites" />
		<TextField source="devices_count" label="#Devices" />
		<BooleanField source="nightswatch" />
		<TextField source="support_level" />
		<TextField source="default_data_limit_contacts" />
		<TextField source="description" label="Notes"/>
		<DateField label="Created at" source="created_at" />
		<DateField label="Updated at" source="updated_at" />
		<DateField label="Archived at" source="archived_at" />
	</SimpleShowLayout>
);

const CustomerRow = ({ children, ...props }) => (
	<Datagrid drawer={<CustomerDrawer {...props} />} {...props}>
		<LinkFieldButton label="Name" sortBy="name">
			<TextField source="name" />
		</LinkFieldButton>
		<TextField source="description" label="Notes" />
		<TextField source="short_name" />
		<TextField source="sites_count" label="#Sites" sortable={false} />
		<TextField source="devices_count" label="#Devices" sortable={false} />
		<TextField source="support_level" />
		<DateField source="updated_at" />
		<RecordSplitButton />
		<ImpersonateButton />
		<FreshdeskLink>Freshdesk</FreshdeskLink>
		{children}
	</Datagrid>
)

CustomerRow.defaultProps = {
	basePath: '/customers'
};

export default CustomerRow;