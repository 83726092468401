import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextInput, FormTab, PasswordInput, Toolbar, SaveButton, useNotify, required, minLength } from 'react-admin';
import { useFormState } from 'react-final-form';
import Card from '@material-ui/core/Card';
import { Title } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import TabbedForm from './common/TabbedForm';

import { getAccount, updateAccount } from '../services/api';
import { changeTheme } from '../actions/theme';


const CustomToolbar = ({ onSave, ...props }) => {
    const formState = useFormState();
    const notify = useNotify();

    const handleClick = async () => {
        if(formState.valid) {
            const response = await updateAccount(JSON.stringify(formState.values));
            if(response.errors) {
                let message = '';
                for(const error in response.errors) {
                    message += `${error}: ${response.errors[error]}\n`;
                }
                notify(message, "warning");
            }
            else {
                notify("Account updated");
            }
        }
    };

    return (
        <Toolbar {...props}>
            <SaveButton handleSubmit={handleClick} handleSubmitWithRedirect={handleClick} />
        </Toolbar>
    );
};

const Account = () => {
    const [account, setAccount] = useState({});
    const theme = useSelector((state) => state.theme);
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
            const response = await getAccount();
            setAccount(response);
        };

        fetchData();
    }, []);

    const handleChange = () => {
        dispatch(changeTheme(theme === 'light' ? 'dark' : 'light'));
    };

    return (
        <Card>
            <Title title="Account" />
            <TabbedForm record={account} toolbar={<CustomToolbar />}>
                <FormTab label="Account">
                    <Typography variant="h5">Settings</Typography>
                    <TextInput source="email" type="email" validate={required()} />
                    <TextInput source="firstname" validate={required()} />
                    <TextInput source="lastname" validate={required()} />
                    <PasswordInput source="password" validate={minLength(8)} />
                    <FormControl>
                        <FormGroup aria-label="position" row>
                            <FormControlLabel
                                control={<Switch color="primary" onChange={handleChange} checked={theme === 'dark'} />}
                                label="Dark theme"
                                labelPlacement="start"
                            />
                        </FormGroup>
                    </FormControl>
                </FormTab>
                {/* <FormTab label="Two-factor authentication">
                    <BooleanInput source="mfa_enabled" />
                </FormTab> */}
            </TabbedForm>
        </Card>
    );
};

export default Account;