import React from 'react';
import { AutocompleteInput } from 'react-admin';

const choices = [
    { id: 'AVAILABLE', name: 'AVAILABLE' },
    { id: 'ACTIVE', name: 'ACTIVE' },
    { id: 'RESERVED', name: 'RESERVED' },
    { id: 'SUSPENDED', name: 'SUSPENDED' },
    { id: 'TERMINATED', name: 'TERMINATED' },
	{ id: 'UNKNOWN', name: 'UNKNOWN' }
];

const SimCardStatusInput = ({ classes, record, source = "status", ...props }) => {
	if (record && record[source] && !choices.find((provider) => provider.id === record[source])) {
		choices.push({ id: record[source], name: record[source]});
	}

	return (
		<AutocompleteInput choices={choices} source={source} record={record} allowEmpty {...props} />
	);
};

export default SimCardStatusInput;
