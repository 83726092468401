import React, { Component } from 'react';
import moment from 'moment';
import filesize from 'filesize';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';

import GraphTooltip from '../common/GraphTooltip';

import { getDeviceUsage } from '../../services/api';

const CustomToolTip = props => {
  const { active, payload, label } = props;
  if (!active || !payload) {
    return null;
  }
  return (
    <GraphTooltip>
      <p>
        <strong>{ moment(label).format('DD-MM-YY')}</strong>
      </p>
      {payload.map((item, i) => (
        <p key={i}>Usage: <strong>{filesize(item.value*1024)}</strong></p>
      ))}
    </GraphTooltip>
  );
};

export default class DeviceUsageGraph extends Component {
  state = {
		data: []
  };

  fetchData = async () => {
    const { record } = this.props;
    const results = await getDeviceUsage(record.id);
		this.setState(results);
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.record.id !== this.props.record.id) {
      this.fetchData();
    }
  }

  render() {
    const { small } = this.props;

    return (
      <ResponsiveContainer width="100%" height={small ? 200 : 400}>
        <BarChart
          data={this.state.data}
          margin={{
            top: 50, right: 30, left: 20, bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="day" tickFormatter={datetime => moment(datetime).format('YYYY-MM-DD')}/>
          <YAxis tickFormatter={tick => { return filesize(tick*1024);}}/>
          <Tooltip content={<CustomToolTip />}/>
          <Legend />
          <Bar name="Data Usage" dataKey="value" fill="#3A8B7E" />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}
