import React from 'react';
import get from 'lodash/get';
import { compose, withProps } from 'recompose';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';

import { GOOGLE_MAP_API_KEY } from '../../services/settings';

const GoogleMapComponent = compose(
	withProps(props => {
		return {
			googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
			loadingElement: <div style={{ height: `100%` }} />,
			containerElement: <div style={{ height: props.height || `400px` }} />,
			mapElement: <div style={{ height: `100%` }} />,
			defaultZoom: 12
		}
	}),
	withScriptjs,
	withGoogleMap
)(({ record = {}, source, defaultZoom, lat, lng, ...props }) => (
	<GoogleMap
		defaultZoom={defaultZoom}
		defaultCenter={{ lat, lng }}
		center={{ lat, lng }}
		{...props}
	>
		<Marker position={{ lat, lng }} />
	</GoogleMap>
));

const MapComponent = ({ record = {}, source, ...props }) => {
	let obj = record;
	if (source) {
		obj = get(record, source);
	}
	if (!obj || !obj.lat || !obj.lng) {
		return <p></p>;
	}

	return (
		<GoogleMapComponent
			lat={obj.lat}
			lng={obj.lng}
			{...props}
		/>
	);
};

export default MapComponent;
