import RouterIcon from '@material-ui/icons/Router';

import DeviceList from './DeviceList';
import DeviceCreate from './DeviceCreate';
import DeviceShow from './DeviceShow';
import DeviceEdit from './DeviceEdit';


export default {
	list: DeviceList,
	create: DeviceCreate,
	show: DeviceShow,
	edit: DeviceEdit,
	icon: RouterIcon
};
