import React from 'react';
import { AutocompleteInput } from 'react-admin';


const choices = [
	{ id: 'no', name: 'No Support' },
	{ id: 'basic', name: 'Basic' },
	{ id: 'intermediate', name: 'Intermediate' },
	{ id: 'advanced', name: 'Advanced' },
];

const CustomerSupportField = ({ classes, record, source = "support_level", ...props }) => {
	if (!record) {
		return null;
	}

	if (record[source] && !choices.find((provider) => provider.id === record[source])) {
		choices.push({ id: record[source], name: record[source]});
	}

	return (
		<AutocompleteInput choices={choices} source={source} record={record} allowEmpty {...props} />
	);
};

export default CustomerSupportField;
