import React from 'react';
import { TextField } from 'react-admin';

const MultilineTextField = ({ ...props }) => (
    <TextField style={{ whiteSpace: 'pre-wrap' }} {...props} />
);

MultilineTextField.defaultProps = {
    addLabel: true
};

export default MultilineTextField;