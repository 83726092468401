import React from 'react';

import PingButton from './PingButton';

const IPActionsButtons = ({ record }) => (
	<>
		<PingButton record={record} />
	</>
);

export default IPActionsButtons;