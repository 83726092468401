import { createMuiTheme } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

import variables from './variables';

const defaultTheme = createMuiTheme({
	palette: {
		primary: {
			main: variables.colors.primary
		}
	},
	typography: {
		fontFamily: ['TT Commons', 'Roboto', 'sans-serif'].join(', ')
	},
});
const theme = {
	palette: {
		primary: {
			main: variables.colors.primary
		}
	},
	overrides: {
		MuiAppBar: {
			root: {
				boxShadow: '0px 1px 30px 1px rgba(0,0,0,0.11)',
				zIndex: 1300
			}
		},
		MuiDrawer: {
			docked: {
				minHeight: 'calc(100vh - 48px)',
				boxShadow: '0 1px 30px 1px rgba(0, 0, 0, 0.11)',
				marginRight: '19px',

				'& > div': {
					height: 'calc(100% - 1.5em)',
					display: 'flex',
					flexDirection: 'column',

					'& .build-date': {
						padding: '5px',
						justifySelf: 'flex-end',
						alignSelf: 'center',
						marginTop: 'auto'
					}
				}
			}
		},
		MuiCard: {
			root: {
				boxShadow: '0 1px 15px 1px rgba(0, 0, 0, 0.11)'
			}
		},
		MuiPaper: {
			elevation4: {
				boxShadow: '0 1px 15px 1px rgba(0, 0, 0, 0.11)'
			}
		},
		MuiFormControl: {
			root: {
				width: '100%',
				'.ra-input &': {
					minWidth: '50%',
				}
			}
		},
		MuiSnackbarContent: {
			message: {
				whiteSpace: 'pre-wrap'
			}
		},
		MuiMenuItem: {
			root: {
				'@media (min-width: 600px)': {
					minHeight: '36px'
				}
			}
		},
		MuiButton: {
			text: {
				'& span': {
					fontSize: '13px',
					lineHeight: '13px'
				}
			}
		}
	},
	props: {
		MuiInputBase: {
			autoComplete: 'off'
		}
	}
};

const lightTheme = createMuiTheme({
	...defaultTheme,
	overrides: {
		RaLayout: {
			root: {
				backgroundColor: fade(theme.palette.primary.main, 0.1)
			}
		},
		RaMenuItemLink: {
			root: {
				'&:hover': {
					backgroundColor: fade(theme.palette.primary.main, 0.1) + '!important',
					color: theme.palette.primary.main,
					'& svg': {
						color: theme.palette.primary.main
					}
				}
			},
			active: {
				backgroundColor: fade(theme.palette.primary.main, 0.1) + '!important',
				color: theme.palette.primary.main,
				'& svg': {
					color: theme.palette.primary.main
				}
			}
		},
		MuiDrawer: {
			docked: {
				backgroundColor: 'white'
			}
		}
	}
}, theme);

const defaultDarkTheme = createMuiTheme({ palette: { type: 'dark' } });
const darkTheme = createMuiTheme({
	...defaultTheme,
	palette: {
		type: 'dark',
		primary: {
			main: variables.colors.primary
		}
	},
	overrides: {
		MuiDrawer: {
			docked: {
				backgroundColor: defaultDarkTheme.palette.background.paper
			}
		},
		MuiFormGroup: {
			root: {
				color: defaultDarkTheme.palette.text.primary
			}
		},
		MuiCssBaseline: {
			'@global': {
				'::-webkit-calendar-picker-indicator': {
					filter: 'invert(1)'
				},
				'a': {
					color: variables.colors.primary
				}
			}
		}
	}
}, theme);

export { lightTheme, darkTheme, theme };
