import green from '@material-ui/core/colors/green';
import orange from '@material-ui/core/colors/orange';
import red from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';

const rowStyle = (record, index, defaultStyle = {}) => {
	if (record.status_led === 'green')
		return {
			...defaultStyle,
			borderLeftColor: green[500],
			borderLeftWidth: 5,
			borderLeftStyle: 'solid',
		};
	if (record.status_led === 'yellow')
		return {
			...defaultStyle,
			borderLeftColor: orange[500],
			borderLeftWidth: 5,
			borderLeftStyle: 'solid',
		};
	if (record.status_led === 'red')
		return {
			...defaultStyle,
			borderLeftColor: red[500],
			borderLeftWidth: 5,
			borderLeftStyle: 'solid',
		};
	if (record.status_led === 'gray')
		return {
			...defaultStyle,
			borderLeftColor: grey[500],
			borderLeftWidth: 5,
			borderLeftStyle: 'solid',
		};
	return defaultStyle;
};

export default rowStyle;