import React from 'react';
import { TabbedShowLayout, Tab, TextField, FunctionField, ReferenceField, ArrayField, ReferenceManyField, Pagination } from 'react-admin';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import IconAdd from '@material-ui/icons/Add';

import Show, { ShowActions } from '../common/Show';
import Datagrid from '../common/Datagrid';
import DateField from '../common/fields/DateField';
import MultilineTextField from '../common/fields/MultilineTextField';
import NumberCurrencyField from '../common/fields/NumberCurrencyField';
import InvoiceActionsButtons from './InvoiceActionsButtons';
import InvoiceLineRow from '../invoice_lines/InvoiceLineRow';
import IconButton from '../common/IconButton';

import StatusField from './fields/StatusField';
import VatField from './fields/VatField';

import { API_URL } from '../../services/settings';

const useStyles = makeStyles({
    iframe: {
        width: '100%',
        minHeight: '100vh',
        border: 'none'
    }
});

const InvoiceTitle = ({ record }) => (
    <>
        <Helmet>
            <title>{`KB - Invoice[${record.number}]`}</title>
        </Helmet>
        <span>{record.number}</span>
    </>
);

const InvoiceActions = (props) => (
    <ShowActions {...props} hasEdit={props?.data?.status === 'draft'}>
        <InvoiceActionsButtons record={props?.data} />
        { props?.data?.status === 'draft' && <IconButton
			component={Link}
			to={{
				pathname: '/invoice_lines/create',
				state: {
					record: {
						invoice_id: props.data?.id ? parseInt(props.data.id) : '',
					},
					_scrollToTop: true
				},
			}}
			icon={<IconAdd />}
		>
			Add Line
		</IconButton>
        }
    </ShowActions>
);

const InvoicePDF = ({ record }) => {
    const classes = useStyles();

    return (
        <iframe
            className={classes.iframe}
            title="PDF"
            src={`${API_URL}/invoices/${record?.id}/invoice${record?.number ? `_${record.number}` : ''}.pdf`}
        />
    );
};

const LineList = (props) => (
    <ArrayField {...props} source="lines">
        <Datagrid>
            <TextField source="package_reference" />
            <MultilineTextField source="description" />
            <TextField source="qty" label="Quantity" />
            <FunctionField label="Price" render={record => <NumberCurrencyField record={{ currency: props?.record?.currency, ...record }} source="unit_price" />} />
            <VatField />
            <FunctionField label="Total excl. VAT" render={record => <NumberCurrencyField record={{ currency: props?.record?.currency, value: record.qty * record.unit_price }} source="value" />} />
        </Datagrid>
    </ArrayField>
);

LineList.defaultProps = {
    addLabel: true
};

const InvoiceShow = (props) => (
    <Show title={<InvoiceTitle />} actions={<InvoiceActions />} {...props}>
        <TabbedShowLayout>
            <Tab label="PDF">
                <InvoicePDF />
            </Tab>
            <Tab label="summary">
                <TextField label="Id" source="id" />
                <TextField source="number" />
                <StatusField />
                <ReferenceField label="Billing company" source="billing_company_id" reference="companies" allowEmpty>
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField label="Customer" source="customer_id" reference="customers" allowEmpty>
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField label="Invoicing address" reference="addresses" source="invoicing_address_id" allowEmpty>
                    <FunctionField render={record => (
                        <>
                            {record.name}<br />
                            {record.street}<br />
                            {record.zipcode} {record.city}<br />
                            {record.country}<br />
                            {record.vat}
                        </>
                    )} />
                </ReferenceField>
                <TextField source="number" />
                <DateField source="invoiced_at" />
                <DateField source="due_date" />
                <TextField label="Customer Reference" source="purchase_order" />
                <LineList label="Lines" />
                <NumberCurrencyField source="total_untaxed" />
                <NumberCurrencyField source="total_tax" />
                <NumberCurrencyField source="total" />
                <MultilineTextField source="terms" />
                <MultilineTextField source="notes" />
                <DateField label="Created at" source="created_at" />
                <DateField label="Updated at" source="updated_at" />
            </Tab>
            <Tab label="Lines" path="invoice_lines">
				<ReferenceManyField addLabel={false} reference="invoice_lines" target="invoice_id" pagination={<Pagination />} filter={{ archived: false }}>
					<InvoiceLineRow />
				</ReferenceManyField>
			</Tab>
        </TabbedShowLayout>
    </Show>
);

export default InvoiceShow;
