import React from 'react';

import Create from '../common/Create';
import SimpleForm from '../common/SimpleForm';
import InvoiceForm from './InvoiceForm';

const initialValues = {
	bank_iban: '',
	bank_bic: '',
	number: '',
	purchase_order: '',
	invoiced_at: new Date(),
	due_date: null,
	terms: '',
	notes: '',
	currency: 'EUR'
};

const InvoiceCreate = (props) => (
	<Create {...props}>
		<SimpleForm initialValues={initialValues}>
			<InvoiceForm />
		</SimpleForm>
	</Create>
);

export default InvoiceCreate;