import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';

import CompanyList from './CompanyList';
import CompanyCreate from './CompanyCreate';
import CompanyShow from './CompanyShow';
import CompanyEdit from './CompanyEdit';

export default {
	list: CompanyList,
	create: CompanyCreate,
	show: CompanyShow,
	edit: CompanyEdit,
	icon: EuroSymbolIcon
};
