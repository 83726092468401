import React, { useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { SaveButton as RASaveButton, ReferenceInput, AutocompleteInput, useRefresh, required, FormDataConsumer } from 'react-admin';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import SimpleForm from '../../common/SimpleForm';
import IconButton from '../../common/IconButton';
import SiteReferenceInputWithCreate from '../../sites/SiteReferenceInputWithCreate';
import Toolbar from './Toolbar';
import DeployReasonInput from '../DeployReasonInput';

import { moveDevice } from '../../../services/api';

const optionText = choice => choice && choice.name ? choice.name : '';

const SaveButton = ({ onClick, record, ...props }) => {
	const formState = useFormState();
	const form = useForm();

	const handleClick = async () => {
		let formData = new FormData();
		formData.append("site_id", formState.values.site_id);
		if (formState.values.reason) {
			formData.append("reason", formState.values.reason);
		}
		await moveDevice(record.id, formData);
		form.reset();
		onClick();
	};

	return <RASaveButton {...props} handleSubmit={handleClick} handleSubmitWithRedirect={handleClick} />;
};

const MoveDeviceButton = ({ record, variant, color }) => {
	const refresh = useRefresh();
	const [showDialog, setShowDialog] = useState(false);

	const handleSave = async () => {
		handleClose();
		refresh();
	};

	const handleOpen = () => {
		setShowDialog(true);
	};

	const handleClose = () => {
		setShowDialog(false);
	};

	if (!record) {
		return null;
	}

	return (
		<>
			<IconButton icon={<SwapHorizIcon />} onClick={handleOpen} variant={variant} color={color}>Move</IconButton>
			<Dialog fullWidth open={showDialog} onClose={handleClose} aria-label="Move device">
				<DialogTitle>Move device</DialogTitle>
				<DialogContent>
					Are you sure to move the device {record.name} ({record.serial})?
					<SimpleForm toolbar={<Toolbar onClose={handleClose} onSave={handleSave} saveButton={<SaveButton onClick={handleSave} record={record} />} />}>
						<ReferenceInput label="Customer" source="customer_id" reference="customers" sort={{ field: 'name', order: 'ASC' }}>
							<AutocompleteInput optionText={optionText} />
						</ReferenceInput>
						<FormDataConsumer>
						{({ formData, ...rest }) => (
							<SiteReferenceInputWithCreate
								{...rest}
								label="Site"
								source="site_id"
								reference="sites"
								filter={formData.customer_id && { customer_id: formData.customer_id }}
								validate={required()}
								key={formData.customer_id}
							/>
						)}
						</FormDataConsumer>
						<DeployReasonInput source="reason" />
					</SimpleForm>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default MoveDeviceButton;