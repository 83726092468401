import React, { useState } from 'react';
import { useRefresh, useNotify, useUnselectAll, SaveButton as RASaveButton/*, DateTimeInput*/ } from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import SpeedIcon from '@material-ui/icons/Speed';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import IconButton from '../../../common/IconButton';
import SimpleForm from '../../../common/SimpleForm';
import Toolbar from '../Toolbar';

import { bulkSpeedTestDevices } from '../../../../services/api';


const SaveButton = ({ onClick, selectedIds, ...props }) => {
    const formState = useFormState();
    const refresh = useRefresh();
    const form = useForm();
    const notify = useNotify();
    const unselectAll = useUnselectAll();

    const handleClick = async () => {
        const formData = new FormData();
        formData.append('device_serials', selectedIds.join(';'));
        if (formState.values.scheduled_at) {
            formData.append('scheduled_at', formState.values.scheduled_at);
        }

        const response = await bulkSpeedTestDevices(formData);
        if (response.success === true) {
            refresh();
            notify(response.message);
        }
        else if (response.reason) {
            notify(response.reason, 'warning');
        }
        else {
            notify('SpeedTest failed', 'warning');
        }

        unselectAll('devices');
        form.reset();
        onClick();
    };

    return <RASaveButton {...props} label="OK" handleSubmit={handleClick} handleSubmitWithRedirect={handleClick} />;
};

const BulkSpeedTestButton = ({ variant, color, ...props }) => {
    const [showDialog, setShowDialog] = useState(false);

    const handleSave = () => {
        handleClose();
    };

    const handleOpen = () => {
        setShowDialog(true);
    };

    const handleClose = () => {
        setShowDialog(false);
    };

    return (
        <>
            <IconButton onClick={handleOpen} icon={<SpeedIcon />} color={color} variant={variant}>Speedtest</IconButton>
            <Dialog fullWidth open={showDialog} onClose={handleClose} aria-label="Speedtest devices">
                <DialogTitle>Speedtest {props.selectedIds.length} devices</DialogTitle>
                <DialogContent>
                    {`Are you sure you want to speedtest these ${props.selectedIds.length} devices?`}
                    <SimpleForm toolbar={<Toolbar onClose={handleClose} onSave={handleSave} saveButton={<SaveButton onClick={handleSave} selectedIds={props.selectedIds} />} />}>
                        {/* TODO: for future step */}
                        {/* <DateTimeInput source="scheduled_at" /> */}
                    </SimpleForm>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default BulkSpeedTestButton;