import React from 'react';
import { AutocompleteInput } from 'react-admin';

const choices = [
    { id: 'BE', name: 'Belgium' },
    { id: 'FR', name: 'France' },
    { id: 'NL', name: 'Netherlands' },
];

const CountryInput = ({ classes, record, source, ...props }) => {
	if (!record) {
		return null;
	}

	if (record[source] && !choices.find((provider) => provider.id === record[source])) {
		choices.push({ id: record[source], name: record[source]});
	}

	return (
		<AutocompleteInput choices={choices} source={source} record={record} allowEmpty {...props} />
	);
};

CountryInput.props = {
    source: 'country'
};

export default CountryInput;
