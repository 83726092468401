import React from 'react';
import {
    Datagrid,
    TextInput,
    TextField,
    DateField,
    UrlField,
} from 'react-admin';

import List from '../common/List';
import Filter from '../common/Filter';
import HumanDateField from '../common/fields/HumanDateField';
import RecordSplitButton from '../common/RecordSplitButton';
import LinkFieldButton from '../common/LinkFieldButton';
import ICServerRefreshOrganizationsButton from './ICServerActionsButtons/ICServerRefreshOrganizationsButton';
import ICServerSyncButton from './ICServerActionsButtons/ICServerSyncButton';

const ICServerFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
    </Filter>
);

const ICServerList = props => (
    <List {...props } filters={<ICServerFilter/>}>
        <Datagrid>
            <LinkFieldButton label="id" sortBy="id">
                <TextField source="id" />
            </LinkFieldButton>
            <LinkFieldButton label="Name" sortBy="name">
                <TextField source="name" />
            </LinkFieldButton>
            <UrlField source="base_url" />
            <TextField source="version" />
            <TextField source="organizations_count" label="Synchronized"/>
            <TextField source="available_organizations_count" label="Available" />
            <DateField source="updated_at" />
            <HumanDateField source="last_sync_at" />
            <RecordSplitButton>
                <ICServerSyncButton />
                <ICServerRefreshOrganizationsButton />
            </RecordSplitButton>
        </Datagrid>
    </List>
);
export default ICServerList;
