import React from 'react';
import { TextField, Link } from 'react-admin';
import RouterIcon from '@material-ui/icons/Router';

import LinkFieldButton from '../../../common/LinkFieldButton';
import DateField from '../../../common/fields/DateField';
import NumberCurrencyField from '../../../common/fields/NumberCurrencyField';
import ServiceCard from './ServiceCard';
import StatusField from './StatusField';


const SDWANServiceCard = ({ record }) => (
	<Link to={`/services/${record.id}/show`}>
		<ServiceCard
			header={
				<>
					SDWAN
					{ record.package_line_id ? '' : '' }
					{record.device ? (
						<>
							{` - `}
							<LinkFieldButton label="Device" source="device" basePath="/devices" record={record}>
								<TextField source="serial" />
							</LinkFieldButton>
						</>
					) : ''}
				</>
			}
			title={
				<LinkFieldButton record={record} basePath="/services">
					<TextField source="name" />
				</LinkFieldButton>
			}
			content={<>Price: <NumberCurrencyField source="price" record={record} /></>}
			footer={
				record?.start_date ? (
					<>
						<DateField source="start_date" record={record} /> - {record.end_date ? <DateField source="end_date" record={record} /> : 'Running'}
						<StatusField record={record} />
					</>
				) : <StatusField record={record} />
			}
			icon={<RouterIcon />}
		/>
	</Link>
);

export default SDWANServiceCard;
