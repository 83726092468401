import React from 'react';
import {
    TextInput,
    ReferenceInput,
    AutocompleteInput,
    BooleanInput,
    TextField
} from 'react-admin';
import { Link } from 'react-router-dom';

import GetAppIcon from '@material-ui/icons/GetApp';

import SimCardRow from './SimCardRow';
import List, { ListActions } from '../common/List';
import Filter from '../common/Filter';
import IconButton from '../common/IconButton';
import IconAdd from '@material-ui/icons/Add';
import SimCardSourceField from './SimCardSourceField';
import SimCardStatusInput from './SimCardSourceField';

import SimCardBulkActions from './SimCardActionsButtons/SimCardBulkActions';

import { API_URL } from '../../services/settings';
import SimCardType from './SimCardType';

const optionText = choice => choice.name ? choice.name : '';
const providerOptionText = choice => choice?.name ? `${choice.name} / ${choice.supplier_name } / ${choice.iccid_prefix}` : '';

const SimCardFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <ReferenceInput label="Provider" source="provider_id" reference="providers" allowEmpty alwaysOn sort={{ field: 'name', order: 'ASC' }} >
            <AutocompleteInput optionText={providerOptionText} />
        </ReferenceInput>
        <ReferenceInput label="Supplier" source="supplier_name" reference="data_suppliers" allowEmpty alwaysOn>
            <AutocompleteInput optionText={optionText} />
        </ReferenceInput>
        <ReferenceInput label="Customer" source="customer_id" reference="customers" allowEmpty sort={{ field: 'name', order: 'ASC' }}>
            <AutocompleteInput optionText={optionText} />
        </ReferenceInput>
        <SimCardType label="Type" source="type" alwaysOn />
        <BooleanInput label="Owned by customer" source="owned_by_customer" alwaysOn />
        <BooleanInput label="In Device" source="in_device" alwaysOn />
        <BooleanInput label="In Pool" source="in_pool" />
        <BooleanInput label="Archived" source="archived" alwaysOn />
        <BooleanInput label="Active" source="active" />
        <SimCardSourceField label="Source" source="source" />
        <SimCardStatusInput label="Status" source="status" />
        <TextInput label="Variant" source="variant" />
    </Filter>
);

const filterDefaultValues = {
    archived: false,
}

const SimcardActions = (props) => (
    <ListActions {...props}>
        <IconButton component={Link} to={`/simcards/bulk-create`} icon={<IconAdd />}>Bulk create</IconButton>
        <IconButton icon={<GetAppIcon />} component="a" href={`${API_URL}/simcards/export.xls`}>Export</IconButton>
    </ListActions>
);

const Row = (props) => (
    <SimCardRow {...props}>
        {props.filterValues.archived && <TextField source="archived_reason" />}
    </SimCardRow>
);

const SimCardList = props => (
    <List {...props} filters={<SimCardFilter />} actions={<SimcardActions />} filterDefaultValues={filterDefaultValues} bulkActionButtons={<SimCardBulkActions />} exporter={false}>
        <Row />
    </List>
);

export default SimCardList;