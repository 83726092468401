import React from 'react';
import { TextInput, BooleanInput } from 'react-admin';

import List from '../common/List';
import Filter from '../common/Filter';
import ProviderRow from './ProviderRow';

const ProviderFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <BooleanInput label="Archived" source="archived" alwaysOn />
    </Filter>
);

const filterDefaultValues = {
    archived: false,
}

const ProviderList = props => (
	<List {...props} filters={<ProviderFilter/>} filterDefaultValues={filterDefaultValues}>
		<ProviderRow />
	</List>
);

export default ProviderList;