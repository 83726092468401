import { fetchUtils } from 'react-admin';

import provider from './provider.js';
import { API_URL } from './services/settings';

const httpClient = (url, options = {}) => {
	if (!options.headers) {
		options.headers = new Headers({ Accept: 'application/json' });
	}
	const token = localStorage.getItem('token');
	options.headers.set('Authorization', `Bearer ${token}`);
	return fetchUtils.fetchJson(url, options);
};

export default provider(API_URL, httpClient);