import React from 'react';
import { ComposedChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Brush } from 'recharts';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    tooltip: {
        margin: '0px',
        padding: '0 10px',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #CCC',
        whiteSpace: 'nowrap'
    },
    chart: {
        '& .recharts-text': {
            fill: theme.palette.text.secondary
        },
        '& .recharts-brush rect:first-child': {
            fill: theme.palette.background.paper
        }
    }
}));

const CustomTooltip = ({ active, payload }) => {
    const classes = useStyles();

    if (active && payload && payload.length) {
        return (
            <div className={classes.tooltip}>
                {payload[0]?.payload?.ts && <p>{new Date(payload[0].payload.ts * 1000).toLocaleString()}</p>}
                <p>{payload[0]?.payload?.band}</p>
                {payload.map(data => (
                    <p key={`tooltip_${data.name}`}><span style={{ color: data.color }}>{data.name}</span> : {data.value} {data.unit}</p>
                ))}
            </div>
        )
    }

    return null;
};

const WanQualityGraph = ({ data }) => {
    const classes = useStyles();

    const formatTimestamp = (ts) => new Date(ts * 1000).toLocaleTimeString();

    if (!data) {
        return null;
    }

    const items = data.reduce((array, item) => {
        if (!array[item['band']]) {
            array[item['band']] = [];
        }
        array[item['band']].push(item);
        return array;
    }, {});

    const lastIndex = Object.keys(items).length - 1;

    return Object.values(items).map((item, index) => (
        <ResponsiveContainer width="100%" height={400} className={classes.chart} key={`graph_${index}`}>
            <ComposedChart
                width={500}
                height={400}
                data={item}
                margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20,
                }}
                syncId="anyId"
            >
                <CartesianGrid stroke="#f5f5f5" />
                <XAxis dataKey="ts" tickFormatter={formatTimestamp} />
                <YAxis yAxisId="dB" label={{ value: 'Quality (dB)', angle: -90, position: 'insideLeft' }} />
                <YAxis yAxisId="dBm" orientation="right" label={{ value: 'Strength (dBm)', angle: 90, position: 'insideRight' }} />
                <Tooltip content={<CustomTooltip />} />
                <Legend verticalAlign="top" />
                <Line name="SINR" dataKey="sinr" stroke="#ff7300" unit="dB" yAxisId="dB" dot={false} />
                <Line name="RSRQ" dataKey="rsrq" stroke="#c494fd" unit="dB" yAxisId="dB" dot={false} />
                <Line name="RSRP" dataKey="rsrp" stroke="#00FF73" unit="dBm" yAxisId="dBm" dot={false} />

                {lastIndex === index && (
                    <Brush dataKey="ts" tickFormatter={formatTimestamp} height={30} />
                )}
            </ComposedChart>
        </ResponsiveContainer>
    ));
};

export default WanQualityGraph;