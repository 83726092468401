import React from 'react';
import { TextField } from 'react-admin';

const vatList = {
    'vat_0': '0%',
    'vat_21': '21%',
	'vat_20': '20%',
    'vat_intra_goods_0': 'INTRA GOODS 0%',
    'vat_intra_services_0': 'INTRA SERVICES 0%',
    'vat_export_goods_0': 'EXPORT GOODS 0%',
	'vat_export_services_0': 'EXPORT SERVICES 0%',
    'vat_not_subject': 'NA',
	'vat_seagoing': 'SEAGOING',
	'vat_eu_0': 'EU 0%'
};

const VatField = ({ record, source, ...props }) => {
	if(!record) {
		return null;
	}

	const vat = record[source];

	if (!vat) {
		return null;
	}

	return (
		<TextField source={vat} record={vatList} {...props} />
	);
};

VatField.defaultProps = {
	source: 'vat_code',
    label: 'VAT',
	addLabel: true
};

export default VatField;
