import React, { useEffect, useState } from 'react';
import { useFormState, useForm } from 'react-final-form';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { TextInput, Title, ReferenceInput, AutocompleteInput, Toolbar, SaveButton, FormDataConsumer, required, useQuery, useReference } from 'react-admin';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';

import SimpleForm from '../common/SimpleForm';
import SimReferenceInput from '../simcards/SimReferenceInput';
import SiteReferenceInputWithCreate from '../sites/SiteReferenceInputWithCreate';

import { prepareDevice } from '../../services/api';
import rowStyle from '../wans/rowStyle';
import DeployReasonInput from '../devices/DeployReasonInput';
import DeviceRentSoldField from '../devices/fields/DeviceRentSoldField';

const deviceOptionText = choice => `${choice.serial ? choice.serial : ''} / ${choice.ic_group_name ? choice.ic_group_name : ''} / ${choice.name ? choice.name : ''}`;
const optionText = choice => (choice && choice.name ? choice.name : '');

const CustomToolbar = ({ onSave, ...props }) => {
	const [loading, setLoading] = useState(false);
	const formState = useFormState();
	const { change } = useForm();
	const customerId = formState?.values?.customer_id;
	const { referenceRecord: customer } = useReference({ reference: 'customers', id: customerId });

	useEffect(() => {
		if (customer?.default_data_limit_contacts) {
			change('data_limit_contacts', customer.default_data_limit_contacts);
		}
	}, [change, customer]);

	const handleClick = async () => {
		setLoading(true);
		let data = new FormData();
		data.append("site_id", formState.values.site_id);
		// data.append("comment", formState.values.comment);
		data.append("reason", formState.values.reason);
		data.append("ports_map", JSON.stringify(formState.values.ports_map));
		if (formState.values.data_limit) {
			data.append("data_limit", formState.values.data_limit);
		}
		if (formState.values.data_limit_contacts) {
			data.append("data_limit_contacts", formState.values.data_limit_contacts);
		}
		if (formState.values.target_device_id) {
			data.append("target_device_id", formState.values.target_device_id);
		}
		if (formState.values.virtual_customer_id) {
			data.append("virtual_customer_id", formState.values.virtual_customer_id);
		}
		if (formState.values.notes) {
			data.append("notes", formState.values.notes);
		}
		if (formState.values.rent_sold) {
			data.append("rent_sold", formState.values.rent_sold);
		}
		await prepareDevice(formState.values.device_id, data);

		if (onSave) {
			onSave();
		}
		setLoading(false);
	};

	let buttonProps = {};
	if (loading) {
		buttonProps = { icon: <CircularProgress size={14} thickness={4} /> };
	}

	return (
		<Toolbar {...props}>
			<SaveButton handleSubmit={handleClick} handleSubmitWithRedirect={handleClick} disabled={loading} {...buttonProps} />
		</Toolbar>
	);
};

const BuildForm = ({ record, actions, onSave }) => {
	const [device_id, setDevice_id] = useState(record.device_id);

	const handleChangeDevice = (id) => {
		setDevice_id(id);
	};

	const renderPorts = (device) => {
		if (!device || !device.ports_map || device.ports_map?.length === 0) {
			return;
		}

		const { ports_map } = device;

		return (
			<Grid container spacing={3} fullWidth>
				{Object.keys(ports_map).map((key) => (
					<Grid item xs={3} key={key}>
						<SimReferenceInput label={key} source={`ports_map[${key}]`} resource="simcards" reference="simcards" variant="outlined" />
					</Grid>
				))}
			</Grid>
		);
	};

	const renderIcSims = (device) => {
		if (!device || !device.ic_sim_cards || device.ic_sim_cards?.length === 0) {
			return;
		}

		return (
			<Table fullWidth>
				<TableHead>
					<TableRow>
						<TableCell>Port</TableCell>
						<TableCell>ICCID</TableCell>
						<TableCell>Wan name</TableCell>
						<TableCell>Wan IC_ID</TableCell>
						<TableCell>Status</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{device.ic_sim_cards.map(sim => {
						const wan = device.wans.find(w => w.id === sim.wan);

						return (
							<TableRow key={`sim_${sim.id}`} style={rowStyle(wan)}>
								<TableCell>{sim.device_port_name}</TableCell>
								<TableCell>{sim.iccid}</TableCell>
								<TableCell>{wan.name}</TableCell>
								<TableCell>{wan.ic_id}</TableCell>
								<TableCell>{wan.ic_status}</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		);
	};

	const { data } = useQuery({
		type: 'getOne',
		resource: 'devices',
		payload: { id: device_id }
	});

	if (data) {
		record = {
			...record,
			device_id: data.id,
			ports_map: data.ports_map,
			customer_id: data.customer_id,
			...(data.site_id !== 1 && { site_id: data.site_id })
		};
	}

	return (
		<Card>
			<Title title="Deploy" />
			<CardContent>
				{actions}
			</CardContent>

			<SimpleForm redirect="show" record={record} resource="devices" toolbar={<CustomToolbar onSave={onSave} />} initialValues={{ data_limit: 10 }}>
				<ReferenceInput label="Device" source="device_id" reference="devices" fullWidth filter={{ in_stock: true }} onChange={handleChangeDevice} validate={required()}>
					<AutocompleteInput optionText={deviceOptionText} />
				</ReferenceInput>

				{(data && data.virtual) && (
					<ReferenceInput label="Virtual Customer" source="virtual_customer_id" reference="customers" validate={required()} sort={{ field: 'name', order: 'ASC' }}>
						<AutocompleteInput optionText={optionText} />
					</ReferenceInput>
				)}

				<ReferenceInput label="Customer" source="customer_id" reference="customers" validate={required()} sort={{ field: 'name', order: 'ASC' }}>
					<AutocompleteInput optionText={optionText} />
				</ReferenceInput>

				<FormDataConsumer>
					{({ formData, ...rest }) => (
						<SiteReferenceInputWithCreate
							{...rest}
							label="Site"
							source="site_id"
							reference="sites"
							filter={formData.customer_id && { customer_id: formData.customer_id }}
							validate={required()}
							key={formData.customer_id}
						/>
					)}
				</FormDataConsumer>
				<DeviceRentSoldField source="rent_sold" label="VENN Billing Mode" />
				<DeployReasonInput source="reason" />

				<FormDataConsumer>
					{({ formData, ...rest }) => (
						<ReferenceInput
							{...rest}
							label="Related Device"
							source="target_device_id"
							reference="devices"
							fullWidth filter={formData.site_id && { in_stock: false, site_id: formData.site_id }}
							sort={{ field: 'name', order: 'ASC' }}
						>
							<AutocompleteInput optionText={deviceOptionText} />
						</ReferenceInput>
					)}
				</FormDataConsumer>
				<TextInput source="notes" options={{ multiline: true }} rows="4" />
				<TextInput label="Data Limit Alert (GB)" source="data_limit" />
				<TextInput label="Data Limit Contacts (CSV)" source="data_limit_contacts" options={{ multiline: true }} rows="4" />

				{data && renderPorts(data)}
				{data && renderIcSims(data)}
			</SimpleForm>
		</Card>
	);
};

export default BuildForm;