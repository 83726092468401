import React from 'react';
import { Link } from 'react-router-dom';
import SimCardIcon from '@material-ui/icons/SimCard';

import IconButton from '../../common/IconButton';


const PortsEditButton = ({ record, variant, color }) => (
	<>
		{record &&
			<IconButton component={Link} to={`/devices/${record.id}/ports`} icon={<SimCardIcon />} variant={variant} color={color}>Edit Ports</IconButton>
		}
	</>
);

export default PortsEditButton;