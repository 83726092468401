import React, { useEffect, useState } from 'react';
import { TextField, ArrayField, ReferenceField, FunctionField, SimpleShowLayout } from 'react-admin';
import { makeStyles } from '@material-ui/core';

import Datagrid from '../common/Datagrid';
import ChipField from '../common/fields/ChipField';
import DateField from '../common/fields/DateField';
import BooleanField from '../common/fields/BooleanField';
import RestartModemDeviceButton from './RestartModemDeviceButton';
import rowStyle from './rowStyle';

import SimCardRow from '../simcards/SimCardRow';
import StatusField from '../simcards/StatusField';
import LineRow from '../lines/LineRow';

const useStyles = makeStyles({
	table: {
		marginBottom: '10px',

		'&:last-child td': {
			borderBottom: 'none'
		}
	}
});

const LineList = ({ record, data, ids, ...props }) => {
	const classes = useStyles();
	const [customData, setCustomData] = useState({});

	useEffect(() => {
		setCustomData({ lines: [record.line] });
	}, [record.line]);

	if (record.line) {
		return (
			<ArrayField source="lines" record={customData} {...props}>
				<LineRow classes={classes} exclude={['device', 'site']} />
			</ArrayField>
		);
	}

	return null;
};

/* Custom drawer to prevent api call to device */
/* TODO: make component more modular */
const SimCardDrawer = ({ basePath, resource, ...props }) => (
	<SimpleShowLayout {...props} basePath="/simcards" resource="simcards">
		<TextField source="id" />
		<ReferenceField label="Customer" source="customer" reference="customers" link="show" allowEmpty>
			<TextField source="name" />
		</ReferenceField>
		<ReferenceField label="SimPool" source="pool_id" reference="simpools" link="show" allowEmpty>
			<TextField source="name" />
		</ReferenceField>
		<FunctionField label="iccid" source="iccid" render={record => `${record.iccid_formatted}`} />
		<TextField source="imsi" />
		<TextField source="sponsored_imsi" />
		<ReferenceField label="Provider" source="provider" reference="providers" allowEmpty>
			<TextField source="name" />
		</ReferenceField>
		<TextField source="supplier_name" />
		<TextField source="carrier_name" />
		<StatusField label="Status" />
		<BooleanField source="active" />
		<TextField source="phone_number" />
		<TextField source="notes" />
		<TextField source="source" />
		<ReferenceField label="Owner" source="owner" reference="customers" link="show" allowEmpty>
			<TextField source="name" />
		</ReferenceField>
		<DateField label="Created at" source="created_at" />
		<DateField label="Updated at" source="updated_at" />
	</SimpleShowLayout>
);

const SimCardList = ({ record, data, ids, ...props }) => {
	const classes = useStyles();
	const [customData, setCustomData] = useState({});

	useEffect(() => {
		setCustomData({
			sims : [
				...(record.sim_a ? [record.sim_a] : []),
				...(record.sim_b ? [record.sim_b] : [])
			]
		});
	}, [record.sim_a, record.sim_b]);

	if (!record.sim_a && !record.sim_b) {
		return null;
	}

	return (
		<ArrayField source="sims" record={customData} {...props}>
			<SimCardRow classes={classes} exclude={['icons']} drawer={<SimCardDrawer {...props} />} />
		</ArrayField>
	);
};

const Expand = ({ basePath, resource, record, ...props }) => {
	if(!record) {
		return null;
	}

	return (
		<>
			{record.line ? (
				<LineList {...props} record={record} basePath="/lines" resource="lines" />
			) :(
				<SimCardList {...props} record={record} basePath="/simcards" resource="simcards" exclude={["icons"]} />
			)}
		</>
	);
};

const WanRow = ({ children, ...props }) => (
	<Datagrid
		expand={<Expand {...props} />}
		rowStyle={rowStyle}
		{...props}
	>
		<TextField source="name" />
		<TextField label="Status" source="ic_status" />
		<TextField source="ip_address" />
		<ChipField label="Network type" source="cellular_network_type" color="primary" size="small" />
		<DateField source="updated_at" />
		<RestartModemDeviceButton />
		{children}
	</Datagrid>
);

export default WanRow;
