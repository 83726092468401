import React, { useState } from 'react';
import { Confirm, useRefresh, useNotify, usePermissions } from 'react-admin';
import NoEncryptionIcon from '@material-ui/icons/NoEncryption';

import IconButton from '../../common/IconButton';

import { disableMFA } from '../../../services/api';

const DisableMFAButton = ({ record, variant, color }) => {
	const [showDialog, setShowDialog] = useState(false);
	const { permissions } = usePermissions();
	const refresh = useRefresh();
	const notify = useNotify();
	const isAdmin = permissions && permissions.includes('is_admin');

	const handleOpen = () => {
		setShowDialog(true);
	};

	const handleClose = () => {
		setShowDialog(false);
	};

	const handleConfirm = async () => {
		handleClose();
		const response = await disableMFA(record.id);

		if (response.success) {
			refresh();
			notify('MFA was successfully disabled');
		}
		else {
			if(response.reason) {
				notify(response.reason, 'warning');
			}
			else {
				notify('An error has occured', 'warning');
			}
		}
	};

	if (!isAdmin || !record?.mfa_enabled) return null;

	if (record) {
		return (
			<>
				<IconButton icon={<NoEncryptionIcon />} variant={variant} color={color} onClick={handleOpen}>Disable MFA</IconButton>
				<Confirm
					isOpen={showDialog}
					title={`Disable MFA`}
					content={`Are you sure you want to disable MFA for ${record.fullname}?`}
					onConfirm={handleConfirm}
					onClose={handleClose}
				/>
			</>
		);
	}

	return null;
};

export default DisableMFAButton;