import ContactMailIcon from '@material-ui/icons/ContactMail';

import ContactList from './ContactList';
import ContactCreate from './ContactCreate';
import ContactShow from './ContactShow';
import ContactEdit from './ContactEdit';

export default {
	list: ContactList,
	create: ContactCreate,
	show: ContactShow,
	edit: ContactEdit,
	icon: ContactMailIcon,
};
