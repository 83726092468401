import React, { useState } from 'react';
import { Confirm, useRefresh } from 'react-admin';
import IconReturn from '@material-ui/icons/KeyboardReturn';

import IconButton from '../../common/IconButton';

import { returnProductItem } from '../../../services/api';


const BackToStockButton = ({ record, variant, color }) => {
	const [showDialog, setShowDialog] = useState(false);
	const refresh = useRefresh();

	const handleOpen = () => {
		setShowDialog(true);
	};

	const handleClose = () => {
		setShowDialog(false);
	};

	const handleConfirm = async () => {
		handleClose();
		await returnProductItem(record.id);
		refresh();
	};

	if (record && !record.in_stock) {
		return (
			<>
				<IconButton icon={<IconReturn />} variant={variant} color={color} onClick={handleOpen}>Back to stock</IconButton>
				<Confirm
					isOpen={showDialog}
					title={`Back to stock #${record.id}`}
					content={`Are you sure you want to return this item?`}
					onConfirm={handleConfirm}
					onClose={handleClose}
				/>
			</>
		);
	}

	return null;
};

export default BackToStockButton;