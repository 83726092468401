import React from 'react';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import deviceColors from './deviceColors';

const OnlineStatusIcon = ({ record, source="online_status" }) => {
	const iconStyle = { color: deviceColors[record[source]] };
	return <FiberManualRecordIcon style={iconStyle} />
};

OnlineStatusIcon.defaultProps = {
	addLabel: true
};

export default OnlineStatusIcon;