import React from 'react';
import { TextInput, SelectInput, FormDataConsumer } from 'react-admin';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import DeleteIcon from '@material-ui/icons/Delete';

import NumberCurrencyField from '../common/fields/NumberCurrencyField';
import InvoiceLineQuickEditButton from './InvoiceLineQuickEditButton';

const InvoiceLineForm = React.memo(({ fields, name, index, line, vatList, typeList, draggable, variant, className, record, ...props }) => {
	const handleDelete = () => {
		fields.remove(index);
	};

	return (
		<>
			{draggable && <TableCell><DragHandleIcon /></TableCell>}
			<TableCell><TextInput label="" source={`${name}.description`} options={{ multiline: true }} variant={variant} helperText={false} /></TableCell>
			<TableCell><TextInput label="" source={`${name}.qty`} variant={variant} helperText={false} /></TableCell>
			<TableCell><TextInput label="" source={`${name}.unit_price`} variant={variant} helperText={false} /></TableCell>
			<TableCell>
				<SelectInput
					label=""
					source={`${name}.vat_code`}
					choices={vatList}
					variant={variant}
					helperText={false}
				/>
			</TableCell>
			<TableCell>
				<FormDataConsumer>
					{({ formData }) => {
						if (formData.lines && formData['lines'][index]) {
							const total = formData['lines'][index].qty * formData['lines'][index].unit_price;

							if (total) {
								return <NumberCurrencyField record={{ currency: record?.currency, total }} source="total" />
							}
						}

						return null;
					}}
				</FormDataConsumer>
			</TableCell>
			<TableCell align="center">
				<FormDataConsumer>
					{({ formData }) => (
						<InvoiceLineQuickEditButton
							id={formData['lines'][index].id}
							vatList={vatList}
							currency={formData?.currency}
							index={index}
							source={name}
							variant={variant}
							typeList = {typeList}
						/>
					)}
				</FormDataConsumer>
				<IconButton aria-label="delete" onClick={handleDelete} size="small">
					<DeleteIcon />
				</IconButton>
			</TableCell>
		</>
	);
});

export default InvoiceLineForm;
