import React from 'react';
import { Link } from 'react-router-dom';
import IconReturn from '@material-ui/icons/KeyboardReturn';

import IconButton from '../../common/IconButton';


const BackToStockButton = ({ record, variant, color }) => (
	<>
		{record && record.in_stock &&
			<IconButton component={Link} to={`/devices/${record.id}/deploy`} icon={<IconReturn />} variant={variant} color={color}>Deploy</IconButton>
		}
		{record && !record.in_stock && (
			<IconButton component={Link} to={`/devices/${record.id}/back-to-stock`} icon={<IconReturn />} variant={variant} color={color}>Back to stock</IconButton>
		)}
	</>
);

export default BackToStockButton;