import React from 'react';
import { TextInput, DateTimeInput } from 'react-admin';

import Create from '../common/Create';
import SimpleForm from '../common/SimpleForm';


const SpeedTestCreate = props => (
	<Create {...props}>
		<SimpleForm redirect="list">
			<TextInput rows="20" source="device_serials" fullWidth multiline label="Device Serials one per line"/>
			<DateTimeInput source="scheduled_at" label="Scheduled at"/>
		</SimpleForm>
	</Create>
);

export default SpeedTestCreate;