import ContactMailIcon from '@material-ui/icons/ContactMail';

import StarlinkAddressList from './StarlinkAddressList';
import StarlinkAddressShow from './StarlinkAddressShow';

export default {
	list: StarlinkAddressList,
	show: StarlinkAddressShow,
	icon: ContactMailIcon,
	title: "Starlink addresses"
};
