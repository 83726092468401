import React, { useState } from 'react';
import { useRefresh, useNotify, SaveButton as RASaveButton, TextInput, ReferenceInput, AutocompleteInput } from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';

import SimpleForm from '../../common/SimpleForm';
import IconButton from '../../common/IconButton';
import Toolbar from '../../common/Toolbar';

import { attachLineToDevice, detachLineFromDevice } from '../../../services/api';


const useStyles = makeStyles(theme => ({
	toolbar: {
		paddingTop: '70px'
	},
	suggestionsContainerOpen: {
		position: 'absolute',
		marginBottom: theme.spacing(3),
		zIndex: theme.zIndex.modal + 200,
	}
}));

const deviceOptionText = choice => `${choice.serial ? choice.serial : ''} / ${choice.ic_group_name ? choice.ic_group_name : ''} / ${choice.name ? choice.name : ''}`;

const DetachSaveButton = ({ onClick, record, ...props }) => {
	const notify = useNotify();
	const formState = useFormState();
	const form = useForm();

	const handleClick = async () => {
		let formData = new FormData();
		formData.append("reason", formState.values.reason);
		const response = await detachLineFromDevice(record.id, formData);
		form.reset();
		onClick();

		if(response.success === false) {
			notify('Line not detached', 'warning');
		}
		else {
			notify('Line detached');
		}
	};

	return <RASaveButton {...props} handleSubmit={handleClick} handleSubmitWithRedirect={handleClick} />;
};

const AttachSaveButton = ({ onClick, record, ...props }) => {
	const notify = useNotify();
	const formState = useFormState();
	const form = useForm();

	const handleClick = async () => {
		let formData = new FormData();
		formData.append("device_id", formState.values.device_id);
		const response = await attachLineToDevice(record.id, formData);
		form.reset();
		onClick();

		if(response.success === false) {
			notify('Line not attached', 'warning');
		}
		else {
			notify('Line attached');
		}
	};

	return <RASaveButton {...props} handleSubmit={handleClick} handleSubmitWithRedirect={handleClick} />;
};

const AttachDetachLineFromDeviceButton = ({ record, variant, color }) => {
	const refresh = useRefresh();
	const [showDialog, setShowDialog] = useState(false);
	const classes = useStyles();

	const handleSave = async () => {
		handleClose();
		refresh();
	};

	const handleOpen = (e) => {
		e.stopPropagation();
		setShowDialog(true);
	};

	const handleClose = () => {
		setShowDialog(false);
	};

	if(!record) {
		return null;
	}

	return (
		record.device_id ? (
			<>
				<IconButton icon={<RemoveCircleOutlineIcon />} onClick={handleOpen} variant={variant} color={color}>Detach Device</IconButton>
				<Dialog fullWidth open={showDialog} onClose={handleClose} aria-label="Detach Device">
					<DialogTitle>Detach Device</DialogTitle>
					<DialogContent>
						Do you want to detach the line {record.subscription_id} from the device?
						<SimpleForm toolbar={<Toolbar onClose={handleClose} onSave={handleSave} saveButton={<DetachSaveButton onClick={handleSave} />} />} record={record}>
							<TextInput source="reason" rows="4" fullWidth multiline />
						</SimpleForm>
					</DialogContent>
				</Dialog>
			</>
		) : (
			<>
				<IconButton icon={<AddCircleOutlineIcon />} onClick={handleOpen} variant={variant} color={color}>Attach Device</IconButton>
				<Dialog fullWidth open={showDialog} onClose={handleClose} aria-label="Attach Device">
					<DialogTitle>Attach Device</DialogTitle>
					<DialogContent>
						Do you want to attach the line {record.subscription_id} to a device?
						<SimpleForm toolbar={<Toolbar className={classes.toolbar} onClose={handleClose} onSave={handleSave} saveButton={<AttachSaveButton onClick={handleSave} />} />} record={record}>
							<ReferenceInput label="Device" source="device_id" reference="devices" filter={{ site_id: record.site_id, page_size: 3 }}>
								<AutocompleteInput
									optionText={deviceOptionText}
									options={{ suggestionsContainerProps: { className: classes.suggestionsContainerOpen, disablePortal: true } }}
								/>
							</ReferenceInput>
						</SimpleForm>
					</DialogContent>
				</Dialog>
			</>
		)
	);
};

export default AttachDetachLineFromDeviceButton;