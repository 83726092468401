import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import UsageIcon from '@material-ui/icons/Phone';

import IconCard from '../common/IconCard';

import { fetchUsageDashboardStats } from '../../services/api';

const UsageDashboard = () => {
    const [stats, setStats] = useState();

    useEffect(() => {
        const fetchStats = async () => {
            const data = await fetchUsageDashboardStats();
            setStats(data);
        }

        fetchStats();
    }, []);

    return (
        <Grid container spacing={2}>
            <Grid container item spacing={2}>
                <Grid item lg={3} sm={6} xl={3} xs={12}>
                    <IconCard title="Incontrol - Last CDR" value={stats?.last_usage_date_by_source?.incontrol} icon={<UsageIcon />} />
                </Grid>
                <Grid item lg={3} sm={6} xl={3} xs={12}>
                    <IconCard title="Telna - Last CDR" value={stats?.last_usage_date_by_source?.telna} icon={<UsageIcon />} />
                </Grid>
                <Grid item lg={3} sm={6} xl={3} xs={12}>
                    <IconCard title="Webbing - Last CDR" value={stats?.last_usage_date_by_source?.webbing} icon={<UsageIcon />} />
                </Grid>
                <Grid item lg={3} sm={6} xl={3} xs={12}>
                    <IconCard title="Phenix - Last CDR" value={stats?.last_usage_date_by_source?.phenix} icon={<UsageIcon />} />
                </Grid>
            </Grid>
            <Grid container item spacing={2}>
                <Grid item lg={3} sm={6} xl={3} xs={12}>
                    <IconCard title="Incontrol - Current Month" value={stats?.current_total_usage_by_source?.incontrol || 0.0 + ` GB`} icon={<UsageIcon />} />
                </Grid>
                <Grid item lg={3} sm={6} xl={3} xs={12}>
                    <IconCard title="Telna - Current Month" value={stats?.current_total_usage_by_source?.telna || 0.0 + ` GB` } icon={<UsageIcon />} />
                </Grid>
                <Grid item lg={3} sm={6} xl={3} xs={12}>
                    <IconCard title="Webbing - Current Month" value={stats?.current_total_usage_by_source?.webbing || 0.0 + ` GB` } icon={<UsageIcon />} />
                </Grid>
                <Grid item lg={3} sm={6} xl={3} xs={12}>
                    <IconCard title="Phenix - Current Month" value={stats?.current_total_usage_by_source?.phenix || 0.0 + ` GB` } icon={<UsageIcon />} />
                </Grid>
            </Grid>
            <Grid container item spacing={2}>
                <Grid item lg={3} sm={6} xl={3} xs={12}>
                    <IconCard title="Incontrol - Last Month" value={stats?.last_total_usage_by_source?.incontrol || 0.0 + ` GB`} icon={<UsageIcon />} />
                </Grid>
                <Grid item lg={3} sm={6} xl={3} xs={12}>
                    <IconCard title="Telna - Last Month" value={stats?.last_total_usage_by_source?.telna || 0.0 + ` GB` } icon={<UsageIcon />} />
                </Grid>
                <Grid item lg={3} sm={6} xl={3} xs={12}>
                    <IconCard title="Webbing - Last Month" value={stats?.last_total_usage_by_source?.webbing || 0.0 + ` GB` } icon={<UsageIcon />} />
                </Grid>
                <Grid item lg={3} sm={6} xl={3} xs={12}>
                    <IconCard title="Phenix - Last Month" value={stats?.last_total_usage_by_source?.phenix || 0.0 + ` GB` } icon={<UsageIcon />} />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default UsageDashboard;