import React from 'react';
import { TextInput } from 'react-admin';

import Create from '../common/Create';
import SimpleForm from '../common/SimpleForm';

const ICServerCreate = props => (
	<Create {...props}>
		<SimpleForm redirect="list">
			<TextInput label="Name" source="name" />
			<TextInput source="description" options={{ multiline: true }} rows="4" />
			<TextInput label="Host" source="hostname" />
			<TextInput label="API Host" source="api_hostname" />
		</SimpleForm>
	</Create>
);

export default ICServerCreate;
