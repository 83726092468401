import React from 'react';
import { NumberField, TabbedShowLayout, Tab, TextField, ReferenceField, ReferenceArrayField } from 'react-admin';
import { Helmet } from 'react-helmet';

import Show, { ShowActions } from '../common/Show';
import DateField from '../common/fields/DateField';
import BooleanField from '../common/fields/BooleanField';
import StarlinkTerminalRow from '../starlink_terminals/StarlinkTerminalRow';
import StarlinkSubscriptionRow from '../starlink_subscriptions/StarlinkSubscriptionRow';
import StarlinkServiceLineActionsButtons from './StarlinkServiceLineActionsButtons';
import StarlinkUsageGraph from '../starlink_usages/StarlinkUsageGraph';


const StarlinkServiceLineTitle = ({ record }) => (
    <>
        <Helmet>
            <title>{`KB - Starlink service line[${record.id}]`}</title>
        </Helmet>
        <span>{record.id}</span>
    </>
);

const StarlinkServiceLineActions = (props) => (
    <ShowActions {...props}>
        <StarlinkServiceLineActionsButtons record={props.data} />
    </ShowActions>
);

const StarlinkServiceLineShow = (props) => (
    <Show title={<StarlinkServiceLineTitle />} actions={<StarlinkServiceLineActions {...props} />} {...props}>
        <TabbedShowLayout>
            <Tab label="summary">
                <TextField source="id" />
                <TextField source="service_line_number" />
                <ReferenceField label="Starlink account" source="starlink_account" reference="starlink_accounts" link="show" allowEmpty>
                    <TextField source="account_number" />
                </ReferenceField>
                <ReferenceField label="Starlink address" source="starlink_address" reference="starlink_addresses" link="show" allowEmpty>
                    <TextField source="formatted_address" />
                </ReferenceField>
                <BooleanField source="active" />
                <NumberField source="last_usage" label="Last Usage(GB)" />
                <NumberField source="current_usage" label="Current Usage(GB)" />
                <BooleanField source="last_service_plan.isOptedIntoOverage" label="StarLink Opted Into Overrage" />
                <NumberField source="last_service_plan.overageLine.usageLimitGB" label="Service Plan Usage Limit(GB)" />
                <TextField source="last_service_plan.productId" label="Service Plan Product" />
                <TextField source="last_service_plan.isoCurrencyCode" label="Currency" />
                <NumberField source="last_service_plan.overageLine.overageAmountGB" label="Overrage(GB)" />
                <NumberField source="last_service_plan.overageLine.overagePrice" label="Overrage Price" />
                <NumberField source="last_service_plan.overageLine.pricePerGB" label="Overrage Price/GB" />
                <DateField source="created_at" showTime />
                <DateField source="updated_at" showTime />
                <ReferenceArrayField addLabel={false} reference="starlink_terminals" source="starlink_terminals">
                    <StarlinkTerminalRow />
                </ReferenceArrayField>
                <ReferenceArrayField addLabel={false} reference="starlink_subscriptions" source="starlink_subscriptions">
                    <StarlinkSubscriptionRow />
                </ReferenceArrayField>
                <StarlinkUsageGraph />
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export default StarlinkServiceLineShow;