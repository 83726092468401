// in src/App.js
import React from 'react';
import { Admin, Resource } from 'react-admin';
import { Route } from 'react-router';
import { Helmet } from 'react-helmet';

import Dashboard from './components/dashboards/Dashboard';
import LoginPage from './components/LoginPage';
import MFACodeForm from './components/mfaMethods/forms/MFACodeForm';
import ForgotPasswordPage from './components/ForgotPasswordPage';
import ResetPasswordPage from './components/ResetPasswordPage';
import AppLayout from './components/AppLayout';

import contacts from './components/contacts';
import customers from './components/customers';
import users from './components/users';
import providers from './components/providers';
import simcards from './components/simcards';
import simpools from './components/simpools';
import devices from './components/devices';
import sites from './components/sites';
import lines from './components/lines';
import servers from './components/servers';
import logs from './components/logs';
import records from './components/records';
import usages from './components/usages';
import device_products from './components/device_products';
import line_providers from './components/line_providers';
import data_pricings from './components/data_pricings';
import service_products from './components/service_products';
import services from './components/services';
import packages from './components/packages';
import product_items from './components/product_items';
import ips from './components/ips';
import location_updates from './components/location_updates';
import data_pools from './components/data_pools';
import addresses from './components/addresses';
import invoices from './components/invoices';
import companies from './components/companies';
import speedtests from './components/speedtests';
import starlink_terminals from './components/starlink_terminals';
import starlink_service_lines from './components/starlink_service_lines';
import starlink_subscriptions from './components/starlink_subscriptions';
import starlink_accounts from './components/starlink_accounts';
import starlink_addresses from './components/starlink_addresses';
import starlink_usages from './components/starlink_usages';
import tasks from './components/tasks';
import package_lines from './components/package_lines';
import invoice_lines from './components/invoice_lines';

import LineDashboard from './components/dashboards/LineDashboard';
import AccountingDashboard from './components/dashboards/AccountingDashboard';
import UsageDashboard from './components/dashboards/UsageDashboard';
import BusinessDashboard from './components/dashboards/BusinessDashboard';
import StarlinkDashboard from './components/dashboards/StarlinkDashboard';
import DeliveryDashboard from './components/dashboards/DeliveryDashboard';
import StockDashboard from './components/dashboards/StockDashboard';
import DevicePrepare from './components/devices/DevicePrepare';
import DeviceEditPorts from './components/devices/DeviceEditPorts';
import DeviceBackToStock from './components/devices/DeviceBackToStock';
import DeviceBulkMove from './components/devices/DeviceBulkMove';
import SiteDevicePrepare from './components/sites/SiteDevicePrepare';
import SimCardBulkCreate from './components/simcards/SimCardBulkCreate';
import DeviceBulkCreate from './components/devices/DeviceBulkCreate';
import ProductItemBulkCreate from './components/product_items/ProductItemBulkCreate';
import ProductItemBulkMove from './components/product_items/ProductItemBulkMove';
import IPBulkCreate from './components/ips/IPBulkCreate';
import UsageReportList from './components/usages/UsageReportList';
import StarlinkUsageReportList from './components/starlink_usages/StarlinkUsageReportList';
import MapDeviceList from './components/devices/MapDeviceList';
import InvoiceExport from './components/invoices/InvoiceExport';
import StarlinkProvision from './components/starlink/StarlinkProvision';
import ServiceProvision from './components/services/ServiceProvision';
import ServiceShip from './components/services/ServiceShip';
import ServiceDeliver from './components/services/ServiceDeliver';
import PackageLineProvision from './components/package_lines/PackageLineProvision';
import PackageLineShip from './components/package_lines/PackageLineShip';
import PackageLineDeliver from './components/package_lines/PackageLineDeliver';
import Account from './components/Account';

import dataProvider from './dataProvider';
import authProvider from './authProvider';
import themeReducer from './reducers/theme';


const ExternalRedirect = ({ to, ...routeProps }) => {
  return <Route {...routeProps} render={() => window.location = to} />;
};

const customRoutes = [
  <Route path="/dashboards/lines" component={LineDashboard} />,
  <Route path="/dashboards/accounting" component={AccountingDashboard} />,
  <Route path="/dashboards/usages" component={UsageDashboard} />,
  <Route path="/dashboards/business" component={BusinessDashboard} />,
  <Route path="/dashboards/starlink" component={StarlinkDashboard} />,
  <Route path="/dashboards/stocks" component={StockDashboard} />,
  <Route path="/dashboards/delivery" component={DeliveryDashboard} />,
  <Route path="/devices/:id/deploy" component={DevicePrepare} />,
  <Route path="/devices/:id/ports" component={DeviceEditPorts} />,
  <Route path="/devices/:id/back-to-stock" component={DeviceBackToStock} />,
  <Route path="/devices/bulk-move" component={DeviceBulkMove} />,
  <Route path="/devices/map" component={MapDeviceList} />,
  <Route path="/sites/:id/build" component={SiteDevicePrepare} />,
  <Route path="/simcards/bulk-create" component={SimCardBulkCreate} />,
  <Route path="/devices/bulk-create" component={DeviceBulkCreate} />,
  <Route path="/product_items/bulk-create" component={ProductItemBulkCreate} />,
  <Route path="/product_items/bulk-move" component={ProductItemBulkMove} />,
  <Route path="/ips/bulk-create" component={IPBulkCreate} />,
  <Route path="/usages/reports" component={UsageReportList} />,
  <Route path="/starlink_usages/reports" component={StarlinkUsageReportList} />,
  <Route path="/invoices/export" component={InvoiceExport} />,
  <Route path="/starlink/provision" component={StarlinkProvision} />,
  <Route path="/services/:service_id/provision" component={ServiceProvision} />,
  <Route path="/services/:service_id/ship" component={ServiceShip} />,
  <Route path="/services/:service_id/deliver" component={ServiceDeliver} />,
  <Route path="/package_lines/:package_line_id/provision" component={PackageLineProvision} />,
  <Route path="/package_lines/:package_line_id/ship" component={PackageLineShip} />,
  <Route path="/package_lines/:package_line_id/deliver" component={PackageLineDeliver} />,
  <Route path="/account" component={Account} />,
  <Route exact path="/login/mfa/code" noLayout component={MFACodeForm} />,
  <Route exact path="/forgot-password" noLayout component={ForgotPasswordPage} />,
  <Route exact path="/reset_password/:token" noLayout component={ResetPasswordPage} />,
  <ExternalRedirect exact path="/freshdesk" to="https://help.venntelecom.com" />
];

const App = () => (
  <Admin
    layout={AppLayout}
    dashboard={Dashboard}
    loginPage={LoginPage}
    dataProvider={dataProvider}
    authProvider={authProvider}
    customRoutes={customRoutes}
    title="Kenobi"
    customReducers={{ theme: themeReducer }}
    disableTelemetry
  >
      <Helmet>
        <title>Venn - Kenobi</title>
      </Helmet>
      <Resource name="customers" {...customers} />
      <Resource name="simcards" {...simcards} />
      <Resource name="simpools" {...simpools} />
      <Resource name="lines" {...lines} />
      <Resource name="devices" {...devices} />
      <Resource name="wans" />
      <Resource name="providers" {...providers} />
      <Resource name="locations" />
      <Resource name="sites" {...sites} />
      <Resource name="contacts" {...contacts} />
      <Resource name="orders" />
      <Resource name="invoices" {...invoices} />
      <Resource name="products" />
      <Resource name="users" {...users} />
      <Resource name="servers" {...servers} />
      <Resource name="logs" {...logs} />
      <Resource name="records" {...records} />
      <Resource name="usages" {...usages} />
      <Resource name="device_products" {...device_products} />
      <Resource name="usages/report" />
      <Resource name="starlink_usages/report" />
      <Resource name="line_providers" {...line_providers} />
      <Resource name="data_pricings" {...data_pricings} />
      <Resource name="data_suppliers" />
      <Resource name="location_updates" {...location_updates} />
      <Resource name="service_products" {...service_products} />
      <Resource name="packages" {...packages} />
      <Resource name="services" {...services} />
      <Resource name="product_items" {...product_items} />
      <Resource name="ips" {...ips} />
      <Resource name="data_pools" {...data_pools} />
      <Resource name="addresses" {...addresses} />
      <Resource name="companies" {...companies} />
      <Resource name="speedtests" {...speedtests} />
      <Resource name="starlink_terminals" {...starlink_terminals} />
      <Resource name="starlink_service_lines" {...starlink_service_lines} />
      <Resource name="starlink_subscriptions" {...starlink_subscriptions} />
      <Resource name="starlink_accounts" {...starlink_accounts} />
      <Resource name="starlink_addresses" {...starlink_addresses} />
      <Resource name="starlink_usages" {...starlink_usages} />
      <Resource name="tasks" {...tasks} />
      <Resource name="package_lines" {...package_lines} />
      <Resource name="invoice_lines" {...invoice_lines} />
  </Admin>
);

export default App;
