import React from 'react';
import { SelectInput } from 'react-admin';


const choices = [
	{ id: 'LOW', name: 'LOW' },
	{ id: 'NORMAL', name: 'NORMAL' },
	{ id: 'HIGH', name: 'HIGH' },
	{ id: 'URGENT', name: 'URGENT' },
];

const PriorityField = ({ classes, record, source="priority", ...props }) => {
	if (!record) {
		return null;
	}

	return (
		<SelectInput choices={choices} source={source} record={record} {...props} />
	);
};

export default PriorityField;
