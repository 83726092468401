import React, { useEffect, useState } from 'react';
import { useFormState, useForm } from 'react-final-form';
import { Title, TextInput, NumberInput, AutocompleteInput, Toolbar, SaveButton, required } from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import SimpleForm from '../common/SimpleForm';

import { provisionStarlink, fetchStarlinkAccounts, fetchStarlinkTerminals, fetchStarlinkProducts } from '../../services/api';

const CustomSaveButton = ({ onClick, ...props }) => {
    const formState = useFormState();
    const form = useForm();

    const handleClick = async () => {
        if (!props.invalid) {
            const formData = new FormData();
            formData.append('account_number', formState.values.account_number);
            formData.append('product_id', formState.values.product_id);
            //formData.append('subscription_reference_id', formState.values.subscription_reference_id);
            formData.append('user_terminal_id', formState.values.user_terminal_id);
            formData.append('user_terminal_id_secondary', formState.values.user_terminal_id_secondary || '');
            formData.append('name', formState.values.name);
            formData.append('address', formState.values.address);
            formData.append('postal_code', formState.values.postal_code);
            formData.append('region_code', formState.values.region_code);
            formData.append('locality', formState.values.locality);
            formData.append('latitude', formState.values.latitude);
            formData.append('longitude', formState.values.longitude);

            const response = await provisionStarlink(formData);
            if (response.success) {
                form.change('account_number', null)
                //form.change('subscription_reference_id', null);
                form.change('product_id', null);
                form.change('user_terminal_id', null);
                form.change('user_terminal_id_secondary', null);
                form.change('name', null);
                form.change('address', null);
                form.change('postal_code', null);
                form.change('region_code', null);
                form.change('locality', null);
                form.change('latitude', null);
                form.change('longitude', null);
                form.restart();
            }

            if (response.message) {
                onClick({ message: response.message });
            }
        }
    };

    return <SaveButton {...props} handleSubmit={handleClick} handleSubmitWithRedirect={handleClick} />;
};

const CustomToolbar = ({ onSave, ...props }) => (
    <Toolbar {...props}>
        <CustomSaveButton onClick={onSave} disabled={props.invalid} />
    </Toolbar>
);

const AddressForm = ({ className, variant }) => (
    <Box my={3} className={className}>
        <Typography variant="h6">Address</Typography>
        <TextInput source="name" helperText={false} variant={variant} validate={required()} />
        <TextInput label="Full Address" source="address" helperText={false} variant={variant} validate={required()} />
        <TextInput source="region_code" helperText={false} variant={variant} validate={required()} />
        <TextInput source="postal_code" helperText={false} variant={variant} validate={required()} />
        <TextInput source="locality" helperText={false} variant={variant} validate={required()} />
        <NumberInput source="latitude" helperText={false} variant={variant} validate={required()} />
        <NumberInput source="longitude" helperText={false} variant={variant} validate={required()} />
    </Box>
);

// const AutocompleteSubscription = (props) => {
//     const [choices, setChoices] = useState()
//     const formState = useFormState();

//     useEffect(() => {
//         const fetchData = async () => {
//             const response = await fetchStarlinkSubscriptions({ account_number: formState.values.account_number, available: true });
//             if (response.results) {
//                 setChoices(response.results.map(item => ({ id: item.subscriptionReferenceId, name: `${item.description}` })));
//             }
//         };

//         fetchData();
//     }, [formState.values.account_number]);

//     return (
//         <AutocompleteInput label="Available Subscription" source="subscription_reference_id" choices={choices} helperText={false} validate={required()} {...props} />
//     );
// };

const AutocompleteTerminal = (props) => {
    const [choices, setChoices] = useState()
    const formState = useFormState();

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetchStarlinkTerminals({ account_number: formState.values.account_number, available: true });
            if (response.results) {
                setChoices(response.results.map(item => ({ id: item.userTerminalId, name: item.kitSerialNumber })));
            }
        };

        fetchData();
    }, [formState.values.account_number]);

    return (
        <AutocompleteInput label="Available Terminal" source="user_terminal_id" choices={choices} helperText={false} validate={required()} {...props} />
    );
};

// TOREFACTOR
const AutocompleteSecondaryTerminal = (props) => {
    const [choices, setChoices] = useState()
    const formState = useFormState();

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetchStarlinkTerminals({ account_number: formState.values.account_number, available: true });
            if (response.results) {
                setChoices(response.results.map(item => ({ id: item.userTerminalId, name: item.kitSerialNumber })));
            }
        };

        fetchData();
    }, [formState.values.account_number]);

    return (
        <AutocompleteInput label="Available Secondary Terminal" source="user_terminal_id_secondary" choices={choices} helperText={false} {...props} />
    );
};

const AutocompleteProduct = (props) => {
    const [choices, setChoices] = useState()
    const formState = useFormState();

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetchStarlinkProducts({ account_number: formState.values.account_number });
            if (response.results) {
                setChoices(response.results.map(item => ({ id: item, name: `${item}` })));
            }
        };

        fetchData();
    }, [formState.values.account_number]);

    return (
        <AutocompleteInput label="Available Products" source="product_id" choices={choices} helperText={false} validate={required()} {...props} />
    );
};

const StarlinkProvision = () => {
    const [accounts, setAccounts] = useState([]);
    const [message, setMessage] = useState();

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetchStarlinkAccounts();
            if (response.results) {
                setAccounts(response.results.map(item => ({ id: item.accountNumber, name: item.accountName })));
            }
        };

        fetchData();
    }, []);

    const renderSummary = () => {
        if (!message) {
            return null;
        }

        return (
            <Box mt={1}>
                <Card>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>Summary</Typography>
                        <Typography>{message}</Typography>
                    </CardContent>
                </Card>
            </Box>
        );
    };

    const handleSave = async (result) => {
        if (result?.message) {
            setMessage(result.message);
        }
    };

    return (
        <>
            <Card>
                <Title title="Starlink provision" />
                <SimpleForm toolbar={<CustomToolbar onSave={handleSave} />}>
                    <AutocompleteInput label="Account" source="account_number" choices={accounts} helperText={false} validate={required()} />
                    <AutocompleteProduct />
                    <AutocompleteTerminal />
                    <AutocompleteSecondaryTerminal />
                    <AddressForm />
                </SimpleForm>
            </Card>

            {renderSummary()}
        </>
    );
};

export default StarlinkProvision;