import React from 'react';
import { Button, Toolbar as RAToolbar } from 'react-admin';
import IconCancel from '@material-ui/icons/Cancel';
import { withStyles } from '@material-ui/core';


const styles = {
	toolbar: {
		background: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end'
	},
	btnClose: {
		marginLeft: '15px'
	}
};

const Toolbar = withStyles(styles)(({ classes, onClose, onSave, saveButton, ...props }) => (
	<RAToolbar className={classes.toolbar} {...props}>
		{saveButton}

		{/* Fragment used to fix issue with toolbar */}
		<>
			<Button label="ra.action.cancel" onClick={onClose} className={classes.btnClose}>
				<IconCancel />
			</Button>
		</>
	</RAToolbar>
));

export default Toolbar;