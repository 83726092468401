import React from 'react';
import { TextInput, AutocompleteInput, ReferenceInput } from 'react-admin';

import Create from '../common/Create';
import SimpleForm from '../common/SimpleForm';

const customerOptionText = choice => {
	return choice.name ? choice.name : '';
}

const ContactCreate = props => (
	<Create {...props}>
		<SimpleForm redirect="list">
			<ReferenceInput label="Customer" source="customer_id" reference="customers" sort={{ field: 'name', order: 'ASC' }}>
				<AutocompleteInput optionText={customerOptionText} />
			</ReferenceInput>
			<TextInput source="name" />
			<TextInput source="job_title" label="Job title" />
			<TextInput source="email" />
			<TextInput source="phone" />
			<TextInput source="contact_type" />
			<TextInput source="department" />
			<TextInput source="address"/>
			<TextInput source="lang" />
			<TextInput source="timezone" />
			<TextInput source="description" label="Notes" options={{ multiline: true }} rows="4" />
		</SimpleForm>
	</Create>
);

export default ContactCreate;