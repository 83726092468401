import React from 'react';
import { TextInput, ReferenceInput, AutocompleteInput } from 'react-admin';

import Create from '../common/Create';
import SimpleForm from '../common/SimpleForm';
import VlanInput from './IPVlan';
import FireWallInput from './IPFirewall';
import SubnetTypeInput from './IPSubnetType';

const deviceOptionText = choice => `${choice.serial ? choice.serial : ''} / ${choice.ic_group_name ? choice.ic_group_name : ''} / ${choice.name ? choice.name : ''}`;

const IPCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <TextInput source="address" />
            <TextInput source="subnet" disabled />         
            <ReferenceInput label="Device" source="device_id" reference="devices" allowEmpty>
                <AutocompleteInput optionText={deviceOptionText} />
            </ReferenceInput>
            <TextInput source="unmanaged_device_description" />
            <TextInput source="notes" options={{ multiline: true }} rows="10" />
            <SubnetTypeInput />
            <TextInput source="subnet_notes" options={{ multiline: true }} rows="4" />
            <TextInput source="gateway" label="Gateway ex: 10.10.10.1"/>
            <VlanInput />
			<FireWallInput />
        </SimpleForm>
    </Create>
);

export default IPCreate;
