import AccountBoxIcon from '@material-ui/icons/AccountBox';

import StarlinkAccountList from './StarlinkAccountList';
import StarlinkAccountShow from './StarlinkAccountShow';

export default {
	list: StarlinkAccountList,
	show: StarlinkAccountShow,
	icon: AccountBoxIcon,
	title: "Starlink accounts"
};
