import React, { useState, useEffect } from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import IconButton from '../common/IconButton';
import { getConfigBackups } from '../../services/api';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';


/*
    Need to be refactored in react-admin style
    I used basic materail ui to go quick and stay with basic widgets
*/
const DeviceConfigBackups = ({ record }) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        async function fetchData() {
            const results = await getConfigBackups(record.id);
            console.debug(results.response);
            setData(results.response);
        }

        fetchData();
    }, [record.id]);

    return (
        <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell align="right">Size</TableCell>
                        <TableCell align="right">URL</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.reverse().map((row) => (
                        <TableRow key={row.id}>
                            <TableCell>{row.date} {row.time}</TableCell>
                            <TableCell align="right">{row.size}</TableCell>
                            <TableCell align="right"> <IconButton icon={<GetAppIcon />} component="a" href={row.url}>Download</IconButton></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default DeviceConfigBackups;
