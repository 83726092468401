import React from 'react';
import Badge from '@material-ui/core/Badge';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	root: {
		padding: '4px'
	},
	badge: {
		color: props => props.backgroundColor ? '#FFF' : '',
		backgroundColor: (props) => props.backgroundColor ? props.backgroundColor : '',
		position: 'relative',
		transform: 'none'
	}
});

const CustomBadge = ({ color, ...props }) => {
	let backgroundColor;
	if(!['default', 'primary', 'secondary', 'error'].includes(color)) {
		backgroundColor = color;
		color = 'default';
	}
	const classes = useStyles({ backgroundColor });
	return <Badge color={color} classes={classes} {...props} />;
};

export default CustomBadge;