import React from 'react';
// import BulkSendButton from './BulkSendButton';

// Teporary remove the BulkSendButton

const InvoiceBulkActions = (props) => (
    <>
    </>
);

export default InvoiceBulkActions;