import React, { useState, useEffect } from 'react';
import { TextField } from 'react-admin';

import { getStatusSimCard } from '../../services/api';

const StatusField = ({ record }) => {
	const [status, setStatus] = useState();

	useEffect(() => {
		const getStatus = async () => {
			const response = await getStatusSimCard(record.id);
			setStatus(response.status);
		};

		if (record) {
			getStatus();
		}
		else {
			setStatus('');
		}
	}, [record]);

	if (!status) {
		return null;
	}

	return (
		<TextField record={{ status }} source="status" />
	);
};

StatusField.defaultProps = {
	addLabel: true
};

export default StatusField;