import React from 'react';
import { ReferenceInput, AutocompleteInput } from 'react-admin';

const optionText = choice => choice && `${choice.serial ? `${choice.serial} /` : ''} ${choice.ic_group_name ? `${choice.ic_group_name} /` : ''} ${choice.name ? choice.name : ''}`;

const DeviceReferenceInput = ({ classes, record, source, icon, ...props }) => (
	<ReferenceInput label="Device" source="device_id" reference="devices" {...props}>
		<AutocompleteInput optionText={optionText} />
	</ReferenceInput>
);

export default DeviceReferenceInput;
