import React from 'react';
import { TabbedShowLayout, Tab, ReferenceField, TextField, FunctionField } from 'react-admin';
import { Helmet } from 'react-helmet';

import Show, { ShowActions } from '../common/Show';
import DateField from '../common/fields/DateField';
import BooleanField from '../common/fields/BooleanField';
import HumanDateField from '../common/fields/HumanDateField';
import IPActionsButtons from './IPActionsButtons';


const IPTitle = ({ record }) => (
	<>
		<Helmet>
			<title>{`KB - IP[${record.address}]`}</title>
		</Helmet>
		<span>{record ? `${record.address} ` : ''}</span>
	</>
);

const IPActions = (props) => (
	<ShowActions {...props}>
		<IPActionsButtons record={props.data} />
	</ShowActions>
);

const CustomerField = (props) => (
	<ReferenceField label="Customer" source="device_id" resource="devices" reference="devices" allowEmpty link={false} {...props} basePath="/customers">
		<ReferenceField source="customer_id" resource="customers" reference="customers" allowEmpty link="show">
			<TextField source="name" />
		</ReferenceField>
	</ReferenceField>
);

const Tabs = props => (
	<TabbedShowLayout {...props}>
		<Tab label="Summary">
			<TextField label="Id" source="id" />
			<TextField source="address" />
			<BooleanField label="Used" source="used" />
			<ReferenceField label="Device" source="device_id" reference="devices" link="show" allowEmpty>
				<FunctionField render={record => `${record.name} / ${record.serial}`} />
			</ReferenceField>
			<TextField source="unmanaged_device_description" />
			<CustomerField label="Customer" addLabel />
			<TextField source="subnet" />
			<TextField source="gateway" />
			<TextField source="subnet_type" />
			<TextField source="firewall" />
			<TextField source="vlan_id" />
			<HumanDateField label="Last ping at" addLabel source="last_ping_at" />
			<FunctionField label="Last ping result" render={record => (record.last_ping_result?.avg_rtt) ? `${record.last_ping_result.avg_rtt} ms` : ''} />
			<DateField label="Created at" source="created_at" />
			<DateField label="Updated at" source="updated_at" />
			<TextField source="notes" />
		</Tab>
	</TabbedShowLayout>
);

const IPShow = (props) => (
	<Show title={<IPTitle />} actions={<IPActions {...props} />}  {...props}>
		<Tabs />
	</Show>
);

export default IPShow;