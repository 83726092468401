import React from 'react';
import { SelectInput } from 'react-admin';


const choices = [
	{ id: 'NEW', name: 'NEW' },
	{ id: 'IN PROGRESS', name: 'IN PROGRESS' },
	{ id: 'ON HOLD', name: 'ON HOLD' },
	{ id: 'DONE', name: 'DONE' },
	{ id: 'CANCELED', name: 'CANCELED' },
];

const StatusField = ({ classes, record, source="status", ...props }) => {
	if (!record) {
		return null;
	}

	return (
		<SelectInput choices={choices} source={source} record={record} {...props} />
	);
};

export default StatusField;
