import React, { useEffect, useState } from 'react';
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	ResponsiveContainer
} from 'recharts';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import GraphTooltip from '../common/GraphTooltip';

import { getUsageStats } from '../../services/api';

const CustomToolTip = props => {
	const { active, payload, label } = props;
	if (!active || !payload) {
		return null;
	}
	return (
		<GraphTooltip>
			<p>
				<strong>{moment(label).format('DD-MM-YY')}</strong>
			</p>
			{payload.map((item, i) => (
				<p key={i}>Usage: <strong>{item.value} GB</strong></p>
			))}
		</GraphTooltip>
	);
};

const UsageGraph = ({ title, small, record, resource, freq, onClickBar, ...props }) => {
	const [data, setData] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			let options;
			switch (resource) {
				case 'simcards':
					options = { ...props.options, sim_id: record.id, freq: freq }
					break;
				case 'devices':
					options = { ...props.options, device_id: record.id, freq: freq  };
					break;
				case 'sites':
					options = { ...props.options, site_id: record.id, freq: freq };
					break;
				default:
					break;
			}

			if (options) {
				const results = await getUsageStats(options);

				if (results) {
					setData(results.data);
				}
			}
		}

		fetchData();
	}, [record, resource, freq, props.options]);

	const handleClickBar = (data) => {
		if (onClickBar) {
			onClickBar(data);
		}
	};

	if (!data || data.length === 0) {
		return null;
	}

	return (
		<Box mt={3}>
			{title && <Typography variant="h6">{title}</Typography>}
			<ResponsiveContainer width="100%" height={small ? 200 : 450}>
				<BarChart
					data={data}
					margin={{
						top: 50, right: 30, left: 20, bottom: 10,
					}}
				>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey="date" />
					<YAxis dataKey="value" />
					<Tooltip content={<CustomToolTip />} />
					<Bar name="Data Usage" dataKey="value" fill="#3A8B7E" onClick={handleClickBar} />
				</BarChart>
			</ResponsiveContainer>
		</Box>
	);
};

UsageGraph.defaultProps = {
	options: {}
};

export default UsageGraph;
