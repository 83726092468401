import Computer from '@material-ui/icons/Computer';

import ICServerList from './ICServerList';
import ICServerEdit from './ICServerEdit';
import ICServerShow from './ICServerShow';
import ICServerCreate from './ICServerCreate';

export default {
	list: ICServerList,
	create: ICServerCreate,
	show: ICServerShow,
	edit: ICServerEdit,
	icon: Computer,
};
